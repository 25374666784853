import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Alert from '../components/core/Alert';
import { AlertTypes } from '../constants';
import ConfirmationDialog from '../components/ConfirmationDialog';

class NotificationDispatcher {
  constructor() {
    this._lastID = 1;
    this._callbacks = new Map();
    // Modal alerts should persist across pages until they're closed, so we
    // preserve them here using the globalHandler defined below
    this.activeModal = {};
  }

  register(callback) {
    let id = this._lastID++;
    this._callbacks.set(id, callback);
    return id;
  }

  unregister(id) {
    this._callbacks.delete(id);
  }

  dispatch(notification) {
    // dispatch to only the most recently registered notification area...
    const callbacks = [...this._callbacks.values()];
    const newestCallback = callbacks.pop();
    newestCallback(notification);
    // ...and the global handler that keeps alerts around across pages
    this.globalHandler(notification);
  }

  globalHandler(notification) {
    if (notification.type === 'MODAL') {
      this.activeModal = notification;
    } else {
      this.activeModal = {};
    }
  }
}

const DISPATCHER = new NotificationDispatcher();

export function NotificationArea({ className }) {
  const [notification, setNotification] = useState(DISPATCHER.activeModal);
  useLayoutEffect(() => {
    const id = DISPATCHER.register(setNotification);
    return () => {
      DISPATCHER.unregister(id);
    };
  }, []);
  return (
    <div className={className}>
      {notification.type === 'ALERT' ? (
        <AlertNotification {...notification.params} />
      ) : (
        notification.type === 'MODAL' && (
          <ModalNotification {...notification.params} />
        )
      )}
    </div>
  );
}

NotificationArea.propTypes = {
  className: PropTypes.string
};

function AlertNotification(params) {
  return (
    <Alert
      css={css`
        display: block;
      `}
      onHide={clear}
      {...params}
    />
  );
}

function ModalNotification({ onConfirm, message }) {
  return (
    <ConfirmationDialog showing onConfirm={onConfirm}>
      <div
        css={css`
          margin: 1rem;
          & > * {
            margin-bottom: 2rem;
          }
        `}
      >
        <p>{message}</p>
      </div>
    </ConfirmationDialog>
  );
}

export function showError(message) {
  DISPATCHER.dispatch({
    type: 'ALERT',
    params: {
      type: AlertTypes.ERROR,
      cancellable: true,
      children: message
    }
  });
}

export function showSuccess(message) {
  DISPATCHER.dispatch({
    type: 'ALERT',
    params: {
      type: AlertTypes.SUCCESS,
      disappearing: true,
      children: message
    }
  });
}

export function showAlertModal(message, onConfirm = clear) {
  DISPATCHER.dispatch({
    type: 'MODAL',
    params: { message, onConfirm }
  });
}

export function clear() {
  DISPATCHER.dispatch({});
}
