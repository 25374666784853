import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';

const WithText = ({ children }) => {
  if (!children) {
    return null;
  }

  return <Typography>{parse(children)}</Typography>;
};

WithText.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WithText };
