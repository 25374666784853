import { useState, useEffect } from 'react';
import { suggestedQuestions } from '../../../../utils/api';
import {
  useSelectSendMessage,
  useSelectActiveChat,
} from '../../../Chat/store/selectors';
import { CHAT_MESSAGE_TYPE } from '../../../../types';

const useAssistantShortcuts = () => {
  const [shortcuts, setShortcuts] = useState([]);
  const {
    isBotReplying,
    isInteractionFromMessageRequired,
    isAwaitingForFeedback,
  } = useSelectActiveChat();
  const sendMessage = useSelectSendMessage();

  const selectionDisabled =
    isBotReplying || isInteractionFromMessageRequired || isAwaitingForFeedback;
  const onSelectShortcut = (shortcut) =>
    sendMessage(shortcut, CHAT_MESSAGE_TYPE.SUGGESTED_QUESTION);

  useEffect(() => {
    const fetchData = async () => {
      const data = await suggestedQuestions.getAll();
      setShortcuts(data);
    };

    fetchData();
  }, []);

  return [shortcuts, onSelectShortcut, selectionDisabled];
};

export default useAssistantShortcuts;
