import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { MessageContent } from './styled';
import RatingWithFeedback from '../../../../../components/organisms/RatingWithFeedback';

const WithTextAndRating = ({ onClick, children }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      <MessageContent component="div">{parse(children)}</MessageContent>
      <RatingWithFeedback onSubmit={onClick} />
    </>
  );
};

WithTextAndRating.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export { WithTextAndRating };
