import { css } from '@emotion/react';
import { capitalize, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SentimentDocText from '../documents/SentimentDocText';
import TextList from '../components/TextList';
import { Concept } from '../classes/Concepts';
import { StoreContext } from '../StoreContext';
import { Button } from '../components/core/Button';
import { notifier } from '../actions';
import Spinner from '../components/core/Spinner';
import { useStableMemo } from '../utils/hooks';
import { Icon, IconTypes } from '../components/icons';

const ICON_EMOTIONS = {
  positive: 'happy',
  neutral: 'neutral',
  negative: 'sad'
};

const SENTIMENT_PRIORITY = {
  negative: 1,
  positive: 2,
  neutral: 3
};

export default function SentimentExamples({ concept, downloadExport }) {
  const sortedExampleDocs = sortBy(concept.exampleDocuments, exampleOrder);
  return (
    <div
      css={css`
        padding: 1rem 0.5rem;
      `}
    >
      {sortedExampleDocs.map(doc => (
        <ExampleDocument doc={doc} key={exampleKey(doc)} />
      ))}
      <DownloadButton downloadExport={downloadExport} />
    </div>
  );
}

SentimentExamples.propTypes = {
  concept: PropTypes.instanceOf(Concept).isRequired,
  downloadExport: PropTypes.func.isRequired
};

function exampleKey(doc) {
  const { sentiment, termIds } = doc.exampleDetails;
  return `${sentiment}-${termIds.join('-')}`;
}

function exampleOrder(doc) {
  const { sentiment, texts } = doc.exampleDetails;
  return [SENTIMENT_PRIORITY[sentiment], texts.map(t => t.toLowerCase())];
}

function ExampleDocument({ doc }) {
  const { projectLanguage } = React.useContext(StoreContext);
  const { sentiment, texts, termIds } = doc.exampleDetails;
  const emotion = ICON_EMOTIONS[sentiment];
  // Define a new Concept, used to highlight JUST the terms whose sentiment
  // is being demonstrated by this example.
  const concept = useStableMemo(() => {
    return Concept.fromJSON({ texts, exactTermIds: termIds });
  }, [texts, termIds]);
  const textsForHeader = sortBy(texts, text => text.toLowerCase()).map(
    text => `“${text}”`
  );
  return (
    <div>
      <div>
        <Icon type={IconTypes.FACE} emotion={emotion} />
        <strong
          css={css`
            margin-left: 0.5rem;
          `}
        >
          {capitalize(sentiment)} for <TextList items={textsForHeader} />
        </strong>
      </div>
      <div
        css={css`
          margin: 0 1.5rem;
          max-width: 50rem;
        `}
      >
        <SentimentDocText
          doc={doc}
          language={projectLanguage}
          selection={concept}
        />
      </div>
    </div>
  );
}

function DownloadButton({ downloadExport }) {
  const [downloading, setDownloading] = useState(false);

  function handleClick() {
    setDownloading(true);
    downloadExport()
      .catch(() => notifier.error('Sentiment download failed'))
      .finally(() => setDownloading(false));
  }

  return (
    <Button
      flavor="subtle"
      css={css`
        margin-left: -0.5rem;
      `}
      disabled={downloading}
      onClick={handleClick}
      hideOnPrint={true}
    >
      {downloading ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
      Download more matches
    </Button>
  );
}
