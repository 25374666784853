import { css } from '@emotion/react';

import { Colors } from '../styles';
import { MetadataTypes } from '../constants';

export const selectedHeaderStyles = css`
  color: ${Colors.blue4};
`;

export const skippedHeaderStyles = css`
  font-weight: normal;
  font-style: italic;

  /* This selector is used to not target the input for the line-through, but 
   * it's also needed to bump up the specificity. */
  span:not(input) {
    text-decoration: line-through;
    color: ${Colors.red3};

    /* When the header name is blank, a "No header" message is rendered in gray
     * inside an em, so we need to target it specifically. */
    em {
      color: ${Colors.red3};
    }
  }

  input {
    color: ${Colors.red3};
  }
`;

export function shouldUseSkippedHeaderStyles({ type } = {}, stats) {
  if (type === 'skip') {
    return true;
  }
  if (Object.values(MetadataTypes).includes(type)) {
    return stats?.uniqueValues.length === 0;
  }
  return false;
}
