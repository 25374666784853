import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button } from '../../../components/core/Button';
import { StoreContext } from '../../../StoreContext';

export const AddCollocateTextEntry = ({ placeholder, submitSelection }) => {
  const [inputText, setInputText] = useState('');
  const [showHelper, setShowHelper] = useState(false);
  const {project, collocationNumbers } = useContext(StoreContext);
  const editCount = project.next_build_collocation || collocationNumbers

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        const words = inputText.trim().split(/\s+/);
        if (words.length >= 2 && words.length <= editCount) {
          submitSelection(inputText.trim());
          setInputText('');
          setShowHelper(false);
        } else {
          setShowHelper(true);
        }
      }}
    >
      <TextInput
        aria-label="Concept to collocate"
        value={inputText}
        placeholder={placeholder}
        onChange={event => {
          const value = event.target.value;
          if (value.trim().split(/\s+/).length <= editCount) {
            setInputText(value);
            setShowHelper(false);
          } else {
            setShowHelper(true);
          }
        }}
        onKeyDown={event => {
          if (event.key === 'Escape') {
            event.stopPropagation();
            setShowHelper(false);
          }
        }}
      />
      <Button type="submit">Add</Button>
      {showHelper && (
        <HelperMessage>
          {editCount > 2
            ? `Please enter between 2 and ${editCount} words as a collocate.`
            : `Please enter exactly 2 words as a collocate.`}
        </HelperMessage>
      )}
    </Form>
  );
};

AddCollocateTextEntry.propTypes = {
  placeholder: PropTypes.string,
  submitSelection: PropTypes.func.isRequired
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  flex-grow: 1;

  input {
    flex-grow: 1;
    margin-right: 1rem;
    height: 2rem;
  }
`;

const TextInput = styled.input`
  height: 2rem;
  margin: 0;
  width: 100%;
`;

const HelperMessage = styled.span`
  color: #d32f2f;
  font-size: 0.875rem;
`;
