import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ConversationContainer = styled(Box)`
  margin: 0;
  padding: 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
`;
