import React from 'react';

import UserManual from './components/UserManual';
import SuggestedQuestions from './components/SuggestedQuestions';
import { css } from '@emotion/react';
import ExportMessages from './components/ExportMessages/ExportMessages';

const AssistantInfo = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
      `}
    >
      <UserManual />
      <ExportMessages />
      <SuggestedQuestions />
    </div>
  );
};

export default AssistantInfo;
