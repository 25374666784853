import React from 'react';
import { css } from '@emotion/react';

import { Card } from '../../components/Card';
import { Title } from '../components';
import DeleteDocumentSection from './deleteDocumentSection/DeleteDocumentSection';

export const DocumentManagementSection = () => {
  return (
    <>
      <Card data-test-id="document-management-section">
        <DocumentManagementTitle />
        <Card.Separator />
        <DeleteDocumentSection/>
      </Card>
    </>
  );
};

const DocumentManagementTitle = () => (
  <>
    <Card.Section
      css={css`
          display: flex;
          flex-direction: row;
      `}
    >
      <Title>Document Management</Title>
    </Card.Section>
  </>
);