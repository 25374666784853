import React from 'react';
import Typography from '@mui/material/Typography';

import { ChatStatusInfoBuildingContainer } from './styled';
import { ToolCard } from '../../../../../../../../highlights/Cards';

const ChatStatusInfoBuilding = ({ statusDetails }) => {
  return (
    <ChatStatusInfoBuildingContainer>
      <Typography variant="h6" mb="0.5rem">
        Data is being generated for Helios. Please wait until the process is
        completed
      </Typography>
      {statusDetails ? (
        <>
          <progress
            value={statusDetails.progress && statusDetails.progress / 100}
          />
          <Typography align="left">{statusDetails?.details}</Typography>
        </>
      ) : (
        <ToolCard.VisualizationPlaceholder />
      )}
    </ChatStatusInfoBuildingContainer>
  );
};

export default ChatStatusInfoBuilding;
