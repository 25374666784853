import _ from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  getSharedViews,
  loadSharedConceptLists,
  updateActiveListName
} from '../../actions';
import {
  deleteSharedConceptList,
  renameActiveConceptList,
  restoreSharedConceptList
} from '../../utils/ApiUtilsV5';
import { Button } from '../../components/core/Button';
import { Icon, IconTypes } from '../../components/icons';
import Tooltip from '../../components/core/Tooltip';
import { naturalSortByName } from '../../utils/NaturalSort';
import { StoreContext } from '../../StoreContext';
import { useSharedListWithActiveListName } from '../hooks';
import { useDispatchUndoBanner } from '../../UndoBanner';

export default function DeleteSharedListButton({ alertController }) {
  const { projectId } = useParams();
  const { sharedViews, activeConceptListName } = useContext(StoreContext);
  const matchingList = useSharedListWithActiveListName();
  const dispatchUndoBanner = useDispatchUndoBanner();

  function refetchConceptLists() {
    loadSharedConceptLists(projectId);
  }

  function deleteList() {
    getSharedViews(projectId).then(sharedViews => {
      const sharedViewsUsingConceptList = getSharedViewsUsingConceptList(
        matchingList,
        sharedViews
      );

      if (sharedViewsUsingConceptList.length > 0) {
        alertController.showWarning('Unable to delete concept list');
      } else {
        deleteSharedConceptList(projectId)
          .then(() => {
            refetchConceptLists();
            updateActiveListName(null);
            alertController.showSuccess('Success!');
            dispatchUndoBanner({
              message: `Deleted "${matchingList.name}"`,
              onUndo: () => {
                return restoreSharedConceptList(
                  projectId,
                  matchingList.name,
                  matchingList.concepts.map(c => _.omit(c, 'shared_concept_id'))
                )
                  .then(refetchConceptLists)
                  .then(() =>
                    renameActiveConceptList(projectId, matchingList.name)
                  )
                  .then(() => updateActiveListName(matchingList.name));
              },
              trackingItem: 'undo-delete-concept-list'
            });
          })
          .catch(() => {
            alertController.showWarning('Unable to delete concept list');
          });
      }
    });
  }

  const sharedViewsUsingConceptList = getSharedViewsUsingConceptList(
    matchingList,
    sharedViews
  );

  // If the list is used by any shared views, we don't want to allow the user
  // to delete it. If there is no saved list that corresponds with the active
  // list, then there is nothing to delete.
  const unableToDelete =
    sharedViewsUsingConceptList.length > 0 || activeConceptListName === null;

  return (
    <Tooltip
      // Disable the tooltip when the button is disabled to avoid a bug where
      // the tooltip won't disappear as long as the button is disabled.
      visible={!unableToDelete ? false : undefined}
      position="right"
      tooltipWidth="32rem"
      contentHoverable
      anchor={
        <Button
          palette="red"
          onClick={deleteList}
          disabled={unableToDelete}
          css={css`
            /* Allows tooltip to close when button is disabled*/
            &:disabled {
              pointer-events: none;
            }
          `}
        >
          <Icon type={IconTypes.TRASH} />
          Delete
        </Button>
      }
    >
      <PaddedMessageContainer>
        {activeConceptListName === null ? (
          <p>
            You cannot delete an unsaved concept list. If your intention is to
            clear out your <em>active concepts</em>, check <em>select all</em>{' '}
            and hit <em>remove</em>.
          </p>
        ) : (
          <ListIsUsedBySharedViewsMessage
            sharedViewsUsingConceptList={sharedViewsUsingConceptList}
          />
        )}
      </PaddedMessageContainer>
    </Tooltip>
  );
}

function getSharedViewsUsingConceptList(conceptList, sharedViews) {
  if (!conceptList) {
    return [];
  }

  return _.filter(sharedViews, {
    concept_list_id: conceptList.concept_list_id
  }).sort(naturalSortByName);
}

function ListIsUsedBySharedViewsMessage({ sharedViewsUsingConceptList }) {
  return (
    <>
      <p>
        This list cannot be deleted because it is used by the following{' '}
        {sharedViewsUsingConceptList.length === 1 ? 'view' : 'views'}:
      </p>
      <ul
        css={css`
          max-height: 30vh;
          overflow: auto;
        `}
      >
        {sharedViewsUsingConceptList.map(view => (
          <li key={view.shared_view_id}>{view.name}</li>
        ))}
      </ul>
    </>
  );
}

const PaddedMessageContainer = styled.div`
  padding: 0.5rem;
`;
