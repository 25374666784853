import React, { useState } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { MostPrevalentConceptsCard } from './highlights/MostPrevalentConcepts';
import { WhatPeopleFeelStronglyAboutCard } from './highlights/WhatPeopleFeelStronglyAbout';
import { ConversationClustersCard } from './highlights/ConversationClusters';
import { IssuesAffectingScoreCard } from './highlights/IssuesAffectingScore';
import { ProjectDetail } from './components/ProjectDetail';
import { FlexibleGrid } from './components/core/Layout';
import { Colors } from './styles';
import { ProjectTitle } from './highlights/ProjectTitle';
import { trademark } from './utils/trademark';
import { ToolCard } from './highlights/Cards';
import { chatbotGenerate } from './utils/ApiUtilsV5';
import { Button } from './components/core/Button';
import { FeatureFlagsContext } from './FeatureFlagsContext';

const summaryPrompt = 'summarize this project.';

function HeliosSummary({ projectId }) {
  const [fetched, setFetched] = useState(false);
  const [content, setContent] = useState('Fetching summary...');

  function startFetch() {
    setFetched(true);
    console.log('fetching summary from helios.');

    chatbotGenerate(projectId, summaryPrompt).then(response => {
      console.log('response');
      console.log(response);

      setContent(response.text);
    });
  }

  return (
    <ToolCard
      header="✨ Helios Summary ✨"
      visualization={
        fetched ? (
          <div>{content}</div>
        ) : (
          <Button onClick={startFetch}> Generate Summary </Button>
        )
      }
      footer={ <div></div>}
    />
  );
}

export function Highlights({ projectId, workspaceId }) {
  const featureFlags = React.useContext(FeatureFlagsContext);

  return (
    <div
      css={css`
        box-sizing: border-box;
        height: 100%;
        padding: 1rem;
        background: ${Colors.gray0};
        overflow-y: auto;

        > * {
          margin-top: 1rem;
        }
      `}
    >
      <ProjectTitle />
      <FlexibleGrid minColumnWidth="25rem" gap="medium">
        <MostPrevalentConceptsCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <ConversationClustersCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <IssuesAffectingScoreCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <WhatPeopleFeelStronglyAboutCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        {featureFlags.helios_features && (
          <HeliosSummary projectId={projectId} />
        )}
      </FlexibleGrid>
      <footer
        css={css`
          text-align: center;
        `}
      >
        <ProjectDetail />
        {trademark}
      </footer>
    </div>
  );
}

Highlights.propTypes = {
  projectId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired
};
