import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { ZIndexes } from '../../styles';
import { TooltipBox, TooltipPointer } from './Tooltip';
import { useBoundingClientRect } from '../../utils/hooks';

/**
 * @typedef {Object} PopoverProps
 * Additional props are passed through to the container element.
 *
 * @property {JSX.Element} children - Content of popover.
 * @property {any} buttonRef - Ref to button which controls the popover.
 * @property {[string]} width - Width of Popover. Defaults to "max-content".
 */

/**
 * @param {PopoverProps} props
 * @param {any} ref
 */
export const Popover = forwardRef(function (
  { children, buttonRef, width = 'max-content', ...props },
  ref
) {
  const clientRect = useBoundingClientRect(buttonRef);

  return createPortal(
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: row;
        position: fixed;
        top: calc(${clientRect.top}px - 0.5rem);
        left: calc(${clientRect.right}px + 0.5rem);
        box-sizing: border-box;
        z-index: ${ZIndexes.flyout};
        /* Prevent popover from getting to within 0.5rem of the right and bottom
         * of the screen. */
        max-height: calc(100vh - ${clientRect.top}px - 1rem);
        max-width: calc(100vw - ${clientRect.right}px - 1rem);
      `}
      tabIndex={0}
      {...props}
    >
      <TooltipPointer
        position="right"
        css={css`
          /* Center the pointer in the middle of the button */
          position: relative;
          top: calc(${clientRect.height / 2}px - 0.25rem);
        `}
      />
      <TooltipBox
        css={css`
          width: ${width};
        `}
      >
        {children}
      </TooltipBox>
    </div>,
    document.body
  );
});

Popover.propTypes = {
  /** Content of the popover. */
  children: PropTypes.node.isRequired,
  /** Ref to button that opens/closes the Popover. The popover will open to the
   *  right of it. */
  buttonRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  /** Width of the Popover. Defaults to trying to fit all of its content. */
  width: PropTypes.string
};
