import React from 'react';
import { css } from '@emotion/react';

import { Button } from '../../../components/core/Button';

const TestCategory = ({ openTestCategory }) => {
  return (
    <div
      css={css`
        position: fixed;
        bottom: 20px;
        right: 20px;
      `}
    >
      <Button onClick={openTestCategory}>Test data stream</Button>
    </div>
  );
};

export default TestCategory;
