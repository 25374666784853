import React, { useCallback, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { uniqueId } from 'lodash';
import { css } from '@emotion/react';

import { RoutePatterns } from '../../../constants';
import {
  createProject,
  createReviewsTaskDashboard,
  deleteProject,
  removeProjectTask
} from '../../../utils/ApiUtilsV5';
import StreamSourceLayout from '../StreamSourceLayout';
import { OptionsPage } from '../OptionsPage';
import { Button } from '../../../components/core/Button';
import TestCategoryUrlImportModal from './TestCategoryUrlImportModal';
import TestCategoryInputDataForm from './TestCategoryInputDataForm';
import { TokenModal } from '../TokenModal';
import buildRoutePath from '../../../utils/buildRoutePath';

export const TestCategoryPage = ({
  projectData,
  onAlert,
  inputs,
  setInputs,
  initialInputs,
  repeat,
  repeatInterval,
  setRepeat,
  setRepeatInterval,
  setProjectId,
  projectTasks
}) => {
  const streamDataRoute = projectData?.projectId
    ? buildRoutePath(RoutePatterns.UPDATE_PROJECT_STREAM_DATA, {
        workspaceId: projectData.workspaceId,
        projectId: projectData.projectId
      })
    : RoutePatterns.UPLOAD_STREAM_DATA;

  const INPUT_URLS_PATHNAME = `${streamDataRoute}/select/test/input-urls`;
  const SELECT_INTERVAL_PATHNAME = `${streamDataRoute}/select/test/select-interval`;

  const history = useHistory();
  const location = useLocation();
  const successHandler = message => {
    onAlert({
      type: 'success',
      message
    });
    setTimeout(() => {
      window.location.replace(window.location.origin);
    }, 1000);
  };

  const errorHandler = message => {
    onAlert({
      type: 'error',
      message
    });
  };

  const [step, setStep] = useState(1);

  const [projectDataErrors, setProjectDataErrors] = useState([]);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [isImportUrlsOpen, setIsImportUrlsOpen] = useState(false);
  const [tokenModalIsOpen, setTokenModalIsOpen] = useState(false);

  const inputChangeHandler = event => {
    const newInputs = [...inputs];
    const existedInputIndex = newInputs.findIndex(
      input => input.name === event.target.name
    );
    newInputs[existedInputIndex] = {
      ...newInputs[existedInputIndex],
      value: event.target.value
    };
    setInputs(newInputs);
  };

  const addInputHandler = () => {
    setInputs(prevState => [
      ...prevState,
      { ...initialInputs[0], name: `url${uniqueId()}` }
    ]);
  };

  const removeInputHandler = name => {
    setInputs(prevState => prevState.filter(input => input.name !== name));
  };

  const removeAllInputsHandler = () => {
    setInputs(initialInputs);
  };

  const checkProjectData = useCallback(() => {
    setProjectDataErrors([]);
    if (!projectData.language) {
      setProjectDataErrors(prevState => [
        ...prevState,
        "Please, select the dataset's language to continue"
      ]);
    }
    if (!projectData.workspaceId) {
      setProjectDataErrors(prevState => [
        ...prevState,
        'Please, select the workspace to continue'
      ]);
    }
  }, [projectData.language, projectData.workspaceId]);

  const openImportHandler = () => {
    setIsImportUrlsOpen(true);
  };
  const closeImportHandler = () => {
    setIsImportUrlsOpen(false);
  };

  const validateUrl = url => {
    return url;
  };

  const validateInputs = () => {
    const validatedInputs = inputs.map(input => {
      if (!validateUrl(input.value)) {
        return { ...input, error: 'Please provide a valid URL.' };
      }
      return { ...input, error: '' };
    });
    setInputs(validatedInputs);
    return !validatedInputs.filter(input => input.error).length;
  };

  useEffect(() => {
    checkProjectData();
  }, [checkProjectData]);

  let existingProjectId = projectData.projectId;

  const submitTestTask = async (token, shouldNotify, skipSentiment) => {
    setRequestInProcess(true);

    try {
      if (!existingProjectId) {
        const createdProject = await createProject(
          projectData.workspaceId,
          projectData.name || 'Reddit Scrape',
          projectData.language,
          projectData.description
        );

        if (!createdProject) {
          throw new Error('Unexpected issue during project creation');
        }

        existingProjectId = createdProject.project_id;
      } else {
        await removeProjectTask(existingProjectId, projectTasks[0]._id);
      }

      const taskResponse = await createReviewsTaskDashboard(
        existingProjectId,
        inputs,
        repeat,
        repeatInterval,
        token,
        shouldNotify,
        skipSentiment
      );

      if (
        taskResponse?.status_code === 200 &&
        taskResponse?.status === 'success'
      ) {
        setRequestInProcess(false);
        successHandler(
          !projectData.projectId
            ? 'Test task successfully created!'
            : 'Test task successfully updated!',
          existingProjectId
        );
      } else {
        throw new Error('Something went wrong!');
      }
    } catch (e) {
      try {
        if (!projectData.projectId && existingProjectId)
          await deleteProject(existingProjectId);
      } catch (e) {
        console.error(e);
      }
      setRequestInProcess(false);
      errorHandler(e.message);
    }
  };

  const submitHandler = (shouldNotify, skipSentiment, token) => {
    if (token || !repeat) {
      void submitTestTask(token);
    } else {
      setTokenModalIsOpen(true);
    }
  };

  const goNextStepHandler = () => {
    if (step === 1) {
      if (!validateInputs()) return;
    }
    setStep(prevState => prevState + 1);
  };

  const goBackHandler = () => {
    if (step === 1) {
      if (streamDataRoute !== RoutePatterns.UPLOAD_STREAM_DATA) {
        return history.go(-1);
      } else {
        return history.push(streamDataRoute);
      }
    }
    setStep(prevState => prevState - 1);
  };

  useEffect(() => {
    if (step === 1 && location.pathname !== INPUT_URLS_PATHNAME)
      history.replace(INPUT_URLS_PATHNAME);
    if (step === 2 && location.pathname !== SELECT_INTERVAL_PATHNAME)
      history.replace(SELECT_INTERVAL_PATHNAME);
  }, [history, step]);

  useEffect(() => {
    if (location.pathname === INPUT_URLS_PATHNAME) setStep(1);

    if (location.pathname === SELECT_INTERVAL_PATHNAME && validateInputs())
      setStep(2);
  }, [location.pathname]);

  return (
    <>
      <TokenModal
        isOpen={tokenModalIsOpen}
        onClose={() => setTokenModalIsOpen(false)}
        onError={errorHandler}
        onSubmit={submitTestTask}
      />
      <StreamSourceLayout onGoBack={goBackHandler}>
        <Switch>
          <Route exact path={`${streamDataRoute}/select/test`}>
            <Redirect to={INPUT_URLS_PATHNAME} />
          </Route>
          <Route path={SELECT_INTERVAL_PATHNAME}>
            <OptionsPage
              repeat={repeat}
              setRepeat={setRepeat}
              setRepeatInterval={setRepeatInterval}
              repeatInterval={repeatInterval}
              submitHandler={submitHandler}
              isLoading={requestInProcess}
            />
          </Route>
          <Route path={INPUT_URLS_PATHNAME}>
            <>
              <div
                css={css`
                  display: flex;
                  margin: 20px 0;
                  justify-content: space-between;
                  width: 100%;
                `}
              >
                <Button flavor="subtle" onClick={openImportHandler}>
                  Import URLs
                </Button>
                <div
                  css={css`
                    display: flex;
                    gap: 10px;
                  `}
                >
                  <Button
                    onClick={removeAllInputsHandler}
                    css={css`
                      height: 35px;
                    `}
                    palette="red"
                  >
                    Remove All
                  </Button>
                </div>
              </div>
              <TestCategoryUrlImportModal
                isOpen={isImportUrlsOpen}
                setInputs={setInputs}
                onClose={closeImportHandler}
              />
              <TestCategoryInputDataForm
                inputs={inputs}
                onPressNext={goNextStepHandler}
                onAddInput={addInputHandler}
                onChangeInput={inputChangeHandler}
                onRemoveInput={removeInputHandler}
                projectDataErrors={projectDataErrors}
              />
            </>
          </Route>
        </Switch>
      </StreamSourceLayout>
    </>
  );
};
