import axios from 'axios';

const instance = axios.create({
  // baseURL: import.meta.env.VITE_ASSISTANT_API_BASE_URL,
  baseURL: "0.0.0.0:8000",
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default {
  get: async (url) => {
    const response = await instance.get(url);
    return response.data;
  },
  post: async (url, body) => {
    const response = await instance.post(url, body);
    return response.data;
  },
  put: async (url, body) => {
    const response = await instance.put(url, body);
    return response.data;
  },
  delete: async (url) => {
    const response = await instance.delete(url);

    if (response.status < 200 || response.status > 299) {
      throw new Error(`Failed to delete: ${response.status}`);
    }
  },
};
