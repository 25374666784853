import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import SimpleModal from '../components/core/SimpleModal';
import {
  AuthForm,
  CancelButton,
  DropdownField,
  FIELD_WIDTH,
  SubmitButton,
  TextField
} from './Forms';
import { Workspace, AuthContext, formatRoleName } from './model';
import { Button } from '../components/core/Button';
import { showSuccess } from './notifications';
import { ButtonBar } from './ButtonBar';
import { Icon, IconTypes } from '../components/icons';

export default function AddUserButton({ workspace, className }) {
  const history = useHistory();
  const workspaceId = workspace.workspace_id;
  function goToAddUsersPage() {
    history.push(
      buildRoutePath(RoutePatterns.WORKSPACE_ADD_USER, {
        workspaceId
      })
    );
  }

  function goToListUsersPage() {
    history.push(
      buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
        workspaceId
      })
    );
  }

  return (
    <>
      <Button onClick={goToAddUsersPage} className={className}>
        <Icon type={IconTypes.ADD} />
        Add a user
      </Button>
      <Switch>
        <Route exact path={RoutePatterns.WORKSPACE_ADD_USER}>
          <AddUserModal hide={goToListUsersPage} workspace={workspace} />
        </Route>
      </Switch>
    </>
  );
}

AddUserButton.propTypes = {
  workspace: PropTypes.instanceOf(Workspace).isRequired
};

function AddUserModal({ hide, workspace }) {
  const users = workspace.getUsers();
  const { workspaceRoles } = useContext(AuthContext);

  function inviteUser(email, role) {
    const trimmedEmail = email.trim();

    // Do some basic validation on the email address
    if (!trimmedEmail.includes('@')) {
      return Promise.reject({
        fieldErrors: {
          email: ['You must enter a valid email address.']
        }
      });
    }
    if (users.some(u => u.username === trimmedEmail)) {
      return Promise.reject({
        fieldErrors: {
          email: ['That user already has access to the workspace.']
        }
      });
    }

    return workspace.inviteUser(trimmedEmail, role).catch(error => {
      if (error.code === 'INVALID_PARAMS') {
        throw {
          fieldErrors: { email: ['You must enter a valid email address.'] }
        };
      }

      throw error;
    });
  }

  return (
    <SimpleModal
      isOpen
      onHide={hide}
      css={css`
        width: ${FIELD_WIDTH}rem;
      `}
      header="Invite a user to this workspace"
    >
      <AuthForm
        onSubmit={formData => {
          return inviteUser(formData.email, formData.role).then(() => {
            hide();
            showSuccess(`Successfully invited ${formData.email}.`);
          });
        }}
        defaults={{
          email: '',
          role: 'viewer'
        }}
      >
        <TextField label="user's email address" field="email" />
        <DropdownField
          label="user role"
          field="role"
          options={workspaceRoles.map(role => ({
            name: formatRoleName(role),
            value: role
          }))}
        />
        <ButtonBar>
          <SubmitButton>
            <Icon type={IconTypes.ADD} />
            Invite user
          </SubmitButton>
          <CancelButton onClick={hide}>Cancel</CancelButton>
        </ButtonBar>
      </AuthForm>
    </SimpleModal>
  );
}

AddUserModal.propTypes = {
  hide: PropTypes.func.isRequired,
  workspace: PropTypes.instanceOf(Workspace).isRequired
};
