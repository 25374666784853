import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { RoutePatterns } from '../constants';
import { loadProject } from '../actions';
import PageNotFound from './PageNotFound';
import { GalaxyWorkArea } from '../Galaxy';
import { GalaxySettings } from '../side_panel/configure_visualization/GalaxySettings';
import { SentimentDownloadButton, SentimentWorkArea } from '../Sentiment';
import { SentimentSettings } from '../side_panel/configure_visualization/SentimentSettings';
import { VolumeDownloadButton, VolumeWorkArea } from '../Volume';
import { VolumeSettings } from '../side_panel/configure_visualization/VolumeSettings';
import { DriversDownloadButton, DriversWorkArea } from '../Drivers';
import { DriversSettings } from '../side_panel/configure_visualization/DriversSettings';
import NotificationToast from './NotificationToast';
import ProjectInexplorable from './ProjectInexplorable';
import { Highlights } from '../Highlights';
import { Concept } from '../classes/Concepts';
import { useDocumentTitle, useFetch, usePrevious } from '../utils/hooks';
import { StoreContext } from '../StoreContext';
import Workspace from './Workspace';
import { AuthContext } from '../settings/model';
import { useSearchParams, useSyncFilterAndSearch } from '../search_params';
import { ProjectManagement } from '../project_management/ProjectManagement';
import { Navigation } from './Navigation';
import { RecentBuildLog } from '../project_management/RecentBuildLog';
import { useConceptManagement } from '../data_hooks';
import { FetchErrorHandler } from '../utils/hooks/useFetch';
import { FeatureFlagsContext } from '../FeatureFlagsContext';
import AiAssistantUi from '../ai_assistant_ui/src/App';
import { getChatbotInformation } from '../utils/ApiUtilsV5';
import { useAssistantStore } from '../ai_assistant_ui/src/features/Chat/store';
import OnlyGalaxyVisualisation from './OnlyGalaxyVizualisation';

export default function ProjectExplorer({ onlyGalaxy }) {
  const { projectHasLoaded, project, notification, projectError } =
    useContext(StoreContext);
  const {
    serverStatus: { minimum_science_version }
  } = useContext(AuthContext);
  const { searchParams } = useSearchParams();
  useSyncFilterAndSearch();
  const projectName = project?.name ?? 'Unknown Project';
  const { workspaceId, projectId } = useParams();
  const prevWorkspaceId = usePrevious(workspaceId);
  const prevProjectId = usePrevious(projectId);
  const { conceptManagement } = useConceptManagement(project);
  const showRecentBuildLog =
    project?.last_access_time &&
    project.last_access_time < project.last_successful_build_time;
  const featureFlags = useContext(FeatureFlagsContext);

  const reloadProject = () =>
    loadProject(
      projectId,
      Concept.fromString(searchParams.search),
      searchParams.filter,
      minimum_science_version
    );
  const projectExplorerFetchErrorHandler = error => {
    if (error.code === 'PROJECT_LOCKED') {
      reloadProject();
      return true;
    }
    return false;
  };

  useDocumentTitle(
    projectHasLoaded
      ? `${projectName} | Luminoso Daylight`
      : 'Luminoso Daylight'
  );

  useEffect(() => {
    reloadProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevWorkspaceId !== workspaceId || prevProjectId !== projectId) {
      reloadProject();
    }
  });

  const chatbotInformation = useFetch(getChatbotInformation, projectId);
  const setChatbotStatus = useAssistantStore(state => state.setChatbotStatus);
  const setChatbotKnowledgeBaseId = useAssistantStore(
    state => state.setChatbotKnowledgeBaseId
  );
  const setProjectId = useAssistantStore(state => state.setProjectId);

  useEffect(() => {
    let chatbotKnowledgeBaseId = null;
    let chatbotStatus = null;

    if (
      chatbotInformation.status === 'FULFILLED' &&
      chatbotInformation.response
    ) {
      chatbotKnowledgeBaseId = chatbotInformation.response.knowledge_base_id;
      chatbotStatus = chatbotInformation.response.status;
    } else if (!(chatbotInformation.status === 'FULFILLED')) {
      chatbotKnowledgeBaseId = 'none';
      chatbotStatus = 'no_exist';
    }

    setChatbotStatus(chatbotStatus);
    setChatbotKnowledgeBaseId(chatbotKnowledgeBaseId);
    setProjectId(projectId);
  }, [
    chatbotInformation.status,
    chatbotInformation.response?.status,
    projectId
  ]);

  return (
    <div className="project-explorer">
      {!onlyGalaxy && <Navigation />}
      <div className="project-explorer__tool">
        <FetchErrorHandler errorHandler={projectExplorerFetchErrorHandler}>
          {projectHasLoaded && (
            <Switch>
              <Redirect
                exact
                from={RoutePatterns.PROJECT_EXPLORER}
                to={RoutePatterns.HIGHLIGHTS}
              />
              <Route exact path={RoutePatterns.HIGHLIGHTS}>
                <Highlights projectId={projectId} workspaceId={workspaceId} />
              </Route>
              <Route exact path={RoutePatterns.GALAXY}>
                <Workspace
                  settings={<GalaxySettings />}
                  workArea={<GalaxyWorkArea projectId={projectId} />}
                />
              </Route>
              <Route exact path={RoutePatterns.ONLY_GALAXY}>
                <OnlyGalaxyVisualisation projectId={projectId} />
              </Route>
              <Route exact path={RoutePatterns.VOLUME}>
                <Workspace
                  settings={<VolumeSettings />}
                  downloadButton={
                    <VolumeDownloadButton projectName={projectName} />
                  }
                  workArea={<VolumeWorkArea />}
                  withId={false}
                />
              </Route>
              <Route exact path={RoutePatterns.DRIVERS}>
                <Workspace
                  settings={<DriversSettings />}
                  downloadButton={
                    <DriversDownloadButton projectName={projectName} />
                  }
                  workArea={<DriversWorkArea />}
                  withId={false}
                />
              </Route>
              <Route exact path={RoutePatterns.SENTIMENT}>
                <Workspace
                  settings={<SentimentSettings />}
                  downloadButton={
                    <SentimentDownloadButton
                      projectId={projectId}
                      projectName={projectName}
                    />
                  }
                  workArea={<SentimentWorkArea projectName={projectName} />}
                  withId={false}
                />
              </Route>
              <Route exact path={RoutePatterns.PROJECT_MANAGEMENT}>
                <ProjectManagement />
              </Route>
              <Redirect
                from="/projects/:workspaceId/:projectId/score_drivers"
                to={RoutePatterns.DRIVERS}
              />
              <Redirect
                from="/projects/:workspaceId/:projectId/cloud"
                to={RoutePatterns.GALAXY}
              />
              <Redirect
                from="/projects/:workspaceId/:projectId/compare"
                to={RoutePatterns.VOLUME}
              />
              <PageNotFound />
            </Switch>
          )}
        </FetchErrorHandler>
        <AiAssistantUi visible={!onlyGalaxy && featureFlags.gen_ai_poc} />
      </div>
      {projectError && <ProjectInexplorable />}
      <NotificationToast
        className="project-explorer__notification"
        notification={notification}
      />
      {showRecentBuildLog && conceptManagement && (
        <RecentBuildLog conceptManagement={conceptManagement} />
      )}
    </div>
  );
}
