import { messages } from '../../../../utils/api';

const updateRatingOfMessage =
  (set, get) => async (rating, feedback, messageId) => {
    const chatId = get().activeChat.id;
    try {
      await messages.updateRating(chatId, messageId, rating, feedback);
    } catch (error) {
      set((state) => ({
        errors: [...state.errors, error.message],
      }));
    }
  };

export default updateRatingOfMessage;
