import { css } from '@emotion/react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { RoutePatterns } from '../constants';
import { getSuggestedClusters } from '../utils/ApiUtilsV5';
import { useBoundingClientRect, useFetch } from '../utils/hooks';
import { RequestStatuses } from '../constants';
import { ToolCard } from './Cards';
import PlaceholderText from '../components/core/PlaceholderText';
import { ClustersVisualization } from './ClustersVisualization';
import { getMoreDetailLink } from './utils';

export function ConversationClustersCard({ workspaceId, projectId }) {
  const { status, response: clusters } = useFetch(
    getSuggestedClusters,
    projectId
  );
  const visualization = useRef();
  const { width, height } = useBoundingClientRect(visualization);

  return (
    <ToolCard
      header="What are the largest clusters of conversation?"
      blurb={<Blurb cluster={clusters && clusters[0]} />}
      visualization={
        status === RequestStatuses.PENDING ? (
          <ToolCard.VisualizationPlaceholder />
        ) : (
          status === RequestStatuses.FULFILLED && (
            <div
              ref={visualization}
              css={css`
                height: 100%;
              `}
            >
              <ClustersVisualization
                width={width}
                height={height}
                clusters={clusters}
              />
            </div>
          )
        )
      }
      footer={
        <ToolCard.MoreDetailLink
          name="Galaxy feature"
          link={getMoreDetailLink(workspaceId, projectId, RoutePatterns.GALAXY)}
          disabled={status !== RequestStatuses.FULFILLED}
          trackingItem="highlights_more-details-link_largest-clusters"
        />
      }
    />
  );
}

ConversationClustersCard.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

function Blurb({ cluster }) {
  return (
    <p>
      We've discovered distinct clusters of conversation in your project, the
      largest cluster of which is about{' '}
      <ClusterConcept concept={cluster && cluster[0]} />,{' '}
      <ClusterConcept concept={cluster && cluster[1]} />,{' '}
      <ClusterConcept concept={cluster && cluster[2]} />, and{' '}
      <ClusterConcept concept={cluster && cluster[3]} />.
    </p>
  );
}

Blurb.propTypes = {
  cluster: PropTypes.array
};

function ClusterConcept({ concept }) {
  return (
    <>
      "
      {concept ? (
        <b>
          <bdi>{concept.name}</bdi>
        </b>
      ) : (
        <PlaceholderText style={{ width: '3rem' }} />
      )}
      "
    </>
  );
}

ClusterConcept.propTypes = {
  concept: PropTypes.shape({ name: PropTypes.string.isRequired })
};
