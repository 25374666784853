import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import Tooltip from '../components/core/Tooltip';
import getTextWidth from '../utils/getTextWidth';
import { labelOrientation } from './utils';
import { StoreContext } from '../StoreContext';
import { useCurrentView } from '../side_panel/views/view';
import { useSearchParams } from '../search_params';
import { AddForSvg } from '../components/icons/icon_svgs/Add';
import { addActiveConcepts } from '../actions';
import { getNewConceptColor } from '../components/Concept';
import { TooltipRow } from '../components/TooltipRow';
import { percentify } from '../utils/NumFmtUtils';
import { useTotalMatches } from '../utils/hooks/useTotalMatches';

export const DataPoint = ({
  cell,
  xScale,
  yScale,
  d,
  mouseEnterHandler,
  selected,
  hovered,
  id,
  onKeyPress,
  onFocus,
  isZooming,
  concepts,
  totalMatches
}) => {
  const x = xScale(d.x);
  const y = yScale(d.y);
  const [xMin, xMax] = xScale.range();
  const [yMin, yMax] = yScale.range();

  const visible =
    !isZooming &&
    hovered &&
    _.inRange(x, xMin, xMax) &&
    _.inRange(y, yMin, yMax);

  const text = d.name;
  const textWidth = useMemo(() => getTextWidth(text, '1rem'), [text]);
  // This is an approximation since we don't have an easy way of
  // measuring the height of the text.
  const textHeight = 14;

  const isOutlierConcept = d.outlier;
  const outlierLabel = isOutlierConcept ? 'rgba(79,72,72,0.76)' : 'inherit';
  const { activeConcepts, outlierFilterCount } = React.useContext(StoreContext);
  const { conceptSelector } = useCurrentView();
  const { searchParams } = useSearchParams();
  const { filter } = searchParams;
  const { projectId } = useParams();
  const isTotalMatches = useTotalMatches();
  const findConcept = () => {
    return concepts.find(concept => concept.name === d.name);
  };


  const outlierTooltipData = useMemo(() => {
    if (isOutlierConcept) {
      return {
        outlierMatches: d.tip.props.matchCount,
        outlierPercentage: percentify(
          d.tip.props.matchCount,
          outlierFilterCount
        ),
        totalMatches
      };
    }
  }, [d.tip.props.matchCount, isOutlierConcept, outlierFilterCount, totalMatches]);

  return (
    <>
      {isOutlierConcept ? (
        <Tooltip
          forSVG
          delayed={false}
          visible={visible}
          anchor={
            <g transform={`translate(0,0)`}>
              <AddForSvg
                x={x}
                y={y}
                size={20}
                iconColor={'#04aade'}
                lineWidth="2"
                onClick={() => {
                  addActiveConcepts(
                    conceptSelector,
                    filter,
                    projectId,
                    findConcept().update({
                      color: getNewConceptColor(activeConcepts)
                    })
                  );
                }}
              />
              <text x={x + 28} y={y + 12} fill={outlierLabel}>
                {text}
              </text>
            </g>
          }
        >
          <div className="drivers__tooltip">
            <TooltipRow header="Concept" value={d.name} />
            <TooltipRow
              header="Matches in Outlier documents"
              value={outlierTooltipData.outlierMatches}
            />
            <TooltipRow
              header="% of Outlier documents"
              value={outlierTooltipData.outlierPercentage}
            />
            <TooltipRow
              header={`${isTotalMatches ? 'Total' : 'Exact'} matches in current documents`}
              value={outlierTooltipData.totalMatches}
            />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          forSVG
          delayed={false}
          visible={visible}
          anchor={
            <g
              className={cx('scatter-plot__datapoint-tip', {
                'scatter-plot__datapoint-tip--selected': selected || hovered
              })}
              onMouseEnter={mouseEnterHandler}
              id={id}
              role="button"
              tabIndex={0}
              onFocus={onFocus}
              onKeyPress={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                  onKeyPress();
                }
              }}
              data-tracking-item="drivers_scatterplot-point"
            >
              {!isOutlierConcept && (
                <Circle
                  x={x}
                  y={y}
                  selected={hovered || selected}
                  color={d.color}
                  selectedBorderColor={d.selectedBorderColor}
                />
              )}
              <text
                x={x}
                y={y}
                fill={outlierLabel}
                {...labelOrientation(
                  cell,
                  x,
                  y,
                  { width: textWidth, height: textHeight },
                  { left: xMin, right: xMax, top: yMax, bottom: yMin }
                )}
              >
                {text}
              </text>
            </g>
          }
        >
          {d.tip}
        </Tooltip>
      )}
    </>
  );
};

DataPoint.propTypes = {
  cell: PropTypes.any,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  d: PropTypes.object.isRequired,
  mouseEnterHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  hovered: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func
};

const Circle = memo(function Circle({
  x,
  y,
  selected,
  color,
  selectedBorderColor
}) {
  return (
    <circle
      cx={x}
      cy={y}
      r="6"
      css={css`
        fill: ${color};

        ${selected &&
        css`
          stroke: ${selectedBorderColor};
          stroke-width: 3px;
        `}
      `}
    />
  );
});

Circle.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  selectedBorderColor: PropTypes.string.isRequired
};
