import React, { useEffect } from 'react';

import { useAssistantStore } from '../../../store';
import ChatStatusInfoNotReady from './ChatStatusInfoNotReady';
import { ChatStatusInfoContainer } from './styled';
import ChatStatusInfoBuilding from './ChatStatusInfoBuilding';
import { getChatbotInformation } from '../../../../../../../utils/ApiUtilsV5';

const ChatStatusInfo = () => {
  const store = useAssistantStore((store) => store);

  const projectId = store.projectId;
  const chatbotStatus = store.chatbotStatus;

  const setChatbotStatus = useAssistantStore((state) => state.setChatbotStatus);

  const [buildStatusDetails, setBuildStatus] = React.useState(null);

  const updateBuildStatus = () => {
    getChatbotInformation(projectId).then((res) => {
      console.log('getChatbotInfromation res', res);

      let chatbotStatus;
      if (res.status) {
        chatbotStatus = res.status;
      } else {
        chatbotStatus = 'no_exist';
      }

      setChatbotStatus(chatbotStatus);
      setBuildStatus(res.status_details);
    });
  };

  useEffect(() => {
    updateBuildStatus();
  }, []);

  useEffect(() => {
    if (projectId) {
      const checkIsBuildingTimer = setInterval(() => {
        if (chatbotStatus !== 'building') {
          clearInterval(checkIsBuildingTimer);
        } else {
          updateBuildStatus();
        }
      }, 4000);

      return () => {
        clearInterval(checkIsBuildingTimer);
      };
    }
  }, [chatbotStatus, projectId, setChatbotStatus]);

  // return (
  //   <ChatStatusInfoContainer>
  //     {chatbotStatus !== 'ready' && chatbotStatus !== 'building' && (
  //       <ChatStatusInfoNotReady projectId={projectId} />
  //     )}
  //     {chatbotStatus === 'building' && (
  //       <ChatStatusInfoBuilding statusDetails={buildStatusDetails} />
  //     )}
  //   </ChatStatusInfoContainer>
  // );
  return (
    <ChatStatusInfoContainer>
      {chatbotStatus === 'building' ? (
        <ChatStatusInfoBuilding statusDetails={buildStatusDetails} />
      ) : chatbotStatus === 'build_failed' ? (
        <ChatStatusInfoNotReady projectId={projectId} buildFailed={true} />
      ) : (
        <ChatStatusInfoNotReady projectId={projectId} />
      )}
    </ChatStatusInfoContainer>
  );
};

export default ChatStatusInfo;
