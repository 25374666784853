import { css } from '@emotion/react';
import React, { Children } from 'react';
import PropTypes from 'prop-types';

function getGap(size) {
  switch (size) {
    case 'small':
      return '0.5rem';
    case 'medium':
      return '1rem';
  }
  return 0;
}

export function FlexibleGrid({
  minColumnWidth = 'auto',
  gap = 'small',
  children,
  inline
}) {
  return (
    <div
      className="flexible-grid"
      css={css`
        display: ${inline ? 'inline-grid' : 'grid'};
        box-sizing: border-box;
        gap: ${getGap(gap)};
        grid-template-columns: repeat(auto-fit, minmax(${minColumnWidth}, 1fr));
      `}
    >
      {children}
    </div>
  );
}

FlexibleGrid.propTypes = {
  minColumnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gutter: PropTypes.oneOf(['none', 'small', 'medium']),
  children: PropTypes.node
};

export function NestedWrappingGrid({ children, minColumnWidth }) {
  const childCount = Children.count(children);
  const fractionalWidth = `calc(100% / ${childCount} - ${
    childCount - 1
  } * 0.5rem / ${childCount})`;

  return (
    <FlexibleGrid
      minColumnWidth={minColumnWidth || fractionalWidth}
      gap="small"
    >
      {children}
    </FlexibleGrid>
  );
}
