import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';

import { Card } from '../../../components/Card';
import { Button } from '../../../components/core/Button';
import SimpleModal from '../../../components/core/SimpleModal';
import { RequestStatuses } from '../../../constants';
import { buildProject, copyProject, deleteDocs } from '../../../utils/ApiUtilsV5';
import { useMountedRef } from '../../../utils/hooks';
import { markDocumentsForDelete } from '../../../actions';
import Spinner from '../../../components/core/Spinner';
import { ProjectCreationStatus } from '../../../components/ProjectBrancher';

const ConfirmDeleteDocumentsModal = ({ isOpen, onHide, project, deletedDocs }) => {
  const request = useRef();
  const isMounted = useMountedRef();
  const [{ creationStatus, statusMessage }, setRequestStatus] = useState({
    creationStatus: '',
    statusMessage: null
  });
  const matchType = 'both';
  const shouldNotify = false;
  const [loading, setLoading] = useState(false);

  const skipSentiment = project.last_build_info.sentiment.skip_sentiment;
  const dashboardBuild = false;

  const createProject = () => {
    setLoading(true);
    request.current = copyProject({
      projectId: project.project_id,
      workspaceId: project.workspace_id,
      name: `$Copy of ${project.name}`,
      description: project.description,
      filter: [],
      matchType,
      shouldNotify,
      skipRebuild: true
    }).then(copiedProject => {
      return deleteDocs(copiedProject.project_id, deletedDocs)
        .then(() => copiedProject);
    })
      .then(capturedCopiedProject => {
        if (capturedCopiedProject) {
          return buildProject(
            capturedCopiedProject.project_id,
            shouldNotify,
            skipSentiment,
            dashboardBuild
          );
        }
      })
      .then(() => {
        markDocumentsForDelete([]);
        setLoading(false);

        if (isMounted.current) {
          setRequestStatus({
            creationStatus: RequestStatuses.FULFILLED,
            statusMessage: 'Project rebuilt successfully'
          });
        }
      })
      .catch(error => {

        if (isMounted.current) {
          setRequestStatus({
            creationStatus: RequestStatuses.REJECTED,
            statusMessage: error.code
          });
        }
      });
  };
  const hasCreatedBranchedProject =
    creationStatus && creationStatus !== RequestStatuses.PENDING;

  return (
    <SimpleModal
      isOpen={isOpen}
      header={<h4>Document Management</h4>}
      css={css`
          width: 40vw;
          max-width: 40rem;
          max-height: 40vh;
          display: flex;
          flex-direction: column;
      `}
      containerCss={css`
          max-width: 80vw;
      `}
    >
      {hasCreatedBranchedProject ? (
        <ProjectCreationStatus
          status={creationStatus}
          statusMessage={statusMessage}
          closeModal={onHide}
        />
      ) : (
        <>
          <Card.Separator />
          <p css={css`
              text-align: center;
              padding: 1rem;
          `}>
            The documents will not be deleted until a Rebuild. When the Rebuild is performed, a copy of this Project
            with the documents deleted will be created and the original Project will stay intact.
          </p>
          <Card.Separator />
          <div
            css={css`
                display: flex;
                flex-direction: row;
                gap: 1rem;
                padding: 10px 30px;
                justify-content: space-around;
            `}
          >
            <Button onClick={createProject} palette="green" css={css`
                width: 150px;
                align-self: end;
            `}>
              {loading ? (
                  <Spinner />
                ) :
                <>
                  Rebuild
                </>}
            </Button>

            <Button onClick={onHide} palette="red" css={css`
                width: 150px;
                align-self: end;
            `}>
              Later
            </Button>
          </div>
        </>)}
    </SimpleModal>
  );
};

export default ConfirmDeleteDocumentsModal;