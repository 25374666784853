import { create } from 'zustand';

import createChat from './actions/createChat';
import initConversation from './actions/initConversation';
import sendMessage from './actions/sendMessage';
import updateRatingOfMessage from './actions/updateRatingOfMessage';
import askToProvideFeedback from './actions/askToProvideFeedback';

const defaultState = {
  isLoading: false,
  isCreating: false,
  errors: [],
  chats: [],
  activeChat: {
    id: null,
    messages: [],
    isConversationStarted: false,
    isBotReplying: false,
    isInteractionFromMessageRequired: false,
    isAwaitingForFeedback: false,
    isFeedbackSent: false,
  },
  chatbotStatus: 'uninitialized_chatbotStatus',
  chatbotKnowledgeBaseId: 'uninitialized_chatbotKnowledgeBaseId',
  projectId: null,

  docSearchResults: [],
  filter: [],
};

export const useAssistantStore = create((set, get) => ({
  ...defaultState,
  createChat: createChat(set),
  initConversation: initConversation(set, get),
  sendMessage: sendMessage(set, get),
  updateRatingOfMessage: updateRatingOfMessage(set, get),
  askToProvideFeedback: askToProvideFeedback(set, get),
  resetState: () =>
    set((state) => ({
      ...defaultState,
      chatbotStatus: state.chatbotStatus,
      chatbotKnowledgeBaseId: state.chatbotKnowledgeBaseId,
      projectId: state.projectId,
    })),
  setChatbotStatus: (chatbotStatus) => set((state) => ({ chatbotStatus })),
  setChatbotKnowledgeBaseId: (chatbotKnowledgeBaseId) =>
    set((state) => ({ chatbotKnowledgeBaseId })),
  setProjectId: (projectId) => set((state) => ({ projectId })),

  setDocSearchResults: (docSearchResults) =>
    set((state) => ({ docSearchResults })),
  setFilter: (filter) => set((state) => ({ filter })),
}));
