import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#04aade',
    },
  },
  typography: {
    fontFamily: ['"Lato"', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    h6: {
      marginBottom: '0.5rem',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      color: 'rgba(45, 55, 72, 1)',
    },
    body1: {
      lineHeight: '1.5',
      margin: '0 0 0.5rem 0',
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1rem',
      color: 'rgba(45, 55, 72, 0.7)',
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        sx: {
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});

export default theme;
