import React, { useContext, useState, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import SimpleModal from '../components/core/SimpleModal';
import { StoreContext } from '../StoreContext';
import { Colors } from '../styles';
import { thousandify } from '../utils/NumFmtUtils';
import { formatTimestamp, getProjectLanguageName } from '../utils/projectUtils';
import { AuthContext } from '../settings/model';

const P = styled.p`
  margin-bottom: 0;
`;
const LI = styled.li`
  list-style-type: none;
`;

export function RecentBuildLog({ conceptManagement }) {
  const [showLogModal, setShowLogModal] = useState(true);
  const { project } = useContext(StoreContext);
  return (
    <>
      <RecentBuildLogModal
        isOpen={showLogModal}
        onHide={() => {
          setShowLogModal(false);
        }}
        conceptManagement={conceptManagement}
        project={project}
      />
    </>
  );
}

function RecentBuildLogModal({ isOpen, onHide, conceptManagement, project }) {
  const { serverStatus } = useContext(AuthContext);
  const languageName = getProjectLanguageName(serverStatus, project);

  const scrollboxNode = useRef();
  const stickyableNode = useRef();
  const stickHeightReferenceNode = useRef();

  const [stickyStylingStatus, setStickyStylingStatus] = useState(false);

  function scrollboxScroll() {
    if (
      scrollboxNode.current.scrollTop >
      stickHeightReferenceNode.current.scrollHeight
    ) {
      setStickyStylingStatus(true);
    } else {
      setStickyStylingStatus(false);
    }
  }

  const ignoreConcepts = conceptManagement.currentBuild.ignore;
  const mergeConcepts = conceptManagement.currentBuild.merge;
  const noticeConcepts = conceptManagement.currentBuild.notice;
  const collocateConcepts = conceptManagement.currentBuild.collocate;

  const stickyStyles = css`
    position: sticky;
  `;
  const scrollingStyles = css`
    top: -0.5rem;
    padding: 0.25rem 1rem;
    margin: 0 -1rem;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px #0000001a;
  `;

  let ignoreLength = conceptManagement.currentBuild.ignore.length,
    multiIgnoreAssertions = ignoreLength !== 1;

  const mergeLength = conceptManagement.currentBuild.merge?.length || 0;
  const multiMergeActions = mergeLength !== 1;

  const noticeLength = noticeConcepts.length;
  const multiNoticeActions = noticeLength !== 1;

  const collocateLength = collocateConcepts.length;
  const multiCollocateActions = collocateLength !== 1;

  return (
    <SimpleModal
      isOpen={isOpen}
      onHide={onHide}
      header={<h4>This project has been rebuilt</h4>}
    >
      <div
        css={css`
          border: 1px solid ${Colors.gray0};
          padding: 0.5rem 0.75rem;
          max-height: 60vh;
          overflow-y: scroll;
        `}
        ref={scrollboxNode}
        onScroll={scrollboxScroll}
      >
        <div ref={stickHeightReferenceNode}>
          <P>{formatTimestamp(project.last_build_info.stop_time)}</P>
          <P>
            Documents: <strong>{thousandify(project.document_count)}</strong>
          </P>
          <P>
            Science Version:{' '}
            <strong>{project.last_build_info.science_version}</strong>
          </P>
          <P>
            Language: <strong>{languageName}</strong>
          </P>
        </div>
        <P
          ref={stickyableNode}
          css={css`
            ${stickyStyles}
            ${stickyStylingStatus && scrollingStyles}
          `}
        >
          Ignore:{' '}
          <strong>
            {ignoreLength} assertion{multiIgnoreAssertions && 's'}
          </strong>
        </P>
        <ul>
          {ignoreConcepts.map(({ concept }) => {
            return <LI key={concept}>{concept}</LI>;
          })}
        </ul>
        <P
          css={css`
            ${stickyStyles}
            ${stickyStylingStatus && scrollingStyles}
          `}
        >
          Merge:{' '}
          <strong>
            {mergeLength} assertion{multiMergeActions && 's'}
          </strong>
        </P>
        <ul>
          {mergeConcepts &&
            mergeConcepts.map(({ concept, merge_with }) => (
              <LI
                key={`${concept}-${merge_with}`}
              >{`${concept} merged with ${merge_with}`}</LI>
            ))}
        </ul>
        <P
          ref={stickyableNode}
          css={css`
            ${stickyStyles}
            ${stickyStylingStatus && scrollingStyles}
          `}
        >
          Notice:{' '}
          <strong>
            {noticeLength} assertion{multiNoticeActions && 's'}
          </strong>
        </P>
        <ul>
          {noticeConcepts.map(({ concept }) => {
            return <LI key={concept}>{concept}</LI>;
          })}
        </ul>

        <P
          css={css`
            ${stickyStyles}
            ${stickyStylingStatus && scrollingStyles}
          `}
        >
          Collocate:{' '}
          <strong>
            {collocateLength} assertion{multiCollocateActions && 's'}
          </strong>
        </P>
        <ul>
          {collocateConcepts.map(({ concept }) => {
            return <LI key={concept}>{concept}</LI>;
          })}
        </ul>
      </div>
    </SimpleModal>
  );
}
