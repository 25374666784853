import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Alert from './components/core/Alert';
import ErrorBoundary from './components/core/ErrorBoundary';
import CloudWrapper from './galaxy/CloudWrapper';
import UnknownErrorMsg from './components/UnknownErrorMsg';
import { AlertTypes } from './constants';
import { StoreContext } from './StoreContext';
import { useHover } from './HoverContext';
import { useSearchParams } from './search_params';
import Spinner from './components/core/Spinner';
import {
  useConceptsWithRelevance,
  useConceptsBySharedList
} from './data_hooks';
import { useAxes } from './galaxy/useAxes';
import { GalaxyFooter } from './galaxy/GalaxyFooter';
import { usePrevious } from './utils/hooks';
import { getSentimentStatus, SENTIMENT_STATUS } from './utils/sentimentStatus';

// Concepts can occasionally be malformed. They can:
// - have empty vectors
// - have all-zero vectors
//
// We want to prevent the cloud from ever showing them, as it will cause
// layout problems.
//
const hasValidVector = ({ galaxyVectors }) =>
  galaxyVectors?.[0]?.some(component => component !== 0);

function useGalaxyConcepts() {
  const concepts = useConceptsWithRelevance();

  // format the returned concepts a bit:
  //
  // - filter out boolean / edited concepts
  // - sort the concepts in descending order of relevance
  // - filter out concepts with invalid vectors
  //
  return concepts
    ?.filter(c => !c.boolean)
    .sort((c1, c2) => c2.relevance - c1.relevance)
    .filter(hasValidVector);
}

function useGalaxyColorConcepts() {
  const { activeConcepts, suggestions } = React.useContext(StoreContext);

  const { searchParams, updateSearch } = useSearchParams();
  const prevActiveConcepts = usePrevious(activeConcepts);

  const conceptLists = useConceptsBySharedList();
  const colorListConcepts = searchParams.color_by_list
    ? conceptLists[searchParams.color_by_list]
    : [];

  // handle transitions
  useEffect(() => {
    // if we go from having no active concepts to some active concepts,
    // then switch to coloring by the active concepts. this transition
    // however should not happen if we are coloring by the concept lists,
    // in which case we let the user add to the active concepts without
    // affecting the galaxy visualization.
    if (
      prevActiveConcepts.length === 0 &&
      activeConcepts.length > 0 &&
      searchParams.color_by !== 'concept_lists'
    ) {
      updateSearch({ color_by: 'active_concepts' });
    }

    // if we were originally coloring by the active concepts, and we go from
    // having some active concepts to no active concepts, then switch to
    // coloring by conversation clusters.
    if (
      prevActiveConcepts.length > 0 &&
      activeConcepts.length === 0 &&
      searchParams.color_by === 'active_concepts'
    ) {
      updateSearch({ color_by: 'clusters' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConcepts]);

  const colorConcepts =
    searchParams.color_by === 'clusters'
      ? suggestions
      : searchParams.color_by === 'active_concepts'
        ? activeConcepts
        : colorListConcepts;

  return colorConcepts;
}

export function GalaxyWorkArea({ projectId ,onlyGalaxy= false }) {
  const { topConcepts, projectHasLoaded, selection, project } =
    React.useContext(StoreContext);

  const [hovered, setHovered] = useHover();
  const { searchParams } = useSearchParams();
  const { filter } = searchParams;
  const {
    xAxisConcept,
    yAxisConcept,
    axisLabelsAreVisible,
    resetAxes,
    hideLabels
  } = useAxes();
  const conceptsToVisualize = useGalaxyConcepts();
  const colorConcepts = useGalaxyColorConcepts();

  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY

  return (
    <div className="galaxy-work-area">
      {projectHasLoaded &&
        (conceptsToVisualize ? (
          // Workaround the fact that the cloud's clustering code frequently triggers errors when there
          // are particularly meaningless vectors, e.g. when there are very few top concepts
          conceptsToVisualize.length < 5 ? (
            <span className="galaxy-work-area__cloud-message">
              <Alert type={AlertTypes.WARNING}>
                Galaxy needs at least five concepts to calculate relationships.
              </Alert>
            </span>
          ) : (
            <ErrorBoundary
              errorMessage={
                <span className="galaxy-work-area__cloud-message">
                  <Alert type={AlertTypes.ERROR}>
                    <UnknownErrorMsg />
                  </Alert>
                </span>
              }
            >
              <CloudWrapper
                isSentimentReady={isSentimentReady}
                projectId={projectId}
                filter={filter}
                conceptsToVisualize={conceptsToVisualize}
                colorConcepts={colorConcepts}
                selection={selection}
                hovered={hovered}
                resetAxes={resetAxes}
                xAxisConcept={xAxisConcept}
                yAxisConcept={yAxisConcept}
                hideAxisLabels={hideLabels}
                axisLabelsAreVisible={axisLabelsAreVisible}
                setHovered={setHovered}
                fontSizeDomain={getFontSizeDomain(
                  topConcepts.length > 0 ? topConcepts : conceptsToVisualize
                )}
              />

              {!onlyGalaxy && <GalaxyFooter />}
            </ErrorBoundary>
          )
        ) : (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 0.5rem;
              margin: auto;
            `}
          >
            <Spinner />
            <div> Loading galaxy concepts </div>
          </div>
        ))}
    </div>
  );
}

GalaxyWorkArea.propTypes = {
  projectId: PropTypes.string.isRequired
};

const getFontSizeDomain = concepts => [
  concepts[concepts.length - 1].relevance,
  concepts[0].relevance
];
