import React from 'react';
import { css } from '@emotion/react';

import HRSitesUrlInput from './HRSitesUrlInput';

const HRSitesInputDataForm = ({ inputs, onChangeInput, onRemoveInput }) => {
  return (
    <form
      css={css`
        overflow-y: auto;
        max-height: 30rem;
      `}
    >
      {inputs.map(input => (
        <HRSitesUrlInput
          key={input.name}
          input={input}
          inputs={inputs}
          onInputChange={onChangeInput}
          onInputRemove={onRemoveInput}
        />
      ))}
    </form>
  );
};

export default HRSitesInputDataForm;
