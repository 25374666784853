import PropTypes from 'prop-types';
import React, { useRef, useMemo } from 'react';
import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import styled from '@emotion/styled';
import _ from 'lodash';

import { Concept as ConceptClass, Concept } from '../classes/Concepts';
import { MetadataField } from '../classes/MetadataFields';
import sortObjects from '../utils/sortObjects';
import { naturalSortByName } from '../utils/NaturalSort';
import AreaChart from './charts/AreaChart';
import BarChart from './charts/BarChart';
import VerticalBarChart from './charts/VerticalBarChart';
import ChartLabels from './ChartLabels';
import {
  Body,
  LoadingTableBody,
  ScrollableTable,
  ConceptHeader,
  ConceptRow,
  useScrollToSelectedConceptRow
} from '../components/core/Table';
import { Buckets } from '../classes/Buckets';
import ComparisonChartTooltipContent from './ComparisonChartTooltipContent';
import { StoreContext } from '../StoreContext';
import { Mixins } from '../styles';

const NUMBER_OF_TICKS = 5;

const StyledVolumeTable = styled.div`
  ${Mixins.shadowOutset};
  ${Mixins.roundedCorners};
  overflow: hidden;
  height: 100%;
`;

export function PlaceholderTable({ displayPlaceholderText, matchType }) {
  return (
    <StyledVolumeTable data-test-id="volume-table">
      <ConceptHeader loading matchType={matchType} />
      <LoadingTableBody displayPlaceholderText={displayPlaceholderText} />
    </StyledVolumeTable>
  );
}

PlaceholderTable.propTypes = {
  displayPlaceholderText: PropTypes.bool,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired
};

export default function VolumeTable({
  concepts,
  sortConcepts,
  sortOrder,
  matchType,
  breakdown,
  totalCount,
  filterCount,
  projectId,
  normalized,
  onSortChange,
  isActive,
  isColor,
  totalMatches,
  buckets
}) {
  const { selection, activeConcepts } = React.useContext(StoreContext);
  const tableRef = useRef();
  const selectedRowRef = useRef();
  const scale = useMemo(() => calculateScale(concepts, matchType), [
    concepts,
    matchType
  ]);
  useScrollToSelectedConceptRow(tableRef, selectedRowRef);

  const handleSortChange = (sortingKey, direction) => {
    onSortChange(`${sortingKey}-${direction}`);
  };

  const sortedConcepts = getSortedConcepts(
    concepts,
    sortConcepts,
    sortOrder,
    matchType
  );

  return (
    <StyledVolumeTable data-test-id="volume-table">
      <ScrollableTable
        ref={tableRef}
        sortedColumn={sortConcepts}
        sortDirection={sortOrder}
        onSortChange={handleSortChange}
        header={
          <ConceptHeader
            matchType={matchType}
            visualizationHeader={
              (!breakdown || buckets.totals.length === 1) && (
                <ChartLabels scale={scale} numTicks={NUMBER_OF_TICKS} />
              )
            }
          />
        }
        body={
          <Body>
            {isRowInView =>
              sortedConcepts.map((concept, index) => {
                const matchCount = Concept.getMatchCount(concept)[matchType];
                const selected = Concept.areTextsEqual(selection, concept);
                const totalMatchesValue = totalMatches?.[concept.name]?.matchCount;

                const matchingConcept = concept.isActive
                  ? _.find(activeConcepts, { sharedConceptId: concept.sharedConceptId })
                  : activeConcepts.find(ac => ConceptClass.areTextsEqual(concept, ac));

                return (
                  <ConceptRow
                    key={concept.hash()}
                    ref={selected ? selectedRowRef : undefined}
                    projectId={projectId}
                    concept={concept}
                    selected={selected}
                    matchCount={matchCount}
                    totalCount={totalCount}
                    withAdditionalTooltip={isActive}
                    filterCount={filterCount}
                    totalMatches={totalMatchesValue}
                    index={index}
                    color={isColor ? matchingConcept?.color : null}
                    renderVisualization={() =>
                      isRowInView(index) &&
                      (breakdown ? (
                        <BreakdownVisualization
                          isColor={isColor}
                          breakdown={breakdown}
                          totalCount={totalCount}
                          matchType={matchType}
                          concept={concept}
                          buckets={buckets}
                          normalized={normalized}
                          scale={scale}
                          tooltipPosition={index === 0 ? 'below' : 'above'}
                        />
                      ) : (
                        <BarChart
                          matchType={matchType}
                          scale={scale}
                          matchCount={Concept.getMatchCount(concept)}
                          numTicks={NUMBER_OF_TICKS}
                          color={isColor ? matchingConcept?.color : null}
                          tooltipContent={
                            <ComparisonChartTooltipContent
                              color={isColor ? concept.color : null}
                              header={concept.name}
                              matchCounts={Concept.getMatchCount(concept)}
                              matchType={matchType}
                              total={totalCount}
                            />
                          }
                          tooltipPosition={index === 0 ? 'below' : 'above'}
                        />
                      ))
                    }
                  />
                );
              })
            }
          </Body>
        }
      />
    </StyledVolumeTable>
  );
}

VolumeTable.propTypes = {
  projectId: PropTypes.string.isRequired,
  sortConcepts: PropTypes.oneOf(['default', 'name', 'matches']).isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onSortChange: PropTypes.func.isRequired,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired,
  concepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
    .isRequired,
  totalCount: PropTypes.number.isRequired,
  breakdown: PropTypes.instanceOf(MetadataField),
  normalized: PropTypes.bool,
  buckets: PropTypes.instanceOf(Buckets).isRequired,
  filterCount: PropTypes.number,
  isActive: PropTypes.bool,
  isColor: PropTypes.bool,
  totalMatches: PropTypes.object,
};

function calculateScale(concepts, matchType) {
  const maxCount = max(concepts, concept =>
    matchType === 'exact' ? concept.exactMatchCount : concept.matchCount
  );

  return scaleLinear().domain([0, maxCount]).range([0, 100]).nice();
}

function getSortedConcepts(concepts, sortConcepts, sortOrder, matchType) {
  const directionalModifier = sortOrder === 'asc' ? 1 : -1;

  return sortObjects(
    concepts,
    (conceptA, conceptB) => {
      switch (sortConcepts) {
        case 'name':
          return directionalModifier * naturalSortByName(conceptA, conceptB);
        default: {
          const matchCountA = Concept.getMatchCount(conceptA),
            matchCountB = Concept.getMatchCount(conceptB);
          return (
            directionalModifier *
            (matchType === 'exact'
              ? matchCountA.exact - matchCountB.exact
              : matchCountA.total - matchCountB.total)
          );
        }
      }
    },
    naturalSortByName
  );
}

const BreakdownVisualization = ({
  breakdown,
  totalCount,
  matchType,
  buckets,
  normalized,
  scale,
  concept,
  tooltipPosition,
  isColor
}) => {
  const matchCounts = buckets.getMatchCountFor(concept);

  const renderTooltipContent = index => {
    return (
      <ComparisonChartTooltipContent
        color={isColor ? concept.color : null}
        header={`${breakdown.name}: ${buckets.labels[index]}`}
        matchCounts={matchCounts[index]}
        matchType={matchType}
        total={totalCount}
      />
    );
  };

  if (matchCounts.length === 1) {
    return (
      <BarChart
        color={isColor ? concept.color : null}
        matchType={matchType}
        scale={scale}
        matchCount={matchCounts[0]}
        numTicks={NUMBER_OF_TICKS}
        tooltipContent={renderTooltipContent(0)}
        tooltipPosition={tooltipPosition}
      />
    );
  }

  const breakdownProps = {
    matchType,
    matchCounts: normalized
      ? buckets.getNormalizedMatchCountsFor(concept)
      : matchCounts,
    color: isColor ? concept.color : null,
    renderTooltipContent,
    tooltipPosition
  };

  return breakdown.type === 'string' ? (
    <VerticalBarChart {...breakdownProps} />
  ) : (
    ['date', 'number', 'score'].includes(breakdown.type) && (
      <AreaChart {...breakdownProps} />
    )
  );
};

BreakdownVisualization.propTypes = {
  breakdown: PropTypes.instanceOf(MetadataField).isRequired,
  totalCount: PropTypes.number.isRequired,
  matchType: PropTypes.string.isRequired,
  buckets: PropTypes.instanceOf(Buckets).isRequired,
  normalized: PropTypes.bool.isRequired,
  scale: PropTypes.func.isRequired,
  concept: PropTypes.instanceOf(Concept).isRequired,
  isColor: PropTypes.bool
};
