import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { uniqueId } from 'lodash';

import { Button } from '../../../components/core/Button';
import ReviewsInputDataForm from './ReviewsInputDataForm';
import { CloseButton } from '../../../components/CloseButton';

export const ReviewsInputDataContent = ({
  inputs,
  setInputs,
  checkProjectData,
  title,
  selectSource
}) => {
  const initialInputs = [{ name: `url${uniqueId()}`, value: '', error: '' }];

  const inputChangeHandler = event => {
    const newInputs = [...inputs];
    const existedInputIndex = newInputs.findIndex(
      input => input.name === event.target.name
    );
    newInputs[existedInputIndex] = {
      ...newInputs[existedInputIndex],
      value: event.target.value,
      error: ''
    };

    setInputs(newInputs);
  };

  const addInputHandler = () => {
    setInputs(prevState => [
      ...prevState,
      { ...initialInputs[0], name: `url${uniqueId()}` }
    ]);
  };

  const removeInputHandler = name => {
    setInputs(prevState => prevState.filter(input => input.name !== name));
  };

  const removeAllInputsHandler = () => {
    setInputs(initialInputs);
  };

  useEffect(() => {
    checkProjectData();
  }, [checkProjectData]);

  return (
    <div
      css={css`
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: 0.1875rem;
        box-sizing: border-box;
        padding: 1rem;
        width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 8px;
        `}
      >
        <h4>{title}</h4>

        <CloseButton onClick={() => selectSource(title)} />
      </div>

      <div
        css={css`
          display: flex;
          margin: 10px 0 20px;
          justify-content: flex-end;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 10px;
          `}
        >
          <Button
            onClick={removeAllInputsHandler}
            css={css`
              height: 35px;
            `}
            palette="red"
          >
            Remove All
          </Button>{' '}
        </div>
      </div>

      <ReviewsInputDataForm
        inputs={inputs}
        onAddInput={addInputHandler}
        onChangeInput={inputChangeHandler}
        onRemoveInput={removeInputHandler}
      />
      <Button
        onClick={addInputHandler}
        css={css`
          height: 35px;
        `}
      >
        Add more
      </Button>
    </div>
  );
};
