import React, { useState, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { InlineChangeArrow, Title } from './components';
import { Card } from '../components/Card';
import { editProject } from '../utils/ApiUtilsV5';
import Dropdown from '../components/core/Dropdown';
import { StoreContext } from '../StoreContext';
import { Colors } from '../styles';
import { Button } from '../components/core/Button';
import Spinner from '../components/core/Spinner';
import { Icon, IconTypes } from '../components/icons';
import { objHasPerm } from '../utils/common';
import { editNextBuildLanguage } from '../actions';
import { AuthContext } from '../settings/model';

export const LanguageSection = () => {
  const { project } = useContext(StoreContext);
  const { serverStatus } = useContext(AuthContext);

  const hasChangeLanguagePermission = objHasPerm(project, 'write');

  const languageChangeQueued = project.language !== project.next_build_language;

  const { languages } = serverStatus;
  const currentLanguage = languages.find(
    lang => lang.code === project.language
  );

  const nextBuildLanguage = languages.find(
    lang => lang.code === project.next_build_language
  );

  const languageOptions = languages
    .filter(lang => lang.code !== project.language)
    .map(lang => (
      <option key={lang.code} value={lang.code}>
        {lang.name}
      </option>
    ));

  // from the gui spec: we don't show this section if the user does not have
  // change language permission.
  if (!hasChangeLanguagePermission) {
    return null;
  }

  return (
    <Card data-test-id="language-section">
      <Card.Section>
        <Title>Language: {currentLanguage.name}</Title>
      </Card.Section>
      <Card.Separator />
      <Card.Section>
        <WarningMessage />
        {languageChangeQueued ? (
          <NextBuildInfo
            currentLanguage={currentLanguage}
            nextBuildLanguage={nextBuildLanguage}
            project={project}
          />
        ) : (
          <SelectLanguageDropdown languageOptions={languageOptions} />
        )}
      </Card.Section>
    </Card>
  );
};

const WarningMessage = () => (
  <div
    css={css`
      color: ${Colors.red5};
      margin: 1rem 0;
    `}
  >
    You should not need to change this unless there was a mistake during the
    initial upload.
  </div>
);

const NextBuildInfo = ({ currentLanguage, nextBuildLanguage }) => {
  const { projectId } = useParams();
  const [processing, setProcessing] = useState(false);

  const cancelLanguageChange = () => {
    setProcessing(true);
    editProject(projectId, {
      language: currentLanguage.code
    }).then(() => editNextBuildLanguage(currentLanguage.code));
  };

  return (
    <RowDiv>
      <div
        css={css`
          color: ${processing && Colors.gray5};
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        Next build: {currentLanguage.name}
        <InlineChangeArrow />
        {nextBuildLanguage?.name}
      </div>
      {processing ? (
        <Spinner />
      ) : (
        <Button
          aria-label="Discard next build language change"
          flavor="subtle"
          palette="red"
          padded={false}
          onClick={cancelLanguageChange}
        >
          <Icon type={IconTypes.TRASH} />
        </Button>
      )}
    </RowDiv>
  );
};

const SelectLanguageDropdown = ({ languageOptions }) => {
  const { projectId } = useParams();
  const [newLanguage, setNewLanguage] = useState('');
  const [processing, setProcessing] = useState(false);

  const updateLanguage = () => {
    setProcessing(true);
    editProject(projectId, { language: newLanguage }).then(() =>
      editNextBuildLanguage(newLanguage)
    );
  };

  return (
    <RowDiv>
      <Dropdown
        aria-label="Next build language selection"
        disabled={processing}
        onChange={event => setNewLanguage(event.target.value)}
        value={newLanguage}
      >
        <option value="" disabled>
          Select a new language
        </option>
        {languageOptions}
      </Dropdown>
      {processing ? (
        <Spinner />
      ) : (
        <Button onClick={updateLanguage} disabled={newLanguage === ''}>
          Update at next build
        </Button>
      )}
    </RowDiv>
  );
};

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
