import { css } from '@emotion/react';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  loadSharedConceptLists,
  restoreActiveConceptList,
  updateActiveListName
} from '../../actions';
import {
  OverlayAlert,
  useOverlayAlertController
} from '../../components/core/Alert';
import { Button } from '../../components/core/Button';
import Combobox from '../../components/core/Combobox';
import {
  createConceptList,
  getSharedConceptLists,
  restoreSharedConceptList
} from '../../utils/ApiUtilsV5';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { StoreContext } from '../../StoreContext';

export function ShareActiveConcepts({ permissions, conceptLists }) {
  const alertController = useOverlayAlertController();
  const [conceptListName, setConceptListName] = useState('');
  const shareConcepts = useShareConcepts();

  // Users without write permissions can't share concept lists with other users
  if (!permissions.includes('write')) {
    return null;
  }
  const trimmedConceptListName = conceptListName.trim();

  const createList = () => {
    shareConcepts(conceptListName)
      .then(() => {
        alertController.showSuccess('Success!');
        setConceptListName('');
      })
      .catch(() => {
        alertController.showWarning('Unable to share concept list');
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    createList();
  }

  return (
    <OverlayAlert
      controller={alertController}
      css={css`
        width: 100%;
      `}
    >
      <form
        onSubmit={handleSubmit}
        data-tracking-item="active-concepts_save-list"
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <Combobox
          aria-label="Concept list name"
          placeholder="Type to share active concepts"
          value={conceptListName}
          onChange={setConceptListName}
          options={conceptLists?.map(cl => cl.name)}
          optionsDescription="existing concept lists"
          containerCss={css`
            flex: 1;
            margin-right: 0.5rem;

            // Match default button height
            input {
              height: 2.2rem;
            }
            button {
              top: 0.6rem;
            }
          `}
        />
        <Button disabled={trimmedConceptListName === ''} type="submit">
          Share
        </Button>
      </form>
    </OverlayAlert>
  );
}

ShareActiveConcepts.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  conceptLists: PropTypes.array
};

export const useShareConcepts = () => {
  const { projectId } = useParams();
  const dispatchUndoBanner = useDispatchUndoBanner();
  const { activeConceptListName, activeConcepts } = useContext(StoreContext);
  const refetchConceptLists = () => loadSharedConceptLists(projectId);

  return function createList(conceptListName) {
    const trimmedConceptListName = conceptListName.trim();
    let mostRecentConceptLists;
    return getSharedConceptLists(projectId)
      .then(sharedLists => {
        mostRecentConceptLists = sharedLists;
      })
      .then(() => createConceptList(projectId, trimmedConceptListName, true))
      .then(() => {
        const overwrittenList = _.find(mostRecentConceptLists, {
          name: trimmedConceptListName
        });
        if (overwrittenList) {
          dispatchUndoBanner({
            message: `Updated "${trimmedConceptListName}"`,
            onUndo: () => {
              return Promise.all([
                restoreSharedConceptList(
                  projectId,
                  overwrittenList.name,
                  overwrittenList.concepts,
                  true
                ),
                restoreActiveConceptList(
                  projectId,
                  activeConceptListName,
                  activeConcepts
                )
              ]).then(refetchConceptLists);
            },
            trackingItem: 'undo-create-over-concept-list'
          });
        }
        refetchConceptLists();
        updateActiveListName(trimmedConceptListName);
      });
  };
};
