import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Header, HeaderToolbar, HeaderButton } from './styled';
import { useTranslation } from 'react-i18next';

const AppHeader = ({ children, actions }) => {
  const { t } = useTranslation();

  return (
    <Header position="relative">
      <HeaderToolbar variant="dense">
        <Typography variant="h5" sx={{ flexGrow: 1, fontSize: '1.125rem' }}>
          {children || t('main.header.title')}
        </Typography>
        {actions.length ? (
          <Box sx={{ display: 'flex' }}>
            {actions.map(({ icon, handleClick, disabled }, idx) => (
              <HeaderButton key={idx} onClick={handleClick} disabled={disabled}>
                <img src={icon.src} alt={icon.alt} />
              </HeaderButton>
            ))}
          </Box>
        ) : null}
      </HeaderToolbar>
    </Header>
  );
};

AppHeader.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      disabled: PropTypes.bool,
      handleClick: PropTypes.func,
    }).isRequired,
  ),
  children: PropTypes.node,
};

export default AppHeader;
