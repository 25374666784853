import React, { useState } from 'react';
import { css } from '@emotion/react';
import _ from 'lodash';

import SimpleModal from '../../components/core/SimpleModal';
import { Button } from '../../components/core/Button';
import { AuthContext } from '../../settings/model';
import Alert from '../../components/core/Alert';
import { AlertTypes } from '../../constants';

export const TokenModal = ({ isOpen, onClose, onSubmit, skipSentiment, shouldNotify }) => {
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { profile } = React.useContext(AuthContext);

  const handleChange = e => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setErrors([]);
    const formData = {
      password,
      description: `new_stream_data_${Math.floor(Math.random() * 99999999)}`
    };
    const fieldErrors = [];
    if (formData.password === '') {
      fieldErrors.push('Password is required.');
    }
    if (!_.isEmpty(fieldErrors)) {
      setErrors(fieldErrors);
      setIsSubmitting(false);
      return;
    }
    return profile.createToken(formData).then(
      data => {
        setIsSubmitting(false);
        onSubmit(data.token, shouldNotify, skipSentiment);
        return onClose();
      },
      error => {
        if (error.code === 'AUTH_CREDS_INVALID') {
          setIsSubmitting(false);
          return setErrors(["That's not your password. Please try again."]);
        } else {
          setIsSubmitting(false);
          return setErrors(['Something went wrong. Please try again later.']);
        }
      }
    );
  };

  return (
    <SimpleModal onHide={onClose} isOpen={isOpen}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
        `}
      >
        <p>Please, re-enter your password to continue.</p>
        <div
          css={css`
            width: 100%;
          `}
        >
          <input
            css={css`
              width: calc(100% - 16px);
            `}
            name="password"
            placeholder="Type your password here..."
            onChange={handleChange}
            value={password}
            type="password"
            required={true}
            disabled={isSubmitting}
          />
          {errors?.length > 0 && (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              {errors?.map(error => (
                <Alert key={error} type={AlertTypes.ERROR}>
                  {error}
                </Alert>
              ))}
            </div>
          )}
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              margin-top: 20px;
            `}
          >
            <Button onClick={handleSubmit} disabled={!password || isSubmitting}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};
