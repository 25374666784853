import React, { useContext } from 'react';
import { css } from '@emotion/react';

import { useSelectActiveChat } from '../../../Chat/store/selectors';
import { Icon, IconTypes } from '../../../../../../components/icons';
import { Button } from '../../../../../../components/core/Button';
import { AuthContext } from '../../../../../../settings/model';

const ExportMessages = () => {
  const { messages } = useSelectActiveChat();
  const { profile } = useContext(AuthContext);

  const CSVData = messages?.map((message) => ({
    User: message.role === 'user' ? message.content : '',
    Helios: message.role === 'assistant' ? message.content : '',
  }));

  const exportChatHandler = () => {
    const csvRows = [
      `Helios session exported by ${profile.username} at ${new Date().toLocaleString('en-US')}`.replace(
        ',',
        '',
      ),
    ];
    const headers = Object.keys(CSVData[0]);
    csvRows.push(headers.join(','));

    for (const row of CSVData) {
      const values = headers.map((header) => {
        const value = row[header];
        if (value.includes('"')) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return `"${value}"`;
      });
      csvRows.push(values);
    }

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${new Date().toLocaleDateString('en-US')}-${new Date().toLocaleTimeString('en-US')}-Helios-export.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Button
        onClick={exportChatHandler}
        disabled={!messages.length}
        css={css`
          width: 100%;
          &:hover,
          &:active {
            svg {
              fill: none;
            }
          }
        `}
      >
        <Icon type={IconTypes.COPY} size="1em" />
        Export chat
      </Button>
    </div>
  );
};

export default ExportMessages;
