import { css } from '@emotion/react';
import React from 'react';

import { Checkbox } from '../../../components/core/Checkbox';
import { Button } from '../../../components/core/Button';

export const HRSitesSourceSelect = ({
  sources,
  selectSource,
  nextIsDisabled,
  onNext
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      `}
    >
      {sources.map(source => (
        <label
          key={source.id}
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            width: 100px;
          `}
        >
          <Checkbox
            checked={source.selected}
            css={css`
              margin-right: 0.5rem;
            `}
            aria-label={`select ${source.title}`}
            // prevent expanding/collapsing selector header
            onChange={event => {
              event.stopPropagation();
              selectSource(source.title);
            }}
          />
          <span>{source.title}</span>
        </label>
      ))}

      <Button disabled={nextIsDisabled} onClick={onNext}>
        Next
      </Button>
    </div>
  );
};
