import _ from 'lodash';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { css } from '@emotion/react';

import { Colors, Mixins, standardRadius } from '../styles';
import { thousandify } from '../utils/NumFmtUtils';
import getTextWidth from '../utils/getTextWidth';
import {
  useBoundingClientRect,
  useDefaultFontSize,
  useUniqueId
} from '../utils/hooks';

export function UsageMeter({ billingPeriod, className }) {
  const { limit, totalUsage } = billingPeriod;

  if (limit === null || (limit === 0 && totalUsage === 0)) {
    return null;
  }

  const maximum = Math.max(limit, totalUsage);
  const overLimit = totalUsage > limit;

  const segments = [];
  if (overLimit) {
    segments.push({
      color: Colors.green4,
      value: totalUsage,
      label: `${thousandify(totalUsage - limit)} over the limit`
    });
    segments.push({
      color: Colors.blue4,
      value: limit,
      label: `${thousandify(limit)} allowed`
    });
  } else {
    segments.push({
      color: Colors.blue4,
      value: totalUsage,
      label: `${thousandify(totalUsage)} used`
    });
    segments.push({
      color: 'white',
      value: maximum,
      label: `${thousandify(Math.max(limit - totalUsage, 0))} unused`
    });
  }

  return <Chart className={className} maximum={maximum} segments={segments} />;
}

UsageMeter.propTypes = {
  billingPeriod: PropTypes.shape({
    limit: PropTypes.number,
    totalUsage: PropTypes.number.isRequired
  }).isRequired
};

export function UploadUsageMeter({
  billingPeriod,
  className,
  uploadCount,
  uploadCountNoText
}) {
  const { limit, totalUsage } = billingPeriod;
  const netUploaded = (uploadCount ?? 0) - (uploadCountNoText ?? 0);
  const usageAfterUpload = totalUsage + netUploaded;

  if (limit === null || (limit === 0 && usageAfterUpload === 0)) {
    return null;
  }

  const overLimit = usageAfterUpload > limit;
  const maximum = Math.max(limit, usageAfterUpload);
  const segments = [
    {
      color: Colors.blue4,
      label: `${thousandify(totalUsage)} used`,
      value: totalUsage
    },
    {
      color: 'white',
      value: maximum,
      label: `${thousandify(Math.max(limit - usageAfterUpload, 0))} ${
        uploadCount ? 'remaining after upload' : 'remaining'
      }`
    }
  ];
  if (uploadCount) {
    let label = `${thousandify(uploadCount)} to be uploaded`;
    if (uploadCountNoText > 0) {
      label += ` (-${thousandify(uploadCountNoText)} with blank Text fields)`;
    }
    segments.unshift({
      color: Colors.blue1,
      label,
      value: usageAfterUpload
    });
  }

  const lines = [];
  if (overLimit) {
    lines.push({ value: limit, label: 'Beyond contract limit' });
  }

  return (
    <Chart
      className={className}
      maximum={maximum}
      segments={segments}
      lines={lines}
    />
  );
}

UploadUsageMeter.propTypes = {
  billingPeriod: PropTypes.shape({
    limit: PropTypes.number,
    totalUsage: PropTypes.number.isRequired
  }).isRequired,
  uploadCount: PropTypes.number,
  uploadCountNoText: PropTypes.number
};

function Chart({ segments, lines, maximum, className }) {
  const ref = useRef();
  const { barHeight, textHeight } = useHeights();
  const { width } = useBoundingClientRect(ref);

  if (maximum === 0 || maximum == null) {
    return null;
  }

  const scale = scaleLinear().range([0, width]).domain([0, maximum]);

  const sortedSegments = _.sortBy(segments, 'value');

  return (
    <div className={className}>
      <svg
        ref={ref}
        css={css`
          height: ${textHeight + barHeight}px;
          width: 100%;
          margin-bottom: 0.5rem;
        `}
      >
        <BarWrapper width={width}>
          {[...sortedSegments].reverse().map(segment => (
            <Bar
              key={segment.label}
              width={scale(segment.value)}
              color={segment.color}
            />
          ))}
        </BarWrapper>
        {lines?.map(line => (
          <LimitLine
            key={line.label}
            x={scale(line.value)}
            label={line.label}
            width={width}
          />
        ))}
      </svg>
      {sortedSegments.map(segment => (
        <Key key={segment.label} color={segment.color}>
          {segment.label}
        </Key>
      ))}
    </div>
  );
}

Chart.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    }).isRequired
  ),
  maximum: PropTypes.number.isRequired
};

function Bar({ width, color }) {
  const { barHeight, textHeight } = useHeights();
  return (
    <rect x={0} y={textHeight} width={width} height={barHeight} fill={color} />
  );
}

function Key({ color, children }) {
  return (
    <span
      css={css`
        margin-right: 2rem;
      `}
    >
      <ColorSquare color={color} /> {children}
    </span>
  );
}

function ColorSquare({ color }) {
  return (
    <svg
      viewBox="0 0 1 1"
      css={css`
        ${Mixins.roundedCorners};
        height: 1rem;
        border: 1px solid ${Colors.blue6};
        vertical-align: text-bottom;
      `}
    >
      <rect x={0} y={0} height={1} width={1} fill={color} />
    </svg>
  );
}

function LimitLine({ x, width, label }) {
  const rem = useDefaultFontSize();
  const { barHeight, textHeight } = useHeights();
  const fontSize = 0.875 * rem;
  const textWidth = getTextWidth(label, `${fontSize}px`);
  const cutoffLeft = 0 > x - textWidth / 2;
  const cutoffRight = width < x + textWidth / 2;
  const textAnchor = cutoffLeft ? 'start' : cutoffRight ? 'end' : 'middle';
  const adjustedX = cutoffLeft ? 0 : cutoffRight ? width : x;

  return (
    <>
      <text
        x={adjustedX}
        textAnchor={textAnchor}
        fontSize={fontSize}
        dominantBaseline="middle"
        y={textHeight / 2}
      >
        {label}
      </text>
      <line
        x1={x}
        x2={x}
        y1={textHeight}
        y2={`${barHeight + textHeight}px`}
        stroke="black"
        strokeDasharray="5"
      />
    </>
  );
}

function BarWrapper({ children, width }) {
  const { barHeight, textHeight } = useHeights();
  const clipPathId = useUniqueId();
  const shape = {
    x: 0,
    y: textHeight,
    width,
    height: barHeight,
    rx: standardRadius // rounded corners
  };

  return (
    <>
      <defs>
        <clipPath id={clipPathId}>
          <rect {...shape} />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipPathId})`}>
        {children}
        <rect {...shape} fill="none" stroke={Colors.blue6} strokeWidth="2px" />
      </g>
    </>
  );
}

function useHeights() {
  const oneRem = useDefaultFontSize();
  return {
    textHeight: oneRem,
    barHeight: oneRem
  };
}
