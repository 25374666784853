import React from 'react';
import { useEffect } from 'react';
import Conversation from './components/Conversation';
import ChatInput from './components/ChatInput';
import { ChatScrollContainer } from './styled';
import {
  useSelectActiveChat,
  useSelectInitConversation,
  useSelectSendMessage,
  useSelectUpdateRatingOfMessage,
  useSelectIsAwaitingForFeedback,
  useSelectSetDocSearchResults,
  useSelectSetFilter,
} from './store/selectors';
import { CHAT_MESSAGE_TYPE } from '../../types';

import useScrollTo from './hooks/useScrollTo';

import { StoreContext } from '../../../../StoreContext';
import { useFilter } from '../../../../search_params';

const Chat = () => {
  const {
    messages,
    isBotReplying,
    isConversationStarted,
    isInteractionFromMessageRequired,
  } = useSelectActiveChat();
  const initConversation = useSelectInitConversation();
  const sendMessage = useSelectSendMessage();
  const updateRatingOfMessage = useSelectUpdateRatingOfMessage();
  const isAwaitingForFeedback = useSelectIsAwaitingForFeedback();
  const [scrollToRef, setScrollToLast] = useScrollTo();
  const setDocSearchResults = useSelectSetDocSearchResults();
  const setFilter = useSelectSetFilter();

  const { docSearchResults } = React.useContext(StoreContext);
  const filter = useFilter();
  useEffect(() => {
    setDocSearchResults(docSearchResults);
    setFilter(filter);
  }, [docSearchResults, filter]);

  const handleMessageSubmit = (message) => {
    const type = isAwaitingForFeedback
      ? CHAT_MESSAGE_TYPE.FEEDBACK
      : CHAT_MESSAGE_TYPE.TEXT;
    return sendMessage(message, type);
  };

  useEffect(() => {
    if (!isConversationStarted) {
      initConversation();
    }

    if (messages.length) {
      setScrollToLast(messages.length);
    }
  }, [
    isConversationStarted,
    initConversation,
    messages.length,
    setScrollToLast,
  ]);

  return (
    <>
      <ChatScrollContainer>
        <Conversation
          onClickBtn={sendMessage}
          onChangeRating={updateRatingOfMessage}
          history={messages}
          ref={scrollToRef}
        />
      </ChatScrollContainer>

      {/* <ChatInput */}
      {/*   onSubmit={handleMessageSubmit} */}
      {/*   /\* disabled={isBotReplying || isInteractionFromMessageRequired} *\/ */}
      {/*   disabled={false} */}
      {/* /> */}
      <ChatInput onSubmit={handleMessageSubmit} />
    </>
  );
};

export default Chat;
