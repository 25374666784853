import React, { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  ManagementPageContainer,
  Subtitle,
  TableAndControlsContainer
} from './managementPageStyles';
import { NavigationDropdown } from './NavigationDropdown';
import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import { AuthContext } from './model';
import Spinner from '../components/core/Spinner';
import MultiSelectTable, {
  useClickableSelections
} from '../components/MultiSelectTable';
import { UsageMeter } from './UsageMeter';

function findBillingPeriod(usageReport, billingPeriodIndex) {
  const billingPeriods = usageReport?.getBillingPeriods() ?? [];
  let billingPeriod;
  if (billingPeriodIndex == null) {
    billingPeriod = billingPeriods?.[0];
  } else {
    billingPeriod = billingPeriods?.[billingPeriodIndex];
  }
  const months = billingPeriod?.months ?? usageReport?.months;
  return [billingPeriod, months];
}

export default function OrganizationUsage() {
  const { organizationId, billingPeriodIndex } = useParams();
  const { profile } = useContext(AuthContext);
  const organization = profile.organizationsById[organizationId];
  const usageReport = organization.getUsageReport();
  const [billingPeriod, months] = findBillingPeriod(
    usageReport,
    billingPeriodIndex
  );
  const isEmpty = _.isEmpty(usageReport?.billingPeriods) && _.isEmpty(months);
  const rows = months?.map(month => {
    return {
      ...month,
      label: month.timestamp.format('MMMM YYYY'),
      clickable: true
    };
  });
  const primaryKey = 'timestamp';
  const selections = useClickableSelections(
    rows,
    primaryKey,
    organizationId,
    billingPeriodIndex
  );

  function formatMonthlyUsage(row) {
    const total = row.total_usage.toLocaleString();
    const uploaded = row.docs_uploaded.toLocaleString();
    const refunded = row.docs_refunded.toLocaleString();
    return (
      <>
        <MonthlyUploadCount>{total}</MonthlyUploadCount>
        {row.docs_refunded > 0 && (
          <MonthlyUploadRefund>
            ({uploaded} documents uploaded - {refunded} refunded)
          </MonthlyUploadRefund>
        )}
      </>
    );
  }

  const columns = [
    {
      key: 'timestamp',
      label: 'Month',
      width: '1fr',
      render: row => row.label,
      defaultSortColumn: true,
      defaultSortDirection: 'desc'
    },
    {
      key: 'total_usage',
      label: 'Document usage',
      width: '2fr',
      render: formatMonthlyUsage
    }
  ];

  const getSubrows = row => row.workspaces;

  const subrowColumns = [
    { key: 'name' },
    { key: 'total_usage', render: formatMonthlyUsage }
  ];

  return (
    <ManagementPageContainer>
      <NavigationDropdown
        message="Switch your organization"
        options={profile.organizations.map(o => ({
          name: o.name,
          id: o.organization_id
        }))}
        buildPath={organizationId =>
          buildRoutePath(RoutePatterns.ORGANIZATION_USAGE, { organizationId })
        }
      />
      <h2
        css={css`
          margin-left: 0.5625rem;
        `}
      >
        {organization.name}
      </h2>
      <h4>Usage</h4>
      {usageReport == null ? (
        <Spinner size="large" />
      ) : (
        <TableAndControlsContainer>
          {billingPeriod && (
            <UsageMeter
              billingPeriod={billingPeriod}
              css={css`
                margin-left: 0.5625rem;
                margin-bottom: 0.5rem;
              `}
            />
          )}
          <UsageSummary
            usageReport={usageReport}
            billingPeriod={billingPeriod}
            isEmpty={isEmpty}
          />
          {billingPeriod?.totalUsage === 0 ? (
            <Subtitle>There was no usage in this billing period.</Subtitle>
          ) : (
            <MultiSelectTable
              rows={rows}
              columns={columns}
              primaryKey={primaryKey}
              selections={selections}
              editable={true}
              getSubrows={getSubrows}
              subrowColumns={subrowColumns}
            />
          )}
        </TableAndControlsContainer>
      )}
    </ManagementPageContainer>
  );
}

function UsageSummary({ billingPeriod, usageReport, isEmpty }) {
  if (isEmpty) {
    return (
      <Summary>It looks like you don't have any usage to display.</Summary>
    );
  }
  if (billingPeriod == null) {
    return (
      <Summary>
        Currently viewing all usage by month
        <BillingPeriodNavigationDropdown usageReport={usageReport} />
      </Summary>
    );
  }
  return (
    <Summary>
      <SummaryLine title="Billing period">
        {billingPeriod.title}
        <BillingPeriodNavigationDropdown usageReport={usageReport} />
      </SummaryLine>
      <SummaryLine title="Total document usage">
        {billingPeriod.totalUsage.toLocaleString()}
        {billingPeriod.includesRefund && '*'}
      </SummaryLine>
      <SummaryLine title="Limit">
        {billingPeriod.limit?.toLocaleString() ?? 'No limit'}
      </SummaryLine>
      {billingPeriod.percentUsed !== null && (
        <SummaryLine title="Percentage used">
          {billingPeriod.percentUsed}
        </SummaryLine>
      )}
      {billingPeriod.includesRefund && (
        <SummaryFootnote>
          * {billingPeriod.uploaded.toLocaleString()} documents uploaded -{' '}
          {billingPeriod.refunded.toLocaleString()} refunded
        </SummaryFootnote>
      )}
    </Summary>
  );
}

function BillingPeriodNavigationDropdown({ usageReport }) {
  const { organizationId } = useParams();
  const billingPeriods = usageReport.getBillingPeriods();

  function buildPath(index) {
    return buildRoutePath(RoutePatterns.ORGANIZATION_USAGE, {
      organizationId,
      billingPeriodIndex: index
    });
  }

  const options = billingPeriods
    .map((bp, index) => ({
      name: bp.title,
      id: index.toString()
    }))
    .concat({ name: 'All usage by month', id: 'all' });

  return (
    <span
      css={css`
        margin-left: 1rem;
        line-height: normal;
      `}
    >
      <NavigationDropdown
        buildPath={buildPath}
        message="See another billing period"
        options={options}
      />
    </span>
  );
}

const Summary = styled.div`
  line-height: 2;
  margin-bottom: 1rem;
  margin-left: 0.5625rem;
`;
function SummaryLine({ title, children }) {
  return (
    <div>
      <SummaryTitle>{title}: </SummaryTitle>
      {children}
    </div>
  );
}
const SummaryTitle = styled.span`
  font-weight: bold;
`;
const SummaryFootnote = styled.div`
  font-style: italic;
`;

const MonthlyUploadCount = styled.div`
  display: inline-block;
  min-width: 9rem;
  text-align: right;
`;
const MonthlyUploadRefund = styled.span`
  margin-left: 1rem;
  font-style: italic;
`;
