import React from 'react';
import { css } from '@emotion/react';

import { Icon, IconTypes } from '../../components/icons';
import { Colors } from '../../styles';
import { Card } from '../../components/Card';
import { useConceptCountContext } from './ConceptCountProvider';
import { Button } from '../../components/core/Button';

export const PlainConcept = ({ concept }) => (
  <ConceptListEntry concept={concept} />
);

export const DeletedConcept = ({ concept }) => (
  <ConceptListEntry
    concept={concept}
    color={Colors.red5}
    iconType={IconTypes.MINUS}
    conceptDisplayFn={concept => <s>{concept.concept}</s>}
  />
);

export const AddedConcept = ({ concept }) => (
  <ConceptListEntry
    concept={concept}
    color={Colors.green7}
    iconType={IconTypes.ADD}
  />
);

export const TrashableConcept = ({ concept, onClick }) => (
  <ConceptListEntry
    concept={concept}
    iconColor={Colors.red5}
    iconType={IconTypes.TRASH}
    iconAfterLabel={true}
    iconOnClick={onClick}
    iconAriaLabel="Remove assertion"
  />
);

export const MergeDeletedConcept = ({ concept }) => (
  <ConceptListEntry
    merge={true}
    concept={concept}
    color={Colors.red5}
    iconType={IconTypes.MINUS}
    conceptDisplayFn={concept => (
      <s>
        {concept.concept} merged with {concept.merge_with}
      </s>
    )}
  />
);

export const MergeAddedConcept = ({ concept }) => (
  <ConceptListEntry
    merge={true}
    concept={concept}
    conceptDisplayFn={concept => (
      <>
        {concept.concept} merged with {concept.merge_with}
      </>
    )}
    color={Colors.green7}
    iconType={IconTypes.ADD}
  />
);

export const MergePlainConcept = ({ concept }) => (
  <ConceptListEntry
    merge={true}
    concept={concept}
    conceptDisplayFn={concept => (
      <>
        {concept.concept} merged with {concept.merge_with}
      </>
    )}
  />
);

export const EditableConceptsListMerge = ({ concepts, onRemoveMerge }) => (
  <div
    css={css`
      overflow-y: auto;
    `}
  >
    {concepts.map((concept, index) => (
      <MergeableConcept
        key={`${concept.concept}-${concept.merge_with}-${index}`}
        concept={concept}
        onClick={() => {
          onRemoveMerge(concept);
        }}
      />
    ))}
  </div>
);

const MergeableConcept = ({ concept, onClick }) => (
  <Card.Section
    data-test-id="merge-concept-list-entry"
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: ${Colors.blue5};
      gap: 0.5rem;
      &:not(:last-child) {
        border-bottom: 1px solid ${Colors.gray1};
      }
    `}
  >
    <div>
      <span>{`${concept.concept} merged with ${concept.merge_with}`}</span>
    </div>
    <Button
      aria-label="Remove merge"
      onClick={onClick}
      flavor="subtle"
      palette="red"
      padded={false}
    >
      <Icon type={IconTypes.TRASH} />
    </Button>
  </Card.Section>
);

export const EditableConceptsList = ({ concepts, onRemoveConcept }) => (
  <div
    css={css`
      overflow-y: auto;
    `}
  >
    {concepts.map(concept => (
      <TrashableConcept
        key={concept.concept}
        concept={concept}
        onClick={() => {
          onRemoveConcept(concept);
        }}
      />
    ))}
  </div>
);

export const ConceptListEntry = ({
  concept,
  color,
  iconColor,
  iconType,
  iconOnClick,
  iconAriaLabel,
  conceptDisplayFn = c => c.concept,
  iconAfterLabel = false,
  merge = false
}) => {
  const { conceptCounts } = useConceptCountContext();

  const icon = (
    <div
      css={css`
        width: 1rem;
        display: flex;
        align-items: center;
        margin-left: ${iconAfterLabel && 'auto'};
        color: ${iconColor};
      `}
    >
      {iconType &&
        (iconOnClick ? (
          <Button
            aria-label={iconAriaLabel}
            onClick={iconOnClick}
            flavor="subtle"
            palette="red"
            padded={false}
          >
            <Icon type={iconType} />
          </Button>
        ) : (
          <Icon type={iconType} />
        ))}
    </div>
  );

  const label = (
    <div>
      <span>{conceptDisplayFn(concept)}</span>
      <span>
        {conceptCounts && conceptCounts[concept.concept]
          ? ' (' + conceptCounts[concept.concept] + ')'
          : null}
      </span>
    </div>
  );

  const labelMerge = (
    <div>
      <span>{conceptDisplayFn(concept)}</span>
      <span>
        {conceptCounts &&
        (conceptCounts[concept.concept] || conceptCounts[concept.merge_with])
          ? ' (' +
            ((conceptCounts[concept.concept] || 0) +
              (conceptCounts[concept.merge_with] || 0)) +
            ')'
          : null}
      </span>
    </div>
  );

  return (
    <Card.Section
      data-test-id="concept-list-entry"
      css={css`
        display: flex;
        flex-direction: row;
        color: ${color};
        gap: 0.5rem;
        &:not(:last-child) {
          border-bottom: 1px solid ${Colors.gray1};
        }
      `}
    >
      {iconAfterLabel ? (
        <>
          {merge ? labelMerge : label} {icon}
        </>
      ) : (
        <>
          {icon} {merge ? labelMerge : label}
        </>
      )}
    </Card.Section>
  );
};
