import React from 'react';

import { WhichConceptsToVisualize } from './WhichConceptsToVisualize';
import CalculateMatchCountPercentage from './CalculateMatchCountPercentage';
import { ConfigSectionWrapper } from './ConfigWrapper';

export function SentimentSettings() {
  return (
    <>
      <WhichConceptsToVisualize />
      <ConfigSectionWrapper>
        <CalculateMatchCountPercentage />
      </ConfigSectionWrapper>
    </>
  );
}
