import React from 'react';

import { useTranslation } from 'react-i18next';

import Card from '../../../../components/organisms/Card';

const UserManual = () => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        title={t('user_manual.title')}
        description={t('user_manual.details')}
      />
    </>
  );
};

export default UserManual;
