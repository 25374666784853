import React from 'react';
import PropTypes from 'prop-types';

import Progress from '../components/core/Progress';
import Spinner from '../components/core/Spinner';
import { thousandify } from '../utils/NumFmtUtils';

export function UploadProgressPage({ filename, totalDocs, uploadedDocs }) {
  return (
    <div className="upload-page__result-container upload-page__result-container--uploading">
      <p className="upload-page__result-msg upload-page__result-msg--header">
        Uploading documents from {filename}
      </p>
      <p className="upload-page__result-msg upload-page__result-msg--header">
        Please keep this browser tab open until the file upload is complete
      </p>
      <Progress
        className="upload-page__uploading-progress"
        max={totalDocs}
        value={uploadedDocs}
      />
      <p className="upload-page__result-msg upload-page__result-msg--footer">
        <Spinner /> {thousandify(uploadedDocs)} / {thousandify(totalDocs)}{' '}
        documents uploaded
      </p>
    </div>
  );
}

UploadProgressPage.propTypes = {
  filename: PropTypes.string.isRequired,
  totalDocs: PropTypes.number.isRequired,
  uploadedDocs: PropTypes.number.isRequired
};
