import _ from 'lodash';
import { css } from '@emotion/react';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Constraint } from '../../classes/Constraints';
import { updateActiveConcept } from '../../actions';
import { Mixins, Colors } from '../../styles';
import { Concept } from '../../classes/Concepts';
import { FeatureFlagsContext } from '../../FeatureFlagsContext';
import { inputHeaderText } from './ActiveConceptEditor';
import { Icon, IconTypes } from '../../components/icons';
import { StoreContext } from '../../StoreContext';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../utils/sentimentStatus';

export function ConstituentList({ concept, ...props }) {
  const featureFlags = useContext(FeatureFlagsContext);
  if (!featureFlags.boolean_search) {
    return (
      <PillBox
        {...props}
        concept={concept}
        texts={concept.texts}
        color={Colors.blue1}
      />
    );
  }

  return (
    <>
      <span css={inputHeaderText}>Included - All matches</span>
      <PillBox
        {...props}
        concept={concept}
        color={Colors.blue1}
        texts={concept.included ?? concept.texts}
      />
      {concept.excluded?.length > 0 && (
        <>
          <span css={inputHeaderText}>Excluded - Exact matches only</span>
          <PillBox
            {...props}
            concept={concept}
            color={Colors.red1}
            texts={concept.excluded}
            allowRemovingLast
          />
        </>
      )}
    </>
  );
}

ConstituentList.propTypes = {
  projectId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.instanceOf(Constraint).isRequired)
    .isRequired,
  concept: PropTypes.instanceOf(Concept).isRequired
};

function PillBox({
  concept,
  filter,
  projectId,
  color,
  texts,
  allowRemovingLast = false,

}) {
  const { project } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY
  function removeConcept(conceptText) {
    const updated = concept.removeTexts(conceptText);
    updateActiveConcept(projectId, updated, filter, true, isSentimentReady);
  }

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        max-height: 8rem;
        margin: 0.5rem 0;
        overflow-y: scroll;
      `}
    >
      {texts.map(text => {
        return (
          <Pill
            key={text}
            onRemove={
              allowRemovingLast || texts.length > 1
                ? _.partial(removeConcept, text)
                : null
            }
            text={text}
            color={color}
          />
        );
      })}
    </div>
  );
}

PillBox.propTypes = {
  projectId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.instanceOf(Constraint).isRequired)
    .isRequired,
  concept: PropTypes.instanceOf(Concept).isRequired,
  color: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

function Pill({ text, onRemove, color }) {
  return (
    <span
      data-test-id="pill"
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        padding: 0.5em 1em;
        font-size: 0.75em;
        max-width: calc(100% - 2 * 1rem);
        color: ${Colors.gray9};
        background: ${color};
        border-radius: 1rem; /* semi-circular edges */
      `}
    >
      <span
        css={css`
          ${Mixins.ellipsify};
          flex: 1;
        `}
      >
        {text}
      </span>
      {onRemove && (
        <button
          onClick={onRemove}
          aria-label={`Remove ${text}`}
          data-tracking-item="edit-saved-concept_remove-text"
          css={css`
            display: flex;
            align-items: center;
            flex-shrink: 0;
            color: inherit;
            background: transparent;
            margin-left: 0.25em;
            padding-left: 0.125rem;

            &:hover {
              color: inherit;
              background: inherit;
            }
          `}
        >
          <Icon type={IconTypes.CLOSE} size="0.625rem" />
        </button>
      )}
    </span>
  );
}

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  onRemove: PropTypes.func
};
