import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import { ConversationContainer } from './styled';
import ChatMessage from '../ChatMessage';
import { CHAT_MESSAGE_TYPE } from '../../../../types';
import { useAssistantStore } from '../../store';
import ChatStatusInfo from './ChatStatusInfo';

const Conversation = forwardRef(function Conversation(
  { history, onClickBtn, onChangeRating },
  ref,
) {
  const store = useAssistantStore((store) => store);

  const chatbotStatus = store.chatbotStatus;

  const actions = {
    [`${CHAT_MESSAGE_TYPE.BUTTONS}`]: onClickBtn,
    [`${CHAT_MESSAGE_TYPE.TEXT_WITH_RATING}`]: onChangeRating,
  };

  return (
    <ConversationContainer component="ul" ref={ref}>
      {chatbotStatus !== 'ready' && <ChatStatusInfo />}
      {chatbotStatus === 'ready' && (
        <AnimatePresence>
          {!history.length
            ? null
            : history.map(
                ({ id, role, type, content, placeholder, created_at }, idx) => {
                  const action = actions[type] || null;
                  return (
                    <ChatMessage
                      id={id}
                      type={type}
                      author={role}
                      dateTime={created_at}
                      userAction={action}
                      key={`[${idx}]:${role}:${content}`}
                      placeholder={placeholder}
                    >
                      {content}
                    </ChatMessage>
                  );
                },
              )}
        </AnimatePresence>
      )}
    </ConversationContainer>
  );
});

Conversation.propTypes = {
  history: PropTypes.array,
  onClickBtn: PropTypes.func.isRequired,
  onChangeRating: PropTypes.func.isRequired,
};

export default Conversation;
