import React from 'react';
import PropTypes from 'prop-types';

export const TooltipRow = ({ header, value }) => {
  return (
    <div className="drivers-tooltip-content__row">
      <div className="drivers-tooltip-content__header">{header}</div>
      <div className="drivers-tooltip-content__value">{value}</div>
    </div>
  );
}

TooltipRow.propTypes = {
  header: PropTypes.string,
}