import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { addActiveConcepts, selectConcept, selectConceptOnlySearch } from '../actions';
import ConceptComponent, { getNewConceptColor } from '../components/Concept';
import Autosuggest from '../components/core/Autosuggest';
import { Concept } from '../classes/Concepts';
import { RemoveActiveConcept } from './RemoveActiveConcept';
import { Button } from '../components/core/Button';
import { StoreContext } from '../StoreContext';
import { ActiveConceptEditor } from './active_concept_editor/ActiveConceptEditor';
import { FeatureFlagsContext } from '../FeatureFlagsContext';
import { Colors, Mixins } from '../styles';
import { useFilter, useSearchParams } from '../search_params';
import Tooltip from '../components/core/Tooltip';
import { Icon, IconTypes } from '../components/icons';
import { useConceptManagement } from '../data_hooks';
import { getSpecifiedConcepts } from '../utils/ApiUtilsV5';
import { useCurrentView } from '../side_panel/views/view';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';
import { ConceptTypes } from '../constants';

const optionFromConcept = concept => {
  return {
    id: concept.sharedConceptId ?? Concept.toString(concept),
    text: concept.name
  };
};

const SelectedConceptDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    min-width: 0;
`;

const SelectedConceptTooltipWrapper = styled.div`
    margin-right: 1rem;
`;

const SelectionIsActive = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 0;
    max-width: 20rem;
    padding: 0 0.5rem;
    background: ${Colors.blue0};
    ${Mixins.roundedCorners};
    ${Mixins.shadowOutset};
`;

const TooltipEm = styled.em`
    color: ${Colors.red5};
`;

export default function SelectedConcept({
                                          onInputTextChange,
                                          selectedConceptMatchesSearch,
                                          onlySearch = false
                                        }) {
  const { projectId } = useParams();
  const filter = useFilter();
  const { selection, topConcepts, activeConcepts, project } =
    React.useContext(StoreContext);

  const { conceptManagement } = useConceptManagement(project);

  const { concepts } = usePostSpecifiedConcepts(conceptManagement);

  const featureFlags = useContext(FeatureFlagsContext);
  const [editFlyoutIsOpen, setEditFlyoutIsOpen] = useState(false);
  const activeConceptsTexts = useMemo(
    () => new Set(activeConcepts.map(ac => ac.toString())),
    [activeConcepts]
  );
  const findConceptFromId = useCallback(
    optionId => {
      return (
        activeConcepts.find(concept => concept.sharedConceptId === optionId) ||
        topConcepts.find(concept => concept.toString() === optionId) ||
        concepts.find(concept => concept.toString() === optionId)
      );
    },
    [activeConcepts, topConcepts, concepts]
  );

  const selectionIsActive =
    selection &&
    activeConcepts.find(ac => {
      return Concept.areTextsEqual(ac, selection);
    }) !== undefined;

  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;

  const prevSelection = useRef(selection);
  useEffect(() => {
    if (selection?.sharedConceptId !== prevSelection.current?.sharedConceptId) {
      setEditFlyoutIsOpen(false);
    }
    prevSelection.current = selection;
  }, [selection, selectionIsActive, setEditFlyoutIsOpen]);

  const [error, setError] = React.useState(false);

  const renderSuggestion = useCallback(
    ({ suggestion }) => {
      const concept = findConceptFromId(suggestion.id);
      return <SuggestedConcept concept={concept} />;
    },
    [findConceptFromId]
  );
  const getInputText = useCallback(
    value => {
      if (typeof value === 'string') {
        return value;
      }

      const concept = findConceptFromId(value.id);

      if (concept?.type === ConceptTypes.AND) {
        return value.text;
      }

      return concept?.toString() ?? value.text;
    },
    [findConceptFromId]
  );
  const autosuggestOptions = useMemo(
    () => [
      ...activeConcepts.map(optionFromConcept),
      ...concepts
        .filter(concept => !activeConceptsTexts.has(concept.toString()))
        .map(optionFromConcept),
      ...topConcepts
        .filter(concept => !activeConceptsTexts.has(concept.toString()))
        .map(optionFromConcept)
    ],
    [activeConcepts, topConcepts, activeConceptsTexts, concepts]
  );
  const handleSelect = useCallback(
    selection => {
      const matchingConcept = findConceptFromId(selection);
      if (onlySearch) {
        if (matchingConcept) {
          selectConceptOnlySearch(projectId, matchingConcept, filter, isSentimentReady);
        } else {
          try {
            selectConceptOnlySearch(
              projectId,
              Concept.fromString(selection),
              filter,
              isSentimentReady
            );
          } catch (e) {
            setError(e.message);
          }
        }
      } else {
        if (matchingConcept) {
          selectConcept(projectId, matchingConcept, filter, isSentimentReady);
        } else {
          try {
            selectConcept(
              projectId,
              Concept.fromString(selection),
              filter,
              isSentimentReady
            );
          } catch (e) {
            setError(e.message);
          }
        }
      }
    },
    [projectId, findConceptFromId, filter]
  );
  const handleTextChange = useCallback(
    newText => {
      setError(null);
      onInputTextChange(newText);
    },
    [onInputTextChange]
  );
  const defaultOption = useMemo(
    () => selection && optionFromConcept(selection),
    [selection]
  );
  return (
    <SelectedConceptDiv>
      <SelectedConceptTooltipWrapper>
        <Tooltip
          tooltipWidth="24rem"
          position="right"
          visible={!!error}
          anchor={
            <Autosuggest
              id="concepts-work-area_search-box"
              fillWidth
              css={
                error &&
                css`
                    input {
                        /* replace the normal focus outline with an error outline */
                        outline: none;
                        ${Mixins.errorOutline};
                    }
                `
              }
              aria-label="Concept search"
              placeholder={
                featureFlags.boolean_search
                  ? 'Example: coffee, -tea'
                  : 'No selection'
              }
              disabled={editFlyoutIsOpen}
              renderSuggestion={renderSuggestion}
              getInputText={getInputText}
              options={autosuggestOptions}
              onSelect={handleSelect}
              defaultOption={defaultOption}
              onTextChange={handleTextChange}
              clearInputOnSubmit={false}
              allowNonOptionSearch
              displayIcons
              trackingPrefix="concept-detail-pane_selected-concept"
            />
          }
        >
          <TooltipEm>{error}</TooltipEm>
        </Tooltip>
      </SelectedConceptTooltipWrapper>
      {selectedConceptMatchesSearch && selection && selectionIsActive && (
        <SelectionIsActive>
          <ConceptComponent concept={selection} />
          {selection && selectionIsActive && (
            <>
              <ActiveConceptEditor
                editFlyoutIsOpen={editFlyoutIsOpen}
                toggleFlyout={() => {
                  setEditFlyoutIsOpen(editFlyoutIsOpen => !editFlyoutIsOpen);
                }}
              />
              <RemoveActiveConcept
                disabled={editFlyoutIsOpen}
                projectId={projectId}
                selection={selection}
                activeConcepts={activeConcepts}
              />
            </>
          )}
        </SelectionIsActive>
      )}
      {!onlySearch && selectedConceptMatchesSearch && selection && !selectionIsActive && (
        <AddConcept />
      )}
    </SelectedConceptDiv>
  );
}

SelectedConcept.propTypes = {
  selectedConceptMatchesSearch: PropTypes.bool.isRequired,
  onInputTextChange: PropTypes.func.isRequired,
  onlySearch: PropTypes.bool
};

export const AddConcept = () => {
  const { projectId } = useParams();
  const { selection, suggestions, activeConcepts } =
    React.useContext(StoreContext);
  const { conceptSelector } = useCurrentView();
  const { searchParams } = useSearchParams();
  const { filter } = searchParams;
  const suggestion = suggestions.find(s => s.equals(selection));

  return (
    <>
      <Button
        padded={false}
        css={css`
            padding: 0 0.5rem;
        `}
        onClick={() => {
          addActiveConcepts(
            conceptSelector,
            filter,
            projectId,
            selection.update({
              color: suggestion
                ? suggestion.color
                : getNewConceptColor(activeConcepts)
            })
          );
        }}
        data-tracking-item="concept-detail-pane_selected-concept_save-concept-button"
      >
        <Icon type={IconTypes.ADD} />
        Add to active concepts
      </Button>
    </>
  );
};

// Align with active concepts that display circle
const SuggestedConceptDiv = styled.div`
    margin-left: 1.5rem;
`;

const SuggestedConcept = ({ concept }) => {
  return concept.isActive ? (
    <ConceptComponent concept={concept} />
  ) : (
    <SuggestedConceptDiv>{concept.toString()}</SuggestedConceptDiv>
  );
};

SuggestedConcept.propTypes = {
  concept: PropTypes.instanceOf(Concept).isRequired
};

export function usePostSpecifiedConcepts(conceptManagement) {
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [concepts, setConcepts] = useState([]);

  useEffect(() => {
    if (!conceptManagement || !conceptManagement.currentBuild || !projectId) {
      return; // Only proceed if all data is available
    }

    setIsLoading(true);
    const notice = conceptManagement.currentBuild.notice;
    const collocate = conceptManagement.currentBuild.collocate;
    const unifiedArray = notice.concat(collocate);
    const transformedArray = unifiedArray.map(item => ({
      texts: [item.concept]
    }));

    if (transformedArray.length) {
      getSpecifiedConcepts(projectId, transformedArray)
        .then(response => {
          setConcepts(response.concepts);
          setIsLoading(false);
        })
        .catch(err => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [conceptManagement, projectId]);

  return { isLoading, error, concepts };
}
