import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Category } from '../classes/MetadataFields';
import { thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';
import { Icon, IconTypes } from './icons';
import { Button } from './core/Button';

const ViewContainer = styled.span`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
`;

const ViewValue = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  padding-right: 1rem;
  flex: 1;
  overflow: ellipsis;
`;

const ViewCount = styled.span`
  color: ${Colors.gray5};
`;

export default function CategoryView({ category }) {
  const { value, count } = category;

  return (
    <ViewContainer data-test-id="category-view">
      <CollapsableViewValue value={value} />
      <ViewCount>({thousandify(count)})</ViewCount>
    </ViewContainer>
  );
}

CategoryView.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired
};

function CollapsableViewValue({ value }) {
  const [expanded, setExpanded] = useState(true);

  const formattedValue =
    value.length >= 40 ? `${value.slice(0, 40).trim()}...` : value;

  return (
    <ViewValue
      data-test-id="category-view-value"
      // TODO replace with Tooltip
      title={value}
    >
      <span>{expanded ? value : formattedValue}</span>
      {value.length >= 40 && (
        <Button
          css={css`
            padding: 0;
          `}
          flavor="subtle"
          palette="gray"
          onClick={() => setExpanded(prevState => !prevState)}
        >
          {expanded ? (
            <Icon type={IconTypes.CARET_STROKE} size="18" direction="up" />
          ) : (
            <Icon type={IconTypes.CARET_STROKE} size="18" direction="down" />
          )}
        </Button>
      )}
    </ViewValue>
  );
}
