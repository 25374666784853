import { CHAT_MESSAGE_TYPE } from '../../../../types';
import { WithText } from './WithText';
import { WithTextAndRating } from './WithTextAndRating';
import { WithButtons } from './WithButtons';
import { WithSytemInfo } from './WithSytemInfo';

const MessageContentType = new Map();

MessageContentType.set(CHAT_MESSAGE_TYPE.TEXT, WithText);
MessageContentType.set(CHAT_MESSAGE_TYPE.FEEDBACK, WithText);
MessageContentType.set(CHAT_MESSAGE_TYPE.TEXT_WITH_RATING, WithTextAndRating);
MessageContentType.set(CHAT_MESSAGE_TYPE.BUTTONS, WithButtons);
MessageContentType.set(CHAT_MESSAGE_TYPE.INFO, WithSytemInfo);
MessageContentType.set(CHAT_MESSAGE_TYPE.AWAITING, WithSytemInfo);

export default MessageContentType;
