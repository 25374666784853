import { css } from '@emotion/react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../../styles';
import { Button } from '../../components/core/Button';
import { Icon, IconTypes } from '../../components/icons';
import { TagEditor } from './TagEditor';

export function ActiveConceptEditor({ editFlyoutIsOpen, toggleFlyout }) {
  const ref = useRef();

  return (
    <>
      <Button
        ref={ref}
        padded={false}
        flavor="subtle"
        aria-label="Edit"
        onClick={toggleFlyout}
        disabled={editFlyoutIsOpen}
        trackingItem="concept-detail-pane_selected-concept_edit-saved-concept"
        css={css`
          margin-left: 1rem;
          margin-right: 0.5rem;
        `}
      >
        <Icon type={IconTypes.PEN} />
      </Button>
      {editFlyoutIsOpen && <TagEditor closeModal={toggleFlyout} />}
    </>
  );
}

ActiveConceptEditor.propTypes = {
  editFlyoutIsOpen: PropTypes.bool.isRequired,
  toggleFlyout: PropTypes.func.isRequired
};

export const inputHeaderText = css`
  font-size: 0.875rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  color: ${Colors.gray5};
`;

export const flyoutInputStyle = css`
  width: 100%;
  margin-right: 1rem;
  ::placeholder {
    font-style: italic;
  }
`;

export const flyoutButtonStyle = css`
  height: 2.5rem;
  margin: 0.5rem 0;
  align-self: flex-end;
`;
