import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

export const Rate = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#626264',
  },
  '& .MuiRating-iconHover': {
    color: '#626264',
  },
  '& .MuiRating-iconEmpty': {
    color: '#878C96',
  },
});

export const RateLabel = styled(Typography)``;

export const RateContainer = styled(Box)`
  margin-top: 1rem;

  p.rate-label {
    margin-bottom: 0.5rem;
  }
`;
