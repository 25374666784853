import React, { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { HoverProvider } from '../HoverContext';
import { GalaxyWorkArea } from '../Galaxy';

const OnlyGalaxyVisualisation = memo(({projectId}) => {

  useEffect(() => {
    const userflowUI = document.getElementById('userflow-ui');
    const userflowBanner = document.querySelector('.userflowjs-banner');

    if (userflowUI) {
      userflowUI.style.display = 'none';
    }
    if (userflowBanner) {
      userflowBanner.style.display = 'none';
    }

    return () => {
      if (userflowUI) {
        userflowUI.style.display = '';
      }
      if (userflowBanner) {
        userflowBanner.style.display = '';
      }
    };
  }, []);

  return (
    <HoverProvider>
      <div className="workspace">
        <div
          id={'print'}
          css={css`
              position: relative;
              min-height: 0;
              flex: 1;
              display: flex;
              flex-direction: column;
          `}
        >
          <GalaxyWorkArea projectId={projectId} onlyGalaxy={true}></GalaxyWorkArea>
        </div>
      </div>
    </HoverProvider>
  );
});

export default OnlyGalaxyVisualisation;
