import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { css } from '@emotion/react';

import { CheckboxOption } from '../../CheckboxOption';
import { Colors } from '../../../styles';
import { loadMoreDocuments } from '../../../actions';
import { Concept } from '../../../classes/Concepts';
import { useFilter, useSearchParams } from '../../../search_params';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../../utils/sentimentStatus';
import { StoreContext } from '../../../StoreContext';
import { Button } from '../../../components/core/Button';
import Spinner from '../../../components/core/Spinner';
import SelectedConcept from '../../../search_bar/SelectedConcept';
import { DocumentCard } from './DocumentCard';
import { IncludeConceptualMatches } from '../../../side_panel/configure_visualization/VolumeSettings';
import { SentimentToggle, useMatchTypes } from '../../../documents/DocSearchResults';

const DocumentsDisplay = ({ deletedDocs, handleCheckboxChange, markDocumentsForDelete }) => {
  const { searchParams } = useSearchParams();
  const { project, selection,projectLanguage } = React.useContext(StoreContext);
  const [currentSearchText, setCurrentSearchText] = useState(Concept.toString(selection));
  const sentimentStatus = getSentimentStatus(project);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY
  const filter = useFilter();
  const concept = useMemo(() => Concept.fromString(searchParams.search), [searchParams.search]);
  const matchType = useMemo(
    () => (searchParams.match_type === 'total' ? 'both' : 'exact'),
    [searchParams.match_type]
  );
  const {sentiment_indication, toggleSentimentIndication } = useMatchTypes();

  const loadDocuments = async (offset = 0) => {
    const limit = 50;
    const isFirstLoad = offset === 0;

    if (isFirstLoad) setLoading(true);
    else setIsLoadingMore(true);

    try {
      const { matches = [], filterDocCount: totalDocs = 0 } = await loadMoreDocuments(
        project.project_id,
        concept,
        filter,
        matchType,
        getSentimentStatus(project) === SENTIMENT_STATUS.READY,
        limit,
        offset
      );
      setTotalDocs(totalDocs)
      setDocuments(prevDocs => (isFirstLoad ? matches : [...prevDocs, ...matches]));
      setIsEndOfList(offset + matches.length >= totalDocs);
    } catch (error) {
      console.error('Error loading documents:', error);
    } finally {
      if (isFirstLoad) setLoading(false);
      else setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    loadDocuments(0); // Load the first batch of documents

  }, [concept, filter, matchType]);

  const handleLoadMore = () => {
    const offset = documents.length;
    loadDocuments(offset);
  };

  const searchMatchesSelection = useMemo(() => {
    try {
      return Concept.areTextsEqual(Concept.fromString(currentSearchText), selection);
    } catch {
      return false;
    }
  }, [currentSearchText, selection]);

  const isSelectAllChecked = useMemo(
    () => documents.length > 0 && documents.every(doc => deletedDocs.includes(doc.docId)),
    [documents, deletedDocs]
  );

  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      markDocumentsForDelete([]);
    } else {
      const allFilteredValues = documents.map(doc => doc.docId);
      markDocumentsForDelete([...new Set([...deletedDocs, ...allFilteredValues])]);
    }
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 10px 30px;
          align-items: center;
          gap: 2rem;
        `}
      >
        <SelectedConcept
          onInputTextChange={setCurrentSearchText}
          selectedConceptMatchesSearch={searchMatchesSelection}
          onlySearch={true}
        />

        <div
          css={css`
            color: red;
            width: 10rem;
          `}
        >
          {deletedDocs.length > 0 && `${deletedDocs.length} documents marked for deletion`}
        </div>

        <CheckboxOption
          pStyle={'0px'}
          key="selectAll"
          option={{
            value: 'selectAll',
            action: 'Select/Deselect all',
          }}
          selectedValues={deletedDocs}
          handleCheckboxChange={handleSelectAll}
          disabledValues={loading ? ['selectAll'] : []}
          checked={isSelectAllChecked}
        />
        {loading ? (
            <div
              css={css`
                  align-self: center;
                  height: 100px;
                  display: flex;
                  align-items: center;
              `}
            >
              <Spinner />
            </div>
          ) :
          <div
            css={css`
                width: 10rem;
            `}
          >
            Viewing {documents.length} out of {totalDocs || 0} documents
          </div>
        }

      </div>
      <div css={css`
          display: flex;
          align-items: center;
          justify-content: space-evenly;`}>
        {selection && <IncludeConceptualMatches />}
        {isSentimentReady && selection &&  <SentimentToggle isEnabled={sentiment_indication} toggleEnabled={toggleSentimentIndication} />}
      </div>

      <CardSeparator />
      {loading ? (
        <div
          css={css`
              align-self: center;
            height: 100px;
            display: flex;
            align-items: center;
          `}
        >
          <Spinner />
        </div>
      ) : (
        <Scrollbars style={{ width: '100%', height: '90vh' }} autoHide>
          <Container>
            {documents.map((doc, index) => (
              <>
                <DocumentCard
                  selection={selection}
                  projectLanguage={projectLanguage}
                  key={index}
                  doc={doc}
                  selectedValues={deletedDocs}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </>

            ))}
            {isLoadingMore ? (
              <Spinner />
            ) : (
              !isEndOfList && (
                <Button
                  css={css`
                    width: 150px;
                    align-self: center;
                  `}
                  onClick={handleLoadMore}
                  palette="green"
                >
                  Load More
                </Button>
              )
            )}
          </Container>
        </Scrollbars>
      )}
    </>
  );
};

export default DocumentsDisplay;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
`;

const CardSeparator = styled.hr`
  margin: 0;
  border: none;
  border-bottom: 1px solid ${Colors.gray1};
`;
