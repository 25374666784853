import React, { useContext } from 'react';
import { css } from '@emotion/react';

import SimpleModal from '../../../components/core/SimpleModal';
import { Button } from '../../../components/core/Button';
import DocumentsDisplay from './DocumentManageCard';
import { Card } from '../../../components/Card';
import { markDocumentsForDelete } from '../../../actions';
import FilterTool from '../../../components/FilterTool';
import { StoreContext } from '../../../StoreContext';

const ManageDeleteDocumentModal = ({ isOpen, onHide, openConfirmModal, docSearchResults, deletedDocs }) => {
  const { metadata } = useContext(StoreContext);

  const handleCheckboxChange = option => {
    const { docId } = option;
    if (deletedDocs.includes(docId)) {
      markDocumentsForDelete(deletedDocs.filter(item => item !== docId));
    } else {
      markDocumentsForDelete([...deletedDocs, docId]);
    }
  };
  const closeModal = () => {
    onHide();
    if (deletedDocs.length) {
      openConfirmModal();
    }
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      header={<h4>Document Management</h4>}
      css={css`
        width: 100%;
        max-width: 95rem;
        max-height: 90vh;
        display: flex;
        flex-direction: column;
      `}
      containerCss={css`
        max-width: 80vw;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          overflow: auto;
        `}
      >
        <Card.Separator />
        <div
          css={css`
            max-width: 400px;
            width: 30%;
          `}
        >
          <FilterTool enableSelectAllFields={true} metadata={metadata} />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 70%;
            max-height: 80vh;
          `}
        >
          <DocumentsDisplay
            documents={docSearchResults}
            handleCheckboxChange={handleCheckboxChange}
            deletedDocs={deletedDocs}
            markDocumentsForDelete={markDocumentsForDelete}
          />
          <div
            css={css`
              position: absolute;
              bottom: 40px;
              align-self: end;
                right: 40px;
               `}
          >
            <Button
              onClick={closeModal}
              palette="green"
              css={css`
                    width: 150px;
                    align-self: end;
                  `}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

export default ManageDeleteDocumentModal;
