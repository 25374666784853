import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { CheckboxOption } from '../../CheckboxOption';
import { FullDocText } from '../../../documents/FullDocText';

export  const DocumentCard = ({
                        doc, selectedValues,
                        handleCheckboxChange,
                        disabledValues = [],
                        selection,
                        projectLanguage
                      }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const fullHeight = textRef.current.scrollHeight;
    const visibleHeight = textRef.current.clientHeight;
    if (fullHeight > visibleHeight) {
      setCanExpand(true);
    } else {
      setCanExpand(false);
    }
  }, [doc.text]);

  return (
    <Card>
      <CheckboxContainer>
        <CheckboxOption
          pStyle={'0'}
          option={doc}
          selectedValues={selectedValues}
          handleCheckboxChange={handleCheckboxChange}
          disabledValues={disabledValues}
          checked={selectedValues.includes(doc.docId)} // Assumes doc has a 'value' field
        />
      </CheckboxContainer>

      <TextContent ref={textRef} isExpanded={isExpanded}>
        <FullDocText doc={doc} selection={selection} language={projectLanguage} />
      </TextContent>
      {canExpand && (
        <ExpandIcon
          onClick={() => setIsExpanded(!isExpanded)}
          isVisible={canExpand}
        >
          {isExpanded ? '↑' : '↓'}
        </ExpandIcon>
      )}
    </Card>
  );
};

const Card = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    word-wrap: break-word;
    position: relative;
    overflow: hidden;

    @media (max-width: 600px) {
        max-width: 260px; // Smaller width on mobile devices
        padding: 15px;
        margin: 5px;
    }
`;

const TextContent = styled.p`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    -webkit-line-clamp: ${({ isExpanded }) => isExpanded ? 'none' : '4'};
    -webkit-box-orient: vertical;
`;

const ExpandIcon = styled.span`
    display: block;
    text-align: right;
    cursor: pointer;
    color: ${({ isVisible }) => isVisible ? '#0093ff' : '#333'};
    user-select: none;
`;

const CheckboxContainer = styled.div`
    display: flex;
`;
