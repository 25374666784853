import HTTPClient from './client';

export const chats = {
  getOne: async (cid) => {
    const url = `/chats/${cid}/`;
    const response = await HTTPClient.get(url);
    return response;
  },
  getAll: async () => {
    const url = `/chats/`;
    const response = await HTTPClient.get(url);
    return response;
  },
  create: async () => {
    const url = `/chats/`;
    const body = {
      title: `[Test Chat ${
        Math.random().toFixed(2) * 100
      }] - AI Assistant Conversation`,
      projectId: import.meta.env.VITE_TEST_PROJECT_ID,
    };
    const response = await HTTPClient.post(url, body);
    return response;
  },
};

export const messages = {
  create: async (cid, message) => {
    const url = `/chats/${cid}/messages`;
    const body = {
      type: message?.type,
      role: message?.role,
      content: message?.content,
      history: message?.history,
    };
    const response = await HTTPClient.post(url, body);
    return Array.isArray(response) ? response : [response];
  },
  update: async (cid, mid, data) => {
    const url = `/chats/${cid}/messages/${mid}`;
    const body = {
      content: data,
    };
    const response = await HTTPClient.post(url, body);
    return response;
  },
  updateRating: async (cid, mid, rating, feedback) => {
    const url = `/chats/${cid}/messages/${mid}`;
    const body = { rating: { score: rating, text: feedback } };
    const response = await HTTPClient.put(url, body);
    return response;
  },
};

export const suggestedQuestions = {
  getAll: async () => {
    const url = `/suggested-questions/`;
    const response = await HTTPClient.get(url);
    return response;
  },
};
