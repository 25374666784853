// Utilities for working with Daylight documents
// These utilities assume that indices passed in have already been converted from backend-supplied
// indices to js-ready indices.
import cx from 'classnames';

export function getMatchIntervals(text, matchRangesByType) {
  // Construct a list of intervals to highlight
  // This fn expects that the 'lo' and 'hi' values for matches passed into it
  // are using indices relative to the 'text' passed in (which may be
  // a substring of the text that the backend used to produce match indices).
  const exact = [];
  const highlights = [];
  function isOverlapping(aLo, aHi, bLo = 0, bHi = text.length) {
    return aLo < bHi && aHi > bLo;
  }
  for (let [lo, hi, sentiment] of matchRangesByType.exact) {
    if (isOverlapping(lo, hi)) {
      exact.push([lo, hi]);
      highlights.push({ type: 'exact', lo, hi, sentiment });
    }
  }
  for (let [lo, hi,sentiment] of matchRangesByType.conceptual) {
    if (isOverlapping(lo, hi)) {
      // Ignore any conceptual matches which overlap with an exact match
      if (!exact.some(other => isOverlapping(lo, hi, ...other))) {
        highlights.push({ type: 'conceptual', lo, hi, sentiment });
      }
    }
  }

  return highlights;
}

export function matchIntervalSorter(a, b) {
  if (a.lo !== b.lo) {
    // Sort ascendingly left to right
    return a.lo - b.lo;
  } else {
    // For highlights that begin on the same character, prefer the longer one
    return b.hi - a.hi;
  }
}

export function nearbyIndexAdjacentToSpace(
  text,
  initialIndex,
  maxDistance,
  backwards
) {
  const increment = backwards ? -1 : 1;
  for (
    let index = initialIndex;
    backwards ? index >= 0 : index < text.length;
    index += increment
  ) {
    if (index <= 0) {
      return 0;
    }
    if (index >= text.length - 1) {
      return text.length - 1;
    }
    if (Math.abs(index - initialIndex) > maxDistance) {
      return initialIndex;
    }
    if (/\s/.test(text[index + increment])) {
      return index;
    }
  }
}

export function snippetAroundRange(
  [lo, hi],
  text,
  padLeft,
  padRight,
  maxDistanceToSpace
) {
  const startGuess = Math.max(0, lo - padLeft);
  const start = nearbyIndexAdjacentToSpace(
    text,
    startGuess,
    maxDistanceToSpace,
    true
  );

  const unusedPadLeft = padLeft - (lo - start);
  const endGuess = Math.min(hi + padRight + unusedPadLeft, text.length);
  const end =
    1 +
    nearbyIndexAdjacentToSpace(text, endGuess - 1, maxDistanceToSpace, false);

  return { start, end, snippetText: text.slice(start, end) };
}

export function tagText(text, matchRangesByType) {
  let index;
  const highlights = getMatchIntervals(text, matchRangesByType);
  highlights.sort(matchIntervalSorter);

  let cursor = (index = 0);
  const taggedText = [];

  while (index < highlights.length) {
    const highlight = highlights[index];

    // Include interstitial text, as well as the start of the string
    if (cursor < highlight.lo) {
      taggedText.push(['text', text.slice(cursor, highlight.lo)]);
    }

    // Include the highlight and update the cursor
    taggedText.push([highlight.type, text.slice(highlight.lo, highlight.hi), highlight.sentiment]);
    cursor = highlight.hi;

    // Skip to the next highlight after the cursor
    // We may skip more than one when there are duplicates or nested ranges.
    while (index < highlights.length && highlights[index].lo < cursor) {
      index++;
    }
  }

  // Include the end of the string as plain text if it wasn't part of a highlight
  if (cursor < text.length) {
    taggedText.push(['text', text.slice(cursor)]);
  }

  return taggedText;
}

export function setRtlClass(className, language) {
  return cx(className, { rtl: language === 'ar' });
}

export function splitIntoParagraphs(taggedText) {
  const paragraphs = [[]];
  for (let [type, text, sentiment] of taggedText) {
    if (type === 'text') {
      const segments = text.split('\n');

      paragraphs[paragraphs.length - 1].push(['text', segments[0]]);

      for (let segment of segments.slice(1, segments.length)) {
        paragraphs.push([['text', segment]]);
      }
    } else {
      paragraphs[paragraphs.length - 1].push([type, text, sentiment]);
    }
  }

  return paragraphs;
}

export const getFormattedDocument = (doc, selection, truncated) => {
  let matchIndices = doc.getMatchIndices(selection);

  let { text } = doc;
  const matchRange = matchIndices.exact[0] ??
    matchIndices.conceptual[0] ?? [0, 0];

  const { start, end } = snippetAroundRange(matchRange, text, 40, 60, 10);

  const truncatedText = doc.text.substring(start, end);
  const canTruncate = truncatedText !== doc.text;
  const truncatedStart = truncated && start > 0;
  const truncatedEnd = truncated && end < doc.text.length;

  if (truncated) {
    text = truncatedText;
    const offsetToSnippet = ([lo, hi, sentiment]) => {
      return [lo - start, hi - start, sentiment];
    };

    matchIndices = {
      conceptual: matchIndices.conceptual.map(offsetToSnippet),
      exact: matchIndices.exact.map(offsetToSnippet)
    };
  }

  const paragraphList = splitIntoParagraphs(tagText(text, matchIndices));
  return { paragraphList, canTruncate, truncatedStart, truncatedEnd };
};
