import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../styles';
import Spinner from '../components/core/Spinner';
import { DisableableLink } from './DisableableLink';
import { Card } from '../components/Card';
import { Icon, IconTypes } from '../components/icons';

export function ToolCard({ header, blurb, visualization, footer, styles }) {
  const blurbHeight = 6;
  const blurbPadding = 0.5;
  const footerHeight = 2.25;
  const footerPadding = 0.5;
  // If the blurb or the footer aren't provided, have the visualization take
  // up that space
  const visualizationHeight =
    30 +
    (blurb ? 0 : blurbHeight + 2 * blurbPadding) +
    (footer ? 0 : footerHeight + 2 * footerPadding);

  return (
    <Card css={styles}>
      <Card.Section padding="0.5rem" height="2rem">
        <h6
          css={css`
            padding: 0;
            white-space: nowrap;
            display: flex;
            align-items: baseline;
          `}
        >
          {header}
        </h6>
      </Card.Section>
      <Card.Separator />
      {blurb && (
        <>
          <Card.Section
            padding={`${blurbPadding}rem`}
            height={`${blurbHeight}rem`}
          >
            {blurb}
          </Card.Section>
          <Card.Separator />
        </>
      )}
      <Card.Section padding="1.875rem" height={`${visualizationHeight}rem`}>
        {visualization}
      </Card.Section>
      {footer && (
        <>
          <Card.Separator />
          <Card.Section
            padding={`${footerPadding}rem`}
            height={`${footerHeight}rem`}
          >
            {footer}
          </Card.Section>
        </>
      )}
    </Card>
  );
}

ToolCard.propTypes = {
  header: PropTypes.node.isRequired,
  blurb: PropTypes.node,
  visualization: PropTypes.node.isRequired,
  footer: PropTypes.node
};

ToolCard.VisualizationPlaceholder = VisualizationPlaceholder;

function VisualizationPlaceholder() {
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      `}
    >
      <Spinner size="large" />
    </div>
  );
}

function MoreDetailLink({ name, link, disabled, trackingItem }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        font-weight: bold;
        height: 100%;
      `}
    >
      <DisableableLink
        to={link}
        data-tracking-item={trackingItem}
        disabled={disabled}
        css={css`
          display: flex;
          svg {
            align-self: end;
          }
          &:not([href]) {
            color: ${Colors.gray5};
            &:active,
            &:hover {
              color: ${Colors.gray5};
              text-decoration: none;
            }
          }
        `}
      >
        {name} <Icon type={IconTypes.CARET_STROKE} direction="right" />
      </DisableableLink>
    </div>
  );
}

MoreDetailLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  trackingItem: PropTypes.string
};

ToolCard.MoreDetailLink = MoreDetailLink;
