import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Card } from '../../../components/Card';
import { CurrentBuildCard, NextBuildCard } from '../ConceptsCard';
import { EditableConceptsList } from '../ConceptsList';
import SimpleModal from '../../../components/core/SimpleModal';
import { Button } from '../../../components/core/Button';
import { Colors } from '../../../styles';
import { Icon, IconTypes } from '../../../components/icons';
import { useConceptCountContext } from '../ConceptCountProvider';
import Tooltip from '../../../components/core/Tooltip';
import { AddNoticeTextEntry } from './AddNoticeTextEntry';

export default function EditNoticeAssertionsModal({
  isOpen,
  onHide,
  apiConceptManagement,
  updateNextBuild
}) {
  const { requestCount } = useConceptCountContext();

  const [conceptManagement, setConceptManagement] = useState(
    apiConceptManagement.withSortedBuilds
  );
  useEffect(() => {
    setConceptManagement(apiConceptManagement.withSortedBuilds);
  }, [apiConceptManagement]);

  const addConcept = concept => {
    const cleanedConcept = concept.trim().replace(/\s+/g, ' ');
    if (!cleanedConcept) {
      return;
    }
    requestCount(cleanedConcept);
    setConceptManagement(conceptManagementInformation =>
      conceptManagementInformation.withAddedNoticeConcept(cleanedConcept)
    );
  };

  const removeConcept = concept => {
    setConceptManagement(conceptManagementInformation =>
      conceptManagementInformation.withRemovedNoticeConcept(concept.concept)
    );
  };

  const resetChanges = () => {
    setConceptManagement(
      conceptManagement => conceptManagement.withChangesResetNotice
    );
  };

  const saveAndCloseDisabled = conceptManagement.nextBuildIsEqualNotice(
    apiConceptManagement
  );
  const closeModal = () => {
    setConceptManagement(apiConceptManagement);
    onHide();
  };
  return (
    <SimpleModal
      isOpen={isOpen}
      onHide={closeModal}
      header={<h4>Notice</h4>}
      css={css`
        width: 80vw;
        max-width: 50rem;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
      `}
      containerCss={css`
        max-width: 80vw;
      `}
    >
      <ColumnsContainer>
        <ConceptsCardColumn data-test-id="current-build-column">
          <CurrentBuildCard
            currentConcepts={conceptManagement.currentBuild.notice}
          />
        </ConceptsCardColumn>
        <MiddleColumn data-test-id="middle-column">
          <SearchAndAddConceptsSection>
            <AddNoticeTextEntry
              placeholder="Type concept"
              submitSelection={addConcept}
            />
          </SearchAndAddConceptsSection>
          <EditableConceptsList
            concepts={conceptManagement.nextBuild.notice}
            onRemoveConcept={removeConcept}
          />
        </MiddleColumn>
        <ConceptsCardColumn data-test-id="next-build-column">
          <Button
            onClick={resetChanges}
            css={css`
              width: fit-content;
            `}
          >
            <Icon type={IconTypes.ROTATE} />
            <span>Reset changes</span>
          </Button>
          <NextBuildCard
            hasChanges={conceptManagement.hasNoticeChanges}
            conceptsDiff={conceptManagement.noticeDiff}
          />
        </ConceptsCardColumn>
      </ColumnsContainer>
      <BottomButtonsRow>
        <Button onClick={closeModal} palette="red">
          Cancel
        </Button>
        <Tooltip
          disabled={!saveAndCloseDisabled}
          anchor={
            <Button
              onClick={() => {
                updateNextBuild({
                  ...conceptManagement.nextBuild,
                  notice: conceptManagement.nextBuild.notice
                });
                onHide();
              }}
              disabled={saveAndCloseDisabled}
              palette="green"
              css={css`
                &:disabled {
                  pointer-events: none;
                }
              `}
            >
              Save & close
            </Button>
          }
        >
          There are no changes to save
        </Tooltip>
      </BottomButtonsRow>
    </SimpleModal>
  );
}

const ConceptsCardColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const MiddleColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: ${Colors.gray0} 1px;
  border-style: none solid;
`;

const SearchAndAddConceptsSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;

const ColumnsContainer = styled(Card.Section)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${Colors.gray0};
  flex: 1;
  overflow-x: auto;
  padding: 0;
`;
