import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export const FeedbackForm = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FeedbackInput = styled(TextField)`
  & .MuiOutlinedInput-input {
    padding: 0.5rem 0.875rem;
    font-size: 0.875rem;
  }

  & .MuiInputBase-root {
    margin: 0;
  }
`;
