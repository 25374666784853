import React, { useContext } from 'react';
import { css } from '@emotion/react';

import { StoreContext } from '../StoreContext';
import PlaceholderText from '../components/core/PlaceholderText';
import { percentify, thousandify } from '../utils/NumFmtUtils';
import { Colors } from '../styles';
import { useSearchParams } from '../search_params';

export function MatchingDocuments() {
  const { searchParams } = useSearchParams();
  const {
    filterCount,
    filterIsLoading,
    searchInProgress,
    selection,
    totalDocCount
  } = useContext(StoreContext);

  const percentageByTotal = searchParams.count_percentage === 'total';
  const includingConceptual = searchParams.match_type === 'total';

  if (!selection) {
    return null;
  }

  const matchCount = includingConceptual
    ? selection.matchCount
    : selection.exactMatchCount;

  if (filterIsLoading || searchInProgress) {
    return (
      <span
        css={css`
          margin-left: 0.5rem;
        `}
      >
        <PlaceholderText style={{ width: '5rem' }} /> matches
      </span>
    );
  }

  return (
    <span
      css={css`
        margin-left: 0.5rem;
      `}
    >
      {thousandify(matchCount)}{' '}
      <span
        css={css`
          color: ${Colors.gray5};
        `}
      >
        (
        {percentageByTotal
          ? percentify(matchCount, totalDocCount)
          : percentify(matchCount, filterCount)}
        )
      </span>{' '}
      matches
    </span>
  );
}
