import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { StoreContext } from '../StoreContext';
import Doc, { PlaceholderDoc } from './Doc';
import { Colors } from '../styles';
import { Checkbox } from '../components/core/Checkbox';
import { useUniqueId } from '../utils/hooks';
import { DocumentViewer } from './DocumentViewer/DocumentViewer';
import { useSearchParams } from '../search_params';
import { Icon, IconTypes } from '../components/icons';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';

const DocsContainer = styled.div`
  overflow: auto;
  /* Make sure the docs are still scrollable even when there isn't much room */
  min-height: 4rem;
  margin-top: 0.5rem;
  padding-top: 0.125rem;
`;

export default function DocSearchResults({
  loading,
  noResultsMessage,
  docViewerMetadata,
  sidePanelMetadata
}) {
  const ref = useRef();
  const { docSearchResults, selection, projectLanguage, project } =
    React.useContext(StoreContext);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const {
    selectedMatchTypes,
    toggleSelectedMatchTypes,
    sentiment_indication,
    toggleSentimentIndication
  } = useMatchTypes();
  const docsRef = useRef({}); // maps docId to Doc element ref

  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;

  let docs = docSearchResults;

  if (loading || !docs) {
    return (
      <DocsContainer data-test-id="docs-container">
        {[...Array(10)].map((n, i) => (
          <PlaceholderDoc key={i} />
        ))}
      </DocsContainer>
    );
  }

  if (docs.length === 0) {
    return noResultsMessage;
  }

  if (selection) {
    docs = docs
      .filter(doc => {
        const { exact } = doc.getMatchIndices(selection);
        return (
          (selectedMatchTypes.has('exact') && exact.length > 0) ||
          (selectedMatchTypes.has('conceptual') && exact.length === 0)
        );
      })
      .slice(0, 50);
  }

  const selectedDocIndex = docs.indexOf(selectedDoc);

  const selectNextDocument = direction => {
    const doc = docs[selectedDocIndex + direction];
    setSelectedDoc(doc);
    docsRef.current[doc.docId].scrollIntoView();
  };

  return (
    <>
      {selectedDoc && (
        <DocumentViewer
          doc={selectedDoc}
          selection={selection}
          language={projectLanguage}
          onNext={() => selectNextDocument(1)}
          onPrev={() => selectNextDocument(-1)}
          nextDisabled={selectedDocIndex >= docs.length - 1}
          prevDisabled={selectedDocIndex <= 0}
          onClose={() => setSelectedDoc(null)}
          sidePanelRef={ref}
          fieldsToDisplay={docViewerMetadata}
        />
      )}
      <>
        <MatchTypeSwitch
          selectedMatchTypes={selectedMatchTypes}
          toggleSelectedMatchTypes={toggleSelectedMatchTypes}
        />
        {isSentimentReady && selection && (
          <SentimentToggle
            isEnabled={sentiment_indication}
            toggleEnabled={toggleSentimentIndication}
          />
        )}
      </>

      {docs.length === 0 ? (
        noResultsMessage
      ) : (
        <DocsContainer data-test-id="docs-container" ref={ref}>
          {docs.map(doc => (
            <Doc
              ref={ref => (docsRef.current[doc.docId] = ref)}
              key={doc.docId}
              doc={doc}
              language={projectLanguage}
              selection={selection}
              onSelectDocument={setSelectedDoc}
              isSelected={doc === selectedDoc}
              sidePanelMetadata={sidePanelMetadata}
            />
          ))}
        </DocsContainer>
      )}
    </>
  );
}

DocSearchResults.propTypes = {
  noResultsMessage: PropTypes.node,
  loading: PropTypes.bool.isRequired
};

function MatchTypeIcon({ isChecked, type }) {
  const styles = [
    css`
      color: ${Colors.blue5};
    `,
    isChecked &&
      css`
        border-radius: 50%;
        box-shadow:
          0 0 0 0.125rem white,
          0 0 0 0.25rem ${Colors.blue5};
      `
  ];
  const props = { width: '1rem', height: '1rem', css: styles };
  switch (type) {
    case 'exact':
      return <Icon type={IconTypes.EXACT_CIRCLE} {...props} />;
    case 'conceptual':
      return <Icon type={IconTypes.APPROXIMATE_CIRCLE} {...props} />;
    case 'sentiment':
      return <Icon type={IconTypes.EXACT_CIRCLE} {...props} />;
    default:
      return null;
  }
}

MatchTypeIcon.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

function MatchTypeCheckbox({ type, selectedTypes, onChange }) {
  const id = useUniqueId();
  const checked = selectedTypes.has(type);
  return (
    <div
      css={css`
        display: flex;
        margin-left: 0.5rem;
      `}
    >
      <Checkbox
        id={id}
        size="1rem"
        outlined={false}
        checked={checked}
        onChange={onChange}
        checkedIcon={<MatchTypeIcon isChecked type={type} />}
        uncheckedIcon={<MatchTypeIcon isChecked={false} type={type} />}
      />
      <label
        css={css`
          margin-left: calc(0.125rem + 0.25rem);
        `}
        htmlFor={id}
      >
        {_.capitalize(type)}
      </label>
    </div>
  );
}

MatchTypeCheckbox.propTypes = {
  type: PropTypes.string.isRequired,
  selectedTypes: PropTypes.instanceOf(Set).isRequired,
  onChange: PropTypes.func.isRequired
};

function MatchTypeWrapper({ children }) {
  return (
    <div
      css={css`
        display: flex;
        padding-left: 0.75rem;
        margin-top: 0.5rem;
        flex-shrink: 0;
      `}
    >
      <div
        css={css`
          color: ${Colors.gray5};
          font-size: 0.875rem;
        `}
      >
        Showing:
      </div>
      {children}
    </div>
  );
}

function MatchTypeSwitch({ selectedMatchTypes, toggleSelectedMatchTypes }) {
  const { selection } = React.useContext(StoreContext);
  const checkboxFor = type => (
    <MatchTypeCheckbox
      type={type}
      selectedTypes={selectedMatchTypes}
      onChange={() => toggleSelectedMatchTypes(type)}
    />
  );

  return (
    selection && (
      <MatchTypeWrapper>
        {checkboxFor('exact')}
        {checkboxFor('conceptual')}
      </MatchTypeWrapper>
    )
  );
}

MatchTypeSwitch.propTypes = {
  selectedMatchTypes: PropTypes.instanceOf(Set).isRequired,
  toggleSelectedMatchTypes: PropTypes.func.isRequired
};

export function useMatchTypes() {
  const { searchParams, updateSearch } = useSearchParams();
  const doc_match_type = searchParams.doc_match_type;
  const sentiment_indication = searchParams.sentiment_indication === 'true';

  let selectedMatchTypes = new Set();
  if (doc_match_type && doc_match_type !== 'neither') {
    selectedMatchTypes = new Set(
      doc_match_type === 'both' ? ['exact', 'conceptual'] : [doc_match_type]
    );
  }

  function toggleSelectedMatchTypes(matchType) {
    const result = new Set([...selectedMatchTypes, matchType]);
    if (selectedMatchTypes.has(matchType)) {
      result.delete(matchType);
    }
    let doc_match_type;
    if (result.size === 2) {
      doc_match_type = 'both';
    } else if (result.size === 0) {
      doc_match_type = 'neither';
    } else {
      doc_match_type = [...result][0];
    }
    updateSearch({ doc_match_type });
  }

  const toggleSentimentIndication = () => {
    updateSearch({
      sentiment_indication: (!sentiment_indication).toString()
    });
  };

  return {
    selectedMatchTypes,
    toggleSelectedMatchTypes,
    toggleSentimentIndication,
    sentiment_indication
  };
}

export function SentimentToggle({ isEnabled, toggleEnabled }) {
  const id = useUniqueId();

  return (
    <div
      css={css`
        display: flex;
        margin-left: 0.5rem;
        margin-top: 3%;
      `}
    >
      <Checkbox
        id={id}
        size="1rem"
        outlined={false}
        checked={isEnabled}
        onChange={toggleEnabled}
        checkedIcon={<MatchTypeIcon isChecked type="sentiment" />}
        uncheckedIcon={<MatchTypeIcon isChecked={false} type="sentiment" />}
      />
      <label
        css={css`
          margin-left: calc(0.125rem + 0.25rem);
        `}
        htmlFor={id}
      >
        Sentiment Indicator
      </label>
    </div>
  );
}
