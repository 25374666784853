import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';

import PlaceholderText from '../../components/core/PlaceholderText';
import { naturalSortByName } from '../../utils/NaturalSort';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import { StoreContext } from '../../StoreContext';
import Card from '../Card';
import { TabLink } from '../TabTitles';
import { useCurrentView } from './view';
import { useActiveListName } from '../active_concepts/activeListName';
import { useToggle } from '../../utils/hooks';
import { Button } from '../../components/core/Button';
import { ExpandCollapseAnimation } from '../../components/core/ExpandCollapseAnimation';
import { ShareViewButton } from './ShareViewButton';
import { SharedViewLoader } from './SharedViewLoader';
import { ViewDetailRow, ViewDetails } from './ViewDetails';
import { useSearchParams } from '../../search_params';
import { Icon, IconTypes } from '../../components/icons';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../utils/sentimentStatus';
import { instanceOf } from '../../drivers/utils';
import { NumericField, ScoreField } from '../../classes/MetadataFields';

export default function CurrentViewBox({ onLoadView }) {
  const { project, metadata } = useContext(StoreContext);
  const [expanded, toggleExpanded] = useToggle();
  const { searchParams } = useSearchParams();
  const currentView = useCurrentView();
  const { feature, dependsOnConceptList } = currentView;
  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsReady = sentimentStatus === SENTIMENT_STATUS.READY;
  const scoreAndNumericFields = metadata.filter(
    field => instanceOf(ScoreField, field) || instanceOf(NumericField, field)
  );
  const noValidFields = scoreAndNumericFields.length === 0;

  const onDriversPage = feature === 'drivers';
  const onVolumesPage = feature === 'volume';
  const onSentimentsPage = feature === 'sentiment' && sentimentIsReady;
  const driversAvailable = onDriversPage  && !noValidFields
  const isActiveConcepts = searchParams.concepts === 'active';
  const [sharePopoverOpen, setSharePopoverOpen] = useState(false);


  return (
    <Card
      header={
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            /* Make height constant, regardless of button being rendered, in
             * order to be consistent with the side panel tab headers */
            height: 1.75rem;
          `}
        >
          <div
            css={css`
                display: flex;
                flex-direction: column;
            `}
          >
            <span>
              Viewing: <FilterCounts /> documents
            </span>
            {isActiveConcepts && (onVolumesPage || driversAvailable || onSentimentsPage) &&
              <span
                css={css`
                    font-size: 12px;
                `}
              >
                Coverage: <FilterOutlierCounts />
              </span>
            }
          </div>

          {project.permissions.includes('write') && (
            <ShareViewButton
              popoverOpen={sharePopoverOpen}
              togglePopover={() => setSharePopoverOpen(isOpen => !isOpen)}
              closePopover={() => setSharePopoverOpen(false)}
            />
          )}
        </div>
      }
      footer={
        !sharePopoverOpen &&
        (onDriversPage || searchParams.filter.length > 1) && (
          <Splitter expanded={expanded} onToggleExpanded={toggleExpanded} />
        )
      }
      data-test-id="current-view-box"
    >
      <div
        css={css`
          font-size: 0.875rem;
        `}
      >
        <SharedViewLoader onLoadView={onLoadView} />
        {sharePopoverOpen ? (
          <ViewDetails
            view={currentView}
            showOperationIcon={false}
            showUnsavedStyling={true}
          />
        ) : (
          <>
            {dependsOnConceptList && <ActiveConceptListName />}
            <ConceptsBeingVisualized />
            {onDriversPage && <CurrentScoreField expanded={expanded} />}
            <CurrentFilter expanded={expanded} />
          </>
        )}
      </div>
    </Card>
  );
}

function FilterCounts() {
  const { filterCount, filterIsLoading, totalDocCount } =
    useContext(StoreContext);

  return filterIsLoading ? (
    <>
      <PlaceholderText style={{ width: '3rem' }} /> (
      <PlaceholderText style={{ width: '2rem' }} />)
    </>
  ) : (
    <>
      {thousandify(filterCount)} ({percentify(filterCount, totalDocCount)})
    </>
  );
}

function FilterOutlierCounts() {
  const { filterIsLoading, coverageLoading, outlierFilterCount,filterCount } =
    useContext(StoreContext);
  const coverageDiff = filterCount - outlierFilterCount;
  return filterIsLoading || coverageLoading ? (
    <>
      <PlaceholderText style={{ width: '3rem' }} /> (
      <PlaceholderText style={{ width: '2rem' }} />)
    </>
  ) : (
    <>
      {thousandify(coverageDiff)} of {thousandify(filterCount)} ({percentify(coverageDiff, filterCount)})
    </>
  );
}

function SummaryLine({ tab, children }) {
  return (
    <ViewDetailRow>
      <TabLink tab={tab} withLabel={false} />
      <span>{children}</span>
    </ViewDetailRow>
  );
}

function ActiveConceptListName() {
  const { nameAsHTML } = useActiveListName();
  return <SummaryLine tab="activeConcepts">{nameAsHTML}</SummaryLine>;
}

function ConceptsBeingVisualized() {
  const { conceptSelectorDescription } = useCurrentView();
  if (conceptSelectorDescription == null) {
    return null;
  }

  return (
    <SummaryLine tab="configure">{conceptSelectorDescription}</SummaryLine>
  );
}

function CurrentFilter({ expanded }) {
  const { searchParams } = useSearchParams();
  const sortedFilter = [...searchParams.filter].sort(naturalSortByName);
  if (sortedFilter.length === 0) {
    return (
      <SummaryLine tab="filter">
        <em>No filter selected</em>
      </SummaryLine>
    );
  }
  const [firstConstraint, ...otherConstraints] = sortedFilter;
  const approximateExpandedHeight = 1.2 * otherConstraints.length;
  return (
    <>
      <SummaryLine tab="filter" key={firstConstraint.name}>
        {firstConstraint.toString()}
      </SummaryLine>
      <ExpandCollapseAnimation
        expanded={expanded}
        heightHint={`${approximateExpandedHeight}em`}
      >
        <div>
          {otherConstraints.map(constraint => (
            <SummaryLine tab="filter" key={constraint.name}>
              {constraint.toString()}
            </SummaryLine>
          ))}
        </div>
      </ExpandCollapseAnimation>
    </>
  );
}

function CurrentScoreField({ expanded }) {
  const { currentScoreField } = useCurrentView();
  return (
    <ExpandCollapseAnimation expanded={expanded} heightHint="1.2em">
      <SummaryLine tab="configure">
        {currentScoreField ? (
          `Axis: ${currentScoreField}`
        ) : (
          <em>No score selected</em>
        )}
      </SummaryLine>
    </ExpandCollapseAnimation>
  );
}

function Splitter({ expanded, onToggleExpanded }) {
  return (
    <Button
      onClick={onToggleExpanded}
      padded={false}
      flavor="subtle"
      css={css`
        width: 100%;
        font-size: 0.875rem;
      `}
    >
      <Icon type={IconTypes.CARET_FILL} direction={expanded ? 'up' : 'down'} />
      {expanded ? 'Show less' : 'Show more'}
    </Button>
  );
}
