import { css } from '@emotion/react';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import * as gtm from '../utils/gtm';
import { DateField } from '../classes/MetadataFields';
import { DateRangeConstraint } from '../classes/Constraints';
import FilterSelector from './FilterSelector';
import { DateRangeSelectorFlyout } from './DateRangeSelectorFlyout';
import { Button } from './core/Button';
import { Icon, IconTypes } from './icons';

export default class FilterSelectorDate extends React.Component {
  constructor(props) {
    super(props);

    const enteredMinimum = format(this.props.constraint.minimum);
    const enteredMaximum = format(this.props.constraint.maximum);

    this.state = {
      flyoutIsOpen: false,
      enteredMinimum,
      enteredMaximum
    };

    this.minimum = React.createRef();
    this.maximum = React.createRef();

    this.onClear = this.onClear.bind(this);
  }

  onClear() {
    if (!this.state.enteredMinimum && !this.state.enteredMaximum) {
      return;
    }

    this.setState({ enteredMinimum: '', enteredMaximum: '' }, () => {
      const { constraint, updateConstraint } = this.props;
      fetchFilterResults(constraint, '', '', updateConstraint);
    });
  }

  render() {
    return (
      <FilterSelector
        constraint={this.props.constraint}
        onClear={this.onClear}
        expanded={this.props.expanded}
        toggleExpanded={this.props.toggleExpanded}
      >
        <DateRangeSelectorFlyout
          constraint={this.props.constraint}
          field={this.props.field}
          updateConstraint={this.props.updateConstraint}
          enteredMaximum={this.state.enteredMaximum}
          enteredMinimum={this.state.enteredMinimum}
          minimumInputRef={this.minimum}
          maximumInputRef={this.maximum}
          onChange={({ minimum, maximum }) =>
            this.setState({ enteredMinimum: minimum, enteredMaximum: maximum })
          }
          onCloseFlyout={() => this.setState({ flyoutIsOpen: false })}
          flyoutIsOpen={this.state.flyoutIsOpen}
          anchor={
            <div className="filter-selector__container">
              <FlyoutOpener
                label="From"
                momentDate={this.props.constraint.minimum}
                onClick={() => {
                  this.setState(
                    {
                      enteredMinimum:
                        format(this.props.constraint.minimum) ||
                        format(this.props.field.minimum),
                      enteredMaximum: format(this.props.constraint.maximum),
                      flyoutIsOpen: true
                    },
                    () => {
                      if (this.minimum.current) {
                        this.minimum.current.focus();
                      }
                    }
                  );
                }}
              />

              <FlyoutOpener
                label="To"
                momentDate={this.props.constraint.maximum}
                onClick={() => {
                  this.setState(
                    {
                      enteredMinimum: format(this.props.constraint.minimum),
                      enteredMaximum:
                        format(this.props.constraint.maximum) ||
                        format(this.props.field.maximum),
                      flyoutIsOpen: true
                    },
                    () => {
                      if (this.maximum.current) {
                        this.maximum.current.focus();
                      }
                    }
                  );
                }}
              />
            </div>
          }
        />
      </FilterSelector>
    );
  }
}

FilterSelectorDate.propTypes = {
  field: PropTypes.instanceOf(DateField).isRequired,
  constraint: PropTypes.instanceOf(DateRangeConstraint).isRequired,
  updateConstraint: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired
};

export const isValidRangeInput = input => {
  if (input != null && `${input}`.trim().length) {
    return moment.utc(input, 'MMMM D, Y').isValid();
  } else {
    return true;
  }
};

const parseInputAsISOString = input => {
  const inputAsMoment = moment.utc(input, 'MMMM D, Y'); // use UTC instead of client's local time zone
  if (input && inputAsMoment.isValid()) {
    return inputAsMoment.toISOString();
  }
};

const format = m => {
  if (m && m.isValid()) {
    return m.format('MMMM D, Y');
  } else {
    return '';
  }
};

function FlyoutOpener({ momentDate, label, onClick }) {
  return (
    <label className="filter-selector__label">
      {label}
      <Button
        css={css`
          display: block;
          margin: 0.5rem 0;
          padding-left: 0;
          &:hover {
            text-decoration: underline;
          }
        `}
        flavor="subtle"
        onClick={onClick}
      >
        <Icon type={IconTypes.CALENDAR} />
        {format(momentDate) || 'Any time'}
      </Button>
    </label>
  );
}

FlyoutOpener.propTypes = {
  momentDate: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export function isRangeValid(enteredMinimum, enteredMaximum) {
  const parsedMinimum = parseInputAsISOString(enteredMinimum);
  const parsedMaximum = parseInputAsISOString(enteredMaximum);

  if (parsedMinimum && parsedMaximum) {
    return parsedMinimum <= parsedMaximum; // ISO strings in the same subformat sort lexicographically
  } else {
    return true;
  }
}

export function fetchFilterResults(
  constraint,
  enteredMinimum,
  enteredMaximum,
  updateConstraint
) {
  let maximum, minimum;
  if (isRangeValid(enteredMinimum, enteredMaximum)) {
    minimum = parseInputAsISOString(enteredMinimum);
    maximum = parseInputAsISOString(enteredMaximum);
  }

  // Force min/max to start/end of day (until we facilitate entering time ranges)
  if (minimum) {
    minimum = moment.utc(minimum).startOf('day').toISOString();
  }
  if (maximum) {
    maximum = moment.utc(maximum).endOf('day').toISOString();
  }

  if (minimum !== constraint.minimum || maximum !== constraint.maximum) {
    updateConstraint(minimum, maximum);
    gtm.trigger('filter-pane_selector-input_date-range-submit');
  }
}
