import { useAssistantStore } from './index';

export const useSelectActiveChat = () =>
  useAssistantStore((state) => state.activeChat);

export const useSelectInitConversation = () =>
  useAssistantStore((state) => state.initConversation);

export const useSelectSendMessage = () =>
  useAssistantStore((state) => state.sendMessage);

export const useSelectUpdateRatingOfMessage = () =>
  useAssistantStore((state) => state.updateRatingOfMessage);

export const useSelectResetState = () =>
  useAssistantStore((state) => state.resetState);

export const useSelectIsFeedbackSent = () =>
  useAssistantStore((state) => state.activeChat.isFeedbackSent);

export const useSelectIsAwaitingForFeedback = () =>
  useAssistantStore((state) => state.activeChat.isAwaitingForFeedback);

export const useSelectAskToProvideFeedback = () =>
  useAssistantStore((state) => state.askToProvideFeedback);

export const useSelectSetDocSearchResults = () =>
  useAssistantStore((state) => state.setDocSearchResults);

export const useSelectSetFilter = () =>
  useAssistantStore((state) => state.setFilter);
