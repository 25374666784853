import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Tooltip from '../../components/core/Tooltip';
import { Colors } from '../../styles';

const BarChart = ({
  matchCount,
  scale,
  numTicks,
  matchType,
  tooltipContent,
  tooltipPosition = 'above',
  color
}) => {
  const ticks = scale.ticks(numTicks);

  return (
    <svg
      data-test-id="bar-chart"
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 2.5rem;
      `}
    >
      <Tooltip
        forSVG
        anchor={
          <>
            {matchType === 'total' && <Bar width={scale(matchCount.total)} color={color}/>}
            <Bar width={scale(matchCount.exact)} color={color}/>
          </>
        }
        delayed={false}
        position={tooltipPosition}
      >
        {tooltipContent}
      </Tooltip>
      <g>
        {ticks.map(tick => (
          <Tick key={tick} x={scale(tick)} />
        ))}
      </g>
    </svg>
  );
};

BarChart.propTypes = {
  matchCount: PropTypes.shape({
    exact: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  scale: PropTypes.func.isRequired,
  numTicks: PropTypes.number.isRequired,
  matchType: PropTypes.string.isRequired,
  tooltipContent: PropTypes.node.isRequired,
  tooltipPosition: PropTypes.string,
  color: PropTypes.string
};

export default BarChart;

const Bar = ({ width,color }) => {
  return (
    <rect
      height="32px"
      width={`${width}%`}
      css={css`
        fill: ${color ? `${color}80`: Colors.blue2};
        transform: translateY(0.25rem);

        &:last-child {
            fill: ${color ? color : Colors.blue4};
        }
      `}
    />
  );
};

Bar.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string
};

const Tick = ({ x }) => {
  return (
    <line
      x1={`${x}%`}
      x2={`${x}%`}
      y1="0%"
      y2="100%"
      css={css`
        stroke: ${Colors.gray9};
        stroke-opacity: 0.15;

        &:first-child {
          stroke: ${Colors.gray3};
          stroke-width: 2;
          stroke-opacity: 1;
        }
      `}
    />
  );
};

Tick.propTypes = {
  x: PropTypes.number.isRequired
};
