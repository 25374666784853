import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../../assets/images/edit.ico.png';

export const InfoMessageContainer = styled(Typography)`
  position: relative;

  &.awaiting-reply {
    padding-left: 2rem;

    &::before {
      content: url(${EditIcon});
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;
