import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Dropdown from '../components/core/Dropdown';

/**
 * A dropdown menu for navigating between workspaces or organizations
 *
 * @param message - Displayed as the default content of the dropdown
 * @param options - A list of items with id and name properties
 * @param buildPath - Function that takes an ID and returns a new path to
 *                    redirect to, called when the user navigates using the
 *                    dropdown
 * @param selectProps - Any additional properties passed in will be applied
 *                      to the underlying select tag
 */
export function NavigationDropdown({ message, options, buildPath }) {
  const history = useHistory();
  if (options.length <= 1) {
    return null;
  }
  return (
    <Dropdown
      aria-label={message}
      value=""
      onChange={event => history.push(buildPath(event.target.value))}
      containerCss={css`
        align-self: start;
        font-style: italic;
      `}
    >
      <option disabled value="">
        {message}
      </option>
      {options.map(o => (
        <option key={o.id} value={o.id}>
          {o.name}
        </option>
      ))}
    </Dropdown>
  );
}

NavigationDropdown.propTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  buildPath: PropTypes.func.isRequired
};
