import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import SendMessageIcon from '../../../assets/images/send-message.ico.png';

const StyledBtn = styled(Fab)`
  padding: 0.75rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: none;

  &.MuiFab-sizeSmall {
    width: 2.5rem;
  }
`;

const SendMessageBtn = (props) => {
  return (
    <StyledBtn variant="extended" color="primary" type="submit" {...props}>
      <img src={SendMessageIcon} width="24" height="24" />
    </StyledBtn>
  );
};

export default SendMessageBtn;
