import React from 'react';
import { css } from '@emotion/react';

import { Colors, Mixins, standardRadius } from '../../styles';
import {
  AddedConcept,
  DeletedConcept,
  MergeAddedConcept,
  MergeDeletedConcept,
  MergePlainConcept,
  PlainConcept
} from './ConceptsList';
import { Card } from '../../components/Card';

export const CurrentBuildCard = ({ currentConcepts }) => (
  <ConceptsCard
    title="Current build"
    headerColor={Colors.green6}
    backgroundColor={Colors.green0}
  >
    {currentConcepts.length ? (
      <>
        {currentConcepts.map(concept => (
          <PlainConcept key={concept.concept} concept={concept} />
        ))}
      </>
    ) : (
      <Card.Section>
        <i>No concepts</i>
      </Card.Section>
    )}
  </ConceptsCard>
);

export const NextBuildCard = ({ hasChanges, conceptsDiff }) => (
  <ConceptsCard
    title="Next build"
    headerColor={Colors.gray7}
    backgroundColor={Colors.gray0}
  >
    {hasChanges ? (
      <>
        {conceptsDiff.deletedConcepts.map(concept => (
          <DeletedConcept key={concept.concept} concept={concept} />
        ))}
        {conceptsDiff.addedConcepts.map(concept => (
          <AddedConcept key={concept.concept} concept={concept} />
        ))}
        {conceptsDiff.unchangedConcepts.map(concept => (
          <PlainConcept key={concept.concept} concept={concept} />
        ))}
      </>
    ) : (
      <Card.Section>
        <i>Same as current build</i>
      </Card.Section>
    )}
  </ConceptsCard>
);

function ConceptsCard({ title, children, headerColor, backgroundColor }) {
  return (
    <Card
      css={css`
        flex-grow: 1;
        overflow: hidden;
        background: ${backgroundColor};

        // setting the height to auto makes the ConceptCard respect the height
        // of its containing element.
        height: auto;
      `}
    >
      <div
        css={css`
          border-radius: ${standardRadius} ${standardRadius} 0 0;
          background: ${headerColor};
          color: white;
          ${Mixins.ellipsify};
          flex-shrink: 0;
          padding: 0.5rem;
        `}
      >
        {title}
      </div>
      <Card.Separator />
      <Card.Section
        css={css`
          padding: 0;
          overflow-y: auto;
        `}
      >
        {children}
      </Card.Section>
    </Card>
  );
}

export const MergeCurrentBuildCard = ({ currentConcepts }) => (
  <ConceptsCard
    title="Current build"
    headerColor={Colors.green6}
    backgroundColor={Colors.green0}
  >
    {currentConcepts.length ? (
      <>
        {currentConcepts.map(concept => (
          <MergePlainConcept
            key={`${concept.concept}-${concept.merge_with}`}
            concept={concept}
          />
        ))}
      </>
    ) : (
      <Card.Section>
        <i>No concepts</i>
      </Card.Section>
    )}
  </ConceptsCard>
);

export const MergeNextBuildCard = ({ hasChanges, conceptsDiff }) => (
  <ConceptsCard
    title="Next build"
    headerColor={Colors.gray7}
    backgroundColor={Colors.gray0}
  >
    {hasChanges ? (
      <>
        {conceptsDiff.deletedConcepts.map(concept => (
          <MergeDeletedConcept
            key={`${concept.concept}-${concept.merge_with}`}
            concept={concept}
          />
        ))}
        {conceptsDiff.addedConcepts.map(concept => (
          <MergeAddedConcept
            key={`${concept.concept}-${concept.merge_with}`}
            concept={concept}
          />
        ))}
        {conceptsDiff.unchangedConcepts.map(concept => (
          <MergePlainConcept
            key={`${concept.concept}-${concept.merge_with}`}
            concept={concept}
          />
        ))}
      </>
    ) : (
      <Card.Section>
        <i>Same as current build</i>
      </Card.Section>
    )}
  </ConceptsCard>
);
