import { css } from '@emotion/react';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Constraint } from '../../classes/Constraints';
import { updateActiveConcept } from '../../actions';
import { Button } from '../../components/core/Button';
import {
  flyoutInputStyle,
  flyoutButtonStyle,
  inputHeaderText
} from './ActiveConceptEditor';
import { Concept } from '../../classes/Concepts';
import { StoreContext } from '../../StoreContext';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../utils/sentimentStatus';

export function NameEditor({ projectId, concept, filter }) {
  const [conceptName, setConceptName] = useState(concept.name);

  const { project } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY

  function onNameChange(event) {
    setConceptName(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const updated = concept.update({ name: conceptName });
    updateActiveConcept(projectId, updated, filter, true, isSentimentReady);
  }

  return (
    <>
      <label css={inputHeaderText} htmlFor="concept-editor-name">
        Name
      </label>
      <form
        css={css`
          position: relative;
          display: flex;
          justify-content: space-between;
        `}
        onSubmit={handleSubmit}
        data-tracking-item="edit-saved-concept_update-name"
      >
        <input
          css={flyoutInputStyle}
          id="concept-editor-name"
          autoFocus={true}
          onChange={onNameChange}
          value={conceptName}
          autoComplete="off"
        />
        <Button
          type="submit"
          css={flyoutButtonStyle}
          disabled={
            conceptName.trim().length === 0 || conceptName === concept.name
          }
        >
          Save
        </Button>
      </form>
    </>
  );
}

NameEditor.propTypes = {
  projectId: PropTypes.string.isRequired,
  filter: PropTypes.arrayOf(PropTypes.instanceOf(Constraint).isRequired)
    .isRequired,
  concept: PropTypes.instanceOf(Concept).isRequired
};
