import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import buildRoutePath from '../utils/buildRoutePath';
import { RoutePatterns } from '../constants';
import AddUserButton from './AddUserButton';
import { AuthContext, getUserRowsAndColumns } from './model';
import { NavigationDropdown } from './NavigationDropdown';
import EditableName from './EditableName';
import { NotificationArea } from './notifications';
import MultiSelectTable, {
  useClickableSelections
} from '../components/MultiSelectTable';
import RemoveUsersButton from './RemoveUsersButton';
import EditUsersButton from './EditUsersButton';
import {
  ControlBar,
  ManagementPageContainer,
  Subtitle,
  TableAndControlsContainer
} from './managementPageStyles';
import { AdminList } from './AdminList';
import PlaceholderText from '../components/core/PlaceholderText';
import DeleteWorkspaceButton from './DeleteWorkspaceButton';
import { PlaintextWhenDisabledDropdown } from '../upload_page/PlaintextWhenDisabledDropdown';
import { getAPIStatus } from '../utils/ApiUtilsV5';

export function WorkspaceSettings({ workspaceId }) {
  const [languageLoading, setLanguageLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { profile } = useContext(AuthContext);
  const workspaces = profile.workspacesWhoseUsersYouCanSee();
  const workspace = workspaces.find(
    workspace => workspace.workspace_id === workspaceId
  );
  const users = workspace?.getUsers();
  const projects = workspace?.getProjects();
  const defaultLanguage = workspace?.getDefaultLanguage();
  const organization = profile.organizationsById[workspace?.organization_id];
  const { rows, columns } = getUserRowsAndColumns(users, profile.username);
  const primaryKey = 'username';
  const selections = useClickableSelections(rows, primaryKey, workspaceId);

  const changeDefaultLanguageHandler = async value => {
    try {
      setLanguageLoading(true);
      await workspace?.modifyDefaultLanguage(value);
      setSelectedLanguage(value);
      setLanguageLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setSelectedLanguage(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    getAPIStatus().then(res => {
      setLanguages(
        res.languages.map(item => ({ name: item.name, value: item.code }))
      );
    });
  }, []);

  return (
    <ManagementPageContainer>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
      >
        <NavigationDropdown
          message="Switch your workspace"
          options={workspaces?.map(w => ({ name: w.name, id: w.workspace_id }))}
          buildPath={id =>
            buildRoutePath(RoutePatterns.WORKSPACE_SETTINGS, {
              workspaceId: id
            })
          }
        />
        <div>
          Organization: <strong>{organization.name}</strong>
        </div>
      </div>
      <EditableName key={workspace.workspace_id} namedThing={workspace} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          padding-left: 0.5rem;
          margin-bottom: 1.5rem;
        `}
      >
        <label
          css={css`
            font-size: 1rem;
          `}
          htmlFor={workspaceId}
        >
          Default dataset language:
        </label>
        <PlaintextWhenDisabledDropdown
          isLoading={languageLoading}
          id={workspaceId}
          editable={true}
          value={selectedLanguage || 'en'}
          onChange={changeDefaultLanguageHandler}
          options={languages}
          promptOption="Select the default dataset's language"
        />
      </div>
      <h4>Workspace users</h4>
      <Subtitle>
        {users == null ? (
          <PlaceholderText style={{ width: '8rem' }} />
        ) : users.length === 0 ? (
          'This workspace contains no users. You can add users below.'
        ) : (
          <>
            Below is a list of users in this workspace.{' '}
            <AdminList users={users} level={workspace.identifier} />
          </>
        )}
      </Subtitle>
      <TableAndControlsContainer>
        <ControlBar>
          <RemoveUsersButton workspace={workspace} selections={selections} />
          <EditUsersButton editable={workspace} selections={selections} />
          <NotificationArea />
          {workspace.editable && (
            <AddUserButton workspace={workspace} className="right" />
          )}
          {organization?.editable && (
            <DeleteWorkspaceButton workspace={workspace} projects={projects} />
          )}
        </ControlBar>
        {users?.length !== 0 && (
          <MultiSelectTable
            rows={rows}
            columns={columns}
            primaryKey={primaryKey}
            selections={selections}
            editable={workspace.editable}
          />
        )}
      </TableAndControlsContainer>
    </ManagementPageContainer>
  );
}

WorkspaceSettings.propTypes = {
  workspaceId: PropTypes.string.isRequired
};
