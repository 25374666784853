import { useState } from 'react';
import PropTypes from 'prop-types';
import { RateContainer, Rate, RateLabel } from './styled';

const Rating = ({ placeholder, onChange }) => {
  const [rating, setRating] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    onChange(newValue);
    setDisabled(true);
  };

  return (
    <RateContainer component="fieldset">
      <RateLabel className="rate-label" variant="body2" component="label">
        {placeholder}
      </RateLabel>
      <Rate
        name="rate-current-answer"
        value={rating}
        // precision={0.5}
        size="medium"
        disabled={disabled}
        onChange={handleRatingChange}
      />
    </RateContainer>
  );
};

Rating.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default Rating;
