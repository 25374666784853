// import r2wc from '@r2wc/react-to-web-component';
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AIAssistant from './features/AIAssistant';

import theme from './utils/theme';
import './utils/i18n';
// import './App.scss';

const App = ({ visible }) => {
  if (!visible) {
    return null;
  }
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AIAssistant />
      </ThemeProvider>
    </>
  );
};

// App.propTypes = {
//   visible: PropTypes.bool,
// };
// 
// const WebComponent = r2wc(App, {
//   props: {
//     visible: 'boolean',
//   },
// });
// 
// export default WebComponent;

export default App;
