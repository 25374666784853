import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SendMessageBtn from '../../atoms/Button/SendMessage';
import { FeedbackForm, FeedbackInput } from './styled';

const FeedbackForReply = ({ visible, placeholder, onSubmit }) => {
  const [disabled, setDisabled] = useState(false);
  const [feedback, setFeedback] = useState('');

  if (!visible) {
    return null;
  }

  const handleChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    onSubmit(feedback);
    setDisabled(true);
  };

  return (
    <Box component="fieldset">
      <Typography variant="body2" component="label">
        {placeholder}
      </Typography>
      {!disabled ? (
        <FeedbackForm>
          <FeedbackInput
            fullWidth
            value={feedback}
            disabled={disabled}
            autoFocus
            onChange={handleChange}
            className={disabled ? 'submited' : ''}
          />
          <SendMessageBtn
            disabled={!feedback.length}
            size="small"
            onClick={handleClick}
          />
        </FeedbackForm>
      ) : (
        <Typography>{feedback}</Typography>
      )}
    </Box>
  );
};

FeedbackForReply.propTypes = {
  visible: PropTypes.bool,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default FeedbackForReply;
