import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import * as gtm from '../utils/gtm';
import { Colors } from '../styles';
import { AlertTypes, RequestStatuses } from '../constants';
import Alert from '../components/core/Alert';
import { DateRangeConstraint } from '../classes/Constraints';
import DriversScatterPlot from './DriversScatterPlot';
import { StoreContext } from '../StoreContext';
import { DateRangeSelector } from './DateRangeSelector';
import Dropdown from '../components/core/Dropdown';
import { useStableMemo } from '../utils/hooks';
import { DateField, NumericField, ScoreField } from '../classes/MetadataFields';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '../components/core/Tabs';
import { ConceptList } from './ConceptList';
import { useTextWidth } from '../utils/hooks/useTextWidth';
import { DriverContext, useDriverContext } from './DriverContext';
import { Button } from '../components/core/Button';
import { useHover } from '../HoverContext';
import { instanceOf } from './utils';
import { useFilter, useSearchParams } from '../search_params';
import { Icon, IconTypes } from '../components/icons';
import { getSpecifiedMatchCounts } from '../utils/ApiUtilsV5';
import { getConceptCountsOutliers } from '../actions';
import { useCurrentView } from '../side_panel/views/view';

export function WorkArea() {
  const filter = useFilter();
  const driverContext = useDriverContext(filter);

  return (
    <DriverContext.Provider value={driverContext}>
      <InnerWorkArea />
    </DriverContext.Provider>
  );
}

function InnerWorkArea() {
  const { projectId } = useParams();
  const { searchParams, updateSearch } = useSearchParams();
  const { filter } = searchParams;
  const { metadata, activeConceptListName } = useContext(StoreContext);
  const { drivers, status } = useContext(DriverContext);
  const [, setHovered] = useHover();
  const [hoveredDataPoint, setHoveredDataPoint] = useState(undefined);
  const { conceptSelector } = useCurrentView();
  const [mouseIn, setMouseIn] = useState(false);
  const dateFields = metadata.filter(instanceOf(DateField));
  const dateConstraint = filter.find(
    constraint => constraint.name === searchParams.comparison_field
  );
  const comparisonConstraint = useStableMemo(
    () => getComparisonConstraint(dateConstraint, searchParams.time, metadata),
    [dateConstraint, searchParams.time, metadata]
  );
  const matchType = searchParams.match_type === 'total' ? 'both' : 'exact';
  const isActive = searchParams.concepts === 'active';
  const [outlierTotalMatches, setOutlierTotalMatches] = useState(null);

  const scoreAndNumericFields = metadata.filter(
    field => instanceOf(ScoreField, field) || instanceOf(NumericField, field)
  );
  const noValidFields = scoreAndNumericFields.length === 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        getConceptCountsOutliers(projectId, conceptSelector, filter, [], matchType);
      } catch (error) {
        console.error('Error setting outlier filter:', error);
      }
    };
    if (!noValidFields) {
      fetchData();
    }
  }, [matchType, filter, activeConceptListName, isActive]);

  const comparisonFilter = useStableMemo(() => {
    if (!comparisonConstraint) {
      return undefined;
    }

    return [
      ...filter.filter(({ name }) => name !== comparisonConstraint.name),
      comparisonConstraint
    ];
  }, [filter, comparisonConstraint]);

  const comparisonContext = useDriverContext(
    comparisonFilter,
    searchParams.display_same === 'true'
  );

  const showingBothPlots = dateConstraint && dateConstraint.minimum;

  const selectedOptionWidth = useTextWidth(
    searchParams.comparison_field || 'Select date field to compare periods',
    '0.875rem',
    'bold'
  );

  const scoreField = metadata.find(item => item.name === searchParams.field);

  function handleHover(data) {
    if (data) {
      if (data.id !== hoveredDataPoint?.id) {
        const concept = drivers.find(driver => driver.hash() === data.id);
        setHovered(concept);
        setHoveredDataPoint(data);
      }
    } else {
      if (hoveredDataPoint) {
        setHovered();
        setHoveredDataPoint(undefined);
      }
    }
  }

  useEffect(() => {
    if (isActive && Boolean(drivers?.length)) {
      const keys = drivers.reduce((acc, el) => {
        if (el.outlier) {
          acc.push(el.name);
        }
        return acc;
      }, []);

      getSpecifiedMatchCounts(projectId, keys, filter, matchType)
        .then(setOutlierTotalMatches)
        .catch(err => {
          console.log(err);
        });
    }
  }, [isActive, projectId, drivers, setOutlierTotalMatches, filter]);

  const scatterPlots = (
    <div className="drivers">
      {dateFields.length > 0 && (
        <DateHeaderWrapper
          css={css`
              display: flex;
              flex-direction: row;
              align-content: center;
              justify-content: space-between;
          `}
        >
          <div>
            <DateHeaderCompare>Compare period over period</DateHeaderCompare>
            <div>
              <Dropdown
                borderless={true}
                aria-label="comparison date field"
                css={css`
                    width: calc(1.5rem + ${selectedOptionWidth}px);
                    ${SubtleDropdownStyles}
                `}
                disabled={dateFields.length === 0}
                value={searchParams.comparison_field || ''}
                onChange={event => {
                  gtm.trigger('drivers_select-comparison-date-field');
                  const field = metadata.find(
                    field => field.name === event.target.value
                  );
                  updateSearch({
                    comparison_field: field?.name ?? undefined,
                    filter: getUpdatedFilter(filter, field)
                  });
                }}
              >
                <option value="">Select date field to compare periods</option>
                {metadata.filter(instanceOf(DateField)).map(field => (
                  <option key={field.name} value={field.name}>
                    {field.name}
                  </option>
                ))}
              </Dropdown>
              {dateConstraint && (
                <DateRangeSelector
                  constraint={dateConstraint}
                  trackingItem="drivers_select-comparison-date-range"
                />
              )}
            </div>
          </div>
          {dateConstraint && (
            <Button
              flavor="subtle"
              aria-label="Stop comparing"
              data-tracking-item="drivers_stop-comparison"
              onClick={() => {
                const filterWithoutComparison = filter.filter(
                  constraint =>
                    constraint.name !== searchParams.comparison_field
                );
                updateSearch({
                  comparison_field: null,
                  filter: filterWithoutComparison
                });
              }}
            >
              <Icon type={IconTypes.CLOSE} theme="primary" />
            </Button>
          )}
        </DateHeaderWrapper>
      )}
      {status === RequestStatuses.REJECTED ? (
        <Alert type={AlertTypes.ERROR}>
          Something went wrong loading drivers
        </Alert>
      ) : (
        <>
          <DriversScatterPlot
            projectId={projectId}
            hoveredDataPoint={hoveredDataPoint}
            setHoveredDataPoint={handleHover}
            mouseIn={mouseIn}
            setMouseIn={setMouseIn}
            outlierTotalMatches={outlierTotalMatches}
            fieldName={searchParams.field}
          />
          {showingBothPlots && (
            <>
              <ComparisonPeriod
                period={searchParams.time}
                onPeriodChange={event => {
                  updateSearch({ time: event.target.value });
                }}
                dateConstraint={comparisonConstraint}
              />

              <DriverContext.Provider value={comparisonContext}>
                <DriversScatterPlot
                  projectId={projectId}
                  hoveredDataPoint={hoveredDataPoint}
                  setHoveredDataPoint={handleHover}
                  mouseIn={mouseIn}
                  setMouseIn={setMouseIn}
                  fieldName={searchParams.field}
                  outlierTotalMatches={outlierTotalMatches}
                  comparing
                />
              </DriverContext.Provider>
            </>
          )}
        </>
      )}
    </div>
  );

  return (
    <Tabs
      activeTabKey={searchParams.tab}
      onTabKeyChange={tabKey => updateSearch({ tab: tabKey })}
      css={css`
          height: 100%;
          box-sizing: border-box;
      `}
    >
      <TabList>
        <Tab tabKey="visualization" trackingItem="drivers_visualization-tab">
          <Icon type={IconTypes.CHART} />
          Visualization
        </Tab>
        <Tab
          tabKey="concepts"
          trackingItem={
            showingBothPlots
              ? 'drivers_concepts-tab_above'
              : 'drivers_concepts-tab_single'
          }
        >
          <Icon type={IconTypes.TABLE} />
          Concepts
          {showingBothPlots && ' (Above)'}
        </Tab>
        {showingBothPlots && (
          <Tab
            tabKey="compared-concepts"
            trackingItem="drivers_concepts-tab_below"
          >
            <Icon type={IconTypes.TABLE} />
            Concepts (Below)
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel tabKey="visualization">{scatterPlots}</TabPanel>
        <TabPanel tabKey="concepts">
          <ConceptList projectId={projectId} scoreField={scoreField} outlierTotalMatches={outlierTotalMatches} />
        </TabPanel>
        <TabPanel tabKey="compared-concepts">
          <DriverContext.Provider value={comparisonContext}>
            <ConceptList
              projectId={projectId}
              scoreField={scoreField}
              isComparisonList
              outlierTotalMatches={outlierTotalMatches}
            />
          </DriverContext.Provider>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function BoldText({ children, ...props }) {
  return (
    <span
      {...props}
      css={css`
          color: ${Colors.gray9};
          font-size: 0.875rem;
          font-weight: bold;
      `}
    >
      {children}
    </span>
  );
}

export function DateRange({ constraint, ...props }) {
  const { metadata } = useContext(StoreContext);
  const dateField = metadata.find(field => field.name === constraint.name);
  const maxDate = constraint.maximum || dateField.maximum;
  const minDate = constraint.minimum || dateField.minimum;
  const formatDate = date => date.format('MMM D, YYYY');
  return (
    <BoldText {...props} data-test-id="date-range">
      {formatDate(minDate)} - {formatDate(maxDate)}
    </BoldText>
  );
}

DateRange.propTypes = {
  constraint: PropTypes.instanceOf(DateRangeConstraint).isRequired
};

export function getComparisonConstraint(constraint, period, metadata) {
  if (constraint && constraint.minimum) {
    const field = metadata.find(field => field.name === constraint.name);
    const minimum = constraint.minimum;
    const maximum = constraint.maximum || field.maximum;

    let newMinimum, newMaximum;
    if (period === 'previous_period') {
      const dayRange = maximum.diff(minimum, 'days');
      newMaximum = minimum.clone().subtract(1, 'days');
      newMinimum = newMaximum.clone().subtract(dayRange, 'days');
    } else {
      const yearRange = maximum.diff(minimum, 'years');
      newMaximum = maximum.clone().subtract(yearRange + 1, 'years');
      newMinimum = minimum.clone().subtract(yearRange + 1, 'years');
    }

    return new DateRangeConstraint(
      constraint.name,
      newMinimum,
      newMaximum.endOf('day')
    );
  }
}

function ComparisonPeriod({ period, onPeriodChange, dateConstraint }) {
  return (
    <DateHeaderWrapper>
      <DateHeaderCompare>Compare to</DateHeaderCompare>
      <div>
        <Dropdown
          borderless={true}
          aria-label="comparison period"
          css={css`
              ${SubtleDropdownStyles};
              padding-right: 1.5rem; //accommodate the icon
          `}
          value={period}
          onChange={event => {
            gtm.trigger('drivers_select-comparison-period');
            onPeriodChange(event);
          }}
        >
          <option value="previous_period">Previous period</option>
          <option value="year_over_year">Year over year</option>
        </Dropdown>
        <DateRange
          constraint={dateConstraint}
          css={css`
              margin-left: 0.5rem;
          `}
        />
      </div>
    </DateHeaderWrapper>
  );
}

ComparisonPeriod.propTypes = {
  period: PropTypes.oneOf(['previous_period', 'year_over_year']).isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  dateConstraint: PropTypes.instanceOf(DateRangeConstraint).isRequired
};

export const getUpdatedFilter = (filter, field) => {
  const dateConstraints = filter.filter(instanceOf(DateRangeConstraint));
  const filterWithoutDateConstraints = filter.filter(
    constraint => !dateConstraints.includes(constraint)
  );

  if (!field) {
    return filterWithoutDateConstraints;
  } else {
    const existingConstraint = dateConstraints.find(
      ({ name }) => field.name === name
    );

    const halfDayRange = Math.ceil(
      field.maximum.diff(field.minimum, 'days') / 2
    );
    const dateRangeMidPoint = field.maximum
      .clone()
      .subtract(halfDayRange, 'days');

    if (dateConstraints.length !== 1 || !existingConstraint) {
      const constraint =
        existingConstraint ||
        new DateRangeConstraint(field.name, dateRangeMidPoint, field.maximum);
      return filterWithoutDateConstraints.concat(constraint);
    }
  }

  return filter;
};

const DateHeaderCompare = styled.div`
    color: ${Colors.gray5};
    margin-bottom: 0.5rem;
`;

const DateHeaderWrapper = styled.div`
    margin-bottom: 1rem;
    background: ${Colors.blue0};
    border: 1px solid ${Colors.blue1};
    box-sizing: border-box;
    padding: 0.5rem;
`;

const SubtleDropdownStyles = css`
    background: transparent;
    color: inherit;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0rem;
`;
