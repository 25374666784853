import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';

import { NoChangesMessage, Title } from './components';
import { Card } from '../components/Card';
import BuildChangesSummary from './BuildChangesSummary';
import { StoreContext } from '../StoreContext';
import { AuthContext } from '../settings/model';
import { Button } from '../components/core/Button';
import { Icon, IconTypes } from '../components/icons';
import { getEstimate, RebuildModalWithContext } from './RebuildModal';
import { objHasPerm } from '../utils/common';
import { getBuildChanges } from './utils';
import { useConceptManagement } from '../data_hooks';

export const NextBuildSection = () => {
  const { project, collocationNumbers } = useContext(StoreContext);
  const { conceptManagement } = useConceptManagement(project);
  const { serverStatus } = useContext(AuthContext);
  const buildTimeEstimate = getEstimate(project.last_build_info);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <InnerNextBuildSection
        project={project}
        conceptManagement={conceptManagement}
        serverStatus={serverStatus}
        collocationNumbers={collocationNumbers}
        buildTimeEstimate={buildTimeEstimate}
        chooseRebuild={() => {
          setShowModal(true);
        }}
      />
      <RebuildModalWithContext
        isOpen={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

// Presentational component created and exported for storybook only
export const InnerNextBuildSection = ({
  conceptManagement,
  project,
  serverStatus,
  buildTimeEstimate,
  chooseRebuild,
  collocationNumbers
}) => {
  const { hasBuildChanges, canRebuild } = getBuildChanges(
    project,
    serverStatus,
    conceptManagement,
    collocationNumbers
  );

  return (
    <Card data-test-id="next-build-section">
      <Card.Section padding="0.625rem">
        <Title>Next build</Title>
      </Card.Section>
      <Card.Separator />
      <Card.Section>
        {hasBuildChanges ? (
          <BuildChangesSummary
            serverStatus={serverStatus}
            collocationNumbers={collocationNumbers}
            project={project}
            conceptManagement={conceptManagement}
            detailedDiff={false}
          />
        ) : (
          <NoChangesMessage>No changes</NoChangesMessage>
        )}
      </Card.Section>
      {objHasPerm(project, 'write') && (
        <Card.Section>
          <Button
            palette="green"
            onClick={chooseRebuild}
            disabled={!canRebuild}
            css={css`
              margin-right: 1rem;
            `}
          >
            <Icon type={IconTypes.CLOCK} size="18" />
            Rebuild
          </Button>
          {canRebuild && (
            <>
              Estimated time: <strong>{buildTimeEstimate}</strong>
            </>
          )}
        </Card.Section>
      )}
    </Card>
  );
};
