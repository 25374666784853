import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';

import { StoreContext } from '../StoreContext';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';
import { getMoreDetailLink } from '../highlights/utils';
import { RoutePatterns } from '../constants';
import { objHasPerm } from '../utils/common';
import { DisableableLink } from '../highlights/DisableableLink';
import { Colors, Mixins } from '../styles';
import { Button } from './core/Button';
import { ProjectBrancher } from './ProjectBrancher';
import Tooltip from './core/Tooltip';
import { Icon, IconTypes } from './icons';
import { getScrapersV2Status } from '../utils/ApiUtilsV5';
import { useFetch } from '../utils/hooks';
import { FeatureFlagsContext } from '../FeatureFlagsContext';

export function CopyProjectButton({ tooltipPosition = 'left' }) {
  const { project } = useContext(StoreContext);

  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsBuilding = sentimentStatus === SENTIMENT_STATUS.BUILDING;

  return (
    <Tooltip
      disabled={!sentimentIsBuilding}
      position={tooltipPosition}
      anchor={
        <CopyProjectButtonInner
          project={project}
          sentimentIsBuilding={sentimentIsBuilding}
        />
      }
    >
      The project cannot be copied until sentiment is done building.
    </Tooltip>
  );
}
function CopyProjectButtonInner({ project, sentimentIsBuilding }) {
  const [openModal, setOpenModal] = useState(false);
  const { workspaces, userDefaultWorkspaceId, totalDocCount } =
    useContext(StoreContext);
  const workspace = workspaces.find(
    workspace => workspace.workspace_id === project.workspace_id
  );
  const hasAnyWritePermissions = workspaces.some(workspace =>
    objHasPerm(workspace, 'write')
  );
  const isDisabled = !hasAnyWritePermissions || sentimentIsBuilding;

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        disabled={isDisabled}
        trackingItem="highlights_copy-project_open-modal"
        css={css`
          width: 100%;
          &:disabled {
            pointer-events: none;
          }
          svg {
            fill: none;
          }
          &:hover,
          &:active {
            svg {
              fill: none;
            }
          }
        `}
      >
        <Icon type={IconTypes.COPY} size="1em" />
        Copy project
      </Button>
      {openModal && (
        <ProjectBrancher
          title="Copy project"
          subtitle={
            <>
              Copying project <b>{project.name}</b> from workspace{' '}
              <b>{workspace.name}</b>
            </>
          }
          workspaces={workspaces}
          userDefaultWorkspaceId={userDefaultWorkspaceId}
          filter={[]}
          selection={null}
          filterCount={totalDocCount}
          srcProjectId={project.project_id}
          srcProjectWorkspaceId={project.workspace_id}
          srcProjectName={project.name}
          srcProjectDescription={project.description}
          onClose={() => setOpenModal(false)}
          trackingPrefix="highlights_copy-project"
          showing={true}
        />
      )}
    </>
  );
}

export function UploadMoreButton({ tooltipPosition = 'left' }) {
  const { project } = useContext(StoreContext);

  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsBuilding = sentimentStatus === SENTIMENT_STATUS.BUILDING;

  return (
    <Tooltip
      disabled={!sentimentIsBuilding}
      position={tooltipPosition}
      anchor={
        <UploadMoreButtonInner
          project={project}
          sentimentIsBuilding={sentimentIsBuilding}
        />
      }
    >
      More data cannot be uploaded until sentiment is done building.
    </Tooltip>
  );
}

function UploadMoreButtonInner({ project, sentimentIsBuilding }) {
  const isDisabled = !objHasPerm(project, 'create') || sentimentIsBuilding;
  return (
    <DisableableLink
      to={getMoreDetailLink(
        project.workspace_id,
        project.project_id,
        RoutePatterns.UPLOAD_DATA
      )}
      disabled={isDisabled}
      data-tracking-item="highlights_upload-more-button"
      css={actionLinkStyles}
    >
      <Icon
        type={IconTypes.UPLOAD}
        theme={isDisabled ? 'disabled' : 'secondary'}
        size="1em"
      />
      Upload data
    </DisableableLink>
  );
}

export function UpdateDataStreamButton({ tooltipPosition = 'left' }) {
  const { project } = useContext(StoreContext);
  const featureFlags = useContext(FeatureFlagsContext);
  const sentimentStatus = getSentimentStatus(project);
  const sentimentIsBuilding = sentimentStatus === SENTIMENT_STATUS.BUILDING;

  let showDataStreamButton = false;

  if (project.createdByDataStream) showDataStreamButton = true;

  if (featureFlags.new_scrapers) {
    const { status, response } = useFetch(getScrapersV2Status);

    if (status == 'FULFILLED') {
      const projectScrapes = response.filter(
        item => item.project_id == project.project_id
      );

      if (projectScrapes.length > 0) showDataStreamButton = true;
    }
  }

  if (!showDataStreamButton) {
    return <></>;
  }

  return (
    <Tooltip
      disabled={!sentimentIsBuilding}
      position={tooltipPosition}
      anchor={
        <UpdateDataStreamInner
          project={project}
          sentimentIsBuilding={sentimentIsBuilding}
        />
      }
    >
      Data stream cannot be updated until sentiment is done building.
    </Tooltip>
  );
}

function UpdateDataStreamInner({ project, sentimentIsBuilding }) {
  const isDisabled = !objHasPerm(project, 'create') || sentimentIsBuilding;

  return (
    <DisableableLink
      to={getMoreDetailLink(
        project.workspace_id,
        project.project_id,
        RoutePatterns.UPDATE_PROJECT_STREAM_DATA
      )}
      disabled={isDisabled}
      data-tracking-item="update-data-stream-button"
      css={actionLinkStyles}
    >
      <Icon
        type={IconTypes.PEN}
        theme={isDisabled ? 'disabled' : 'secondary'}
        size="1em"
      />
      Update data stream
    </DisableableLink>
  );
}

const actionLinkStyles = css`
  padding: 0.375rem 0.5rem;
  background: ${Colors.green1};
  color: ${Colors.gray9};
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  /* hack to make this the same height as the buttons it appears next to */
  min-height: 1.4375rem;
  ${Mixins.shadowOutset}
  ${Mixins.roundedCorners}
  svg,
  .fa::before,
  &.fa::before {
    margin-right: 0.5em;
    color: ${Colors.green5};
    vertical-align: bottom;
  }

  &:not([href]) {
    background: ${Colors.gray0};
    color: ${Colors.gray5};
    &:active,
    &:hover {
      background: ${Colors.gray0};
      color: ${Colors.gray5};
    }
    .fa::before,
    &.fa::before,
    &:hover .fa::before {
      color: ${Colors.gray5};
    }
  }
  &:hover {
    background: ${Colors.green2};
    color: ${Colors.gray9};
    text-decoration: none;
    .fa::before,
    &.fa::before {
      fill: ${Colors.green6};
      color: ${Colors.green6};
    }
  }
  &:active {
    text-decoration: none;
  }
`;
