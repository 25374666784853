import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import { useMountedRef, useUniqueId } from '../utils/hooks';
import { Colors, Mixins } from '../styles';
import { AlertTypes } from '../constants';
import Alert from '../components/core/Alert';
import Dropdown from '../components/core/Dropdown';
import { Button } from '../components/core/Button';
import { Toggle } from '../components/core/Toggle';

const AuthFormContext = React.createContext();

/**
 * Form component designed for use in the settings page.
 *
 * The `defaults` attribute should contain a map setting the default values of
 * all the fields in the form (corresponding with the `field` attributes of
 * the individual form fields).
 *
 * The `onSubmit` attribute should be a handler function that will be called
 * when the user saves the form.  It will be called with one argument,
 * an object containing the current values of all the form fields, and should
 * return a promise.  If the promise resolves, the form will display a success
 * message.  If the promise is rejected, the rejection reason should include a
 * `fieldErrors` field, which is an object that maps fields to error messages.
 * The error messages will be shown by the appropriate fields, with a red
 * highlight around the input.
 */

export const FIELD_WIDTH = 30;

export function AuthForm({ defaults, onSubmit: handleSubmit, children }) {
  const [formData, updateFormData] = useState(defaults);
  const [formState, updateFormState] = useState({ state: 'active' });
  const isMounted = useMountedRef();
  const generalError = formState.generalError
    ? `Error: ${formState.generalError}`
    : Object.keys(formState.fieldErrors || {}).length > 0
      ? 'Error'
      : 'An unexpected error occurred. If the problem persists, please contact support@luminoso.com.';
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        updateFormState({ state: 'pending' });
        handleSubmit(formData)
          .then(() => {
            if (isMounted.current) {
              updateFormState({ state: 'success' });
            }
          })
          .catch(error => {
            updateFormState({
              state: 'error',
              fieldErrors: error.fieldErrors || {},
              generalError: error.generalError
            });
          });
      }}
      onFocus={() => {
        if (formState.state !== 'error') {
          updateFormState({ state: 'active' });
        }
      }}
    >
      <fieldset
        disabled={formState.state === 'pending'}
        css={css`
          & > * {
            margin-top: 1rem;
          }
        `}
      >
        <AuthFormContext.Provider
          value={{ formData, updateFormData, formState }}
        >
          {children}
          {formState.state === 'success' ? (
            <Alert
              css={css`
                margin-top: 2rem;
                display: block;
                width: ${FIELD_WIDTH}rem;
              `}
              type={AlertTypes.SUCCESS}
              disappearing
            >
              Success!
            </Alert>
          ) : formState.state === 'error' ? (
            <Alert
              css={css`
                margin-top: 2rem;
                display: block;
                width: ${FIELD_WIDTH}rem;
              `}
              type={AlertTypes.ERROR}
            >
              {generalError}
            </Alert>
          ) : null}
        </AuthFormContext.Provider>
      </fieldset>
    </form>
  );
}

AuthForm.propTypes = {
  defaults: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
};

export function StaticText({ label, field }) {
  const { formData } = useContext(AuthFormContext);
  return (
    <>
      <div
        css={css`
          margin-left: 0.5rem;
          color: ${Colors.gray5};
        `}
      >
        {_.upperFirst(label)}
      </div>
      <div
        css={css`
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          margin-left: 0.5rem;
        `}
      >
        {formData[field]}
      </div>
    </>
  );
}

StaticText.propTypes = {
  label: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired
};

function ErrorMessage({ messages }) {
  return (
    <em
      css={css`
        color: ${Colors.red5};
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-left: 1rem;
      `}
    >
      *{messages.join('  ')}
    </em>
  );
}

function FieldErrors({ field }) {
  const { formState } = useContext(AuthFormContext);
  if (
    formState.state === 'error' &&
    field in formState.fieldErrors &&
    formState.fieldErrors[field].length > 0
  ) {
    return <ErrorMessage messages={formState.fieldErrors[field]} />;
  } else {
    return null;
  }
}

export function TextField({ label, field }) {
  const id = useUniqueId();
  const { formData, updateFormData, formState } = useContext(AuthFormContext);
  return (
    <>
      <Label htmlFor={id} label={label} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: ${FIELD_WIDTH}rem;
        `}
      >
        <input
          id={id}
          css={css`
            border-style: solid;
            border-width: 1px;
            width: ${FIELD_WIDTH}rem;
            ${formState.state === 'error' && field in formState.fieldErrors
              ? css`
                  border-color: ${Colors.red5};
                `
              : css`
                  border-color: transparent;
                `}
          `}
          value={formData[field]}
          onChange={event => {
            updateFormData({ ...formData, [field]: event.target.value });
          }}
        />
        <FieldErrors field={field} />
      </div>
    </>
  );
}

TextField.propTypes = {
  label: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired
};

export function TextArea({ label, field, ...textAreaProps }) {
  const id = useUniqueId();
  const { formData, updateFormData, formState } = useContext(AuthFormContext);
  return (
    <>
      <Label htmlFor={id} label={label} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: ${FIELD_WIDTH}rem;
        `}
      >
        <textarea
          id={id}
          css={css`
            border-style: solid;
            border-width: 1px;
            width: ${FIELD_WIDTH}rem;
            ${formState.state === 'error' && field in formState.fieldErrors
              ? css`
                  border-color: ${Colors.red5};
                `
              : css`
                  border-color: transparent;
                `}
            &::placeholder {
              ${Mixins.hintText}
            }
          `}
          value={formData[field]}
          onChange={event => {
            updateFormData({ ...formData, [field]: event.target.value });
          }}
          {...textAreaProps}
        />
        <FieldErrors field={field} />
      </div>
    </>
  );
}

TextArea.propTypes = {
  label: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired
};

export function PasswordField({ label, field }) {
  const id = useUniqueId();
  const { formData, updateFormData, formState } = useContext(AuthFormContext);
  return (
    <>
      <Label htmlFor={id} label={label} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        <input
          id={id}
          css={css`
            border-style: solid;
            border-width: 1px;
            // FIELD_WIDTH - 1 to account for the 1rem of left-right padding
            // native to the input tag
            width: ${FIELD_WIDTH - 1}rem;
            ${formState.state === 'error' && field in formState.fieldErrors
              ? css`
                  border-color: ${Colors.red5};
                `
              : css`
                  border-color: transparent;
                `}
          `}
          value={formData[field]}
          type="password"
          onChange={event => {
            updateFormData({ ...formData, [field]: event.target.value });
          }}
        />
        <FieldErrors field={field} />
      </div>
    </>
  );
}

PasswordField.propTypes = {
  label: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired
};

export function DropdownField({ label, field, options }) {
  const id = useUniqueId();
  const { formData, updateFormData } = useContext(AuthFormContext);
  const noSelection = formData[field] == null;
  return (
    <>
      <Label htmlFor={id} label={label} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        <Dropdown
          id={id}
          containerCss={css`
            display: block;
            width: ${FIELD_WIDTH}rem;
            font-style: ${noSelection ? 'italic' : undefined};
            margin: 0.5rem 0;
          `}
          value={formData[field] ?? ''}
          onChange={event => {
            updateFormData({ ...formData, [field]: event.target.value });
          }}
        >
          {noSelection && (
            <option disabled value="">
              Select {label}
            </option>
          )}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </Dropdown>
        <FieldErrors field={field} />
      </div>
    </>
  );
}

export function ToggleField({ label, field, explanation }) {
  const { formData, updateFormData } = useContext(AuthFormContext);
  return (
    <>
      <div
        css={css`
          margin-left: 0.5rem;
          color: ${Colors.gray5};
        `}
      >
        {_.upperFirst(label)}
      </div>
      {explanation && (
        <p
          css={css`
            width: 30rem;
            margin-left: 0.5rem;
          `}
        >
          {explanation}
        </p>
      )}
      <div
        css={css`
          margin-left: 0.5rem;
        `}
      >
        <Toggle
          label={_.upperFirst(label)}
          onChange={event => {
            updateFormData({ ...formData, [field]: event.target.checked });
          }}
          checked={formData[field]}
        />
      </div>
    </>
  );
}

export function SubmitButton({ children, disabled }) {
  return (
    <Button
      css={css`
        margin-top: 2rem;
      `}
      type="submit"
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool
};

export function CancelButton({ children, onClick }) {
  return (
    <Button
      palette="red"
      css={css`
        margin-left: 0.5rem;
        margin-top: 2rem;
      `}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

function Label(props) {
  return (
    <label
      htmlFor={props.htmlFor}
      css={css`
        margin-left: 0.5rem;
        display: block;
        font-size: 1rem;
        color: ${Colors.gray5};
      `}
    >
      {_.upperFirst(props.label)}
    </label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string
};
