import React from 'react';
import { css } from '@emotion/react';

import { standardRadius } from '../../styles';
import { Button } from '../../components/core/Button';

const StreamSourceLayout = ({ children, onGoBack }) => {
  const goBackHandler = () => {
    onGoBack();
  };

  return (
    <div
      css={css`
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: ${standardRadius};
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        background: white;
        padding: 1rem;
        overflow-y: auto;
        margin-top: 1rem;
      `}
    >
      {' '}
      <div
        css={css`
          align-self: start;
          padding-left: 1rem;
        `}
      >
        <Button onClick={goBackHandler}>Back</Button>
      </div>
      <div
        css={css`
          margin-top: 2rem;
          min-width: 50%;
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default StreamSourceLayout;
