import cx from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { RequestStatuses } from '../constants';
import autoSelectWorkspaceId from '../utils/autoSelectWorkspaceId';
import { objHasPerm } from '../utils/common';
import { thousandify } from '../utils/NumFmtUtils';
import { buildProject, copyProject } from '../utils/ApiUtilsV5';
import { naturalSortByName } from '../utils/NaturalSort';
import { Checkbox } from './core/Checkbox';
import Dropdown from './core/Dropdown';
import Spinner from './core/Spinner';
import SimpleModal from './core/SimpleModal';
import DialogFooter from './DialogFooter';
import SupportEmailLink from './SupportEmailLink';
import { useMountedRef, useUniqueId } from '../utils/hooks';
import { ifAllExist } from '../utils/common';
import { Button } from './core/Button';
import { Colors } from '../styles';
import { useTotalMatches } from '../utils/hooks/useTotalMatches';
import SentimentSelector from '../upload_page/SentimentSelector';

export function ProjectBrancher({ title, subtitle, trackingPrefix, ...props }) {
  const isMounted = useMountedRef();
  const request = useRef();
  const [{ creationStatus, statusMessage }, setRequestStatus] = useState({
    creationStatus: '',
    statusMessage: null
  });

  useEffect(() => {
    return () => {
      if (request.current && request.current.abort) {
        request.current.abort();
      }
    };
  }, []);

  const closeModal = () => {
    if (creationStatus !== RequestStatuses.PENDING) {
      props.onClose();
    }
  };

  const createProject = ({
    workspaceId,
    name,
    description,
    matchType,
    shouldNotify,
    skipSentiment,
    dashboardBuild
  }) => {
    setRequestStatus({
      creationStatus: RequestStatuses.PENDING,
      statusMessage: null
    });
    request.current = copyProject({
      projectId: props.srcProjectId,
      workspaceId,
      name,
      description,
      filter: props.filter,
      selection: props.selection,
      matchType,
      shouldNotify,
      skipRebuild: true
    })
      .then(project => {
        buildProject(
          project?.project_id,
          shouldNotify,
          skipSentiment,
          dashboardBuild
        );
        if (isMounted.current) {
          setRequestStatus({
            creationStatus: RequestStatuses.FULFILLED,
            statusMessage: null
          });
        }
      })
      .catch(error => {
        console.error('Caught error:', error);
        if (isMounted.current) {
          setRequestStatus({
            creationStatus: RequestStatuses.REJECTED,
            statusMessage: error.code
          });
        }
      });
  };

  const mode = props.filter.length || props.selection ? 'branch' : 'copy';
  const hasCreatedBranchedProject =
    creationStatus && creationStatus !== RequestStatuses.PENDING;

  return (
    <SimpleModal
      isOpen={true}
      onHide={closeModal}
      trackingPrefix={trackingPrefix}
      header={!hasCreatedBranchedProject && <h4>{title}</h4>}
    >
      {hasCreatedBranchedProject ? (
        <ProjectCreationStatus
          status={creationStatus}
          statusMessage={statusMessage}
          closeModal={closeModal}
        />
      ) : (
        <>
          <p>{subtitle}</p>
          <ProjectBrancherForm
            mode={mode}
            filterCount={props.filterCount}
            selection={props.selection}
            userDefaultWorkspaceId={props.userDefaultWorkspaceId}
            srcProjectWorkspaceId={props.srcProjectWorkspaceId}
            srcProjectName={props.srcProjectName}
            srcProjectDescription={props.srcProjectDescription}
            workspaces={props.workspaces}
            onCancel={closeModal}
            onSubmit={createProject}
            isCreatingProject={creationStatus === RequestStatuses.PENDING}
            trackingPrefix={trackingPrefix}
          />
        </>
      )}
    </SimpleModal>
  );
}

ProjectBrancher.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      workspace_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  userDefaultWorkspaceId: PropTypes.string,
  srcProjectWorkspaceId: PropTypes.string.isRequired,
  srcProjectId: PropTypes.string.isRequired,
  srcProjectName: PropTypes.string.isRequired,
  srcProjectDescription: PropTypes.string,
  filter: PropTypes.array.isRequired,
  filterCount: PropTypes.number.isRequired,
  selection: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  trackingPrefix: PropTypes.string
};

const Container = ({ contentType, isInline, children }) => {
  const baseClassName = `project-brancher__${contentType}-container`;
  return (
    <div
      className={cx(baseClassName, { [`${baseClassName}--inline`]: isInline })}
    >
      {children}
    </div>
  );
};
const FieldContainer = props => <Container {...props} contentType="field" />;
const LabelContainer = props => <Container {...props} contentType="label" />;

const RequiredFieldMessage = () => (
  <span className="err-text">This is a required field</span>
);

const ProjectBrancherForm = props => {
  const filteredWorkspaces = props.workspaces.filter(workspace =>
    objHasPerm(workspace, 'write')
  );
  const defaultWorkspaceId = autoSelectWorkspaceId(
    filteredWorkspaces,
    props.userDefaultWorkspaceId,
    props.srcProjectWorkspaceId
  );
  const [workspaceId, setWorkspaceId] = useState(defaultWorkspaceId);
  const [name, setName] = useState(
    props.mode === 'copy'
      ? `Copy of ${props.srcProjectName}`
      : `Partial copy of ${props.srcProjectName}`
  );
  const [description, setDescription] = useState(
    props.srcProjectDescription ?? ''
  );
  const [matchType, setMatchType] = useState('both');
  const [shouldNotify, setShouldNotify] = useState(false);
  const [skipSentiment, setSkipSentiment] = useState(false);

  const dashboardBuild = false;
  const isTotalMatch = useTotalMatches();

  const shouldDisableExactMatches = !props.selection?.exactMatchCount;
  const docCount = props.selection
    ? !isTotalMatch
      ? props.selection.exactMatchCount
      : props.selection.matchCount
    : props.filterCount || 0;
  return (
    <form
      noValidate
      onSubmit={evt => {
        evt.preventDefault();
        props.onSubmit({
          workspaceId,
          name,
          description,
          matchType,
          shouldNotify,
          skipSentiment,
          dashboardBuild
        });
      }}
      data-tracking-item={ifAllExist`${props.trackingPrefix}_copy-project-submit`}
    >
      {props.selection && (
        <SelectMatchType
          matchType={matchType}
          onChangeMatchType={event => {
            setMatchType(event.target.value);
          }}
          shouldDisableExactMatches={shouldDisableExactMatches}
        />
      )}
      <NameInput
        name={name}
        onChangeName={event => {
          setName(event.target.value);
        }}
      />
      <DescriptionInput
        description={description}
        onChangeDescription={event => {
          setDescription(event.target.value);
        }}
      />
      {filteredWorkspaces.length !== 1 && (
        <WorkspaceDropDown
          workspaceId={workspaceId}
          onChangeWorkspaceId={event => {
            setWorkspaceId(event.target.value);
          }}
          workspaces={filteredWorkspaces}
        />
      )}
      {props.mode === 'branch' && (
        <>
          <EmailNotificationCheckbox
            checked={shouldNotify}
            onChange={event => {
              setShouldNotify(event.target.checked);
            }}
          />
          <SentimentSelector
            checked={skipSentiment}
            onChange={() => {
              setSkipSentiment(!skipSentiment);
            }}
          />
        </>
      )}
      <div className="project-brancher__required-explanation">
        Required field
      </div>
      <DialogFooter>
        <Button
          palette="red"
          onClick={props.onCancel}
          data-tracking-item={ifAllExist`${props.trackingPrefix}_cancel-button`}
        >
          Cancel
        </Button>
        <CreateProjectButton
          isDisabled={!workspaceId || !name}
          isLoading={props.isCreatingProject}
          docCount={docCount}
          mode={props.mode}
        />
      </DialogFooter>
    </form>
  );
};

ProjectBrancherForm.propTypes = {
  mode: PropTypes.oneOf(['branch', 'copy']).isRequired,
  selection: PropTypes.object,
  filterCount: PropTypes.number.isRequired,
  userDefaultWorkspaceId: PropTypes.string,
  srcProjectWorkspaceId: PropTypes.string.isRequired,
  srcProjectName: PropTypes.string.isRequired,
  srcProjectDescription: PropTypes.string,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      workspace_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export function EmailNotificationCheckbox(props) {
  const id = 'notify-by-email' + useUniqueId();

  return (
    <FieldContainer isInline>
      <Checkbox
        className="project-brancher__field project-brancher__field--checkbox"
        id={id}
        {...props}
      />
      <LabelContainer isInline>
        <label className="project-brancher__label" htmlFor={id}>
          Send me an email when this project is finished building.
        </label>
      </LabelContainer>
    </FieldContainer>
  );
}

function CreateProjectButton({ isDisabled, isLoading, docCount, mode }) {
  return (
    <Button
      style={{ marginLeft: '0.5rem' }}
      palette="green"
      className="project-brancher__field project-brancher__field--button"
      type="submit"
      disabled={isDisabled || isLoading}
    >
      {isLoading ? (
        <>
          <Spinner /> {mode === 'copy' ? 'Copying' : 'Creating'}
        </>
      ) : mode === 'copy' ? (
        'Copy'
      ) : (
        'Create'
      )}{' '}
      project ({thousandify(docCount)} documents)
    </Button>
  );
}

CreateProjectButton.propTypes = {
  mode: PropTypes.oneOf(['branch', 'copy']).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  docCount: PropTypes.number.isRequired
};

function SelectMatchType({
  matchType,
  onChangeMatchType,
  shouldDisableExactMatches
}) {
  const id = 'match-type' + useUniqueId();

  return (
    <FieldContainer>
      <LabelContainer>
        <label className="project-brancher__label" htmlFor={id}>
          Select match type
        </label>
      </LabelContainer>
      <Dropdown id={id} value={matchType} onChange={onChangeMatchType}>
        <option value="exact" disabled={shouldDisableExactMatches}>
          Exact matches only
          {shouldDisableExactMatches && ' (No exact matches)'}
        </option>
        <option value="both">Total matches</option>
      </Dropdown>
    </FieldContainer>
  );
}

SelectMatchType.propTypes = {
  matchType: PropTypes.string.isRequired,
  onChangeMatchType: PropTypes.func.isRequired,
  shouldDisableExactMatches: PropTypes.bool.isRequired
};

function NameInput({ name, onChangeName }) {
  const id = 'name' + useUniqueId();
  const nameIsInvalid = !name;

  return (
    <FieldContainer>
      <LabelContainer>
        <label
          className="project-brancher__label project-brancher__label--required"
          htmlFor={id}
        >
          Name your project
        </label>
      </LabelContainer>
      <input
        className={cx('project-brancher__field project-brancher__field--text', {
          'project-brancher__field--invalid': nameIsInvalid
        })}
        id={id}
        type="text"
        autoComplete="off"
        placeholder="Project name"
        value={name}
        required
        onChange={onChangeName}
      />
      {nameIsInvalid && <RequiredFieldMessage />}
    </FieldContainer>
  );
}

NameInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired
};

function DescriptionInput({ description, onChangeDescription }) {
  const id = 'description' + useUniqueId();

  return (
    <FieldContainer>
      <LabelContainer>
        <label className="project-brancher__label" htmlFor={id}>
          Describe your project
        </label>
      </LabelContainer>
      <textarea
        className="project-brancher__field project-brancher__field--textarea"
        id={id}
        placeholder="Project description"
        value={description}
        onChange={onChangeDescription}
      />
    </FieldContainer>
  );
}

DescriptionInput.propTypes = {
  description: PropTypes.string.isRequired,
  onChangeDescription: PropTypes.func.isRequired
};

function WorkspaceDropDown({ workspaceId, onChangeWorkspaceId, workspaces }) {
  const id = 'workspace' + useUniqueId();
  const workspaceIsInvalid = !workspaceId;

  return (
    <FieldContainer>
      <LabelContainer>
        <label
          className="project-brancher__label project-brancher__label--required"
          htmlFor={id}
        >
          Select project workspace
        </label>
      </LabelContainer>
      <Dropdown
        css={
          workspaceIsInvalid
            ? css`
                border: 1px solid ${Colors.red3};
                box-shadow: 0 0 5px ${Colors.red3};
              `
            : undefined
        }
        containerCss={css`
          width: 100%;
        `}
        id={id}
        required
        value={workspaceId}
        onChange={onChangeWorkspaceId}
      >
        <option value="">Select workspace</option>
        {workspaces
          .slice()
          .sort(naturalSortByName)
          .map(({ workspace_id, name }) => (
            <option key={workspace_id} value={workspace_id}>
              {name}
            </option>
          ))}
      </Dropdown>
      {workspaceIsInvalid && <RequiredFieldMessage />}
    </FieldContainer>
  );
}

WorkspaceDropDown.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  onChangeWorkspaceId: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      workspace_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
  ).isRequired
};

export function ProjectCreationStatus({ status, statusMessage, closeModal }) {
  return status === RequestStatuses.FULFILLED ? (
    <ModalMessage closeModal={closeModal}>
      Your new project is being built. This could take a few minutes.
    </ModalMessage>
  ) : statusMessage === 'PROJECT_LOCKED' ? (
    <ModalMessage closeModal={closeModal}>
      Sorry, something went wrong while trying to copy your project. This is
      probably because the project is currently building. Please try again in a
      few minutes and <SupportEmailLink>contact us</SupportEmailLink> if the
      problem persists.
    </ModalMessage>
  ) : (
    status === RequestStatuses.REJECTED && (
      <ModalMessage displayFooter={false}>
        Sorry, something went wrong while trying to copy your project. Please
        try refreshing the page and{' '}
        <SupportEmailLink>contact us</SupportEmailLink> if the problem persists.
      </ModalMessage>
    )
  );
}

ProjectCreationStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(RequestStatuses)).isRequired,
  statusMessage: PropTypes.string,
  closeModal: PropTypes.func.isRequired
};

function ModalMessage({ children, closeModal, displayFooter }) {
  return (
    <div>
      <p>{children}</p>
      {displayFooter && <ModalMessageFooter closeModal={closeModal} />}
    </div>
  );
}

ModalMessage.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func,
  displayFooter: PropTypes.bool.isRequired
};

ModalMessage.defaultProps = {
  displayFooter: true
};

function ModalMessageFooter({ closeModal }) {
  return (
    <DialogFooter>
      <ProjectListLink />
      <Button autoFocus onClick={closeModal}>
        Close
      </Button>
    </DialogFooter>
  );
}

ModalMessageFooter.propTypes = {
  closeModal: PropTypes.func.isRequired
};

function ProjectListLink() {
  return (
    <a href="/" className="project-brancher__project-list-link">
      Back to Projects page
    </a>
  );
}
