import { uniqueId } from 'lodash';

export const reviewsSources = [
  {
    id: 1,
    title: 'Amazon',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?amazon\.[a-z]{2,}\/?.*$/
  },
  {
    id: 3,
    title: 'BestBuy',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?bestbuy\.[a-z]{2,}\/?.*$/
  },
  {
    id: 4,
    title: 'Samsclub',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?samsclub\.[a-z]{2,}\/?.*$/
  },
  {
    id: 5,
    title: 'Target',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?target\.[a-z]{2,}\/?.*$/
  },
  {
    id: 6,
    title: 'Walmart',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?walmart\.[a-z]{2,}\/?.*$/
  },
  {
    id: 7,
    title: 'Costco',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?costco\.[a-z]{2,}\/?.*$/
  },
  {
    id: 8,
    title: 'ConsumerAffairs',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?consumeraffairs\.[a-z]{2,}\/?.*$/
  },
  {
    id: 9,
    title: 'Google',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?google\.[a-z]{2,}\/?.*$/
  },
  {
    id: 10,
    title: 'G2',
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?g2\.[a-z]{2,}\/?.*$/
  },
  {
    id: 11,
    title: 'TrustPilot',
    selected: false,
    validation: /^(https?:\/\/)?([a-z]{2}\.)?(www\.)?trustpilot\.[a-z]{2,}\/?.*$/
  }
];

export const hrSitesSources = [
  {
    id: 1,
    title: 'Glassdoor',
    selected: true,
    validation: /^(https?:\/\/)?(www\.)?glassdoor\.[a-z]{2,}\/?.*$/
  }
];

export const generateInput = value => ({
  name: `url${uniqueId()}`,
  value,
  error: ''
});

export const transfrormRedditTask = projectTask => {
  return projectTask.reddit_list.map(reddit =>
    reddit.submission_id
      ? `https://www.reddit.com/r/${reddit.subreddit_name}/comments/${reddit.submission_id}`
      : `https://www.reddit.com/r/${reddit.subreddit_name}/`
  );
};

export const transformReviewsTask = projectTask => {
  const reviewsUrls = {};

  projectTask.url_list.forEach(url => {
    for (const el of [...reviewsSources, ...hrSitesSources]) {
      if (el.validation.test(url)) {
        reviewsUrls[el.title.toLocaleLowerCase()]
          ? reviewsUrls[el.title.toLocaleLowerCase()].push(generateInput(url))
          : (reviewsUrls[el.title.toLocaleLowerCase()] = [generateInput(url)]);
      }
    }
  });

  return reviewsUrls;
};
