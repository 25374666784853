import { extent, max, min } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';

import ZoomableChart from './ZoomableChart';
import { MARGINS, PLACEHOLDER_PLOT_DATA } from './constants';
import { XAxis, YAxis, Baseline } from './Axis';
import { DataPoints } from './DataPoints';
import { ResetZoomButton } from './ResetZoomButton';
import { useUniqueId } from '../utils/hooks';

export default function ScatterPlot({ width, height, concepts, ...props }) {
  const [{ mouseX, mouseY }, setMousePosition] = useState({
    mouseX: undefined,
    mouseY: undefined
  });
  const svgRef = useRef();
  const clipperId = 'clipper' + useUniqueId();

  const hasData = props.dataPoints.length > 0;
  const dataPoints = hasData ? props.dataPoints : PLACEHOLDER_PLOT_DATA;
  const getTickLabel = tick => (hasData ? tick : '-');
  const xMin = MARGINS.left;
  const xMax = width - MARGINS.right;
  const yMin = MARGINS.top;
  const yMax = height - MARGINS.bottom;

  return (
    <ZoomableChart width={width} height={height}>
      {({ zoomTransform, zoomRef, resetZoom, isZooming }) => {
        const xScale = getXScale(dataPoints, xMin, xMax, zoomTransform);
        const yScale = getYScale(
          dataPoints,
          yMin,
          yMax,
          zoomTransform,
          props.baseline
        );

        const [clippedXMin, clippedXMax] = xScale.range();
        const [clippedYMin, clippedYMax] = yScale.range();

        return (
          <>
            <svg
              width={width}
              height={height}
              className="scatter-plot"
              ref={svgRef}
              onMouseMove={event => {
                setMousePosition({
                  mouseX: event.pageX,
                  mouseY: event.pageY
                });
              }}
              onMouseLeave={() => {
                props.setMouseIn(false);
                props.setHoveredDataPoint(undefined);
              }}
              onMouseEnter={() => {
                props.setMouseIn(true);
              }}
            >
              <defs>
                <clipPath id={clipperId}>
                  <rect
                    x={clippedXMin}
                    width={clippedXMax - clippedXMin}
                    y={clippedYMax}
                    height={clippedYMin - clippedYMax}
                  />
                </clipPath>
              </defs>
              <XAxis
                axisTitle={props.xAxisTitle}
                getTickLabel={getTickLabel}
                scale={xScale}
                xMin={xMin}
                xMax={xMax}
                yMin={yMin}
                yMax={yMax}
              />
              <YAxis
                axisTitle={props.yAxisTitle}
                getTickLabel={getTickLabel}
                scale={yScale}
                xMin={xMin}
                xMax={xMax}
                yMin={yMin}
                yMax={yMax}
              />
              {props.dataPoints.length > 0 && (
                <Baseline
                  mainClipPathId={clipperId}
                  label={props.baselineLabel}
                  labelWidth={props.baselineLabelWidth}
                  baseline={props.baseline}
                  scale={yScale}
                  xMin={xMin}
                  xMax={xMax}
                  yMin={yMin}
                  yMax={yMax}
                />
              )}
              <g
                style={{ pointerEvents: 'bounding-box' }}
                ref={zoomRef}
                clipPath={`url(#${clipperId})`}
              >
                {/* this rect allows reliably zooming using mouse events in Firefox and Safari */}
                <rect
                  pointerEvents="all"
                  fill="none"
                  width="100%"
                  height="100%"
                />
                <DataPoints
                  concepts={concepts}
                  dataPoints={props.dataPoints}
                  xScale={xScale}
                  yScale={yScale}
                  onClick={props.onDatapointClick}
                  selectedDataPointId={props.selectedDataPointId}
                  mouseX={mouseX}
                  mouseY={mouseY}
                  mouseIn={props.mouseIn}
                  svgRef={svgRef.current}
                  isZooming={isZooming}
                  hoveredDataPoint={props.hoveredDataPoint}
                  setHoveredDataPoint={props.setHoveredDataPoint}
                  outlierTotalMatches={props.outlierTotalMatches}
                />
              </g>
            </svg>
            <ResetZoomButton
              resetZoom={resetZoom}
              css={css`
                position: absolute;
                left: 1.125rem;
                bottom: 0.5rem;
              `}
            />
          </>
        );
      }}
    </ZoomableChart>
  );
}

ScatterPlot.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xAxisTitle: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.node.isRequired,
  dataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      name: PropTypes.string,
      id: PropTypes.string.isRequired,
      tip: PropTypes.node,
      color: PropTypes.string.isRequired,
      selectedBorderColor: PropTypes.string.isRequired
    })
  ),
  onDatapointClick: PropTypes.func.isRequired,
  selectedDataPointId: PropTypes.string,
  hoveredDataPoint: PropTypes.object,
  setHoveredDataPoint: PropTypes.func.isRequired,
  mouseIn: PropTypes.bool,
  setMouseIn: PropTypes.func.isRequired,
  baseline: PropTypes.number.isRequired,
  baselineLabel: PropTypes.node.isRequired,
  baselineLabelWidth: PropTypes.number.isRequired,
  outlierTotalMatches: PropTypes.object,
};

function getXScale(dataPoints, xMin, xMax, zoomTransform) {
  let xScale = scaleLinear()
    // * 1.1 below is to add 10% of extra space
    .domain([0, max(dataPoints, d => d.x) * 1.1])
    .range([xMin, xMax])
    .nice();

  if (zoomTransform) {
    let xDomain = zoomTransform.rescaleX(xScale).domain();
    // prevent it from zooming into negative values on scale X
    if (xDomain[0] < 0) {
      xDomain = [0, xDomain[1] - xDomain[0]];
    }
    xScale = xScale.domain(xDomain);
  }

  return xScale;
}

function getYScale(dataPoints, yMin, yMax, zoomTransform, baseline) {
  let yExtent = extent(dataPoints, d => d.y);
  // Expand the y extent to always include the baseline
  yExtent[0] = min([baseline, yExtent[0]]);
  yExtent[1] = max([baseline, yExtent[1]]);

  // add 10% of extra space
  const diff = Math.abs(yExtent[1] - yExtent[0]);
  yExtent[0] -= diff * 0.1;
  yExtent[1] += diff * 0.1;

  let yScale = scaleLinear()
    .domain(yExtent)
    // account for the origin point in SVG coordinates being at top left
    .range([yMax, yMin])
    .nice();

  if (zoomTransform) {
    yScale = yScale.domain(zoomTransform.rescaleY(yScale).domain());
  }

  return yScale;
}
