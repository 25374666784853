import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { Colors, Mixins } from '../../styles';
import { useUniqueId } from '../../utils/hooks';

export function Toggle({ checked, onChange, label, disabled }) {
  const id = 'toggle' + useUniqueId();

  return (
    <>
      <input
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        css={css`
          /* Hide the input's "checkbox" */
          position: absolute;
          left: -9999rem;
        `}
      />
      <label
        htmlFor={id}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <Slider checked={checked} />
        <LabelText label={label} />
      </label>
    </>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

function Slider({ checked }) {
  return (
    <div
      css={css`
        width: 2rem;
        height: 1rem;
        background-color: ${checked ? Colors.green4 : Colors.gray3};
        border-radius: 0.5rem;
        ${Mixins.shadowInset};
        position: relative;
      `}
    >
      <div
        css={css`
          width: 1.125rem;
          height: 0.75rem;
          background-color: white;
          border-radius: 8px;
          margin: 0.125rem;
          ${Mixins.shadowOutset}
          position: absolute;
          right: ${checked ? '0' : '0.5rem'};
          transition: right 0.2s ease-in-out;
        `}
      />
    </div>
  );
}

Slider.propTypes = {
  checked: PropTypes.bool.isRequired
};

function LabelText({ label }) {
  return (
    <span
      css={css`
        color: ${Colors.gray9};
        font-size: 0.875rem;
        margin-left: 0.5rem;
      `}
    >
      {label}
    </span>
  );
}

LabelText.propTypes = {
  label: PropTypes.string.isRequired
};
