import _ from 'lodash';
import moment from 'moment';

import { isNumeric } from '../utils/common';

const SUPPORTED_DATE_FORMATS = [
  moment.ISO_8601,
  'M/D/YYYY h:mm:ss A',
  'M/D/YYYY H:mm:ss',
  'M/D/YYYY h:mm A',
  'M/D/YYYY H:mm',
  'M/D/YYYY',
  'M/D/YY h:mm:ss A',
  'M/D/YY H:mm:ss',
  'M/D/YY h:mm A',
  'M/D/YY H:mm',
  'M/D/YY',
  'X' // Unix time
];
export const parseDateTypeMetadataValue = s =>
  moment.utc(s, SUPPORTED_DATE_FORMATS, true);

export class Cell {
  static fromString(string) {
    return new Cell(string);
  }

  static blank() {
    return Cell.fromString('');
  }

  constructor(rawValue) {
    this.rawValue = rawValue;
    this.hasValue = true;
    this.stringValues = rawValue
      .split('|')
      .map(s => s.trim())
      .filter(_.negate(_.isEmpty));
  }

  get numberValues() {
    if (this._numberValues === undefined) {
      this._parseAsNumber();
    }
    return this._numberValues;
  }

  get invalidNumbers() {
    if (this._invalidNumbers === undefined) {
      this._parseAsNumber();
    }
    return this._invalidNumbers;
  }

  _parseAsNumber() {
    this._numberValues = [];
    this._invalidNumbers = [];
    for (let fieldValue of this.stringValues) {
      if (isNumeric(fieldValue)) {
        this._numberValues.push(parseFloat(fieldValue));
      } else {
        this._invalidNumbers.push(fieldValue);
      }
    }
  }

  get dateValues() {
    if (this._dateValues === undefined) {
      this._parseAsDate();
    }
    return this._dateValues;
  }

  get invalidDates() {
    if (this._invalidDates === undefined) {
      this._parseAsDate();
    }
    return this._invalidDates;
  }

  _parseAsDate() {
    this._dateValues = [];
    this._invalidDates = [];
    for (let fieldValue of this.stringValues) {
      const date = parseDateTypeMetadataValue(fieldValue);
      if (date.isValid()) {
        this.dateValues.push(date);
      } else {
        this._invalidDates.push(fieldValue);
      }
    }
  }

  valuesAs(type) {
    switch (type) {
      case 'string':
        return this.stringValues;
      case 'number':
      case 'score':
        return this.numberValues;
      case 'date':
        return this.dateValues;
      default:
        return [this.rawValue];
    }
  }

  unparsableValuesAs(type) {
    switch (type) {
      case 'number':
      case 'score':
        return this.invalidNumbers;
      case 'date':
        return this.invalidDates;
      default:
        return [];
    }
  }
}
