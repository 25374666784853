import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';

import { StoreContext } from '../../../StoreContext';
import { useConceptManagement } from '../../../data_hooks';
import { Card } from '../../../components/Card';
import Spinner from '../../../components/core/Spinner';
import { CurrentBuildCard, NextBuildCard } from '../ConceptsCard';
import { InlineChangeArrow, Title } from '../../components';
import { Button } from '../../../components/core/Button';
import EditNoticeAssertionsModal from './EditNoticeAssertionsModal';

export const NoticeAssertions = () => {
  const { project, workspaces } = useContext(StoreContext);
  const workspace = workspaces.find(
    w => w.workspace_id === project.workspace_id
  );
  const canEditAssertions = workspace.permissions.includes('account_manage');
  const { conceptManagement, updateNextBuild } = useConceptManagement(project);

  return (
    <NoticeAssertionsInner
      conceptManagement={conceptManagement}
      showEditButton={canEditAssertions}
      updateNextBuild={updateNextBuild}
    />
  );
};

export const NoticeAssertionsInner = ({
  conceptManagement,
  showEditButton,
  updateNextBuild
}) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return conceptManagement === undefined ? (
    <LoadingNoticeAssertions />
  ) : (
    <>
      <NoticeTitle
        editOnClick={() => setShowEditModal(true)}
        showEditButton={showEditButton}
      />
      <Card.Separator />
      <AssertionsInformation conceptManagement={conceptManagement} />
      <EditNoticeAssertionsModal
        isOpen={showEditModal}
        onHide={() => setShowEditModal(false)}
        apiConceptManagement={conceptManagement}
        updateNextBuild={updateNextBuild}
      />
    </>
  );
};

const LoadingNoticeAssertions = () => (
  <Card.Section
    css={css`
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    `}
  >
    <Spinner />
    <div> Loading notice assertions... </div>
  </Card.Section>
);

const AssertionsInformation = ({ conceptManagement }) => (
  <Card.Section
    css={css`
      display: flex;
      flex-direction: row;
      max-height: 20rem;
    `}
  >
    <CurrentBuildCard currentConcepts={conceptManagement.currentBuild.notice} />

    <div
      css={css`
        padding: 0.5rem;
      `}
    >
      <InlineChangeArrow />
    </div>

    <NextBuildCard
      hasChanges={conceptManagement.hasNoticeChanges}
      conceptsDiff={conceptManagement.noticeDiff}
    />
  </Card.Section>
);

const NoticeTitle = ({ editOnClick, showEditButton }) => {
  return (
    <>
      <Card.Section
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Title
          css={css`
            margin: auto 0;
            font-size: 1.25rem;
          `}
        >
          Notice
        </Title>
        {showEditButton && (
          <Button
            palette="green"
            css={css`
              margin-left: auto;
            `}
            onClick={editOnClick}
          >
            Edit
          </Button>
        )}
      </Card.Section>
    </>
  );
};
