import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const MessageContainer = styled(Box)`
  margin-bottom: 1rem;
  display: flex;
  font-size: 0.875rem;
  word-wrap: break-word;
  position: relative;

    li{
        transform: none!important;
    }
  &::after {
    color: #626264;
    position: absolute;
    top: auto;
    bottom: -1.5rem;
    white-space: nowrap;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    white-space: pre-line;

    &:not(:first-of-type) {
      margin-top: 0.8rem;
    }

    ul {
      padding: 0;
    }
  }

  ol {
    padding-left: 1rem;
  }

  &[data-type='buttons'] {
    max-width: 30rem;
    flex-direction: column;
  }

  &:not([data-type='buttons']):not([data-type='awaiting-reply']):not(
      [data-type='info']
    ) {
    margin-bottom: 2.5rem;
    padding: 0.5rem 1rem;
    flex-direction: column;

    &.assistant {
      max-width: 24rem;
      background-color: #f7f7f7;
      border-radius: 0.75rem 0.75rem 0.75rem 0;
      align-self: flex-start;
        transform: none!important;
      &::after {
        content: attr(data-datetime);
        left: 0;
      }
    }

    &.user {
      max-width: 30rem;
      background-color: #04aade;
      border-radius: 0.75rem 0.75rem 0 0.75rem;
      align-self: flex-end;
      color: #fff;
        transform: none!important;
      &::after {
        content: attr(data-datetime);
        right: 0;
      }
    }
  }

  &[data-type='feedback'] {
    animation:
      scale-fade-in 2s ease-in,
      pulse 3s cubic-bezier(0.34, 1.56, 0.64, 1) 4;
    background-color: rgba(4, 170, 222, 0.3) !important;
  }

  @keyframes scale-fade-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    35% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(4, 170, 222, 0.5);
    }

    70% {
      box-shadow: 0 0 0 15px rgba(4, 170, 222, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(4, 170, 222, 0);
    }
  }
`;
