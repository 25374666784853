import React from 'react';
import { css } from '@emotion/react';

import {
  AuthForm,
  DropdownField,
  StaticText,
  SubmitButton,
  TextField,
  ToggleField
} from './Forms';
import { NotificationArea } from './notifications';
import { AuthContext } from './model';
import { FeatureFlagsContext } from '../FeatureFlagsContext';

export function UserProfile() {
  const { profile } = React.useContext(AuthContext);
  const featureFlags = React.useContext(FeatureFlagsContext);
  const workspaceOptions = profile.workspacesForPermission('create').map(w => ({
    name: w.name,
    value: w.workspace_id
  }));
  const showWorkspaceDropdown = workspaceOptions.length > 0;
  const formDefaults = {
    email: profile.username,
    defaultWorkspace: profile.pickDefault(
      profile.workspacesForPermission('create')
    ),
    fullName: profile.fullName
  };
  if (featureFlags.boolean_search_toggle) {
    formDefaults.booleanSearch = featureFlags.boolean_search;
  }
  return (
    <div
      css={css`
        padding: 0.5rem;
      `}
    >
      <h2>{profile.fullName}</h2>
      <NotificationArea />
      <AuthForm
        defaults={formDefaults}
        onSubmit={formData =>
          profile.modify(formData).then(() => {
            if (formData.booleanSearch !== formDefaults.booleanSearch) {
              window.location.reload();
            }
          })
        }
      >
        <StaticText label="email" field="email" />
        <TextField label="full name" field="fullName" />
        {showWorkspaceDropdown && (
          <DropdownField
            label="default workspace"
            field="defaultWorkspace"
            options={workspaceOptions}
          />
        )}
        {featureFlags.boolean_search_toggle && (
          <ToggleField
            label="advanced concept search"
            field="booleanSearch"
            explanation={
              featureFlags.boolean_search ? (
                <>
                  Switch off to return to old-style compound concepts that
                  average vectors. This view only includes OR components of any
                  advanced concept search you created. Access to this old-style
                  workflow will be removed in a future release.
                </>
              ) : (
                <>
                  Switch on to use and save new advanced concept searches. In
                  this view, old-style compound concepts function like an
                  advanced search concept.
                </>
              )
            }
          />
        )}
        <SubmitButton>Save changes</SubmitButton>
      </AuthForm>
    </div>
  );
}
