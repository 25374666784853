import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import { usePrefersReducedMotion } from '../../utils/hooks';

export function ExpandCollapseAnimation({
  expanded,
  heightHint = '10rem',
  children
}) {
  const prefersReducedMotion = usePrefersReducedMotion();
  if (prefersReducedMotion) {
    return expanded && children;
  }
  return (
    <div
      css={css`
        .expander-enter {
          overflow: hidden;
          max-height: 0;
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        .expander-enter-active {
          overflow: hidden;
          max-height: ${heightHint};
          transition: all 100ms ease-in;
        }

        .expander-exit {
          overflow: hidden;
          max-height: ${heightHint};
        }

        .expander-exit-active {
          overflow: hidden;
          max-height: 0;
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          transition: all 100ms ease-out;
        }
      `}
    >
      <CSSTransition
        in={expanded}
        classNames="expander"
        timeout={100}
        mountOnEnter
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </div>
  );
}

ExpandCollapseAnimation.propTypes = {
  expanded: PropTypes.bool.isRequired,
  heightHint: PropTypes.string,
  children: PropTypes.node.isRequired
};
