import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button } from '../../../components/core/Button';

export const AddNoticeTextEntry = ({ placeholder, submitSelection }) => {
  const [inputText, setInputText] = useState('');
  const [showHelper, setShowHelper] = useState(false);

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        const words = inputText.trim().split(/\s+/);
        if (words.length === 1) {
          submitSelection(inputText.trim());
          setInputText('');
          setShowHelper(false);
        } else {
          setShowHelper(true);
        }
      }}
    >
      <FormContainer>
        <TextInput
          aria-label="Concept to notice"
          value={inputText}
          placeholder={placeholder}
          onChange={event => {
            const value = event.target.value;
            if (!value.includes(' ') && value.trim().split(/\s+/).length <= 1) {
              setInputText(value);
              setShowHelper(false);
            } else {
              setShowHelper(true);
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Escape') {
              event.stopPropagation();
              setShowHelper(false);
            }
          }}
        />
        <Button type="submit">Add</Button>
      </FormContainer>

      {showHelper && (
        <HelperMessage>
          Please enter only a single word
        </HelperMessage>
      )}
    </Form>
  );
};

AddNoticeTextEntry.propTypes = {
  placeholder: PropTypes.string,
  submitSelection: PropTypes.func.isRequired
};

const Form = styled.form`
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex-grow: 1;

  input {
    flex-grow: 1;
    margin-right: 1rem;
    height: 2rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
`;

const TextInput = styled.input`
  height: 2rem;
  margin: 0;
  width: 100%;
`;

const HelperMessage = styled.div`
  color: #d32f2f;
  font-size: 0.875rem;
`;
