import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Doc } from '../classes/Docs';
import { Concept } from '../classes/Concepts';
import { ParagraphList } from './ParagraphList';
import { getFormattedDocument } from '../utils/DocUtils';
import { useSearchParams } from '../search_params';

const ELLIPSIS = '…';

export const TruncatedDocText = memo(({ doc, language, selection }) => {
  const { searchParams } = useSearchParams();
  const isSentimentIndication = searchParams?.sentiment_indication?.toLowerCase() === 'true'
  const { paragraphList, truncatedStart, truncatedEnd } = getFormattedDocument(
    doc,
    selection,
    true
  );

  return (
    <ParagraphList
      isSentimentIndication={isSentimentIndication}
      paragraphList={paragraphList}
      language={language}
      beforeFirst={truncatedStart && ELLIPSIS}
      afterLast={truncatedEnd && ELLIPSIS}
    />
  );
});

TruncatedDocText.propTypes = {
  doc: PropTypes.instanceOf(Doc).isRequired,
  selection: PropTypes.instanceOf(Concept),
  language: PropTypes.string.isRequired
};
