import React from 'react';
import styled from '@emotion/styled';

import { Colors } from '../styles';
import { ProjectBasicsSection } from './ProjectBasicsSection';
import { NextBuildSection } from './NextBuildSection';
import { ScienceAssertionsSection } from './ScienceAssertionsSection';
import { LanguageSection } from './LanguageSection';
import { Footer } from './Footer';
import { Trademark } from './Trademark';
import { Card } from '../components/Card';
import CopyConceptListSection from './CopyConceptListSection';
import { CollocationSection } from './CollocationSection';
import { DocumentManagementSection } from './manageDocumentSection/DocumentManagementSection';

import { Title } from './components';
import { css } from '@emotion/react';
import { Button } from '../components/core/Button';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
  getHeliosVersion,
  putHeliosVersion,
  putHeliosLlmSelection,
  getHeliosLlmSelection
} from '../utils/ApiUtilsV5';
import { useFetch } from '../utils/hooks';
import Dropdown from '../components/core/Dropdown';
import { FeatureFlagsContext } from '../FeatureFlagsContext';

// import { showSuccess } from '../settings/notifications';

const ChangeHeliosTitle = () => (
  <>
    <Card.Section
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Title>Change Helios Backend</Title>
    </Card.Section>
  </>
);

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const ChangeHeliosVersion = () => {
  const { projectId } = useParams();
  const { response: heliosVersion } = useFetch(getHeliosVersion, projectId);

  console.log('heliosVersion', heliosVersion);

  const changeTo =
    heliosVersion && heliosVersion == 'BEDROCK' ? 'CUSTOM' : 'BEDROCK';

  const onClickChange = () => {
    console.log('onclickchange');
    putHeliosVersion(projectId, changeTo).then(response => {
      console.log('response', response);
      window.location.reload();
    });
  };

  const [selectedLlm, setSelectedLlm] = React.useState(
    'us.anthropic.claude-3-5-sonnet-20241022-v2:0'
  );
  const [changingSelection, setChangingSelection] = React.useState(true);

  getHeliosLlmSelection(projectId).then(response => {
    console.log('response', response);
    setSelectedLlm(response);
    setChangingSelection(false);
  });

  const setLlm = value => {
    console.log(value);
    setChangingSelection(true);
    putHeliosLlmSelection(projectId, value).then(response => {
      setSelectedLlm(value);
      window.alert(`changed llm to ${value}`);
      // showSuccess("done successfully!");
    });
  };

  const llmChoices = [
    'us.anthropic.claude-3-5-sonnet-20241022-v2:0',
    'us.meta.llama3-2-3b-instruct-v1:0'
  ].map(choice => (
    <option key={choice} value={choice}>
      {choice}
    </option>
  ));

  return (
    <>
      <Card data-test-id="change-helios-version-section">
        <ChangeHeliosTitle />
        <Card.Separator />
        <Card.Section>
          <div>Current Helios Backend: {heliosVersion}</div>
        </Card.Section>
        <Card.Section>
          <Button
            palette="green"
            onClick={onClickChange}
            disabled={null}
            css={css`
              margin-right: 1rem;
            `}
          >
            Change to {changeTo}
          </Button>
        </Card.Section>
        {heliosVersion === 'CUSTOM' && (
          <Card.Section>
            <div
              css={css`
                margin: 1em 0;
              `}
            >
              Custom Pipeline LLM Model Selection
            </div>
            <RowDiv>
              <Dropdown
                aria-label="Helios Custom LLM"
                onChange={event => setLlm(event.target.value)}
                disabled={changingSelection}
                value={selectedLlm}
              >
                {changingSelection ? (
                  <option value="" disabled>
                    updating llm selection..
                  </option>
                ) : (
                  llmChoices
                )}
              </Dropdown>
            </RowDiv>
          </Card.Section>
        )}
      </Card>
    </>
  );
};

export const ProjectManagement = () => {
  const featureFlags = React.useContext(FeatureFlagsContext);

  return (
    <Container>
      <Row>
        <ProjectBasicsSection />
        <RowContainer>
          <NextBuildSection />
          <CopyConceptListSection />
        </RowContainer>
      </Row>
      <Row>
        <ScienceAssertionsSection />
        <RowContainer>
          <LanguageSection />
          <CollocationSection />
          <DocumentManagementSection />
          {featureFlags.helios_custom && <ChangeHeliosVersion />}
        </RowContainer>
      </Row>
      <Footer />
      <Trademark />
    </Container>
  );
};

const Container = styled.div`
  background: ${Colors.gray0};
  box-sizing: border-box;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  > ${Card} {
    min-width: 25rem;
    height: fit-content;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  > ${Card} {
    flex: 1;
  }
`;

const RowContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;
