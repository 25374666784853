import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function DisableableLink({ children, to, disabled, ...props }) {
  return disabled ? (
    // Links require a `to` prop, so instead we return a plain anchor to
    // "disable" the action.
    <a
      css={css`
        cursor: default;
      `}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
}

DisableableLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string
  }),
  disabled: PropTypes.bool
};
