import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { AlertTypes, RequestStatuses } from './constants';
import {
  downloadVolumeSpreadsheet,
  getConceptCounts,
  getSpecifiedMatchCounts
} from './utils/ApiUtilsV5';
import Alert from './components/core/Alert';
import VolumeTable, { PlaceholderTable } from './volume/VolumeTable';
import { DateField, NumericField, ScoreField } from './classes/MetadataFields';
import { CategoricalBreakdown, DateBreakdown, NumericBreakdown } from './classes/Breakdowns';
import { isBreakdownFieldDisabled } from './utils/breakdownValidityUtils';
import { filterBreakdownField } from './utils/filterBreakdown';
import { Buckets } from './classes/Buckets';
import { useFetch, useRefetchWhenActiveConceptsChange } from './utils/hooks';
import { StoreContext } from './StoreContext';
import { ExportCurrentViewSection } from './side_panel/ExportPanel';
import { useCurrentView } from './side_panel/views/view';
import { useSearchParams } from './search_params';
import { fetchAndCombineConceptCounts, getConceptCountsOutliers } from './actions';

export function VolumeDownloadButton({ projectName }) {
  const { searchParams } = useSearchParams();
  const { conceptSelector } = useCurrentView();
  const { projectId } = useParams();
  const { metadata } = React.useContext(StoreContext);
  const breakdownField = _.find(metadata, { name: searchParams.breakdown });
  const breakdowns = getBreakdowns(breakdownField, searchParams.interval);

  function doExport() {
    return downloadVolumeSpreadsheet(
      projectId,
      projectName,
      conceptSelector,
      searchParams.filter,
      breakdowns
    );
  }

  return (
    <ExportCurrentViewSection featureName="Volume" exportFunction={doExport} />
  );
}

const Volume = styled.div`
  display: flex;
  flex-direction: direction;
  min-height: 0;
`;

const VolumeTableContainer = styled.div`
  flex: 1;
  min-height: 0;
`;

export function VolumeWorkArea() {
  const { projectId } = useParams();
  const { metadata } = React.useContext(StoreContext);
  const { activeConceptListName } = useContext(StoreContext);
  const { searchParams, updateSearch } = useSearchParams();
  const breakdownField = _.find(metadata, { name: searchParams.breakdown });
  const matchType = searchParams.match_type === 'total' ? 'both' : 'exact';
  const { conceptSelector } = useCurrentView();
  const { filter } = searchParams;
  const {
    status,
    buckets,
    totalCount,
    matchCounts,
    filterCount,
    outlierConceptsTotalMatches
  } = useMatchCounts(searchParams);
  const isActive = searchParams.concepts === 'active';
  const isColor = searchParams.concept_color === 'true';

  useEffect(() => {
    const fetchData = async () => {
      try {
        getConceptCountsOutliers(projectId, conceptSelector, filter, [], matchType);
      } catch (error) {
        console.error('Error setting outlier filter:', error);
      }
    };
    fetchData();

  }, [matchType, filter, activeConceptListName, isActive]);


  const [sortConcepts, sortOrder] = searchParams.sortby.split('-');
  return (
    <Volume>
      {status === RequestStatuses.PENDING ? (
        <VolumeTableContainer>
          <PlaceholderTable
            displayPlaceholderText
            matchType={searchParams.match_type}
          />
        </VolumeTableContainer>
      ) : status === RequestStatuses.FULFILLED ? (
        <VolumeTableContainer>
          {matchCounts.length > 0 ? (
            <VolumeTable
              totalCount={totalCount}
              filterCount={filterCount}
              isActive={isActive}
              totalMatches={outlierConceptsTotalMatches}
              projectId={projectId}
              sortConcepts={sortConcepts}
              sortOrder={sortOrder}
              matchType={searchParams.match_type}
              onSortChange={sortBy => updateSearch({ sortby: sortBy })}
              concepts={matchCounts}
              breakdown={breakdownField}
              normalized={searchParams.normalized === 'true'}
              buckets={buckets}
              isColor={isColor}
            />
          ) : (
            <InsufficientDocumentCount matchType={searchParams.match_type} />
          )}
        </VolumeTableContainer>
      ) : (
        <Alert type={AlertTypes.ERROR}>
          Something went wrong loading match counts
        </Alert>
      )}
    </Volume>
  );
}

const VolumeInsufficientDocCount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const InsufficientDocumentCount = ({ matchType }) => (
  <>
    <PlaceholderTable matchType={matchType} />
    <VolumeInsufficientDocCount>
      <Alert type={AlertTypes.WARNING}>
        The documents in the current filter don't have enough concepts to
        display. Try changing or clearing the filter.
      </Alert>
    </VolumeInsufficientDocCount>
  </>
);

InsufficientDocumentCount.propTypes = {
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired
};

function getBreakdowns(field, interval) {
  if (field === undefined) {
    return [];
  }
  switch (field.constructor) {
    case DateField:
      return [new DateBreakdown(field, interval)];
    case NumericField:
    case ScoreField:
      return [new NumericBreakdown(field, parseFloat(interval))];
    default:
      return [new CategoricalBreakdown(field)];
  }
}

function useMatchCounts(searchParams) {
  const { projectId } = useParams();
  const { conceptSelector } = useCurrentView();
  const { filter, interval, breakdown, concepts, outlier_mode } = searchParams;
  const { metadata } = React.useContext(StoreContext);
  const field = metadata.find(field => field.name === breakdown);
  const invalidBreakdown =
    field && isBreakdownFieldDisabled(filterBreakdownField(field, filter));
  const breakdowns = !invalidBreakdown && getBreakdowns(field, interval);
  const activeConcepts = concepts === 'active';
  const [outlierConceptsTotalMatches, setOutlierConceptsTotalMatches] =
    useState(null);

  const matchType = searchParams.match_type === 'total' ? 'both' : 'exact';
  const fetchFunction = determineFetchFunction(outlier_mode, activeConcepts);

  const { status, response, refetch } = useFetch(
    !invalidBreakdown && fetchFunction,
    projectId,
    conceptSelector,
    filter,
    breakdowns,
    activeConcepts ? matchType : undefined,
    searchParams.translate
  );

  useEffect(() => {
    if (activeConcepts && Boolean(response?.matchCounts.length)) {
      const keys = response?.matchCounts.reduce((acc, el) => {
        if (el.outlier) {
          acc.push(el.name);
        }
        return acc;
      }, []);

      getSpecifiedMatchCounts(projectId, keys, filter, matchType)
        .then(setOutlierConceptsTotalMatches)
        .catch(err => {
          console.log(err);
        });
    }
  }, [activeConcepts, filter, projectId, response?.matchCounts]);

  useRefetchWhenActiveConceptsChange(
    searchParams.concepts === 'active' && refetch
  );

  return {
    status,
    matchCounts: response && response.matchCounts,
    totalCount: response && response.totalCount,
    filterCount: response && response.filterCount,
    outlierConceptsTotalMatches,
    buckets: response
      ? Buckets.fromAPIBreakdowns(response.breakdowns, metadata, filter)
      : new Buckets(),
    refetch
  };
}

function determineFetchFunction(outlier_mode, activeConcepts) {
  if (outlier_mode === 'only' && activeConcepts) {
    return getConceptCountsOutliers;
  } else if (outlier_mode === 'include' && activeConcepts) {
    return fetchAndCombineConceptCounts;
  } else {
    return getConceptCounts;
  }
}
