import React from 'react';
import { css } from '@emotion/react';

import AxisSelector from './AxisSelector';
import { useAxes } from './useAxes';
import Tooltip from '../components/core/Tooltip';
import { Button } from '../components/core/Button';
import cloudInterface from './cloudInterface';
import { Icon, IconTypes } from '../components/icons';
import GalaxyColorBySelection from './GalaxyColorBySelection';

export function GalaxyFooter() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <AxisControls />
      <ColorControls />
    </div>
  );
}

function ColorControls() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
          margin-right: 0.5rem;
        `}
      >
        Color by:
      </div>
      <GalaxyColorBySelection style="footer" />
    </div>
  );
}

function AxisControls() {
  return (
    <div className="footer">
      <AxisSelectors />
      <AxesVisibilityToggle />
      <ResetAxesButton />
    </div>
  );
}

function AxisSelectors() {
  const { xAxisConcept, yAxisConcept, setXAxis, setYAxis } = useAxes();

  return (
    <>
      <AxisSelector
        axis="x"
        selectedConceptId={xAxisConcept?.sharedConceptId}
        setAxis={setXAxis}
      />
      <AxisSelector
        axis="y"
        selectedConceptId={yAxisConcept?.sharedConceptId}
        setAxis={setYAxis}
      />
    </>
  );
}

function AxesVisibilityToggle() {
  const { axisLabelsAreVisible, hasSelectedAxis, toggleLabels } = useAxes();

  return (
    <Tooltip
      // Disable the tooltip when the button is disabled to avoid a
      // bug where the tooltip won't disappear as long as the button
      // is disabled.
      visible={!hasSelectedAxis ? false : undefined}
      anchor={
        <Button
          aria-label="Toggle axes visibility"
          className="axis-control__btn"
          palette={hasSelectedAxis && axisLabelsAreVisible ? 'green' : 'blue'}
          disabled={!hasSelectedAxis}
          onClick={() => {
            cloudInterface.reflow();
            toggleLabels();
          }}
          data-tracking-item="galaxy_axis-labels-toggle"
        >
          <Icon type={IconTypes.COORDINATE} plane="center" />
        </Button>
      }
    >
      Toggle axis labels
    </Tooltip>
  );
}

function ResetAxesButton() {
  const { resetAxes } = useAxes();

  return (
    <Tooltip
      anchor={
        <Button
          aria-label="Reset galaxy"
          className="axis-control__btn"
          onClick={() => {
            cloudInterface.resetAxes();
            resetAxes();
          }}
          data-tracking-item="galaxy_reset-button"
        >
          <Icon type={IconTypes.ROTATE} />
        </Button>
      }
    >
      Reset galaxy
    </Tooltip>
  );
}
