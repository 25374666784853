export const CHAT_MESSAGE_AUTHORS = {
  USER: 'user',
  ASSISSTANT: 'assistant',
  SYSTEM: 'system',
};

export const CHAT_MESSAGE_TYPE = {
  // expected from API:
  EXEC_SUMMARY: 'exec_summary',
  BUTTONS: 'buttons',
  WITH_RATING: 'with_rating',
  SUGGESTED_QUESTION: 'suggested_question',
  TEXT_WITH_RATING: 'text_with_rating',
  TEXT: 'text',
  START_CONVERSATION: 'start_conversation',
  FEEDBACK: 'feedback',
  // internal statuses for displaying "fake messages and notifications"
  INFO: 'info',
  AWAITING: 'awaiting-reply',
};
