import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Spinner from './Spinner';
import { Colors, Mixins } from '../../styles';
import { Icon, IconTypes } from '../icons';

export default class Dropdown extends React.Component {
  render() {
    const { containerCss, disabled, isLoading, borderless, ...passedProps } =
      this.props;
    const actuallyDisabled = disabled || isLoading;

    return (
      <DropdownContainer containerCss={containerCss}>
        {isLoading && <DropdownSpinner />}
        <StyledSelect
          {...passedProps}
          disabled={actuallyDisabled}
          isLoading={isLoading}
          borderless={borderless}
        />
        <Icon
          type={IconTypes.CARET_STROKE}
          direction="down"
          css={css`
            position: absolute;
            pointer-events: none;
            top: 50%;
            transform: translate(0, -50%);
            color: ${actuallyDisabled ? Colors.gray5 : Colors.blue3};
            right: 0.5rem;
          `}
        ></Icon>
      </DropdownContainer>
    );
  }
}

Dropdown.propTypes = {
  containerCss: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  borderless: PropTypes.bool
};

Dropdown.defaultProps = {
  disabled: false,
  isLoading: false,
  borderless: false
};

const StyledSelect = styled(({ isLoading, borderless, ...props }) => (
  <select {...props} />
))`
  ${Mixins.shadowOutset}
  ${Mixins.roundedCorners}
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  padding-right: 1.5rem;
  text-overflow: ellipsis;
  width: 100%; // fit container
  min-width: 3rem; // prevent selects w/ only very short options from disappearing

  ${props =>
    props.isLoading &&
    css`
      padding-left: 2rem;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `}

  ${props =>
    props.borderless &&
    css`
      box-shadow: none;
    `}

  ${props => getSelectColors(props)}
`;

const getSelectColors = ({ disabled, borderless }) => {
  let background;
  let color;

  if (disabled && !borderless) {
    background = Colors.gray0;
  } else {
    background = 'white';
  }

  if (disabled) {
    color = Colors.gray5;
  } else if (borderless && !disabled) {
    color = Colors.blue4;
  }

  return css`
    background: ${background};
    color: ${color};
  `;
};

const DropdownContainer = styled(({ containerCss, ...props }) => (
  <span {...props} />
))`
  ${props => props.containerCss}
  display: inline-block;
  position: relative;
`;

const DropdownSpinner = () => {
  return (
    <span
      css={css`
        position: absolute;
        pointer-events: none;
        left: 0.5rem;
        top: 25%;
      `}
    >
      <Spinner />
    </span>
  );
};
