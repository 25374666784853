import _ from 'lodash';
import cx from 'classnames';
import { css } from '@emotion/react';
import Sifter from 'sifter';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as gtm from '../../utils/gtm';
import { standardRadius, Mixins, ZIndexes, Colors } from '../../styles';
import { ifAllExist } from '../../utils/common';
import { Button } from './Button';
import { Icon, IconTypes } from '../icons';

const getText = option => (_.isObject(option) ? option.text : option) || '';
const getId = option => (_.isObject(option) ? option.id : option);

const getSuggestions = (value, options) =>
  // sifter only filters arrays of objects, not strings
  new Sifter(options.map(getText).map(option => ({ value: option })))
    .search(getText(value), {
      fields: ['value'],
      sort: [{ field: 'value', direction: 'desc' }]
    })
    .items.map(({ id: index }) => options[index]);

const areOptionsEqual = (optionA, optionB) => {
  return (
    optionA === optionB ||
    (getId(optionA) === getId(optionB) && getText(optionA) === getText(optionB))
  );
};

export default class Autosuggest extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultOption || '',
      suggesting: false,
      selection: undefined,
      prevValue: props.defaultOption || '',
      prevDefaultOption: props.defaultOption || ''
    };

    this.inputAndIconsContainer = React.createRef();
    this.closeFlyout = this.closeFlyout.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.openFlyout = this.openFlyout.bind(this);
    this.search = this.search.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.defaultId = _.uniqueId('autosuggest-');
  }

  static getDerivedStateFromProps(props, state) {
    const defaultOptionChanged = !areOptionsEqual(
      props.defaultOption,
      state.prevDefaultOption
    );

    return {
      value: defaultOptionChanged ? props.defaultOption || '' : state.value,
      selection: state.value !== state.prevValue ? undefined : state.selection,
      prevValue: state.value,
      prevDefaultOption: props.defaultOption
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onTextChange) {
      if (
        this.state.selection !== prevState.selection ||
        this.state.value !== prevState.value
      ) {
        this.props.onTextChange(
          this.guardGetInputText(
            (this.state.suggesting && this.state.selection) || this.state.value
          )
        );
      }
    }
  }

  search() {
    if (
      this.props.allowNonOptionSearch ||
      this.props.options.map(getText).includes(getText(this.state.value))
    ) {
      this.onSelect(
        (this.state.suggesting && this.state.selection) || this.state.value
      );
    }
  }

  openFlyout() {
    this.setState(state => ({ suggesting: !_.isEmpty(state.value) }));
  }

  closeFlyout() {
    this.setState({ selection: undefined, suggesting: false });
  }

  onBlur(event) {
    if (!this.inputAndIconsContainer.current.contains(event.relatedTarget)) {
      this.closeFlyout();
      this.resetToDefaultOption();
    }
  }

  onSelect(selection) {
    this.props.onSelect(getId(selection));
    this.setState({
      suggesting: false,
      value:
        this.props.clearInputOnSubmit && selection !== this.props.defaultOption
          ? ''
          : selection
    });
  }

  onChange({ target: { value } }) {
    this.setState({
      suggesting: !_.isEmpty(value),
      value
    });
  }

  submitInput() {
    gtm.trigger(ifAllExist`${this.props.trackingPrefix}_search-submit`);
    if (this.state.suggesting && !_.isEmpty(this.state.selection)) {
      const { clearInputOnSubmit } = this.props;
      this.onSelect(this.state.selection);
      this.setState(({ selection }) => ({
        value: clearInputOnSubmit ? '' : selection
      }));
    } else {
      this.search();
    }
  }

  resetToDefaultOption() {
    if (this.props.defaultOption !== undefined) {
      this.setState({
        value: this.props.defaultOption
      });
    }
  }

  moveActiveSelection(key) {
    if (_.isEmpty(this.state.value)) {
      return;
    }

    this.setState(state => {
      const suggestions = getSuggestions(state.value, this.props.options);
      const visibleSuggestions = suggestions.slice(0, this.props.limit);
      const index = visibleSuggestions.findIndex(suggestion =>
        areOptionsEqual(state.selection, suggestion)
      );

      return {
        suggesting: true,
        selection:
          index !== -1
            ? key === 'ArrowUp'
              ? visibleSuggestions[index - 1]
              : visibleSuggestions[index + 1]
            : key === 'ArrowUp'
              ? _.last(visibleSuggestions)
              : _.first(visibleSuggestions)
      };
    });
  }

  onKeyDown(evt) {
    const { key } = evt;

    switch (key) {
      case 'Escape':
        if (this.state.suggesting) {
          evt.stopPropagation();
        }

        this.resetToDefaultOption();
        this.closeFlyout();
        break;

      case 'Enter':
        this.submitInput();
        this.closeFlyout();
        break;

      case 'ArrowDown':
      case 'ArrowUp':
        evt.preventDefault();
        this.moveActiveSelection(key);
    }
  }

  selectSuggestion(suggestion) {
    this.setState({
      suggesting: false,
      value: suggestion
    });
    this.onSelect(suggestion);
    this.closeFlyout();
  }

  guardGetInputText(value) {
    return this.props.getInputText(value || '');
  }

  render() {
    const { suggesting, selection, value } = this.state;
    const id = this.props.id || this.defaultId;
    const currentInputText = this.guardGetInputText(
      (suggesting && selection) || value
    );
    const suggestions = getSuggestions(this.state.value, this.props.options);
    const visibleSuggestions = suggestions.slice(0, this.props.limit);
    const selectionIndex = visibleSuggestions.findIndex(suggestion =>
      areOptionsEqual(selection, suggestion)
    );

    return (
      <div
        className={cx('autosuggest', this.props.className)}
        css={css`
          display: inline-block;
          position: relative;
          ${this.props.fillWidth &&
          css`
            width: 100%;
          `};
        `}
      >
        <div ref={this.inputAndIconsContainer} css={Mixins.inputWithInsetIcon}>
          <Input
            id={id}
            fillWidth={this.props.fillWidth}
            selectionIndex={selectionIndex}
            value={currentInputText}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onClick={this.openFlyout}
            onBlur={this.onBlur}
            aria-label={this.props['aria-label']}
            disabled={this.props.disabled}
          />

          {this.props.displayIcons && (
            <InputIcons
              clear={() => this.onSelect('')}
              search={this.search}
              inputIsEmpty={!currentInputText}
              onBlur={this.onBlur}
              inputMatchesDefaultOption={
                this.guardGetInputText(this.props.defaultOption) ===
                currentInputText
              }
            />
          )}
        </div>

        {suggesting && (
          <SuggestionsFlyout
            id={id}
            onSuggestionClick={this.selectSuggestion}
            renderSuggestion={this.props.renderSuggestion}
            selection={selection}
            visibleSuggestions={visibleSuggestions}
            trackingPrefix={this.props.trackingPrefix}
          />
        )}
      </div>
    );
  }
}

const OptionType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  })
]);

Autosuggest.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(OptionType.isRequired).isRequired,
  onSelect: PropTypes.func,
  limit: PropTypes.number,
  renderSuggestion: PropTypes.func.isRequired,
  // getInputText receives the current option or input value and returns the
  // text which should be displayed in the input.
  getInputText: PropTypes.func.isRequired,
  fillWidth: PropTypes.bool,
  defaultOption: OptionType,
  allowNonOptionSearch: PropTypes.bool,
  clearInputOnSubmit: PropTypes.bool,
  onTextChange: PropTypes.func,
  trackingPrefix: PropTypes.string,
  'aria-label': PropTypes.string,
  disabled: PropTypes.bool,
  displayIcons: PropTypes.bool,
  className: PropTypes.string
};

Autosuggest.defaultProps = {
  placeholder: 'Search…',
  onSelect() {},
  limit: 5,
  renderSuggestion: ({ suggestion }) => getText(suggestion),
  getInputText: value => getText(value),
  fillWidth: false,
  allowNonOptionSearch: false,
  clearInputOnSubmit: true
};

const Input = React.forwardRef(
  ({ id, fillWidth, selectionIndex, ...props }, ref) => (
    <input
      ref={ref}
      {...props}
      id={id}
      css={css`
        border-radius: ${standardRadius};
        ${fillWidth &&
        css`
          box-sizing: border-box;
          width: 100%;
          margin: 0;
          height: 2rem;
        `}
      `}
      type="text"
      name="search"
      autoComplete="off"
      role="combobox"
      aria-autocomplete="both"
      aria-owns={`${id}_search-field-suggestions`}
      aria-activedescendant={
        selectionIndex !== -1
          ? `${id}_search-field-suggestion_${selectionIndex}`
          : undefined
      }
    />
  )
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  fillWidth: PropTypes.bool.isRequired,
  selectionIndex: PropTypes.number.isRequired
};

const InputIcons = React.forwardRef(
  (
    {
      clear,
      search,
      inputIsEmpty,
      inputMatchesDefaultOption,
      onBlur,
      disabled
    },
    ref
  ) => {
    const searching = inputIsEmpty || !inputMatchesDefaultOption;
    return (
      <Button
        ref={ref}
        aria-label={searching ? 'Search' : 'Clear'}
        flavor="subtle"
        disabled={inputIsEmpty || disabled}
        onBlur={onBlur}
        onClick={inputMatchesDefaultOption ? clear : search}
      >
        <Icon
          type={searching ? IconTypes.SEARCH : IconTypes.CIRCULAR_CLOSE}
          alt={searching ? 'search' : 'clear'}
        />
      </Button>
    );
  }
);

InputIcons.propTypes = {
  clear: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  inputIsEmpty: PropTypes.bool.isRequired,
  inputMatchesDefaultOption: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

const SuggestionsFlyout = memo(
  ({
    id,
    onSuggestionClick,
    renderSuggestion,
    selection,
    visibleSuggestions,
    trackingPrefix
  }) => (
    <div
      id={`${id}_search-field-suggestions`}
      css={css`
        ${Mixins.shadowOutset};
        border-radius: ${standardRadius};
        background: white;
        left: 0;
        right: 0;
        position: absolute;
        z-index: ${ZIndexes.flyout};
      `}
    >
      {!_.isEmpty(visibleSuggestions) ? (
        <div role="listbox">
          {visibleSuggestions.map((suggestion, index) => (
            <Suggestion
              key={getId(suggestion)}
              id={`${id}_search-field-suggestion_${index}`}
              suggestion={suggestion}
              selected={getId(suggestion) === getId(selection)}
              onClick={() => onSuggestionClick(suggestion)}
              renderSuggestion={renderSuggestion}
              trackingPrefix={trackingPrefix}
            />
          ))}
        </div>
      ) : (
        <NoMatches />
      )}
    </div>
  )
);

SuggestionsFlyout.propTypes = {
  id: PropTypes.string.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  visibleSuggestions: PropTypes.array.isRequired,
  trackingPrefix: PropTypes.string
};

const Suggestion = ({
  id,
  suggestion,
  selected,
  onClick,
  renderSuggestion,
  trackingPrefix
}) => (
  <div
    id={id}
    className="autosuggest__suggestion"
    css={css`
      padding: 0.25rem 0.5rem;
      overflow: hidden;
      overflow-wrap: break-word;
      cursor: default;
      box-sizing: border-box;

      ${selected &&
      css`
        background: ${Colors.blue0};
        border: 1px solid ${Colors.blue1};
      `}

      &:first-child {
        border-top-left-radius: ${standardRadius};
        border-top-right-radius: ${standardRadius};
      }

      &:last-child {
        border-bottom-left-radius: ${standardRadius};
        border-bottom-right-radius: ${standardRadius};
      }

      &:hover {
        background: ${Colors.blue0};
      }
    `}
    onMouseDown={evt => evt.preventDefault()}
    onClick={onClick}
    tabIndex={-1}
    role="option"
    data-tracking-item={ifAllExist`${trackingPrefix}_search-suggestion`}
  >
    {renderSuggestion({ suggestion })}
  </div>
);

Suggestion.propTypes = {
  id: PropTypes.string.isRequired,
  suggestion: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  trackingPrefix: PropTypes.string
};

const NoMatches = () => (
  <div
    css={css`
      ${Mixins.hintText};
      border-radius: ${standardRadius};
      overflow: hidden;
      overflow-wrap: break-word;
      cursor: default;
      padding: 0.25rem 0.5rem;
    `}
  >
    No matches found
  </div>
);
