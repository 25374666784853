import React, { useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import _ from 'lodash';

import { Colors } from '../../styles';
import { ColumnGroup } from '../ColumnGroup';
import Spinner from '../../components/core/Spinner';
import { scrollToElement } from '../../utils/scrollToElement';
import { usePrevious } from '../../utils/hooks';
import { Bubbles } from './Bubbles';
import { IssuesSummary } from './IssueSummary';
import { Issue } from './Issue';
import { bubblePanelItemStyles } from './bubbleStyles';
import { COLUMN_TYPES, GroupBubble, TypeTooltip } from './GroupBubble';
import { Icon, IconTypes } from '../../components/icons';
import { Button } from '../../components/core/Button';
import InlineEditor from '../../components/InlineEditor';
import { PlaintextWhenDisabledDropdown } from '../PlaintextWhenDisabledDropdown';

export const BubblePanel = React.forwardRef(function BubblePanel(
  {
    analysisInProgress,
    groupStats,
    bubbles,
    onChangeColumnType,
    onSelectGroup,
    onDismissIssue,
    selectedGroups,
    hoveredGroup,
    onHoverGroup,
    onRenameGroup,
    onMouseDown,
    openModalConfigure,
    isConfigure
  },
  ref
) {
  const [expandedBubbles, setExpandedBubbles] = useState({
    ids: [],
    all: false
  });

  const scrollContainerRef = useRef();
  const bubbleRefs = useRef({});
  const [selectedBubbleIds, selectBubble] = useSelectedBubble(
    selectedGroups,
    onSelectGroup
  );

  useImperativeHandle(ref, () => ({
    scrollToGroup(groupId) {
      scrollToElement(bubbleRefs.current[groupId]);
    }
  }));

  function goToNextBubble(type) {
    const nextBubble = bubbles.getNextBubbleWithIssueOfType(
      selectedBubbleIds?.[0],
      type
    );

    selectBubble(nextBubble);
    scrollToElement(bubbleRefs.current[nextBubble.id]);
  }

  const expandBubbleHandler = id => {
    if (id === 'all') {
      setExpandedBubbles({
        ids: expandedBubbles.all ? [] : bubbles.bubbles.map(b => b.id),
        all: !expandedBubbles.all
      });
      return;
    }
    if (expandedBubbles.ids.includes(id)) {
      setExpandedBubbles({
        ids: expandedBubbles.ids.filter(i => i !== id),
        all: false
      });
    } else {
      const newArray = [...expandedBubbles.ids, id];
      const allExpanded = newArray.length === bubbles.bubbles.length;
      setExpandedBubbles({
        ids: [...expandedBubbles.ids, id],
        all: allExpanded
      });
    }
  };
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        /* Apply an arbitrary width so things don't look bad */
        width: 33vw;
        /* Don't let it get too wide */
        max-width: 30rem;
        /* Add spacing between items */

        > :not(:last-child) {
          margin-bottom: 0.5rem;
        }

        [data-is-selected] {
          outline: 2px auto ${Colors.blue4};

          h6 {
            color: ${Colors.blue4};
          }
        }
      `}
    >
      {analysisInProgress ? (
        <AnalysisInProgressMessage />
      ) : (
        <>
          <IssuesSummary
            bubbles={bubbles}
            onClickNextError={() => goToNextBubble('error')}
            onClickNextWarning={() => goToNextBubble('warning')}
            openModalConfigure={openModalConfigure}
            isConfigure={isConfigure}
          />
        </>
      )}
      <div
        css={css`
          padding-left: 3px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-top: 10px;
        `}
      >
        <Button
          onClick={() => expandBubbleHandler('all')}
          css={css`
            margin-bottom: 0.5rem;

            svg {
              margin-right: 0.25rem;
              fill: none;
            }

            &:hover svg,
            &:active svg {
              fill: none;
            }
          `}
        >
          <Icon
            type={IconTypes.CARET_STROKE}
            direction={expandedBubbles.all ? 'right' : 'down'}
            size="14"
          />
          {expandedBubbles.all ? 'Collapse all' : 'Expand all'}
        </Button>
        {selectedGroups?.length > 1 && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <InlineEditor
              onChange={name => onRenameGroup(selectedGroups, name)}
              value=""
              blankValue=""
              action="Rename"
              name={`all selected columns`}
              placeholder="Type a new name"
              ellipsify={false}
              css={[
                css`
                  word-break: break-word;
                  font-weight: bold;
                  margin-right: 0.5rem;
                  /* Make input take up the full width */

                  input {
                    width: 100%;
                  }
                `
              ]}
            />
            <TypeTooltip typeCanBeChanged={true} alternateMessage="">
              <PlaintextWhenDisabledDropdown
                css={css`
                  /* prevent the select from shrinking when names are long */
                  width: unset;
                `}
                editable={true}
                aria-label={`Type of selected columns`}
                value=""
                onChange={newType => {
                  onChangeColumnType(
                    selectedGroups
                      .filter(
                        group =>
                          group.name !== 'Question' && group.typeCanBeChanged
                      )
                      .map(group => group.id),
                    newType
                  );
                }}
                options={COLUMN_TYPES.map(t => ({
                  value: t,
                  name: _.upperFirst(t)
                }))}
                promptOption="Select a type"
                data-tracking-item="upload-page_bubble-type-select"
              />
            </TypeTooltip>
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow: auto;
          word-wrap: break-word;
        `}
        ref={scrollContainerRef}
      >
        {bubbles.map(bubble => {
          const groupProps = bubble.group && {
            onChangeColumnType,
            hoveredGroup,
            onRenameGroup,
            onHoverGroup,
            onSelectGroup,
            onMouseDown,
            stats: groupStats[bubble.group.key]
          };

          return (
            <BubbleElement
              ref={el => (bubbleRefs.current[bubble.id] = el)}
              key={bubble.id}
              expanded={expandedBubbles.ids.includes(bubble.id)}
              onExpand={() => expandBubbleHandler(bubble.id)}
              selected={selectedBubbleIds?.includes(bubble.id)}
              bubble={bubble}
              onDismissIssue={onDismissIssue}
              {...groupProps}
            />
          );
        })}
      </div>
    </div>
  );
});

BubblePanel.propTypes = {
  analysisInProgress: PropTypes.bool.isRequired,
  bubbles: PropTypes.instanceOf(Bubbles).isRequired,
  groupStats: PropTypes.object.isRequired,
  onChangeColumnType: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  onDismissIssue: PropTypes.func.isRequired,
  selectedGroup: PropTypes.instanceOf(ColumnGroup),
  hoveredGroup: PropTypes.instanceOf(ColumnGroup),
  onHoverGroup: PropTypes.func.isRequired,
  onRenameGroup: PropTypes.func.isRequired
};

function AnalysisInProgressMessage() {
  return (
    <div
      css={css`
        min-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > :not(:first-child) {
          margin-left: 0.5rem;
        }
      `}
    >
      <Spinner size="small" />
      <span>Analyzing...</span>
    </div>
  );
}

function useSelectedBubble(selectedGroups, onSelectGroup) {
  const [selectedIssueIds, setSelectedIssueIds] = useState(null);

  const prevSelectedGroups = usePrevious(selectedGroups);
  // if we've selected a new group, clear the selected standalone issue
  if (
    selectedIssueIds?.length &&
    selectedGroups?.[0] &&
    prevSelectedGroups?.[0]?.id !== selectedGroups?.[0]?.id
  ) {
    setSelectedIssueIds(null);
  }
  const selectedBubbleIds =
    (selectedGroups?.length && selectedGroups?.map(item => item.id)) ||
    selectedIssueIds;

  function selectBubble(bubble) {
    if (bubble.group) {
      // Select the next group issue
      onSelectGroup(null, bubble.group);
      setSelectedIssueIds(null);
    } else {
      // Select the next standalone issue

      onSelectGroup(null, null);
      setSelectedIssueIds([bubble.id]);
    }
  }

  return [selectedBubbleIds, selectBubble];
}

const BubbleElement = React.forwardRef(function BubbleElement(
  {
    bubble,
    onDismissIssue,
    selected = false,
    expanded,
    onExpand,
    ...groupProps
  },
  ref
) {
  if (bubble.group) {
    const { hoveredGroup, ...otherGroupProps } = groupProps;

    return (
      <GroupBubble
        ref={ref}
        bubble={bubble}
        expanded={expanded}
        onExpand={onExpand}
        onDismissIssue={onDismissIssue}
        selected={selected}
        hovered={hoveredGroup === bubble.group}
        {...otherGroupProps}
      />
    );
  }

  return (
    <StandaloneIssueBubble
      ref={ref}
      bubble={bubble}
      onDismissIssue={onDismissIssue}
      selected={selected}
    />
  );
});

const StandaloneIssueBubble = React.forwardRef(function StandaloneIssueBubble(
  { bubble, onDismissIssue, selected },
  ref
) {
  const [issue] = bubble.issues;

  return (
    <Issue
      ref={ref}
      data-issue-type={issue.showing ? issue.type : undefined}
      data-bubble-id={issue.showing ? bubble.id : undefined}
      data-is-selected={selected || undefined}
      issue={issue}
      onDismiss={onDismissIssue}
      css={bubblePanelItemStyles}
    />
  );
});
