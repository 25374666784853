import React from 'react';
import PropTypes from 'prop-types';
import { motion, usePresence } from 'framer-motion';

import { MessageContainer } from './styled';
import MessageContentType from '../ChatMessageContent';

const formatDateTime = (dateTime) =>
  new Date(dateTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

const ChatMessage = ({
  id,
  type,
  author,
  placeholder,
  dateTime,
  userAction,
  children,
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const animation = {
    layout: true,
    initial: 'out',
    animate: isPresent ? 'in' : 'out',
    variants: {
      in: { y: 0, opacity: 1 },
      out: { y: 20, opacity: 0 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
  };

  const MessageContent = MessageContentType.get(type);
  const handleClick = (...args) => userAction(...args, id);

  return (
    <MessageContainer
      component={motion.li}
      {...animation}
      className={author}
      data-type={type}
      data-datetime={formatDateTime(dateTime)}
    >
      <MessageContent
        onClick={handleClick}
        className={type}
        placeholder={placeholder}
      >
        {children}
      </MessageContent>
    </MessageContainer>
  );
};

ChatMessage.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  userAction: PropTypes.func,
  placeholder: PropTypes.string,
};

export default ChatMessage;
