import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_MESSAGE_TYPE, CHAT_MESSAGE_AUTHORS } from '../../../../types';
import {
  BedrockAgentRuntimeClient,
  RetrieveAndGenerateCommand,
} from '@aws-sdk/client-bedrock-agent-runtime'; // ES Modules import

const initConversation = (set, get) => async () => {
  if (!get().activeChat.id) {
    await get().createChat();
  }
  const activeChat = { ...get().activeChat };
  activeChat.isConversationStarted = true;
  activeChat.isBotReplying = true;
  // activeChat.messages = [
  //   {
  //     id: uuidv4(),
  //     content: i18next.t('chat.system_info.waiting_for_reply'),
  //     role: CHAT_MESSAGE_AUTHORS.SYSTEM,
  //     type: CHAT_MESSAGE_TYPE.AWAITING,
  //     created_at: new Date().toISOString(),
  //   },
  // ];
  activeChat.messages = [];

  set({ activeChat: { ...activeChat } });

  activeChat.isBotReplying = false;
  activeChat.isInteractionFromMessageRequired = true;
  // activeChat.messages = [
  //   {
  //     id: uuidv4(),
  //     content: i18next.t('chat.greeting.message'),
  //     role: CHAT_MESSAGE_AUTHORS.ASSISSTANT,
  //     type: CHAT_MESSAGE_TYPE.TEXT,
  //     created_at: new Date().toISOString(),
  //   },
  //   {
  //     id: uuidv4(),
  //     content: i18next.t('chat.greeting.actions', { returnObjects: true }),
  //     role: CHAT_MESSAGE_AUTHORS.ASSISSTANT,
  //     type: CHAT_MESSAGE_TYPE.BUTTONS,
  //     created_at: new Date().toISOString(),
  //   },
  // ];

  activeChat.messages = [];

  setTimeout(() => {
    set({ activeChat: { ...activeChat } });
  }, 2000);
};

export default initConversation;
