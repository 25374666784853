import { upperFirst } from 'lodash';
import React, { forwardRef, memo, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Doc as DocClass } from '../classes/Docs';
import { Colors, Mixins } from '../styles';
import { Concept } from '../classes/Concepts';
import PlaceholderText from '../components/core/PlaceholderText';
import { useRandom } from '../utils/hooks';
import { TruncatedDocText } from './TruncatedDocText';
import { scrollElementIntoView } from '../utils/scrollToElement';
import { DocMetadata } from './DocMetadata';
import { Icon, IconTypes } from '../components/icons';

const Doc = memo(
  forwardRef(function Doc(
    {
      doc,
      language,
      selection,
      onSelectDocument,
      isSelected,
      sidePanelMetadata
    },
    ref
  ) {
    const buttonRef = useRef();
    const containerRef = useRef();
    const matchIndices = doc.getMatchIndices(selection);

    useImperativeHandle(ref, () => ({
      scrollIntoView() {
        scrollElementIntoView(containerRef.current);
      },
      isDocButton(element) {
        return element === buttonRef.current;
      }
    }));

    return (
      <DocSkeleton
        ref={containerRef}
        indicator={
          matchIndices.exact.length + matchIndices.conceptual.length > 0 && (
            <DocMatchInfo doc={doc} selection={selection} />
          )
        }
        text={
          <div
            ref={buttonRef}
            data-tracking-item="documents-pane-document"
            role="button"
            tabIndex="0"
            onClick={() => {
              onSelectDocument(doc);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                onSelectDocument(doc);
              }
            }}
            css={css`
              cursor: pointer;
            `}
          >
            {doc.title && (
              <h6 className={language === 'ar' ? 'rtl' : ''}>{doc.title}</h6>
            )}
            <MetadataContainer>
              <DocMetadata
                docMetadata={doc.metadata}
                fieldsToDisplay={sidePanelMetadata}
              />
            </MetadataContainer>
            <TruncatedDocText
              doc={doc}
              language={language}
              selection={selection}
            />
          </div>
        }
        isSelected={isSelected}
      />
    );
  })
);

export default Doc;

Doc.propTypes = {
  language: PropTypes.string.isRequired,
  doc: PropTypes.instanceOf(DocClass).isRequired,
  selection: PropTypes.instanceOf(Concept)
};

const MetadataContainer = styled.ul`
  list-style: none;
  padding: unset;
  font-style: italic;
  li {
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
`;

export function PlaceholderDoc() {
  const numberOfLines = useRandom(1, 4);
  return (
    <DocSkeleton
      text={
        <>
          {[...Array(numberOfLines)].map((n, i) => (
            <PlaceholderLine key={i} />
          ))}
          <PlaceholderLine minWidth={0.1} maxWidth={0.9} />
        </>
      }
    />
  );
}

const DocContainer = styled.div`
  display: flex;
  flex-direction: row;
  word-break: break-word;
`;

const DocInside = styled.div`
  ${Mixins.roundedCorners}
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem 0.125rem;
  margin: 0 0.125rem;
  margin-bottom: 0.5rem;
  flex-grow: 1;
  ${props => (props.isSelected ? `outline: 2px auto ${Colors.blue4};` : '')}
  &:hover {
    background: ${Colors.blue0};
  }

  h6 {
    padding: 0 0 0.5rem;
  }
`;

const DocSkeleton = forwardRef(function DocSkeleton(
  { indicator, text, isSelected },
  ref
) {
  return (
    <DocContainer ref={ref}>
      {indicator}
      <DocInside data-test-id="search-result-doc" isSelected={isSelected}>
        {text}
      </DocInside>
    </DocContainer>
  );
});

function PlaceholderLine({ minWidth = 0.9, maxWidth = 1 }) {
  const width = useRandom(minWidth, maxWidth);
  return (
    <PlaceholderText
      css={css`
        display: block;
        height: 0.8rem;
        margin-bottom: 0.2rem;
        width: ${100 * width}%;
      `}
    />
  );
}

const DocMatchInfoContainer = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  color: ${Colors.blue5};
`;

const DocMatchInfo = ({ doc, selection }) => {
  const matchIndices = doc.getMatchIndices(selection);
  const matchType = matchIndices.exact.length > 0 ? 'exact' : 'conceptual';
  const text = `${upperFirst(matchType)} match`;
  return (
    <DocMatchInfoContainer>
      {matchType === 'exact' ? (
        <Icon type={IconTypes.EXACT_CIRCLE} aria-label={text} />
      ) : (
        <Icon
          type={IconTypes.APPROXIMATE_CIRCLE}
          thickness="light"
          aria-label={text}
        />
      )}
    </DocMatchInfoContainer>
  );
};
