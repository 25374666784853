import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Doc } from '../classes/Docs';
import { Concept } from '../classes/Concepts';
import { ParagraphList } from './ParagraphList';
import { getFormattedDocument } from '../utils/DocUtils';
import { useSearchParams } from '../search_params';

export const FullDocText = memo(({ doc, language, selection }) => {
  const { searchParams } = useSearchParams();
  const isSentimentIndication = searchParams?.sentiment_indication?.toLowerCase() === 'true'
  const { paragraphList } = getFormattedDocument(doc, selection, false);
  return <ParagraphList isSentimentIndication={isSentimentIndication} language={language} paragraphList={paragraphList} />;
});

FullDocText.propTypes = {
  doc: PropTypes.instanceOf(Doc).isRequired,
  selection: PropTypes.instanceOf(Concept),
  language: PropTypes.string.isRequired
};
