import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from './core/Checkbox';
import { Button } from './core/Button';
import { Colors } from '../styles';
import { Constraint } from '../classes/Constraints';
import { Icon, IconTypes } from './icons';

export default function FilterSelector({
  children,
  constraint,
  onClear,
  onToggleSelectAll,
  selectAllChecked,
  expanded,
  toggleExpanded
}) {
  return (
    <div className="filter-selector">
      <div
        css={css`
          padding: 0.5rem 0.5rem;
          display: flex;
        `}
      >
        <ExpandFieldToggle
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          constraint={constraint}
        />
        {onToggleSelectAll && (
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
            `}
          >
            <label>Select All</label>
            <Checkbox
              checked={selectAllChecked}
              onChange={onToggleSelectAll}
              css={css`
                margin-right: 0.5rem;
              `}
              aria-label={
                selectAllChecked
                  ? `Clear values for "${constraint.name}"`
                  : `Select all values for "${constraint.name}"`
              }
              // prevent expanding/collapsing selector header
              onClick={evt => {
                evt.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
      {expanded && (
        <div>
          {onClear && <ClearButton onClear={onClear} />}
          {children}
        </div>
      )}
    </div>
  );
}

FilterSelector.propTypes = {
  constraint: PropTypes.instanceOf(Constraint).isRequired,
  onClear: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired
};

function ClearButton({ onClear }) {
  return (
    <div className="filter-selector__ctrls">
      <Button
        flavor="subtle"
        padded={false}
        css={css`
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
          &:hover {
            text-decoration: underline;
          }
        `}
        onClick={onClear}
        data-tracking-item="filter-pane_clear-field-selector-button"
      >
        Clear
      </Button>
    </div>
  );
}

const bold = css`
  font-weight: bold;
`;

function ExpandFieldToggle({ expanded, toggleExpanded, constraint }) {
  const hasSelections =
    constraint.values?.length ||
    constraint.minimum != null ||
    constraint.maximum != null;
  const selectedMessage = hasSelections
    ? ` (${constraint.values?.length ?? constraint.description} selected)`
    : '';

  return (
    <Button
      flavor="subtle"
      padded={false}
      css={css`
        flex: 1;
        text-align: start;
        cursor: pointer;
        padding: 0 0.5rem;
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${Colors.gray9} !important;
        svg {
          margin-right: 0.25rem;
          fill: none;
        }
        &:hover svg,
        &:active svg {
          fill: none;
        }
      `}
      title={`${constraint.name}${selectedMessage}`}
      onClick={toggleExpanded}
      data-tracking-item={`filter-pane_field-selector-toggle_${
        expanded ? 'collapse' : 'expand'
      }`}
    >
      <Icon
        type={IconTypes.CARET_STROKE}
        direction={expanded ? 'down' : 'right'}
        size="14"
      />
      <span css={[hasSelections && bold]} data-test-id="constraint-name">
        {constraint.name}
      </span>
      {hasSelections && (
        <span
          css={css`
            color: ${Colors.gray5};
          `}
        >
          {selectedMessage}
        </span>
      )}
    </Button>
  );
}
