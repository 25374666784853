import React, { forwardRef, useCallback, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { css, keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

import { AlertTypes } from '../../constants';
import { Colors, Mixins, standardRadius } from '../../styles';
import { Button } from './Button';
import { useMountedRef } from '../../utils/hooks';
import { Icon, IconTypes } from '../icons';

const fadeOut = keyframes`
  from { opacity: 1 }
  to { opacity: 0 }
`;

const Alert = forwardRef(function Alert(
  {
    type,
    className,
    children,
    disappearing = false,
    cancellable = false,
    showing: showingProp,
    onHide = () => {},
    duration = 3,
    dismissTrackingItem,
    ...additionalProps
  },
  ref
) {
  const [showingState, setShowingState] = useState(true);
  const showing = showingProp ?? showingState;
  if (!showing) {
    return null;
  }
  function hide() {
    setShowingState(false);
    onHide();
  }
  const [iconType, mainBackground, iconBackground, alt] = (() => {
    switch (type) {
      case AlertTypes.SUCCESS:
        return [
          IconTypes.CIRCULAR_CHECK,
          Colors.green1,
          Colors.green4,
          'check'
        ];
      case AlertTypes.ERROR:
        return [IconTypes.CIRCULAR_CLOSE, Colors.red0, Colors.red3, 'error'];
      case AlertTypes.WARNING:
        return [
          IconTypes.CIRCULAR_EXCLAMATION,
          Colors.yellow0,
          Colors.yellow2,
          'warning'
        ];
      case AlertTypes.INFO:
        return [IconTypes.CIRCULAR_INFO, Colors.blue0, Colors.blue4, 'info'];
      default:
        return [undefined, undefined, undefined, undefined];
    }
  })();

  return (
    <div
      {...additionalProps}
      ref={ref}
      className={cx('alert', className)}
      css={[
        css`
          ${Mixins.shadowOutset};
          display: inline-block;
          border-radius: 2px;
          box-sizing: border-box;
          color: ${Colors.gray8};
          background: ${mainBackground};
        `,
        disappearing &&
          css`
            animation: ${fadeOut} 0.2s ease-in-out ${duration}s forwards;
          `
      ]}
      onAnimationEnd={disappearing ? hide : undefined}
      data-test-id="alert"
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem;
            color: white;
            background: ${iconBackground};
            border-top-left-radius: ${standardRadius};
            border-bottom-left-radius: ${standardRadius};
          `}
        >
          <Icon type={iconType} alt={alt} />
        </div>
        <div
          css={css`
            display: inline-block;
            margin: 0.5rem 1rem;
            margin-right: ${cancellable ? 0 : '1rem'};
            min-width: 0;
            width: 100%;
          `}
        >
          {children}
        </div>
        {cancellable && (
          <Button
            css={css`
              margin-left: auto;
              padding-right: 1rem;
              align-self: flex-start;
            `}
            flavor="subtle"
            palette="gray"
            aria-label="Dismiss"
            onClick={hide}
            data-tracking-item={dismissTrackingItem}
          >
            <Icon type={IconTypes.CLOSE} />
          </Button>
        )}
      </div>
    </div>
  );
});

Alert.propTypes = {
  type: PropTypes.oneOf(_.values(AlertTypes)).isRequired,
  disappearing: PropTypes.bool,
  cancellable: PropTypes.bool,
  onHide: PropTypes.func,
  showing: PropTypes.bool,
  duration: PropTypes.number,
  dismissTrackingItem: PropTypes.string
};

export default Alert;

/**
 * Hook for managing the state of an OverlayAlert.
 *
 * @returns {Object} - An object with fields:
 *                     status - The alert status
 *                     setStatus - Function to set the status
 */
export function useOverlayAlertController() {
  const [status, setStatus] = useState('none');
  const [message, setMessage] = useState('');
  const mounted = useMountedRef();

  const showWarning = useCallback(
    warningMessage => {
      if (mounted.current) {
        setStatus('failure');
        setMessage(warningMessage);
      }
    },
    [mounted]
  );

  const showSuccess = useCallback(
    successMessage => {
      if (mounted.current) {
        setStatus('success');
        setMessage(successMessage);
      }
    },
    [mounted]
  );

  const clearAlert = useCallback(() => {
    if (mounted.current) {
      setStatus('none');
      setMessage('');
    }
  }, [mounted]);

  return { status, message, showWarning, showSuccess, clearAlert };
}

/**
 * Component for showing an short-term alert message that replaces another
 * component.
 *
 * If status is none, this just shows whatever its children are.  If status is
 * success or failure, it replaces the children with a success or failure
 * banner.  See the "Copy link" button in the "Export" side panel tab for
 * a straightforward example.
 */
export function OverlayAlert({
  controller,
  className,
  children,
  onHideSuccess,
  cancellable = false,
  duration = 1,
  failureDuration = 2
}) {
  if (controller.status === 'none') {
    return children;
  }

  return (
    <Alert
      type={
        controller.status === 'success'
          ? AlertTypes.SUCCESS
          : AlertTypes.WARNING
      }
      className={className}
      disappearing
      onHide={() => {
        if (controller.status === 'success') {
          onHideSuccess?.();
        }
        controller.clearAlert();
      }}
      cancellable={cancellable}
      duration={controller.status === 'success' ? duration : failureDuration}
    >
      {controller.message}
    </Alert>
  );
}
