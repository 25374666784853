import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { CardContainer } from './styled';

const Card = ({ title, description, children, sx }) => {
  return (
    <CardContainer item component={Paper} sx={sx}>
      <Box component="header">
        <Typography variant="h6" mb="0.5rem">
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
      {children}
    </CardContainer>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sx: PropTypes.object,
};

export default Card;
