import React from 'react';
import { css } from '@emotion/react';

import { Colors } from '../../../styles';
import { Button } from '../../../components/core/Button';

const TestCategoryUrlInput = ({
  input,
  inputs,
  onInputChange,
  onInputRemove
}) => {
  return (
    <div>
      <div
        css={css`
          display: flex;
          gap: 20px;
          align-items: center;
        `}
      >
        <input
          css={css`
            width: 100%;
            outline: ${input.error ? `${Colors.red4} 1px solid` : 'none'};
          `}
          name={input.name}
          placeholder="Type your URL here..."
          onChange={onInputChange}
          value={input.value}
          type="text"
          required={true}
        />
        <Button
          onClick={onInputRemove.bind(null, input.name)}
          palette="red"
          css={css`
            height: 35px;
            visibility: ${inputs.length <= 1 ? 'hidden' : 'visible'};
          `}
        >
          Remove
        </Button>
      </div>
      <p
        css={css`
          color: ${Colors.red4};
          font-style: italic;
          font-size: 14px;
        `}
      >
        {input.error && `* ${input.error}`}
      </p>
    </div>
  );
};

export default TestCategoryUrlInput;
