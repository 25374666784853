import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { uniqueId } from 'lodash';

import Reddit from './Reddit/Reddit';
import { FlexibleGrid } from '../../components/core/Layout';
import Reviews from './Reviews/Reviews';
import { AlertTypes, RoutePatterns } from '../../constants';
import Alert from '../../components/core/Alert';
import { ReviewsPage } from './Reviews/ReviewsPage';
import { RedditPage } from './Reddit/RedditPage';
import HRSites from './HRSites/HRSites';
import { HRSitesPage } from './HRSites/HRSitesPage';
import buildRoutePath from '../../utils/buildRoutePath';
import {
  getProjectTasks,
  getScrapersV2Status,
  getScrapersV2RepeatingTasks
} from '../../utils/ApiUtilsV5';
import {
  transformReviewsTask,
  transfrormRedditTask
} from '../../utils/streamData';
import TestCategory from './TestCategory/TestCategory';
import { getFeatureFlags } from '../../featureFlagsSingleton';
import { TestCategoryPage } from './TestCategory/TestCategoryPage';

const initialRedditInputs = [
  { name: `url${uniqueId()}`, value: '', error: '' }
];
const initialTestInputs = [{ name: `url${uniqueId()}`, value: '', error: '' }];

const DisplayStreamData = ({ projectData, setProjectId, project }) => {
  const featureFlags = getFeatureFlags();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const alertType = queryParams.get('alertType');
  const alertMessage = queryParams.get('alertMessage');

  const [projectTasksList, setProjectTasksList] = useState([]);

  const streamDataRoute = project?.project_id
    ? buildRoutePath(RoutePatterns.UPDATE_PROJECT_STREAM_DATA, {
        workspaceId: projectData.workspaceId,
        projectId: projectData.projectId
      })
    : RoutePatterns.UPLOAD_STREAM_DATA;

  const initialAlertState = {
    type: 'success',
    message: ''
  };

  const [alertState, setAlertState] = useState(initialAlertState);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [redditInputs, setRedditInputs] = useState(initialRedditInputs);
  const [redditRepeat, setRedditRepeat] = useState(false);
  const [redditRepeatInterval, setRedditRepeatInterval] = useState('day');

  const [testInputs, setTestInputs] = useState(initialTestInputs);
  const [testRepeat, setTestRepeat] = useState(false);
  const [testRepeatInterval, setTestRepeatInterval] = useState('day');

  const [reviewsSourcesInputs, setReviewsSourcesInputs] = useState([]);

  const [preseletedReviewSources, setPreselectedReviewSources] = useState([]);
  const initialReviewInputs = [
    { name: `url${uniqueId()}`, value: '', error: '' }
  ];
  const [amazonInputs, setAmazonInputs] = useState(initialReviewInputs);
  const [audibleInputs, setAudibleInputs] = useState(initialReviewInputs);
  const [bestBuyInputs, setBestBuyInputs] = useState(initialReviewInputs);
  const [costcoInputs, setCostcoInputs] = useState(initialReviewInputs);
  const [samsclubInputs, setSamsclubInputs] = useState(initialReviewInputs);
  const [walmartInputs, setWalmartInputs] = useState(initialReviewInputs);
  const [targetInputs, setTargetInputs] = useState(initialReviewInputs);
  const [googleInputs, setGoogleInputs] = useState(initialReviewInputs);
  const [trustPilotInputs, setTrustPilotInputs] = useState(initialReviewInputs);
  const [g2Inputs, setG2Inputs] = useState(initialReviewInputs);
  const [consumerAffairsInputs, setConsumerAffairsInputs] =
    useState(initialReviewInputs);

  const [reviewsRepeat, setReviewsRepeat] = useState(false);
  const [reviewsRepeatInterval, setReviewsRepeatInterval] = useState('day');

  const [glassdoorInputs, setGlassdoorInputs] = useState(initialReviewInputs);

  const [hrSitesRepeat, setHRSitesRepeat] = useState(false);
  const [hrSitesRepeatInterval, setHRSitesRepeatInterval] = useState('day');

  const reviewInputs = {
    amazonInputs,
    audibleInputs,
    bestBuyInputs,
    costcoInputs,
    samsclubInputs,
    walmartInputs,
    targetInputs,
    consumerAffairsInputs,
    googleInputs,
    trustPilotInputs,
    g2Inputs
  };

  const setReviewInputs = {
    setAmazonInputs,
    setAudibleInputs,
    setBestBuyInputs,
    setCostcoInputs,
    setSamsclubInputs,
    setWalmartInputs,
    setTargetInputs,
    setConsumerAffairsInputs,
    setGoogleInputs,
    setTrustPilotInputs,
    setG2Inputs
  };

  const hrSitesInputs = {
    glassdoorInputs
  };

  const setHRSitesInputs = {
    setGlassdoorInputs
  };

  const alertHandler = alert => {
    setAlertState(alert);
  };

  const closeAlertHandler = () => {
    setIsAlertOpen(false);
    setAlertState(initialAlertState);
  };

  const projectTasksHandler = useCallback(
    projectTask => {

      if (projectTask.task_type === 'reddit') {
        if (featureFlags.new_scrapers) {
          setRedditInputs(
            projectTask.url_list.map((value, index) => ({
              name: `url${index}`,
              value,
              error: ''
            }))
          );
          setRedditRepeat(projectTask.repeat);
          setRedditRepeatInterval(projectTask.repeat_interval);
          history.replace(`${streamDataRoute}/select/reddit/input-urls`);
        } else {
          // legacy system reddit conversion
          const redditInputs = transfrormRedditTask(projectTask);
          setRedditInputs(
            redditInputs.map((value, index) => ({
              name: `url${index}`,
              value,
              error: ''
            }))
          );
          setRedditRepeat(projectTask.repeat);
          setRedditRepeatInterval(projectTask.repeat_interval);
          history.replace(`${streamDataRoute}/select/reddit/input-urls`);
        }
      }
      if (projectTask.task_type === 'reviews') {
        const reviewsInputs = transformReviewsTask(projectTask);
        if (reviewsInputs.glassdoor) {
          // TODO: add glassdoor to preselected sources
          setGlassdoorInputs(reviewsInputs.glassdoor);
          setHRSitesRepeat(projectTask.repeat);
          setHRSitesRepeatInterval(projectTask.repeat_interval);
          return history.replace(
            `${streamDataRoute}/select/hr-sites/urls-input`
          );
        } else if (reviewsInputs.amazon) {
          setPreselectedReviewSources(prevState => [...prevState, 'Amazon']);
          setAmazonInputs(reviewsInputs.amazon);
        } else if (reviewsInputs.bestbuy) {
          setPreselectedReviewSources(prevState => [...prevState, 'BestBuy']);
          setBestBuyInputs(reviewsInputs.bestbuy);
        } else if (reviewsInputs.samsclub) {
          setPreselectedReviewSources(prevState => [...prevState, 'Samsclub']);
          setSamsclubInputs(reviewsInputs.samsclub);
        } else if (reviewsInputs.target) {
          setPreselectedReviewSources(prevState => [...prevState, 'Target']);
          setTargetInputs(reviewsInputs.target);
        } else if (reviewsInputs.walmart) {
          setPreselectedReviewSources(prevState => [...prevState, 'Walmart']);
          setWalmartInputs(reviewsInputs.walmart);
        } else if (reviewsInputs.costco) {
          setPreselectedReviewSources(prevState => [...prevState, 'Costco']);
          setCostcoInputs(reviewsInputs.costco);
        } else if (reviewsInputs.consumeraffairs) {
          setPreselectedReviewSources(prevState => [
            ...prevState,
            'ConsumerAffairs'
          ]);
          setConsumerAffairsInputs(reviewsInputs.consumeraffairs);
        } else if (reviewsInputs.google) {
          setPreselectedReviewSources(prevState => [...prevState, 'Google']);
          setGoogleInputs(reviewsInputs.google);
        } else if (reviewsInputs.g2) {
          setPreselectedReviewSources(prevState => [...prevState, 'G2']);
          setG2Inputs(reviewsInputs.g2);
        } else if (reviewsInputs.trustpilot) {
          setPreselectedReviewSources(prevState => [
            ...prevState,
            'TrustPilot'
          ]);
          setTrustPilotInputs(reviewsInputs.trustpilot);
        } else {
          setTestInputs(projectTask.url_list);
          setTestRepeat(projectTask.repeat);
          setTestRepeatInterval(projectTask.repeat_interval);
          return history.replace(`${streamDataRoute}/select/test/input-urls`);
        }
        setReviewsRepeat(projectTask.repeat);
        setReviewsRepeatInterval(projectTask.repeat_interval);
        history.replace(`${streamDataRoute}/select/reviews/select-source`);
      }
    },
    [history, streamDataRoute]
  );

  const getProjectTasksHandler = useCallback(async () => {
    if (featureFlags.new_scrapers) {
      // getProjectTasksHandler for new scrapers

      const repeatingTasks = await getScrapersV2RepeatingTasks();

      const projectId = projectData.projectId;

      const projectRepeatingTasks = repeatingTasks.filter(
        task => (task.config.project_id == projectId)
      );

      // mark: not sure why this is returning multiple entries for the project_id
      // for now we can just arbitrarily select the first repeating task.
      // but we may need to look into this more carefully
      const firstProjectRepeatingTask = projectRepeatingTasks[0];

      // sample new format output:
      //
      //
      // {
      //     "config": {
      //         "project_id": "prvtfjcw",
      //         "url_list": [
      //             "https://www.bestbuy.com/site/microsoft-xbox-series-x-1tb-console-carbon-black/6428324.p?skuId=6428324&extStoreId=596https://pisces.bbystatic.com/image2/BestBuy_US/dam/6428324_s_GMC_DER-29d3f72e-b0a0-45dc-b8c6-ee4d79a1fac9.jpg&utm_source=feed&ref=212&loc=20510845600&gad_source=1&gclid=Cj0KCQjwz7C2BhDkARIsAA_SZKZrcI_imGkQkz_QYr-U_mM05hb0eBVwjU9QDWwhLy00JkWv0mgBqXEaAootEALw_wcB&gclsrc=aw.ds"
      //         ],
      //         "should_notify": false,
      //         "skip_sentiment": true,
      //         "api_base": "https://daylight.luminoso.com"
      //     },
      //     "repeating_task_id": "rt-decd3aab-8cad-4c76-9aa3-de81655cd301",
      //     "last_run_time": "2024-12-22T20:55:20.309000",
      //     "repeat_interval": 86400
      // }

      // sample old format output:
      //
      // const projectTasks = [
      //   {
      //     _id: 'hxkpodgd',
      //     lumi_api_url: 'https:daylight.luminoso.com/api/v5/',
      //     project_id: 'prmtpxfw',
      //     token: 'CCEbequysy6TVtmdEngFZF6XP0_GPSHz',
      //     task_type: 'reviews',
      //     url_list: [
      //       'https:www.costco.com/hp-pavilion-plus-16%22-laptop-intel-evo-platform-powered-by-intel-core-ultra-7-155h---nvidia-geforce-rtx-4050---windows-11.product.4000260610.html',
      //       'https:www.bestbuy.com/site/samsung-galaxy-book4-edge-copilot-pc-16-amoled-touch-screen-laptop-snapdragon-x-elite-3-4ghz-16gb-memory-512gb-storage-sapphire-blue/6583788.p?skuId=6583788'
      //     ],
      //     status: 'Starting',
      //     repeat: true,
      //     repeat_interval: 'day',
      //     notify: false,
      //     skip_sentiment: true,
      //     create_time: 1734292308.266461
      //   }
      // ];

      // convert to old format output which the frontend code is based on

      // console.log("firstProjectRepeatingTask");
      // console.log(firstProjectRepeatingTask);

      const isRedditUrl = url => /reddit*/.test(url);
      const task_type = firstProjectRepeatingTask.config.url_list.some(url =>
        isRedditUrl(url)
      )
        ? 'reddit'
        : 'reviews';
        
      // console.log("task_type");
      // console.log(task_type);

      const convertedTask = {
        lumi_api_url: firstProjectRepeatingTask.config.api_base,
        project_id: firstProjectRepeatingTask.config.project_id,
        task_type: task_type,
        url_list: firstProjectRepeatingTask.config.url_list,
        repeat: true,
        // mark
        repeat_interval:
          firstProjectRepeatingTask.repeat_interval == 86400 ? 'day' : 'week',
        notify: firstProjectRepeatingTask.config.should_notify,
        skip_sentiment: firstProjectRepeatingTask.config.skip_sentiment
      };

      const projectTasks = [convertedTask];

      setProjectTasksList(projectTasks);
      if (projectTasks.length) {
        const projectTask = projectTasks[0];
        projectTasksHandler(projectTask);
      }
    } else {
      // getProjectTasksHandler for legacy scrapers system
      const projectTasks = await getProjectTasks(project.project_id);

      setProjectTasksList(projectTasks);
      if (projectTasks.length) {
        const projectTask = projectTasks[0];
        projectTasksHandler(projectTask);
      }
    }
  }, [project?.project_id, projectTasksHandler]);

  useEffect(() => {
    if (project?.project_id) {
      void getProjectTasksHandler();
    }
  }, [getProjectTasksHandler, project?.project_id]);

  useEffect(() => {
    if (projectTasksList.length && location.pathname === streamDataRoute) {
      const projectTask = projectTasksList[0];
      if (projectTask.url_list) projectTasksHandler(projectTask);
    }
  }, [
    location.pathname,
    projectTasksHandler,
    projectTasksList,
    streamDataRoute
  ]);

  useEffect(() => {
    if (alertType && alertMessage) {
      setAlertState({
        type: alertType,
        message: alertMessage
      });
    }
    void getProjectTasksHandler();
  }, [alertMessage, alertType, getProjectTasksHandler]);

  useEffect(() => {
    if (alertState.message) {
      setIsAlertOpen(true);
    }
    const closeAlertTimeout = setTimeout(() => {
      closeAlertHandler();
    }, 3000);
    return () => {
      clearTimeout(closeAlertTimeout);
    };
  }, [alertState.message]);

  return (
    <>
      <Alert
        css={css`
          position: absolute;
          top: 10%;
          left: 50%;
          transform: translateX(-50%);
        `}
        showing={isAlertOpen}
        cancellable={true}
        disappearing={true}
        onHide={closeAlertHandler}
        type={AlertTypes[alertState.type.toUpperCase()]}
      >
        {alertState.message}
      </Alert>

      <Switch>
        <Route exact path={streamDataRoute}>
          <div
            css={css`
              box-sizing: border-box;
              width: 100%;
              margin-top: 1rem;

              * {
                box-sizing: border-box;
              }
            `}
          >
            {projectTasksList.length ||
            streamDataRoute !== RoutePatterns.UPLOAD_STREAM_DATA ? (
              <></>
            ) : (
              <FlexibleGrid minColumnWidth="25rem" gap="medium">
                <Reddit
                  openReddit={() =>
                    history.push(`${streamDataRoute}/select/reddit/input-urls`)
                  }
                />
                <Reviews
                  openReviews={() =>
                    history.push(
                      `${streamDataRoute}/select/reviews/select-source`
                    )
                  }
                />
                <HRSites
                  openHRSites={() =>
                    history.push(
                      `${streamDataRoute}/select/hr-sites/urls-input`
                    )
                  }
                />
                {featureFlags.stream_data_test_category && (
                  <TestCategory
                    openTestCategory={() =>
                      history.push(`${streamDataRoute}/select/test/urls-input`)
                    }
                  />
                )}
                {/*<Surveys />*/}
              </FlexibleGrid>
            )}
          </div>
        </Route>
        <Route path={`${streamDataRoute}/select/reddit`}>
          <RedditPage
            repeatInterval={redditRepeatInterval}
            setRepeat={setRedditRepeat}
            setRepeatInterval={setRedditRepeatInterval}
            repeat={redditRepeat}
            inputs={redditInputs}
            setInputs={setRedditInputs}
            projectData={projectData}
            onAlert={alertHandler}
            initialInputs={initialRedditInputs}
            setProjectId={setProjectId}
            projectTasks={projectTasksList}
          />
        </Route>
        <Route path={`${streamDataRoute}/select/reviews/`}>
          <ReviewsPage
            sourcesInputs={reviewsSourcesInputs}
            setSourcesInputs={setReviewsSourcesInputs}
            projectData={projectData}
            onAlert={alertHandler}
            inputs={reviewInputs}
            setInputs={setReviewInputs}
            setRepeat={setReviewsRepeat}
            repeat={reviewsRepeat}
            repeatInterval={reviewsRepeatInterval}
            setRepeatInterval={setReviewsRepeatInterval}
            setProjectId={setProjectId}
            projectTasks={projectTasksList}
            preselectedSources={preseletedReviewSources}
          />
        </Route>
        <Route path={`${streamDataRoute}/select/hr-sites`}>
          <HRSitesPage
            sourcesInputs={reviewsSourcesInputs}
            setSourcesInputs={setReviewsSourcesInputs}
            projectData={projectData}
            onAlert={alertHandler}
            inputs={hrSitesInputs}
            setInputs={setHRSitesInputs}
            setRepeat={setHRSitesRepeat}
            repeat={hrSitesRepeat}
            repeatInterval={hrSitesRepeatInterval}
            setRepeatInterval={setHRSitesRepeatInterval}
            projectTasks={projectTasksList}
            setProjectId={setProjectId}
          />
        </Route>
        <Route path={`${streamDataRoute}/select/test`}>
          <TestCategoryPage
            repeatInterval={testRepeatInterval}
            setRepeat={setTestRepeat}
            setRepeatInterval={setTestRepeatInterval}
            repeat={testRepeat}
            inputs={testInputs}
            setInputs={setTestInputs}
            projectData={projectData}
            onAlert={alertHandler}
            initialInputs={initialTestInputs}
            setProjectId={setProjectId}
            projectTasks={projectTasksList}
          />
        </Route>
      </Switch>
    </>
  );
};

export default DisplayStreamData;
