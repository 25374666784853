import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { ACTIVE_CONCEPT_COLORS, COLORS } from '../constants';
import ColoredCircle from './ColoredCircle';
import { Colors, Mixins } from '../styles';
import { Icon, IconTypes } from './icons';
import { useDispatchUndoBanner } from '../UndoBanner';
import { recolorActiveConcepts } from '../actions';

const COLOR_OPTIONS = Object.entries(ACTIVE_CONCEPT_COLORS).map(
  ([label, value]) => ({ label, value })
);

export function useRecolorConcepts() {
  const { projectId } = useParams();
  const dispatchUndoBanner = useDispatchUndoBanner();
  return useCallback(
    (concepts, color) => {
      const updated = concepts.map(concept => concept.update({ color }));

      recolorActiveConcepts(projectId, updated).then(() => {
        const colorLabel =
          _.findKey(ACTIVE_CONCEPT_COLORS, c => c === color) || color;
        const message =
          updated.length > 1
            ? `Changed  ${updated.length} concepts’ colors to ${colorLabel}`
            : `Changed one concept’s color to ${colorLabel}`;
        dispatchUndoBanner({
          message,
          onUndo: () => recolorActiveConcepts(projectId, concepts)
        });
      });
    },
    [dispatchUndoBanner, projectId]
  );
}

export function ColorDropdown({
  color,
  onChangeColor,
  menuPlacement = 'bottom',
  emptyValueLabel,
  disabled = false,
  IsCustomColor,
  customColors=[]
}) {
  const mergedColors = mergeUniqueColors(COLOR_OPTIONS, customColors);

  const options = emptyValueLabel
    ? [
        { label: emptyValueLabel, value: null, isDisabled: true },
        ...mergedColors,
      ]
    : mergedColors;

  const value = options.find(({ value }) => value === color);
  const onChange = ({ value }) => onChangeColor(value);

  return (
    <Select
      aria-label="Choose a color"
      isSearchable={false}
      menuPlacement={menuPlacement}
      components={{
        Control,
        Option,
        ValueContainer,
        SingleValue,
        IndicatorsContainer,
        IndicatorSeparator,
        DropdownIndicator
      }}
      options={options}
      value={IsCustomColor ? null : value}
      onChange={onChange}
      isDisabled={disabled}
    />
  );
}

ColorDropdown.propTypes = {
  color: PropTypes.oneOf(COLORS),
  onChangeColor: PropTypes.func.isRequired,
  /** Direction menu is opened in. Defaults to bottom */
  menuPlacement: PropTypes.oneOf(['top', 'bottom'])
};

/** Styles needed to override the default react-select height and to allow the
 *  dropdown to fill its container's height. This is necessary because the
 *  default react-select height is taller than our normal dropdowns and buttons
 */
const heightStyles = css`
  min-height: 2rem !important;
  height: 100%;
`;

const Control = props => {
  return (
    <components.Control
      {...props}
      css={css`
        /* Imitate Dropdown styles */
        ${heightStyles};
        ${Mixins.shadowOutset};
        border: none !important;
        * {
          cursor: pointer;
        }
        /* The Select component renders a hidden input, which was getting the
           height we apply to input elements globally, which was messing the
           height of the dropdown. This fixes the issue. */
        input {
          height: unset;
          margin: 0;
        }
      `}
    />
  );
};

const IndicatorsContainer = props => (
  <components.IndicatorsContainer {...props} css={heightStyles} />
);

const IndicatorSeparator = () => null;

const DropdownIndicator = ({ isDisabled, selectProps }) => {
  const { menuIsOpen, menuPlacement } = selectProps;
  const chevronUp = menuPlacement === 'top' ? !menuIsOpen : menuIsOpen;

  return (
    <Icon
      type={IconTypes.CARET_STROKE}
      direction={chevronUp ? 'up' : 'down'}
      theme={isDisabled ? 'disabled' : 'primary'}
      size="0.875rem"
      css={css`
        margin-right: 0.5rem;
      `}
    />
  );
};

const LabeledColor = ({ data, className, innerProps, innerRef }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      css={css`
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
      `}
      className={className}
    >
      <ColoredCircle color={data.value ?? ACTIVE_CONCEPT_COLORS.Pink} />
      {data.label}
    </div>
  );
};

const ValueContainer = props => (
  <components.ValueContainer {...props} css={heightStyles} />
);

const SingleValue = ({ data, ...props }) => {
  return (
    <components.SingleValue data={data} {...props}>
      <LabeledColor data={data} />
    </components.SingleValue>
  );
};

const Option = ({ data, ...props }) =>
  data.value ? (
    <LabeledColor
      data={data}
      {...props}
      css={css`
        padding: 0.5rem;
        background: ${props.isFocused ? Colors.blue0 : 'transparent'};
        &:hover {
          background: ${Colors.blue0};
        }
      `}
    />
  ) : null;

const mergeUniqueColors = (colorOptions, customColors) => {
  const colorMap = new Map();

  colorOptions.forEach((color) => {
    colorMap.set(color.value, color);
  });

  customColors.forEach((color) => {
    if (!colorMap.has(color.value)) {
      colorMap.set(color.value, color);
    }
  });

  return Array.from(colorMap.values());
};

