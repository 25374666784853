import _ from 'lodash';

import ConceptCloud from './ConceptCloud';
import { comparisonColorScheme, naturalColorScheme } from './colorer';

let cloud = null;
let props = null;

const makeColorScheme = function ({ colorConcepts, selection, hovered }) {
  const comparisonConcept = hovered ?? selection;

  if (!_.isEmpty(comparisonConcept?.vectors)) {
    return comparisonColorScheme(comparisonConcept);
  } else {
    return naturalColorScheme(colorConcepts);
  }
};

export default {
  create(selector, _props) {
    if (cloud) {
      throw new Error(
        'A cloud already exists. Make sure that multiple components are not calling .create()'
      );
    }

    props = _props;

    const colorScheme = makeColorScheme(props);
    cloud = new ConceptCloud(selector, colorScheme, {
      projectId: props.projectId,
      isSentimentReady: props.isSentimentReady,
      filter: props.filter,
      selection: props.selection,
      resetAxes: props.resetAxes,
      hideAxisLabels: props.hideAxisLabels,
      distortion: 5,
      setHovered: props.setHovered,
      xAxisConcept: props.xAxisConcept,
      yAxisConcept: props.yAxisConcept,
      fontSizeDomain: props.fontSizeDomain
    });

    cloud.loaded(props.conceptsToVisualize);
  },

  destroy() {
    cloud = null;
    props = null;
  },

  update(nextProps) {
    if (cloud) {
      const prevProps = props;
      props = nextProps;

      cloud.updateProps(props);

      const changedProps = _.mapValues(
        props,
        (prop, key) => prop !== prevProps[key]
      );

      changedProps.conceptsToVisualize = !_.isEqual(
        props.conceptsToVisualize,
        prevProps.conceptsToVisualize
      );

      changedProps.fontSizeDomain = !_.isEqual(
        props.fontSizeDomain,
        prevProps.fontSizeDomain
      );

      if (
        changedProps.colorConcepts ||
        changedProps.hovered ||
        changedProps.selection
      ) {
        cloud.useColorScheme(makeColorScheme(props));
      }

      if (changedProps.conceptsToVisualize || changedProps.fontSizeDomain) {
        cloud.loaded(props.conceptsToVisualize);
      }

      if (changedProps.xAxisConcept || changedProps.yAxisConcept) {
        if (!cloud.currentlyDragging) {
          cloud.setDefaultAxisVectors();
          cloud.setXAxisByConcept(props.xAxisConcept);
          cloud.setYAxisByConcept(props.yAxisConcept);
          cloud.startLayout();
        }
      }
    }
  },

  reflow() {
    if (cloud) {
      cloud.startLayout();
    }
  },

  resetAxes() {
    if (cloud) {
      cloud.setDefaultAxisVectors();
      cloud.startLayout();
    }
  },

  resize() {
    if (cloud) {
      cloud.onResize();
    }
  }
};
