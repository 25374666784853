import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_MESSAGE_TYPE, CHAT_MESSAGE_AUTHORS } from '../../../../types';

const askToProvideFeedback = (set, get) => () => {
  const message = i18next.t('feedback.message');
  const fakeFeedbackReply = {
    id: uuidv4(),
    content: message,
    role: CHAT_MESSAGE_AUTHORS.ASSISSTANT,
    type: CHAT_MESSAGE_TYPE.FEEDBACK,
    created_at: new Date().toISOString(),
  };
  const messages = get().activeChat.messages;
  if (messages.length > 2) {
    set((state) => ({
      ...state,
      activeChat: {
        ...state.activeChat,
        isAwaitingForFeedback: true,
        isFeedbackSent: false,
        messages: [...messages, fakeFeedbackReply],
      },
    }));
    return;
  }
  set((state) => ({
    ...state,
    activeChat: {
      ...state.activeChat,
      isFeedbackSent: true,
    },
  }));
};

export default askToProvideFeedback;
