import React from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { Title } from './components';
import { Card } from '../components/Card';
import { IgnoreAssertions } from './scienceAssertionsSection/IgnoreSection/IgnoreAssertions';
import { ConceptCountProvider } from './scienceAssertionsSection/ConceptCountProvider';
import { MergeAssertions } from './scienceAssertionsSection/MergeSection/MergeAssertions';
import { useFetch } from '../utils/hooks';
import { getFilterInfo } from '../utils/ApiUtilsV5';
import { NoticeAssertions } from './scienceAssertionsSection/NoticeSection/NoticeAssertions';
import { CollocateAssertions } from './scienceAssertionsSection/CollocateSection/CollocateAssertions';

export const ScienceAssertionsSection = () => {
  const { projectId } = useParams();

  const { response } = useFetch(getFilterInfo, projectId);
  const topConcepts = response ? response.concepts.map(c => c.name) : [];

  return (
    <ConceptCountProvider>
      <Card data-test-id="science-assertions-section">
        <ScienceAssertionsTitle />
        <Card.Separator />
        <IgnoreAssertions topConcepts={topConcepts} />
        <Card.Separator />
        <MergeAssertions topConcepts={topConcepts} />
        <Card.Separator />
        <NoticeAssertions />
        <Card.Separator />
        <CollocateAssertions />
      </Card>
    </ConceptCountProvider>
  );
};

const ScienceAssertionsTitle = () => (
  <>
    <Card.Section
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Title>Science assertions</Title>

      {/* The import assertions button, can be enabled when relevant. */}

      {/* <Button */}
      {/*   css={css` */}
      {/*     margin-left: auto; */}
      {/*   `} */}
      {/* > */}
      {/*   <Icon type={IconTypes.DOWNLOAD} /> */}
      {/*   Import */}
      {/* </Button> */}
    </Card.Section>
  </>
);
