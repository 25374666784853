import React, { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useSearchParams } from '../search_params';
import { BubbleRadio } from '../components/core/Radio';
import Dropdown from '../components/core/Dropdown';
import { StoreContext } from '../StoreContext';

export default function GalaxyColorBySelection({ style }) {
  const { searchParams, updateSearch } = useSearchParams();
  const { sharedConceptLists } = useContext(StoreContext);

  const bubbleRadioName = style + ' color by';

  return (
    <>
      <BubbleRadio
        name={bubbleRadioName}
        label="Conversation clusters"
        checked={searchParams.color_by === 'clusters'}
        onChange={() => {
          updateSearch({ color_by: 'clusters' });
        }}
      />
      <BubbleRadio
        name={bubbleRadioName}
        label="Active concepts"
        checked={searchParams.color_by === 'active_concepts'}
        onChange={() => {
          updateSearch({ color_by: 'active_concepts' });
        }}
      />
      <BubbleRadio
        disabled={sharedConceptLists.length === 0}
        name={bubbleRadioName}
        label="Concept lists"
        checked={searchParams.color_by === 'concept_lists'}
        onChange={() => {
          updateSearch({
            color_by: 'concept_lists',
            color_by_list:
              searchParams.color_by_list ??
              sharedConceptLists[0].concept_list_id
          });
        }}
      />

      {sharedConceptLists.length !== 0 && (
        <DropdownContainer footer={style}>
          <Dropdown
            {...dropdownCss(style)}
            value={searchParams.color_by_list}
            onChange={event =>
              updateSearch({ color_by_list: event.target.value })
            }
            disabled={searchParams.color_by !== 'concept_lists'}
            aria-label="Color by concept list"
          >
            {sharedConceptLists?.map(conceptList => (
              <option
                key={conceptList.name}
                value={conceptList.concept_list_id}
              >
                {conceptList.name}
              </option>
            ))}
          </Dropdown>
        </DropdownContainer>
      )}
    </>
  );
}

const DropdownContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  margin-top: ${props => (props.style === 'footer' ? '0.5rem' : '0.625rem')};
  margin-left: ${props => (props.style === 'footer' ? '0.5rem' : '2rem')};
`;

const dropdownCss = style =>
  style === 'sidePanel'
    ? {
        containerCss: css`
          flex: 1;
          margin-right: 1rem;
        `,
        css: css`
          width: 100%;
        `
      }
    : {};
