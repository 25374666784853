import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import SendMessageIcon from '../../../../assets/images/send-message.ico.png';
import { Input } from './styled';
import SendMessageBtn from '../../../../components/atoms/Button/SendMessage';
import { useAssistantStore } from '../../store';

// const ChatInput = ({ onSubmit, disabled }) => {
const ChatInput = ({ onSubmit }) => {
  const [userInput, changeUserInput] = useState('');

  const store = useAssistantStore((store) => store);
  // console.log('store');
  // console.log(store);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (userInput.length) {
      onSubmit(userInput);
      changeUserInput('');
    }
  };

  const handleChange = (event) => {
    changeUserInput(event.target.value);
  };

  // console.log('inside ChatInput');
  // console.log(chatbotStatus);
  // console.log(chatbotKnowledgeBaseId);

  // const chatbotStatus = 'no_exist';
  const chatbotStatus = store.chatbotStatus;

  const placeholder =
    chatbotStatus === 'ready'
      ? 'Hello! How may I help you?'
      : chatbotStatus === 'building'
        ? 'chatbot data is building. please wait.'
        : 'no chatbot data found. please build chatbot data first';

  const disabled = chatbotStatus !== 'ready';

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      component="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <Input
        type="text"
        autoFocus
        disabled={disabled}
        value={userInput}
        onChange={handleChange}
        /* placeholder="Hello! How may I help you?" */
        placeholder={placeholder}
        autoComplete="off"
      />
      <SendMessageBtn
        variant="extended"
        color="primary"
        type="submit"
        disabled={disabled || !userInput.length}
      >
        <img src={SendMessageIcon} width="24" height="24" />
      </SendMessageBtn>
    </Grid>
  );
};

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ChatInput;
