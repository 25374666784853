import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

import { Colors } from '../../../styles';
import { Button } from '../../../components/core/Button';

const ReviewsUrlInput = ({ input, inputs, onInputChange, onInputRemove }) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, input.value]);

  const handleChange = e => {
    setCursor(e.target.selectionStart);
    onInputChange && onInputChange(e);
  };

  return (
    <div>
      <div
        css={css`
          display: flex;
          gap: 20px;
          align-items: center;
        `}
      >
        <input
          ref={ref}
          css={css`
            width: 100%;
            outline: ${input.error ? `${Colors.red4} 1px solid` : 'none'};
            border-left: ${input.error ? `${Colors.red4} 1px solid` : 'none'};
          `}
          name={input.name}
          placeholder="Type your Reviews URL or ID here..."
          onChange={handleChange}
          value={input.value}
          type="text"
          required={true}
        />
        <Button
          onClick={onInputRemove.bind(null, input.name)}
          palette="red"
          css={css`
            height: 35px;
            visibility: ${inputs.length <= 1 ? 'hidden' : 'visible'};
          `}
        >
          Remove
        </Button>
      </div>
      <p
        css={css`
          color: ${Colors.red4};
          font-style: italic;
          font-size: 14px;
        `}
      >
        {input.error && `* ${input.error}`}
      </p>
    </div>
  );
};

export default ReviewsUrlInput;
