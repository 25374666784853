import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';

import { StoreContext } from '../../StoreContext';
import {
  OverlayAlert,
  useOverlayAlertController
} from '../../components/core/Alert';
import Dropdown from '../../components/core/Dropdown';
import {
  getSharedViews,
  openConceptList,
  restoreActiveConceptList, updateSelectedView
} from '../../actions';
import { useCurrentView, View, viewsAreEqual } from './view';
import { useActiveConceptListHasUnsharedChanges } from './UnsharedChangesAlert';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { naturalSortByName } from '../../utils/NaturalSort';
import { useSearchParams } from '../../search_params';
import ExpandableNote from '../ExpandableNote';

export function SharedViewLoader({ onLoadView }) {
  const { sharedViews, activeConceptListName, activeConcepts } =
    useContext(StoreContext);
  const history = useHistory();
  const { searchParams } = useSearchParams();
  const { projectId, workspaceId } = useParams();
  const alertController = useOverlayAlertController();
  const activeConceptListHasUnsharedChanges =
    useActiveConceptListHasUnsharedChanges();
  const [selectedView, setSelectedView] = useSelectedView();
  const dispatchUndoBanner = useDispatchUndoBanner();
  const existingView = _.find(sharedViews, { name: selectedView });

  useEffect(() => {

      updateSelectedView(existingView);

  }, [existingView]);

  function loadView(viewToLoad) {
    const name = viewToLoad.view.name;
    const currentURL = history.location;

    const updateURL = () => {
      const url = viewToLoad.toURL(projectId, workspaceId, searchParams);
      history.push(url);
      alertController.showSuccess(`Successfully loaded "${name}"`);
      setSelectedView(name);
    };

    const showUndoBanner = () => {
      let message = `Replaced view with "${name}"`;
      const trackingItem = 'undo-load-view';

      const undoViewLoad = () => {
        history.replace(currentURL);
        onLoadView();
        return Promise.resolve();
      };

      if (conceptListId && activeConceptListHasUnsharedChanges) {
        message += '\nThis also overwrote your unsaved active concepts';
        dispatchUndoBanner({
          message,
          onUndo: () =>
            restoreActiveConceptList(
              projectId,
              activeConceptListName,
              activeConcepts
            ).then(undoViewLoad),
          trackingItem
        });
      } else {
        dispatchUndoBanner({
          message,
          onUndo: undoViewLoad,
          trackingItem
        });
      }
    };

    const conceptListId = viewToLoad?.conceptListId;
    if (conceptListId) {
      openConceptList(projectId, conceptListId)
        .then(updateURL)
        .then(onLoadView)
        .then(showUndoBanner);
    } else {
      updateURL();
      onLoadView();
      showUndoBanner();
    }
  }

  if (_.isEmpty(sharedViews)) {
    return null;
  }

  const viewsList = Object.values(sharedViews).sort(naturalSortByName);
  const containerStyles = css`
    width: 100%;
    margin-bottom: 0.5rem;
  `;

  return (
    <>
      <OverlayAlert
        controller={alertController}
        css={containerStyles}
        cancellable
      >
        <Dropdown
          value={selectedView}
          aria-label="Load a shared view"
          onChange={event => {
            const name = event.target.value;
            getSharedViews(projectId).then(sharedViews => {
              const viewObject = _.find(sharedViews, { name });
              if (!viewObject) {
                alertController.showWarning(
                  'Unable to load shared view. This view may have been deleted.'
                );
                return;
              }

              loadView(new View(viewObject));
            });
          }}
          containerCss={containerStyles}
          data-tracking-item="shared-views_load-view-dropdown"
        >
          <option value="" disabled>
            Select a view ({viewsList.length})
          </option>
          {viewsList.map(sharedView => (
            <option key={sharedView.shared_view_id}>{sharedView.name}</option>
          ))}
        </Dropdown>
      </OverlayAlert>
      {existingView?.note && <ExpandableNote content={existingView.note} />}
    </>
  );
}

function useSelectedView() {
  const { sharedViews } = useContext(StoreContext);
  const currentView = useCurrentView();
  const [lastSelectedViewName, setLastSelectedViewName] = useState('');

  const lastSelectedView = _.find(sharedViews, { name: lastSelectedViewName });
  const matchingView = _.find(sharedViews, equalsCurrentView);

  // If the view corresponding to the last view the user loaded matches the
  // current view, use the name of that view (instead of the first view matching
  // the current view).
  let selectedViewName = '';
  if (equalsCurrentView(lastSelectedView)) {
    selectedViewName = lastSelectedView.name;
  } else if (matchingView) {
    selectedViewName = matchingView.name;
  }

  return [selectedViewName, setLastSelectedViewName];

  function equalsCurrentView(sharedView) {
    const sharedViewContent = _.omit(
      sharedView,
      'name',
      'note',
      'shared_view_id'
    );
    return viewsAreEqual(currentView.view, sharedViewContent);
  }
}
