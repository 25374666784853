import _ from 'lodash';
import { css } from '@emotion/react';
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import { Colors } from '../../styles';
import { useUniqueId } from '../../utils/hooks';
import { StoreContext } from '../../StoreContext';
import Dropdown from '../../components/core/Dropdown';
import { Button } from '../../components/core/Button';
import {
  UnsharedChangesAlert,
  useActiveConceptListHasUnsharedChanges
} from './UnsharedChangesAlert';
import { useCurrentView } from './view';
import { naturalSortByName } from '../../utils/NaturalSort';

export function ViewSelector({ onSelectView, closePopover, onSelectViewNote }) {
  const activeConceptListHasUnsharedChanges =
    useActiveConceptListHasUnsharedChanges();
  const currentView = useCurrentView();
  const canSaveCurrentView = !(
    activeConceptListHasUnsharedChanges && currentView.dependsOnConceptList
  );
  return (
    <>
      {!canSaveCurrentView && (
        <UnsharedChangesAlert closePopover={closePopover} />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0.5rem;
        `}
      >
        <ViewNameInput
          onSelectView={onSelectView}
          canCreateView={canSaveCurrentView}
          onSelectViewNote={onSelectViewNote}
        />
        <GridLineSeparator numColumns={1} />
        <ViewsDropdown
          onSelectView={onSelectView}
          onSelectViewNote={onSelectViewNote}
        />
      </div>
    </>
  );
}

function GridLineSeparator({ numColumns }) {
  return [...Array(numColumns)].map((_, i) => {
    return (
      <hr
        key={i}
        css={css`
          border: none;
          border-bottom: 1px solid ${Colors.gray3};
          margin: 0 -1rem;
        `}
      />
    );
  });
}

function ViewNameInput({ onSelectView, canCreateView, onSelectViewNote }) {
  const formId = useUniqueId();
  const nameInputId = useUniqueId();
  const noteTextareaId = useUniqueId();
  const [name, setName] = useState('');
  const trimmedViewName = name.trim();
  const { sharedViews } = useContext(StoreContext);
  const creating = !_.some(sharedViews, { name: trimmedViewName });
  const [note, setNote] = useState('');

  // Render the form around the input, but not the label or the submit button in
  // order to return three separate elements. This is done so we can use a grid
  // to align "ViewNameInput" with "ViewsDropdown" (until subgrid is more widely
  // supported).
  const handleSubmit = event => {
    event.preventDefault();
    onSelectView(trimmedViewName);
    onSelectViewNote(note);
  };

  return (
    <Container>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        data-tracking-item="shared-views_create"
      >
        <Label htmlFor={nameInputId}>
          <LabelText>Name:</LabelText>
          <input
            id={nameInputId}
            type="text"
            value={name}
            onChange={event => setName(event.target.value)}
            placeholder="Type new name of view"
            autoFocus
            css={css`
              margin: 0;
              width: 100%;
            `}
          />
        </Label>
        <Label htmlFor={noteTextareaId}>
          <LabelText>Notes:</LabelText>
          <textarea
            id={noteTextareaId}
            value={note}
            onChange={event => setNote(event.target.value)}
            placeholder="Type new notes of view"
            css={css`
              margin: 0;
              width: 100%;
              height: 4rem;
            `}
          />
        </Label>
      </Form>
      <div
        css={css`
          height: 100%;
        `}
      >
        <Button
          form={formId}
          type="submit"
          disabled={trimmedViewName === '' || (creating && !canCreateView)}
        >
          {creating ? 'Create' : 'Edit'}
        </Button>
      </div>
    </Container>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LabelText = styled.span`
  min-width: 50px;
  font-weight: 700;
`;

function ViewsDropdown({ onSelectView, onSelectViewNote }) {
  const editDropdownId = useUniqueId();
  const { sharedViews } = useContext(StoreContext);
  const viewsList = Object.values(sharedViews).sort(naturalSortByName);
  const handleView = event => {
    onSelectView(event.target.value);
    const existingView = _.find(sharedViews, { name: event.target.value });
    if (typeof existingView?.note === 'string')
      onSelectViewNote(existingView?.note);
  };
  return (
    <GridContainer>
      <label htmlFor={editDropdownId}>
        <LabelText>Edit:</LabelText>
      </label>
      <Dropdown
        id={editDropdownId}
        value=""
        disabled={viewsList.length === 0}
        onChange={handleView}
        data-tracking-item="shared-views_edit-view-dropdown"
      >
        <option disabled value="">
          {viewsList.length === 0
            ? 'Select existing view'
            : `Select existing view (${viewsList.length})`}
        </option>
        {viewsList?.map(view => (
          <option key={view.shared_view_id} value={view.name}>
            {view.name}
          </option>
        ))}
      </Dropdown>
    </GridContainer>
  );
}

const GridContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  gap: 1rem;
`;
