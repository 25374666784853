import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import { HRSitesSourceSelect } from './HRSitesSourceSelect';
import { HRSitesInputData } from './HRSitesInputData';
import { RoutePatterns } from '../../../constants';
import { OptionsPage } from '../OptionsPage';
import {
  createProject,
  createReviewsTaskDashboard,
  deleteProject,
  removeProjectTask,
  createScrapersV2Task
} from '../../../utils/ApiUtilsV5';
import StreamSourceLayout from '../StreamSourceLayout';
import { TokenModal } from '../TokenModal';
import buildRoutePath from '../../../utils/buildRoutePath';
import { FeatureFlagsContext } from '../../../FeatureFlagsContext';

class SourceInput {
  constructor(id, inputs, setInputs, title, validation) {
    this.id = id;
    this.inputs = inputs;
    this.setInputs = setInputs;
    this.title = title;
    this.validation = validation;
  }
}

const hrSitesSources = [
  {
    id: 1,
    title: 'Glassdoor',
    selected: true,
    validation: /^(https?:\/\/)?(www\.)?glassdoor\.[a-z]{2,}\/?.*$/
  }
];

export const HRSitesPage = ({
  projectData,
  onAlert,
  sourcesInputs,
  setSourcesInputs,
  inputs,
  setInputs,
  repeat,
  setRepeat,
  repeatInterval,
  setRepeatInterval,
  setProjectId,
  projectTasks
}) => {
  const history = useHistory();
  const location = useLocation();

  const streamDataRoute = projectData?.projectId
    ? buildRoutePath(RoutePatterns.UPDATE_PROJECT_STREAM_DATA, {
        workspaceId: projectData.workspaceId,
        projectId: projectData.projectId
      })
    : RoutePatterns.UPLOAD_STREAM_DATA;

  const SELECT_SOURCE_PATHNAME = `${streamDataRoute}/select/hr-sites/select-source`;
  const URLS_INPUT_PATHNAME = `${streamDataRoute}/select/hr-sites/urls-input`;
  const SELECT_INTERVAL_PATHNAME = `${streamDataRoute}/select/hr-sites/select-interval`;

  const [step, setStep] = useState(2);
  const [sources, setSources] = useState(hrSitesSources);
  const [tokenModalIsOpen, setTokenModalIsOpen] = useState(false);

  const [errors, setErrors] = useState([]);

  const [requestInProcess, setRequestInProcess] = useState(false);

  const featureFlags = React.useContext(FeatureFlagsContext);

  const selectSource = title => {
    const sourcesWithSelected = sources.map(source => {
      if (source.title !== title) return source;

      source.selected = !source.selected;
      return source;
    });

    setSources(sourcesWithSelected);
  };

  const selectedSources = useMemo(
    () => sources.filter(source => source.selected),
    [sources]
  );

  const createProjectName = () => {
    const titles = selectedSources.map(source => source.title);
    return titles.join(' | ') + ' Scrape';
  };

  const successHandler = message => {
    onAlert({
      type: 'success',
      message
    });
    setTimeout(() => {
      window.location.replace(window.location.origin);
    }, 1000);
  };

  const errorHandler = message => {
    onAlert({
      type: 'error',
      message
    });
  };

  const warningHandler = message => {
    onAlert({
      type: 'warning',
      message
    });
  };

  const validateInputs = sourceInput => {
    const { inputs, setInputs, validation } = sourceInput;

    const validatedInputs = inputs.map(input => ({
      ...input,
      error: !validation.test(input.value) ? 'Please provide a valid URL.' : ''
    }));

    setInputs(validatedInputs);

    return !validatedInputs.some(({ error }) => error !== '');
  };

  let existingProjectId = projectData.projectId;

  const submitHRSitesTask = async (token, shouldNotify, skipSentiment) => {
    setRequestInProcess(true);

    try {
      if (!existingProjectId) {
        const createdProject = await createProject(
          projectData.workspaceId,
          projectData.name || createProjectName(),
          projectData.language,
          projectData.description
        );

        if (!createdProject) {
          throw new Error('Unexpected issue during project creation');
        }

        existingProjectId = createdProject.project_id;
      } else {
        await removeProjectTask(existingProjectId, projectTasks[0]._id);
      }

      const url_list = sourcesInputs
        .map(({ inputs }) => inputs.map(({ value }) => value))
        .flat();

      if (featureFlags.new_scrapers) {
        // use the new scrapers pipeline: glassdoor
        const taskResponse = await createScrapersV2Task(
          existingProjectId,
          url_list,
          repeat,
          repeatInterval,
          token,
          shouldNotify,
          skipSentiment
        );

        setRequestInProcess(false);
        successHandler(
          !projectData.projectId
            ? 'HR Sites task successfully created!'
            : 'HR Sites task successfully updated!'
        );
      } else {
        const taskResponse = await createReviewsTaskDashboard(
          existingProjectId,
          url_list,
          repeat,
          repeatInterval,
          token,
          shouldNotify,
          skipSentiment
        );
        if (
          taskResponse?.status_code === 200 &&
          taskResponse?.status === 'success'
        ) {
          setRequestInProcess(false);
          successHandler(
            !projectData.projectId
              ? 'HR Sites task successfully created!'
              : 'HR Sites task successfully updated!',
            existingProjectId
          );
        } else {
          throw new Error('Something went wrong!');
        }
      }
    } catch (e) {
      try {
        if (!projectData.projectId && existingProjectId)
          await deleteProject(existingProjectId);
      } catch (e) {
        console.error(e);
      }
      setRequestInProcess(false);
      errorHandler(e.message);
    }
  };

  const submitHandler = (shouldNotify, skipSentiment, token) => {
    if (token || !repeat) {
      void submitHRSitesTask(token, shouldNotify, skipSentiment);
    } else {
      setTokenModalIsOpen(true);
    }
  };

  const checkProjectData = useCallback(() => {
    setErrors([]);
    if (!projectData.language) {
      setErrors(prevState => [
        ...prevState,
        "Please, select the dataset's language to continue"
      ]);
    }
    if (!projectData.workspaceId) {
      setErrors(prevState => [
        ...prevState,
        'Please, select the workspace to continue'
      ]);
    }
  }, [projectData.language, projectData.workspaceId]);

  const inputsWithError = () =>
    sourcesInputs.filter(input => !validateInputs(input));

  const nextStepHandler = () => {
    if (step === 2 && inputsWithError().length > 0) return;

    setStep(prevState => prevState + 1);
  };

  const goBackHandler = () => {
    if (step === 2) history.push(streamDataRoute);
    setStep(prevState => prevState - 1);
  };

  useEffect(() => {
    const updatedSourcesInputs = selectedSources.map(source => {
      switch (source.title) {
        case 'Glassdoor':
          return new SourceInput(
            source.id,
            inputs.glassdoorInputs,
            setInputs.setGlassdoorInputs,
            source.title,
            source.validation
          );

        default:
          return new SourceInput(
            source.id,
            inputs.glassdoorInputs,
            setInputs.setGlassdoorInputs,
            source.title,
            source.validation
          );
      }
    });

    setSourcesInputs(updatedSourcesInputs);
  }, [selectedSources, inputs.glassdoorInputs]);

  useEffect(() => {
    if (step === 1 && location.pathname !== SELECT_SOURCE_PATHNAME)
      history.push(SELECT_SOURCE_PATHNAME);

    if (step === 2 && location.pathname !== URLS_INPUT_PATHNAME)
      history.push(URLS_INPUT_PATHNAME);

    if (step === 3 && location.pathname !== SELECT_INTERVAL_PATHNAME)
      history.push(SELECT_INTERVAL_PATHNAME);
  }, [history, step]);

  useEffect(() => {
    if (location.pathname === SELECT_SOURCE_PATHNAME) setStep(1);

    if (location.pathname === URLS_INPUT_PATHNAME && selectedSources?.length)
      setStep(2);

    if (
      location.pathname === SELECT_INTERVAL_PATHNAME &&
      sourcesInputs.length &&
      inputsWithError().length === 0
    )
      setStep(3);
  }, [location.pathname, selectedSources.length]);

  useEffect(() => {
    if (location.pathname === URLS_INPUT_PATHNAME && !selectedSources.length)
      setStep(1);
  }, [location.pathname, selectedSources.length]);

  return (
    <>
      <StreamSourceLayout onGoBack={goBackHandler}>
        <Switch>
          <Route exact path={`${streamDataRoute}/select/hr-sites`}>
            <Redirect to={SELECT_SOURCE_PATHNAME} />
          </Route>
          <Route path={SELECT_SOURCE_PATHNAME}>
            <HRSitesSourceSelect
              sources={sources}
              selectSource={selectSource}
              nextIsDisabled={!selectedSources.length}
              onNext={nextStepHandler}
            />
          </Route>

          <Route path={URLS_INPUT_PATHNAME}>
            <HRSitesInputData
              onWarning={warningHandler}
              sourcesInputs={sourcesInputs}
              errors={errors}
              checkProjectData={checkProjectData}
              onNext={nextStepHandler}
              projectData={projectData}
              onError={errorHandler}
              selectSource={selectSource}
              setInputs={setInputs}
            />
          </Route>

          <Route path={SELECT_INTERVAL_PATHNAME}>
            <OptionsPage
              isLoading={requestInProcess}
              submitHandler={submitHandler}
              repeat={repeat}
              setRepeat={setRepeat}
              setRepeatInterval={setRepeatInterval}
              repeatInterval={repeatInterval}
            />
          </Route>
        </Switch>
      </StreamSourceLayout>

      <TokenModal
        isOpen={tokenModalIsOpen}
        onClose={() => setTokenModalIsOpen(false)}
        onError={errorHandler}
        onSubmit={submitHRSitesTask}
      />
    </>
  );
};
