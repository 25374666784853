import _ from 'lodash';
import React, {
  useState,
  useRef,
  useReducer,
  useContext,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

import { showFilterInfoSpinner } from '../actions';
import {
  DiscreteConstraint,
  DateRangeConstraint,
  NumericRangeConstraint
} from '../classes/Constraints';
import {
  MetadataField,
  CategoricalField,
  DateField,
  NumericField,
  ScoreField
} from '../classes/MetadataFields';
import List from './core/List';
import FilterToolHeader from './FilterToolHeader';
import FilterSelectorCategorical from './FilterSelectorCategorical';
import FilterSelectorDate from './FilterSelectorDate';
import FilterSelectorNumeric from './FilterSelectorNumeric';
import { useFilter, useSetFilter } from '../search_params';
import { naturalSortByName } from '../utils/NaturalSort';
import { StoreContext } from '../StoreContext';

export default function FilterTool({
  metadata,
  onChangeFilter,
  enableSelectAllFields,
  enableClearAllFields
}) {
  const filter = useFilter();
  const setFilter = useSetFilter();
  const [editedFilter, setEditedFilter] = useState(filter);
  const filterRef = useRef(filter);
  const prevFilter = filterRef.current;
  filterRef.current = filter;
  const timer = useRef();
  const { selectedView } = useContext(StoreContext);
  if (filter !== prevFilter) {
    // If the filter in the application has changed, then the
    // value of editedFilter will need to be re-synced with it.
    setEditedFilter(filter);
  }

  const debounceFilterChange = nextFilter => {
    showFilterInfoSpinner();

    // Wait a moment before actually trying to apply the changes to the
    // filter.
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      timer.current = null;
      setFilter(nextFilter);
    }, 1000);
  };

  const updateFilter = constraint => {
    // Get rid of the old version of the corresponding constraint, if any exists
    const updatedFilter = editedFilter.filter(
      ({ name }) => constraint.name !== name
    );

    // Add the new version of the constraint, but only if it is not empty.
    if (!constraint.isEmpty()) {
      updatedFilter.push(constraint);
    }

    setEditedFilter(updatedFilter);
    if (onChangeFilter === undefined) {
      debounceFilterChange(updatedFilter);
    } else {
      onChangeFilter(updatedFilter);
    }
  };

  const selectAllFiltersHandler = () => {
    const updatedFilter = metadata.map(field => {
      switch (field.constructor) {
        case CategoricalField:
          return new DiscreteConstraint(
            field.name,
            field.values.map(value => value.value)
          );
        case DateField:
          return new DateRangeConstraint(
            field.name,
            field.minimum,
            field.maximum
          );
        case NumericField:
          return new DiscreteConstraint(
            field.name,
            field.values.map(value => value.value)
          );
        case ScoreField:
          return new DiscreteConstraint(
            field.name,
            field.values.map(value => value.value)
          );
      }
    });

    setEditedFilter(updatedFilter);
    if (onChangeFilter === undefined) {
      debounceFilterChange(updatedFilter);
    } else {
      onChangeFilter(updatedFilter);
    }
  };

  const fieldNames = metadata.map(field => field.name);
  const expandedFields = useExpanded(fieldNames, filter, selectedView);

  return (
    <div className="filter-tool">
      <FilterToolHeader
        expandedFields={expandedFields}
        disableClearFilter={_.isEmpty(onChangeFilter ? editedFilter : filter)}
        onClear={() => {
          if (onChangeFilter) {
            onChangeFilter([]);
            setEditedFilter([]);
          } else {
            setFilter([]);
          }
        }}
        onSelectAll={selectAllFiltersHandler}
      />
      <List
        className="filter-tool__body"
        hoverable={false}
        bordered={false}
        lined={false}
      >
        {[...metadata].sort(naturalSortByName).map(field => {
          const matchingConstraint = _.find(
            editedFilter,
            constraint => constraint.name === field.name
          );
          const expanded = expandedFields.includes(field.name);
          const toggleExpanded = () => {
            expandedFields.toggleField(field.name);
          };

          switch (field.constructor) {
            case CategoricalField:
              return (
                <FilterSelectorCategorical
                  key={field.name}
                  field={field}
                  constraint={
                    matchingConstraint || new DiscreteConstraint(field.name)
                  }
                  updateConstraint={valuesInFilter => {
                    updateFilter(
                      new DiscreteConstraint(field.name, valuesInFilter)
                    );
                  }}
                  enableSelectAllFields={enableSelectAllFields}
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              );

            case DateField:
              return (
                <FilterSelectorDate
                  key={field.name}
                  field={field}
                  constraint={
                    matchingConstraint || new DateRangeConstraint(field.name)
                  }
                  updateConstraint={(minimum, maximum) => {
                    updateFilter(
                      new DateRangeConstraint(field.name, minimum, maximum)
                    );
                  }}
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              );

            case NumericField:
            case ScoreField: {
              if (field.values.length !== 0 && field.values.length < 20) {
                const constraint =
                  matchingConstraint === undefined
                    ? new DiscreteConstraint(field.name)
                    : matchingConstraint instanceof DiscreteConstraint
                      ? matchingConstraint
                      : new DiscreteConstraint(
                          field.name,
                          matchingConstraint.values
                        );

                return (
                  <FilterSelectorCategorical
                    key={field.name}
                    field={field}
                    constraint={constraint}
                    updateConstraint={valuesInFilter => {
                      updateFilter(
                        new DiscreteConstraint(field.name, valuesInFilter)
                      );
                    }}
                    enableSelectAllFields={enableSelectAllFields}
                    expanded={expanded}
                    toggleExpanded={toggleExpanded}
                    enableClearAllFields={enableClearAllFields}
                  />
                );
              }

              return (
                <FilterSelectorNumeric
                  key={field.name}
                  field={field}
                  constraint={
                    matchingConstraint || new NumericRangeConstraint(field.name)
                  }
                  updateConstraint={(minimum, maximum) => {
                    updateFilter(
                      new NumericRangeConstraint(field.name, minimum, maximum)
                    );
                  }}
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              );
            }
          }
        })}
      </List>
    </div>
  );
}

FilterTool.propTypes = {
  enableSelectAllFields: PropTypes.bool,
  metadata: PropTypes.arrayOf(PropTypes.instanceOf(MetadataField)).isRequired,
  onChangeFilter: PropTypes.func
};

export function useExpanded(fieldNames, filter, selectedView) {
  // Build an object mapping the names of fields in the filter to `true` to use
  // as the initial state of the reducer. This will make it so we start with all
  // the fields in the initial filter expanded.
  const initialState = useMemo(() => {
    return _.fromPairs(filter.map(constraint => [constraint.name, true]));
  }, [filter]);

  const [expandedState, dispatch] = useReducer((expandedState, event) => {
    switch (event.type) {
      case 'toggleField':
        return {
          ...expandedState,
          [event.fieldName]: !expandedState[event.fieldName]
        };
      case 'expandAll':
        return _.fromPairs(fieldNames.map(fieldName => [fieldName, true]));
      case 'collapseAll':
        return _.fromPairs(fieldNames.map(fieldName => [fieldName, false]));
      case 'reset':
        if (selectedView) {
          return initialState;
        } else {
          // Update state to include new entries with true or retain existing state values
          const newState = { ...expandedState };
          filter.forEach(({ name }) => {
            if (!(name in expandedState)) {
              newState[name] = true;
            }
          });
          return newState;
        }

      default:
        throw new Error(
          'Programmer error - unexpected event type in useExpanded'
        );
    }
  }, initialState);

  useEffect(() => {
    // Reset expanded state when filter changes
    dispatch({ type: 'reset' });
  }, [filter]);

  const expanded = fieldNames.filter(fieldName => expandedState[fieldName]);

  return {
    any: expanded.length > 0,
    all: expanded.length === fieldNames.length,
    includes: fieldName => expandedState[fieldName] || false,
    toggleField: fieldName => dispatch({ type: 'toggleField', fieldName }),
    expandAll: () => dispatch({ type: 'expandAll' }),
    collapseAll: () => dispatch({ type: 'collapseAll' })
  };
}
