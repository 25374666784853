import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import UserMenu from './UserMenu';
import BreadcrumbNav from './BreadcrumbNav';
import { Mixins } from '../styles';
import { load } from '../../../lui/coffee/utils/localStorage';
import branding_data from '../../../brandings.json';

export default function Header({ username }) {
  const branding = load('branding');
  if (branding === undefined || branding === null) {
    return (
      <header
        css={css`
          overflow: visible;
          z-index: 1;
        `}
      >
        <HeaderBar>
          <BreadcrumbNav />
          <div
            css={css`
              flex: none;
            `}
          >
            {username && <UserMenu username={username} />}
          </div>
        </HeaderBar>
      </header>
    );
  } else {
    const user_menu = branding_data[`${branding}`].user_menu;
    if (user_menu) {
      return (
        <header
          css={css`
            overflow: visible;
            z-index: 1;
          `}
        >
          <HeaderBar>
            <BreadcrumbNav />
            <div
              css={css`
                flex: none;
              `}
            >
              <span>{username}</span>
            </div>
          </HeaderBar>
        </header>
      );
    } else {
      return (
        <header
          css={css`
            overflow: visible;
            z-index: 1;
          `}
        >
          <HeaderBar>
            <BreadcrumbNav />
          </HeaderBar>
        </header>
      );
    }
  }
}

Header.propTypes = {
  username: PropTypes.string
};

const HeaderBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${Mixins.shadowOutset}
  background: white;
  padding: 0.5rem 1.125rem;
  justify-content: space-between;
  align-items: center;
`;
