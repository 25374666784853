import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../../StoreContext';
import { Colors } from '../../styles';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import { Bold } from '../../components/Bold';
import { useSelections } from '../../utils/hooks';
import { Checkbox } from '../../components/core/Checkbox';
import { Button } from '../../components/core/Button';
import { updateActiveConcept } from '../../actions';
import { useFilter, useSearchParams } from '../../search_params';
import {
  getSentimentStatus,
  SENTIMENT_STATUS
} from '../../utils/sentimentStatus';

export const ConceptualMatchesTable = () => {
  const { conceptSearchResults } = useContext(StoreContext);
  const concepts = conceptSearchResults?.conceptual.concepts ?? [];
  const selections = useSelections(concepts, 'name');

  return (
    <Container>
      <ButtonsContainer>
        <UpdateTextButton selections={selections} type="Exclude" />
        <UpdateTextButton selections={selections} type="Include" />
      </ButtonsContainer>
      <HeaderContainer>
        <Bold>Conceptual matches</Bold>
        <MatchCount />
      </HeaderContainer>
      {concepts.length ? (
        <Table>
          <TableHeader />
          {concepts.map(concept => (
            <ConceptualMatchRow
              key={concept.hash()}
              concept={concept}
              selections={selections}
            />
          ))}
        </Table>
      ) : (
        <NoMatches>No conceptual matches found</NoMatches>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const UpdateTextButton = ({ selections, type }) => {
  const { projectId } = useParams();
  const { selection } = useContext(StoreContext);
  const filter = useFilter();

  const { project } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;

  let texts = selections.selections;
  if (type === 'Exclude') {
    texts = texts.map(text => `-${text}`);
  }

  const updateTexts = () => {
    const updated = selection.addTexts(texts.join(','));
    updateActiveConcept(projectId, updated, filter, true, isSentimentReady);
    selections.clear();
  };

  return (
    <Button
      flavor="subtle"
      padded={false}
      disabled={!selections.any}
      onClick={updateTexts}
    >
      {type}
    </Button>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const MatchCount = () => {
  const { searchParams } = useSearchParams();
  const { filterCount, selection, totalDocCount } = useContext(StoreContext);
  const count = selection.matchCount - selection.exactMatchCount;

  const percentageByTotal = searchParams.count_percentage === 'total';
  const matchCount = percentageByTotal ? totalDocCount : filterCount;

  return (
    <div>
      {thousandify(count)}{' '}
      <GrayText>({percentify(count, matchCount)})</GrayText>
    </div>
  );
};

const GrayText = styled.span`
  color: ${Colors.gray5};
`;

const borderStyle = `1px solid ${Colors.gray2}`;

const Table = styled.div`
  display: grid;
  width: 100%;
  text-align: left;
  font-size: 0.875rem;
  box-sizing: border-box;
  grid-template-columns: 1fr repeat(3, min-content);
  border-bottom: ${borderStyle};
  max-height: 30vh;
  overflow: auto;
`;

const HeaderCell = styled.div`
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  font-weight: bold;
  /* Make the header row not scrollable */
  position: sticky;
  align-self: start;
  top: 0;
  left: 0;
  /* Hide the table body as it scrolls past */
  background: white;
  z-index: 1;
`;

/* There are 4 columns, but only 3 headers, so have the third column take up the
 * last column's space as well. */
const TwoColumnHeaderCell = styled(HeaderCell)`
  grid-column-end: span 2;
`;

const TableHeader = () => (
  <>
    <HeaderCell>Concept</HeaderCell>
    <HeaderCell>Matches</HeaderCell>
    <TwoColumnHeaderCell>Association</TwoColumnHeaderCell>
  </>
);

const ConceptualMatchRow = ({ concept, selections }) => {
  const { selection } = useContext(StoreContext);
  return (
    <>
      <Cell border="left">{concept.name}</Cell>
      <Cell>{thousandify(concept.count)}</Cell>
      <Cell color={Colors.gray5}>
        {selection.getAssociation(concept).toFixed(2)}
      </Cell>
      <Cell border="right">
        <Checkbox
          aria-label={`Select "${concept.name}"`}
          checked={selections.includes(concept.name)}
          onChange={event => {
            const shiftClick = event.nativeEvent.shiftKey;
            selections.toggleRow(concept.name, shiftClick);
          }}
        />
      </Cell>
    </>
  );
};

const Cell = styled.div`
  padding: 0.25rem;
  border-${props => props.border ?? 'top'}: ${borderStyle};
  border-top: ${borderStyle};
  color: ${props => props.color ?? 'inherit'};
`;

const NoMatches = styled.span`
  color: ${Colors.gray5};
  font-style: italic;
`;
