import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

export const ActionButton = styled(ButtonBase)`
  padding: 0.25rem 0.75rem;
  margin-top: 0.5rem;
  border-radius: 0.375rem;
  background: rgba(85, 85, 85, 0.1);
  transition: opacity 0.25s ease-out;

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  &:disabled {
    opacity: 0.35;
  }
`;
