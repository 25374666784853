import React from 'react';
import PropTypes from 'prop-types';
import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { css } from '@emotion/react';

import Tooltip from '../../components/core/Tooltip';
import { Colors } from '../../styles';

const percentageScale = maxNumber => {
  return scaleLinear().domain([0, maxNumber]).range([0, 100]);
};

export default class VerticalBarChart extends React.PureComponent {
  render() {
    const {
      matchCounts,
      matchType,
      renderTooltipContent,
      tooltipPosition,
      color
    } = this.props;

    const yScale = percentageScale(
      max(matchCounts.map(bucket => bucket[matchType]))
    );

    /*
     * Divide the viewBox's width (100 units) evenly among the bars,
     * but add padding between them so that they are visually distinct.
     *
     * The padding coefficient of 30 was chosen after some trial and
     * error. The 100 in the denominator is assumed to be the point at
     * which the chart will stop being useful too.
     */
    const numberOfBars = matchCounts.length;
    const padding = 30 * (numberOfBars / 100);
    const barWidth = 100 / (numberOfBars + padding);

    return (
      <svg
        viewBox="0 0 100 100"
        className="vertical-bar-chart"
        preserveAspectRatio="none"
      >
        {matchCounts.map((normalizedBucket, index) => {
          const offset = (index / numberOfBars) * 100;

          return (
            <Tooltip
              key={index}
              position={tooltipPosition}
              forSVG
              anchor={
                <>
                  <HoverArea width={barWidth} offset={offset} />
                  {matchType === 'total' && (
                    <Bar
                      color={color}
                      height={yScale(normalizedBucket.total)}
                      width={barWidth}
                      offset={offset}
                    />
                  )}
                  <Bar
                    color={color}
                    height={yScale(normalizedBucket.exact)}
                    width={barWidth}
                    offset={offset}
                  />
                </>
              }
              delayed={false}
            >
              {renderTooltipContent(index)}
            </Tooltip>
          );
        })}
      </svg>
    );
  }
}

VerticalBarChart.propTypes = {
  matchCounts: PropTypes.arrayOf(
    PropTypes.shape({
      exact: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  ),
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired,
  renderTooltipContent: PropTypes.func.isRequired,
  tooltipPosition: PropTypes.string
};

VerticalBarChart.defaultProps = {
  tooltipPosition: 'above'
};

const Bar = ({ height, width, offset, color }) => {
  return (
    <rect
      css={css`
        fill: ${color ? `${color}80`: Colors.blue2};
        &:last-child {
            fill: ${color ? color : Colors.blue4};
        }
      `}
      width={`${width}%`}
      height={`${height}%`}
      x={`${offset}%`}
      y={`${100 - height}%`}
    />
  );
};

Bar.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  color: PropTypes.string
};

const HoverArea = ({ width, offset }) => {
  return (
    <rect
      width={width}
      x={offset}
      height="100%"
      fill="transparent"
      style={{ pointerEvents: 'all' }}
    />
  );
};

HoverArea.propTypes = {
  width: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired
};
