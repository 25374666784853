import React, { useContext } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { showSuccess } from './notifications';
import { Button } from '../components/core/Button';
import { Workspace, AuthContext, formatRoleName, Organization } from './model';
import SimpleModal from '../components/core/SimpleModal';
import {
  AuthForm,
  CancelButton,
  DropdownField,
  FIELD_WIDTH,
  SubmitButton
} from './Forms';
import { ButtonBar } from './ButtonBar';
import { Icon, IconTypes } from '../components/icons';

export default function EditUsersButton({ editable, selections }) {
  const [displayModal, setDisplayModal] = React.useState(false);
  if (!selections.any) {
    return null;
  }

  return (
    <>
      <Button flavor="subtle" onClick={() => setDisplayModal(true)}>
        <Icon type={IconTypes.PEN} />
        Edit
      </Button>
      <EditUsersModal
        showing={displayModal}
        hide={() => {
          setDisplayModal(false);
        }}
        editable={editable}
        selections={selections}
      />
    </>
  );
}

EditUsersButton.propTypes = {
  editable: PropTypes.oneOfType([
    PropTypes.instanceOf(Organization),
    PropTypes.instanceOf(Workspace)
  ]).isRequired,
  selections: PropTypes.object
};

function EditUsersModal({ showing, hide, selections, editable }) {
  const { workspaceRoles, organizationRoles } = useContext(AuthContext);
  let roles;
  if (editable.identifier === 'workspace') {
    roles = workspaceRoles;
  } else if (editable.identifier === 'organization') {
    roles = organizationRoles;
  }
  function editUsers(formData) {
    const role = formData.role;
    if (role == null) {
      return Promise.reject({
        fieldErrors: { role: ['Please select a role.'] }
      });
    }
    return editable.editUsers(selections.selections, role).then(() => {
      selections.clear();
      hide();
      showSuccess('Success!');
    });
  }
  return (
    <SimpleModal
      isOpen={showing}
      onHide={hide}
      css={css`
        width: ${FIELD_WIDTH}rem;
      `}
      header={`Edit the role of the selected ${
        selections.singular ? 'user' : 'users'
      } in the ${editable.identifier}`}
    >
      <AuthForm
        onSubmit={editUsers}
        defaults={{
          role: null
        }}
      >
        <DropdownField
          label="user role"
          field="role"
          options={roles.map(role => ({
            name: formatRoleName(role),
            value: role
          }))}
        />
        <ButtonBar>
          <SubmitButton>
            Edit {selections.singular ? 'user' : 'users'}
          </SubmitButton>
          <CancelButton onClick={hide}>Cancel</CancelButton>
        </ButtonBar>
      </AuthForm>
    </SimpleModal>
  );
}

EditUsersModal.propTypes = {
  showing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  selections: PropTypes.object.isRequired,
  editable: PropTypes.oneOfType([
    PropTypes.instanceOf(Organization),
    PropTypes.instanceOf(Workspace)
  ]).isRequired
};
