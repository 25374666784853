import React, { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Card } from '../components/Card';
import {
  ProjectDescription,
  ProjectDetail,
  ProjectName
} from '../components/ProjectDetail';
import {
  CopyProjectButton,
  UpdateDataStreamButton,
  UploadMoreButton
} from '../components/ProjectButtons';
import { objHasPerm } from '../utils/common';
import { StoreContext } from '../StoreContext';
import { Colors } from '../styles';

export const ProjectBasicsSection = () => {
  const { project } = useContext(StoreContext);

  return (
    <Card data-test-id="project-basics-section">
      <Card.Section>
        <ProjectName
          withoutWriteStyle={css`
            font-weight: bold;
            word-break: break-word;
            font-size: 1rem;
            display: inline;
          `}
          withWriteStyle={css`
            font-weight: bold;
            word-break: break-word;
            margin-left: -0.5rem;
          `}
        />
      </Card.Section>
      <Card.Separator />
      <div
        css={css`
          padding: 0.5rem;
        `}
      >
        <Card.Section>
          <ProjectDescription
            withoutWriteStyle={css`
              color: ${Colors.gray8};
              word-break: break-word;
              white-space: pre-wrap;
              padding: 0.5rem;
              margin: 0rem -0.5rem;
            `}
            withWriteStyle={css`
              color: ${Colors.gray8};
              word-break: break-word;
              textarea {
                width: 100%;
              }
              margin: 0rem -0.5rem;
            `}
          />
        </Card.Section>
        <Card.Section>
          <ProjectDetail
            showExtraInfo
            showScienceChangeLog
            baseStyle={css`
              p {
                margin: 0;
              }
            `}
          />
        </Card.Section>
        <Card.Section>
          <ButtonContainer>
            {objHasPerm(project, 'create') ? (
              <UploadMoreButton tooltipPosition="right" />
            ) : null}
            <UpdateDataStreamButton />
            <CopyProjectButton tooltipPosition="right" />
          </ButtonContainer>
        </Card.Section>
      </div>
    </Card>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    display: block;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
