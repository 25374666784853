import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { thousandify } from '../utils/NumFmtUtils';
import Spinner from './core/Spinner';
import { ConceptTypes } from '../constants';
import { Mixins } from '../styles';

const TooltipConceptContent = ({isOpen, setIsOpen, concept, tooltipLoading, selectedConcept }) => {

  if (!isOpen) return null;
  if (tooltipLoading) {
    return (
      <CenteredSpinner />
    );
  }

  return (
    <TooltipContainer>
      <CloseButton
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
      >
        ×
      </CloseButton>
      <ConceptTexts concept={concept} />
      <ConceptDetails concept={concept} />
      <AdditionalInfo selectedConcept={selectedConcept} concept={concept} />
    </TooltipContainer>
  );
};
const CloseButton = ({ onClick }) => (
  <button
    onClick={onClick}
    css={css`
      pointer-events: auto;
      position: relative;;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      z-index: 100;
      top: -8px;
      left: 50%;
    `}
    aria-label="Close Tooltip"
  >
    ×
  </button>
);

const CenteredSpinner = () => (
  <div
    css={css`
        align-self: center;
    `}
  >
    <Spinner />
  </div>
);

const ConceptTexts = ({ concept }) => {
  if (concept.type === ConceptTypes.AND && concept.texts.length > 1) {
    return <AndOrTexts texts={concept.texts} concept={concept} connector="AND" />;
  }
  if (concept.type === ConceptTypes.OR && concept.texts.length > 1) {
    return <AndOrTexts texts={concept.texts} concept={concept} />;
  }
  if (concept.texts.length === 1) {
    return <strong>"{concept.name}"</strong>;
  }
  return null;
};

const AndOrTexts = ({concept, texts, connector }) => (
  <div
    css={css`
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding-bottom: 15px;
        width: 100%;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        row-gap: 10px;
        flex-direction: column
    `}>
      <div>
        <strong>"{concept.name}"</strong>
      </div>
      <div
        css={css`
            width: 100%;
            display: flex;
            column-gap: 12px;
            flex-wrap: wrap;
            align-items: center
        `}>
        {texts.map((text, index) => (
          <React.Fragment key={index}>
            <TextPill>{text}</TextPill>
            {index < texts.length - 1 && <Connector connector={connector} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  </div>
);

const TextPill = ({ children }) => (
  <span
    css={css`
        background-color: #b1e2f7;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        font-weight: bold;
        ${Mixins.ellipsify};
        text-align: center;
        flex: 1;
        margin: 2px;
        min-width: 100px;
    `}
  >
    {children}
  </span>
);

const Connector = ({ connector }) => (
  <span
    css={css`
        font-weight: bold;
    `}
  >
    {connector}
  </span>
);

const ConceptDetails = ({ concept }) => (
  <>
    <p>Exact Matches: {thousandify(concept.exactMatchCount)}</p>
    <p>Total Matches: {thousandify(concept.matchCount)}</p>
  </>
);

const AdditionalInfo = ({ selectedConcept, concept }) => (
  <div
    css={css`
        display: flex;
        width: 100%;
        justify-content: space-around;
    `}
  >
    {selectedConcept?.drivers && (
      <DriversInfo selectedConcept={selectedConcept} concept={concept} />
    )}
    {selectedConcept?.sentiment && (
      <SentimentInfo sentiment={selectedConcept.sentiment} />
    )}
  </div>
);

const DriversInfo = ({ selectedConcept, concept }) => (
  <div>
    <h6>Drivers</h6>
    <p>Overall Average: {selectedConcept.drivers[0].baseline.toFixed(2)}</p>
    <p>
      Average for "{concept.name}":{' '}
      {selectedConcept.drivers[0].averageScore.toFixed(2)}
    </p>
    <p>Difference: {selectedConcept.drivers[0].impact.toFixed(2)}</p>
  </div>
);

const SentimentInfo = ({ sentiment }) => (
  <div>
    <h6>Sentiment</h6>
    {renderSentimentBar('Negative', sentiment.matches[0].sentimentShare.negative, '#af1818')}
    {renderSentimentBar('Neutral', sentiment.matches[0].sentimentShare.neutral, '#d8d8d8')}
    {renderSentimentBar('Positive', sentiment.matches[0].sentimentShare.positive, '#2B9E77')}
  </div>
);

const renderSentimentBar = (label, value, color) => (
  <div
    css={css`
        margin-bottom: 0.5rem;
    `}
  >
    <span
      css={css`
          font-weight: bold;
      `}
    >
      {label}:
    </span>{' '}
    {(value * 100).toFixed(2) + '%'}
    <div
      css={css`
          height: 10px;
          background: ${color};
          width: ${value * 100}%;
          max-width: 100%;
          border-radius: 4px;
          margin-top: 4px;
      `}
    />
  </div>
);

const TooltipContainer = ({ children }) => (
  <div
    css={css`
        width: 30vw;
        padding: 1rem;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `}
  >
    <div
      css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
      `}
    >
      {children}
    </div>
  </div>
);

TooltipConceptContent.propTypes = {
  concept: PropTypes.object.isRequired,
  tooltipLoading: PropTypes.bool.isRequired,
  selectedConcept: PropTypes.object
};

export default TooltipConceptContent;
