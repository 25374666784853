import { useTranslation } from 'react-i18next';
import Card from '../../../../components/organisms/Card';

import AssistantShortcuts from '../AssistantShortcuts';

const SuggestedQuestions = () => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{ flex: 1, width: '100%' }}
      title={t('shortcuts.title')}
      description={t('shortcuts.details')}
    >
      <AssistantShortcuts />
    </Card>
  );
};

export default SuggestedQuestions;
