import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import * as gtm from '../utils/gtm';
import { StoreContext } from '../StoreContext';
import { DateRangeConstraint } from '../classes/Constraints';
import { PlainTextButton } from '../components/core/Button';
import { DateRangeSelectorFlyout } from '../components/DateRangeSelectorFlyout';
import { DateRange } from './WorkArea';
import { usePrevious } from '../utils/hooks';
import { useFilter, useSetFilter } from '../search_params';
import { Icon, IconTypes } from '../components/icons';

export function DateRangeSelector({ constraint, trackingItem }) {
  const flyoutInputRef = React.useRef();
  const filter = useFilter();
  const setFilter = useSetFilter();
  const { metadata } = React.useContext(StoreContext);
  const field = metadata.find(field => constraint.name === field.name);
  const [flyoutIsOpen, setFlyoutIsOpen] = React.useState(false);
  const [{ minimum, maximum }, setRange] = React.useState({
    minimum: format(constraint.minimum),
    maximum: format(constraint.maximum)
  });
  const prevConstraint = usePrevious(constraint);

  React.useEffect(() => {
    if (flyoutIsOpen && flyoutInputRef.current) {
      flyoutInputRef.current.focus();
    }
  }, [flyoutIsOpen]);

  if (
    prevConstraint !== constraint &&
    minimum !== format(constraint.minimum) &&
    maximum !== format(constraint.maximum)
  ) {
    // we only want to update when the range has actually changed
    setRange({
      minimum: format(constraint.minimum),
      maximum: format(constraint.maximum)
    });
  }

  return (
    <DateRangeSelectorFlyout
      enteredMinimum={minimum}
      enteredMaximum={maximum}
      constraint={constraint}
      field={field}
      minimumInputRef={flyoutInputRef}
      updateConstraint={(minimum, maximum) => {
        gtm.trigger(trackingItem);
        const constraint = new DateRangeConstraint(
          field.name,
          minimum,
          maximum
        );
        const filteredFilter = filter.filter(
          c => c.name !== constraint.name && !(c instanceof DateRangeConstraint)
        );
        const newFilter = constraint.isEmpty()
          ? filteredFilter
          : [...filteredFilter, constraint];
        setFilter(newFilter);
      }}
      flyoutIsOpen={flyoutIsOpen}
      onCloseFlyout={() => setFlyoutIsOpen(false)}
      onChange={setRange}
      position="below"
      anchor={
        <PlainTextButton
          aria-label="comparison date range"
          onClick={() => setFlyoutIsOpen(true)}
          css={css`
            margin-left: 0.5rem;
            svg {
              margin-left: 0.5rem;
              // Overwrite the align styles buttons put on svgs
              vertical-align: unset;
            }
          `}
        >
          <DateRange constraint={constraint} />
          <Icon type={IconTypes.PEN} size="14" />
        </PlainTextButton>
      }
    />
  );
}

DateRangeSelector.propTypes = {
  constraint: PropTypes.instanceOf(DateRangeConstraint).isRequired,
  trackingItem: PropTypes.string
};

const format = m => {
  if (m && m.isValid()) {
    return m.format('MMMM D, Y');
  } else {
    return '';
  }
};
