import React, { useContext } from 'react';
import { css } from '@emotion/react';

import { StoreContext } from '../StoreContext';
import { TabPanel } from './Tabs';
import FilterTool from '../components/FilterTool';

export function FilterPanel() {
  const { metadata } = useContext(StoreContext);

  return (
    <TabPanel
      header="Filter documents"
      body={
        metadata.length > 0 ? (
          <FilterTool enableSelectAllFields={true} metadata={metadata} />
        ) : (
          <div
            css={css`
              padding: 0.5rem;
            `}
          >
            Your project does not contain any metadata.
          </div>
        )
      }
    />
  );
}
