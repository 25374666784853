import React from 'react';

import { SettingsLabel } from '../../components/SettingsLabel';
import { ConfigRadioWrapper } from './ConfigWrapper';
import { BubbleRadio } from '../../components/core/Radio';
import { useSearchParams } from '../../search_params';

const CalculateMatchCountPercentage = () => {
  const { searchParams, updateSearch } = useSearchParams();

  return (
    <fieldset>
      <SettingsLabel as="legend">
        Calculate match count percentage as:
      </SettingsLabel>
      <ConfigRadioWrapper>
        <BubbleRadio
          label="Percentage match based on currently set filters"
          checked={!searchParams.count_percentage}
          onChange={() => updateSearch({ count_percentage: null })}
        />
        <BubbleRadio
          label="Percentage match based on total documents"
          checked={searchParams.count_percentage === 'total'}
          onChange={() => updateSearch({ count_percentage: 'total' })}
        />
      </ConfigRadioWrapper>
    </fieldset>
  );
};

export default CalculateMatchCountPercentage;
