import React, { useRef } from 'react';
import { css } from '@emotion/react';
import { uniqueId } from 'lodash';

import { Button } from '../../../components/core/Button';
import SimpleModal from '../../../components/core/SimpleModal';
import { Colors } from '../../../styles';
import { readExcelFileAsCsv } from '../../FileUtils';
import { parseFileContents } from '../../CsvUtils';

const uploadFileFieldCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  box-sizing: border-box;
  background: ${Colors.blue0};
  border: 1px dashed ${Colors.blue1};
  cursor: pointer;
`;

const HRSitesUrlImportModal = ({
  onClose,
  isOpen,
  sourcesInputs,
  selectSource,
  setInputs,
  onError,
  onWarning
}) => {
  const fileRef = useRef();

  const handleFileBrowser = () => {
    fileRef.current.click();
  };

  const getCorrectInputs = title => {
    switch (title) {
      case 'Amazon':
        return setInputs.setAmazonInputs;
      case 'BestBuy':
        return setInputs.setBestBuyInputs;
      case 'Samsclub':
        return setInputs.setSamsclubInputs;
      case 'Target':
        return setInputs.setTargetInputs;
      case 'Walmart':
        return setInputs.setWalmartInputs;
      default:
        onError(`Source: ${title} does not exist`);
        break;
    }
  };

  const transformFileToInputs = async file => {
    const readFile = await readExcelFileAsCsv(file);
    const parsedFile = parseFileContents(readFile);

    for (let index = 0; index < parsedFile.columns.length; index++) {
      const title = parsedFile.columns[index].name;

      const setTargetInputs = getCorrectInputs(title);

      const selectedSource = sourcesInputs.find(
        source => source.title === title
      );

      if (!selectedSource) {
        selectSource(title);
      }

      const values = parsedFile.rows.map(row => row[index].rawValue);
      const filteredValues = values.filter(value => value.trim() !== '');
      if (!filteredValues?.length)
        return onWarning(`Source: ${title} values are empty`);

      const inputs = [];

      for (let index = 0; index < filteredValues.length; index++) {
        const value = filteredValues[index];
        if (value.trim() !== '') {
          inputs.push({
            name: `url${uniqueId()}`,
            value,
            error: ''
          });
        }
      }

      setTargetInputs && setTargetInputs(inputs);
    }
  };

  const fileInputChangeHandler = async event => {
    if (event.target.files.length) {
      await transformFileToInputs(event.target.files[0]);
    }
    onClose();
  };

  const dragFileHandler = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const dropFileHandler = async event => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length) {
      await transformFileToInputs(droppedFiles[0]);
    }
    onClose();
  };

  const CSVData = sourcesInputs.map(({ title, inputs }) => {
    const inputValues = inputs.map(({ value }) => value);

    return { [title]: inputValues };
  });

  function downloadCSVHandler() {
    // Extract column titles (keys)
    const titles = CSVData.map(column => Object.keys(column)[0]);

    // Extract data values
    const data = CSVData.map(column => Object.values(column)[0]);

    // Determine the maximum number of rows needed
    const maxRows = Math.max(...data.map(column => column.length));

    // Construct CSV header row
    let csv = titles.join(',') + '\n';

    // Construct CSV data rows
    for (let i = 0; i < maxRows; i++) {
      for (let j = 0; j < CSVData.length; j++) {
        const columnData = data[j];
        const cellValue = columnData[i] || ''; // If the cell doesn't exist, use an empty string
        csv += `"${cellValue}",`; // Enclose cell value in quotes to handle special characters and commas
      }
      csv = csv.slice(0, -1) + '\n'; // Remove the trailing comma and add a new line
    }

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'URLs-template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return (
    <SimpleModal onHide={onClose} isOpen={isOpen}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
        `}
      >
        <Button onClick={downloadCSVHandler}>Download .csv template</Button>
        <div
          css={css`
            width: 100%;
          `}
        >
          <input
            type="file"
            hidden
            ref={fileRef}
            onChange={fileInputChangeHandler}
          />
          <label
            onDrop={event => dropFileHandler(event)}
            onDragOver={event => dragFileHandler(event)}
            css={uploadFileFieldCSS}
            onClick={handleFileBrowser}
          >
            <div>
              Drag & drop or{' '}
              <span
                css={css`
                  font-weight: 700;
                `}
              >
                Browse
              </span>{' '}
              .csv file
            </div>
          </label>
        </div>
      </div>
    </SimpleModal>
  );
};

export default HRSitesUrlImportModal;
