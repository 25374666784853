import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export const DialogContainer = styled(Paper)`
  width: 40.625rem;
  width: 64.6875rem;
  height: 51.125rem;
  margin: 25px auto;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  bottom: 48px;
  right: 18px;
  max-width: 95%;
  max-height: 80%;
`;

export const DialogContent = styled(Grid)`
  padding: 2.5rem 1.5rem 0.87rem;
  flex-grow: 1;
`;

export const DialogContentColumn = styled(Grid)`
  height: 100%;
  width: 50%;
`;
