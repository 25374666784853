import React from 'react';
import styled from '@emotion/styled';

const PrevalentSizeControl = ({
                                size,
                                minSize,
                                maxSize,
                                handleIncrease,
                                handleDecrease,
                                handleBlur,
                                disabled
                              }) => {
  const handleChange = (e) => {
    if (disabled) return;
    const value = Number(e.target.value);
    handleBlur(value);
  };

  return (
    <Container>
      <ControlButton
        onClick={handleDecrease}
        disabled={size <= minSize || disabled}
      >
        -
      </ControlButton>

      <SizeInput
        type="number"
        value={size}
        onChange={handleChange}
        onBlur={() => handleBlur(size)}
        disabled={disabled}
        aria-label="Number of volume suggestions"
      />

      <ControlButton
        onClick={handleIncrease}
        disabled={size >= maxSize || disabled}
      >
        +
      </ControlButton>
    </Container>
  );
};

export default PrevalentSizeControl;

const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    gap: 5px;
    justify-content: center;
`;

const ControlButton = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ disabled }) => (disabled ? '#e0e0e0' : '#f9f9f9')};
    border: 1px solid #ddd;
    font-size: 18px;
    font-weight: bold;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.1s, box-shadow 0.1s;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:active {
        transform: ${({ disabled }) => (disabled ? 'none' : 'scale(0.95)')};
        box-shadow: none;
    }
`;

const SizeInput = styled.input`
    width: 40px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 0 0 0;
    outline: none;
    height: 1.5rem;
    margin: 0;
    background-color: ${({ disabled }) => (disabled ? '#f5f5f5' : 'white')};
    color: ${({ disabled }) => (disabled ? '#a0a0a0' : 'black')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &:focus {
        border-color: ${({ disabled }) => (disabled ? '#ddd' : '#007bff')};
    }
`;
