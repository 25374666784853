import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';

import { COLORS } from '../constants';
import { addActiveConcepts } from '../actions';
import { ifAllExist } from '../utils/common';
import { Concept as ConceptClass } from '../classes/Concepts';
import ColoredCircle from './ColoredCircle';
import Tooltip from './core/Tooltip';
import { Button } from './core/Button';
import { StoreContext } from '../StoreContext';
import { Icon, IconTypes } from './icons';
import { Mixins } from '../styles';
import { useCurrentView } from '../side_panel/views/view';
import { useSearchParams } from '../search_params';

export const getNewConceptColor = activeConcepts => {
  const usedColors = _.uniq(activeConcepts.map(ac => ac.color));
  const unusedColors = _.difference(COLORS, usedColors);
  if (!_.isEmpty(unusedColors)) {
    return unusedColors[0];
  } else {
    return _.sample(COLORS);
  }
};

export default function Concept({ concept, stopPropagation, trackingPrefix }) {
  const { projectId } = useParams();
  const { activeConcepts } = React.useContext(StoreContext);
  const { conceptSelector } = useCurrentView();
  const { searchParams } = useSearchParams();
  const { filter } = searchParams;
  const matchingConcept = concept.isActive
    ? _.find(activeConcepts, { sharedConceptId: concept.sharedConceptId })
    : activeConcepts.find(ac => ConceptClass.areTextsEqual(concept, ac));

  const conceptCss = css`
    display: flex;
    flex-direction: row;
    ${Mixins.ellipsify};
    align-items: center;
  `;

  return (
    <div css={conceptCss}>
      {matchingConcept !== undefined ? (
        <>
          <Tooltip
            /* Force tooltip to close when a concept is shared */
            key={matchingConcept.sharedConceptId}
            position="right"
            anchor={<ColoredCircle color={matchingConcept.color} type={matchingConcept.type}/>}
          >
            Active concept
          </Tooltip>
          <ConceptName concept={matchingConcept}  isOutlier={concept.outlier}/>
        </>
      ) : (
        <>
          <Tooltip
            position="right"
            anchor={
              <Button
                aria-label="Add to active concepts"
                flavor="subtle"
                padded={false}
                data-tracking-item={ifAllExist`${trackingPrefix}_save-concept-button`}
                onClick={event => {
                  if (stopPropagation) {
                    event.stopPropagation();
                  }

                  addActiveConcepts(
                    conceptSelector,
                    filter,
                    projectId,
                    concept.update({
                      color: getNewConceptColor(activeConcepts)
                    })
                  );
                }}
              >
                <Icon type={IconTypes.ADD} size="1.25rem" />
              </Button>
            }
          >
            Add to active concepts
          </Tooltip>
          <ConceptName concept={concept} isOutlier={concept.outlier} />
        </>
      )}
    </div>
  );
}

Concept.propTypes = {
  concept: PropTypes.instanceOf(ConceptClass).isRequired,
  stopPropagation: PropTypes.bool,
  trackingPrefix: PropTypes.string
};

function ConceptName({ concept, isOutlier }) {
  const conceptNameCss = css`
      flex: 1;
      margin-left: 0.5rem;
      ${Mixins.ellipsify};
      user-select: text;
  `;
  return (
    <div title={!isOutlier ? concept.name : ''} css={conceptNameCss}>
      {concept.name}
    </div>
  );
}

