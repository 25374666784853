import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ChatScrollContainer = styled(Box)`
    min-width: 100%;
    width: 100%;
    min-height: 0;
    height: 0;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px; 
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }

    &::-webkit-scrollbar-thumb {
        background: #888;  
        border-radius: 10px;  
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
`;
