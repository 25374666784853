import { css } from '@emotion/react';
import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import Fade from './Fade';
import { Colors, Mixins, ZIndexes } from '../../styles';

const AnchorWrapper = React.forwardRef(({ forSVG, ...otherProps }, ref) =>
  forSVG ? <g ref={ref} {...otherProps} /> : <span ref={ref} {...otherProps} />
);

export default class Tooltip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.beginShowTimer = this.beginShowTimer.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.hide();
    }

    if (prevProps.visible == null && this.props.visible != null) {
      this.hide();
    }

    if (this.portal != null) {
      // Sync the position & width of the portal with the tooltip
      // wrapper so that the portal lies directly over the anchor.
      const anchorRect = this.anchor.getBoundingClientRect();
      this.portal.style.left = anchorRect.left + 'px';
      this.portal.style.width = anchorRect.width + 'px';
      this.portal.style.height = anchorRect.height + 'px';
      this.portal.style.top = anchorRect.top + document.body.scrollTop + 'px';
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    clearTimeout(this.timer);
  }

  beginShowTimer() {
    this.clearTimer();
    this.timer = setTimeout(
      () => this.setState({ active: true }),
      this.props.delayed ? this.props.delayLength : 0
    );
  }

  hide() {
    this.clearTimer();
    this.setState({ active: false });
  }

  render() {
    const { forSVG } = this.props;

    const displayTooltip =
      this.props.visible != null ? this.props.visible : this.state.active;

    const tooltipPortal = (
      <div
        data-test-id="tooltip"
        ref={ref => {
          this.portal = ref;
        }}
        css={css`
          pointer-events: none;
          position: absolute;
          z-index: ${ZIndexes.tooltip};
        `}
      >
        <TooltipSpeechBubble
          position={this.props.position}
          dark={this.props.dark}
          onMouseEnter={
            this.props.visible == null && this.props.contentHoverable
              ? () => {
                  this.clearTimer();
                  this.setState({ active: true });
                }
              : undefined
          }
          onMouseLeave={
            this.props.visible == null && this.props.contentHoverable
              ? () => {
                  this.clearTimer();
                  this.hide();
                }
              : undefined
          }
          contentHoverable={this.props.contentHoverable}
          tooltipHeight={this.props.tooltipHeight}
          tooltipWidth={this.props.tooltipWidth}
        >
          {this.props.children}
        </TooltipSpeechBubble>
      </div>
    );

    return (
      <>
        <AnchorWrapper
          {..._.omit(this.props, Object.keys(Tooltip.propTypes))}
          ref={ref => {
            this.anchor = ref;
          }}
          className={cx('tooltip', this.props.className)}
          forSVG={forSVG}
          onMouseMove={
            this.props.visible == null ? this.beginShowTimer : undefined
          }
          onMouseLeave={this.props.visible == null ? this.hide : undefined}
        >
          {this.props.anchor}
        </AnchorWrapper>
        {
          // Additionally render a Portal. The Portal will be where the
          // tooltip box, pointer, and its child contents will be rendered.
          // The Portal renders to a div which is a sibling of the app in
          // the DOM, rather than being a descendant of it.
          !this.props.disabled &&
            createPortal(
              this.props.delayed ? (
                <Fade
                  show={displayTooltip}
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                  {tooltipPortal}
                </Fade>
              ) : (
                displayTooltip && tooltipPortal
              ),
              document.body
            )
        }
      </>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // When defined, `visible` indicates if Tooltip content is visible. This overrides Tooltips'
  // default behavior of opening after the cursor has hovered over the anchor for a bit.
  visible: PropTypes.bool,
  // Determines whether or not the tooltip is hoverable at all
  disabled: PropTypes.bool,
  position: PropTypes.oneOf(['above', 'below', 'left', 'right']),

  // These are the elements which can be hovered in order to display
  // the tooltip.
  anchor: PropTypes.node.isRequired,

  // Determines whether or not hovering the tooltip itself should
  // prevent the tooltip from closing
  contentHoverable: PropTypes.bool,

  // Determines if the node that wraps the anchor should be valid within an SVG.
  forSVG: PropTypes.bool,

  // Remove delay when opening/closing the tooltip
  delayed: PropTypes.bool,

  // Number of milliseconds to wait before showing the tooltip
  delayLength: PropTypes.number,

  // Display tooltip with a dark background and white text
  dark: PropTypes.bool,

  tooltipWidth: PropTypes.string,
  tooltipHeight: PropTypes.string
};

Tooltip.defaultProps = {
  disabled: false,
  position: 'above',
  contentHoverable: false,
  forSVG: false,
  delayed: true,
  delayLength: 500,
  dark: false
};

export function TooltipSpeechBubble({
  children,
  dark,
  position,
  onMouseEnter,
  onMouseLeave,
  contentHoverable,
  tooltipWidth = '12rem',
  tooltipHeight = '2rem'
}) {
  const vertical = position === 'above' || position === 'below';
  const horizontal = !vertical;

  return (
    <div
      css={css`
        ${Mixins.fillViewport};
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: ${vertical ? tooltipHeight : 'auto'};
        width: ${horizontal ? tooltipWidth : 'auto'};
        margin: ${vertical ? `calc(-${tooltipWidth} / 2)` : 'auto'};

        ${contentHoverable &&
        css`
          > * {
            /* override for children */
            pointer-events: initial;
          }
        `}

        ${position === 'above' &&
        css`
          bottom: auto;
          margin-top: -${tooltipHeight};
        `}

        ${position === 'below' &&
        css`
          top: auto;
          flex-direction: column-reverse;
          margin-bottom: -${tooltipHeight};
        `}

        ${position === 'left' &&
        css`
          flex-direction: row;
          right: auto;
          margin-left: -${tooltipWidth};
        `}

        ${position === 'right' &&
        css`
          flex-direction: row-reverse;
          left: auto;
          margin-right: -${tooltipWidth};
        `}
      `}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TooltipBox dark={dark}>{children}</TooltipBox>
      <TooltipPointer dark={dark} position={position} />
    </div>
  );
}

TooltipSpeechBubble.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  position: PropTypes.oneOf(['above', 'below', 'left', 'right']).isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  contentHoverable: PropTypes.bool,
  tooltipHeight: PropTypes.string,
  tooltipWidth: PropTypes.string
};

const darkStyles = css`
  background: ${Colors.gray9};
  color: white;
`;

export function TooltipBox({ children, dark, ...props }) {
  // These are the contents that will be displayed in the tooltip.
  return (
    <div
      css={css`
        ${Mixins.shadowOutsetHeavy};
        border-radius: 3px;
        padding: 0.5rem;
        background: white;
        font-size: 0.875rem;
        ${dark && darkStyles}

        /* the last element in a tooltip box shouldn't have any extra space
         * following it. */
        > :last-child {
          margin-bottom: 0;
        }
      `}
      {...props}
    >
      {children}
    </div>
  );
}

TooltipBox.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool
};

export function TooltipPointer({ dark, position, ...props }) {
  const pointerShortEdge = '0.5rem';
  const pointerLongEdge = '1.5rem';

  return (
    <div
      css={css`
        height: ${pointerShortEdge};
        width: ${pointerLongEdge};
        min-height: ${pointerShortEdge};
        overflow: hidden;

        &:after {
          content: ' ';
          display: block;
          height: ${pointerLongEdge};
          width: ${pointerLongEdge};
          background: white;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
          transform: rotate(45deg);
          ${dark && darkStyles}
        }

        ${position === 'above' &&
        css`
          width: ${pointerLongEdge};
          min-height: ${pointerShortEdge};

          &:after {
            margin-top: -${pointerLongEdge};
          }
        `}

        ${position === 'below' &&
        css`
          width: ${pointerLongEdge};
          min-height: ${pointerShortEdge};

          &:after {
            margin-top: ${pointerShortEdge};
            transform: rotate(-135deg);
          }
        `}

          ${position === 'left' &&
        css`
          height: ${pointerLongEdge};
          min-width: ${pointerShortEdge};
          width: ${pointerShortEdge};

          &:after {
            margin-left: -${pointerLongEdge};
            transform: rotate(-45deg);
          }
        `}

          ${position === 'right' &&
        css`
          height: ${pointerLongEdge};
          min-width: ${pointerShortEdge};
          width: ${pointerShortEdge};

          &:after {
            margin-left: ${pointerShortEdge};
            transform: rotate(135deg);
          }
        `}
      `}
      {...props}
    />
  );
}

TooltipPointer.propTypes = {
  dark: PropTypes.bool,
  position: PropTypes.oneOf(['above', 'below', 'left', 'right']).isRequired
};
