import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { TabPanel } from './Tabs';
import { ConfigSectionWrapper } from './configure_visualization/ConfigWrapper';
import { BubbleInput } from '../components/core/BubbleInput';
import { Checkbox } from '../components/core/Checkbox';
import {
  putConceptsTranslateValue,
  getConceptsTranslate,
  postConceptsTranslateBuild
} from '../utils/ApiUtilsV5';
import { StoreContext } from '../StoreContext';
import { useSearchParams } from '../search_params';
import { Button } from '../components/core/Button';

const EnglishTranslationToggle = () => {
  const { projectId } = useParams();
  const { searchParams, updateSearch } = useSearchParams();
  const translate = searchParams.translate === 'true';
  const [status, setStatus] = React.useState(null);

  useEffect(() => {
    let timeoutHandle;
    let request;

    const fetchDataAndPollStatus = async () => {
      try {
        request = getConceptsTranslate(projectId);
        const conceptsTranslate = await request;
        updateSearch({ translate: conceptsTranslate?.translate });
        setStatus(conceptsTranslate?.status);

        if (
          !(
            conceptsTranslate?.status === 'ready' ||
            conceptsTranslate?.status === 'error'
          )
        ) {
          const pollStatus = async () => {
            try {
              request = getConceptsTranslate(projectId);
              const result = await request;
              setStatus(result?.status);

              if (!(result?.status === 'ready' || result?.status === 'error')) {
                timeoutHandle = setTimeout(pollStatus, 4000);
              }
            } catch (error) {
              console.error('Error polling status:', error);
            }
          };

          timeoutHandle = setTimeout(pollStatus, 4000);
        }
      } catch (error) {
        console.error('Error fetching data or setting outlier filter:', error);
      }
    };

    fetchDataAndPollStatus();

    return () => {
      clearTimeout(timeoutHandle);
      if (request?.abort) {
        request.abort();
      }
    };
  }, [projectId, status]);

  const toggleChecked = () => {
    const newTranslateValue = !translate;
    updateSearch({ translate: newTranslateValue ? 'true' : 'false' });
    putConceptsTranslateValue(projectId, newTranslateValue);
  };

  const restartBuild = () => {
    postConceptsTranslateBuild(projectId);
    setStatus('building');
  };

  return (
    <ConfigSectionWrapper>
      {status === 'error' ? (
        <Button onClick={restartBuild} flavor="subtle" padded={false}>
          Restart translations build.
        </Button>
      ) : status === 'building' ? (
        <BubbleInput
          as={Checkbox}
          label="Display concept names in English (building translations, please wait)"
          checked={translate || false}
          onChange={toggleChecked}
          disabled={true}
        />
      ) : (
        <BubbleInput
          as={Checkbox}
          label="Display concept names in English"
          checked={translate || false}
          onChange={toggleChecked}
        />
      )}
    </ConfigSectionWrapper>
  );
};

export function ConfigureVisualizationPanel(props) {
  const { projectLanguage } = React.useContext(StoreContext);

  return (
    <TabPanel
      header="Configure visualization"
      body={
        <div
          css={css`
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            overflow: auto;
          `}
        >
          {props.body}
          {projectLanguage && projectLanguage !== 'en' && (
            <EnglishTranslationToggle />
          )}
        </div>
      }
    />
  );
}

ConfigureVisualizationPanel.propTypes = { body: PropTypes.any };
