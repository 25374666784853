import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

export const ShortcutsContainer = styled(Box)`
  margin: 1.25rem 0 0;
  padding: 0;
  list-style: none;

  li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const ShortcutsGroupTitle = styled(Typography)`
  margin-bottom: 0;
  text-transform: uppercase;
  color: #878c96;
  font-size: 0.625rem;
`;

export const Shortcut = styled(Chip)`
  height: 1.375rem;
  margin-top: 0.5rem;
  padding: 0.25rem 0;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 0.875rem;

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;
