import React, { useState } from 'react';
import { css } from '@emotion/react';

import { useUniqueId } from '../../utils/hooks';
import { PlaintextWhenDisabledDropdown } from '../PlaintextWhenDisabledDropdown';
import { Checkbox } from '../../components/core/Checkbox';
import { Button } from '../../components/core/Button';
import { Colors } from '../../styles';
import { Icon, IconTypes } from '../../components/icons';
import SentimentSelector from '../SentimentSelector';
import { EmailNotificationCheckbox } from '../../components/ProjectBrancher';

const intervalOptions = [
  { value: 'day', name: 'Day' },
  { value: 'week', name: 'Week' }
];

export const OptionsPage = ({
                              isLoading,
                              submitHandler,
                              setRepeatInterval,
                              repeatInterval,
                              repeat,
                              setRepeat
                            }) => {
  const selectId = useUniqueId();
  const [shouldNotify, setShouldNotify] = useState(false);
  const [skipSentiment, setSkipSentiment] = useState(false);


  const handleSubmit = () => {
    submitHandler(shouldNotify, skipSentiment);
  };

  return (
    <div
      css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
      `}
    >
      <div
        css={css`
            display: flex;
            gap: 1rem;
        `}
      >
        <Icon
          css={css`
              color: ${Colors.blue4};
          `}
          size="16"
          type={IconTypes.CIRCULAR_INFO}
        />
        <div>
          <div>
            <p>
              The data scrape can be set up to repeat automatically to add new
              data to the Daylight project.
            </p>
            <p>
              To scrape the data only once, leave the below checkbox unchecked and
              click “Submit”.
            </p>
            <p>
              To repeat the scrape, check the box below and select the frequency
            </p>
          </div>
          <div
            css={css`
                display: flex;
                gap: 1rem;
                align-items: center;
                flex-direction: row;
                margin-top: 30px;
            `}
          >
            <label
              css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 8px;
              `}
            >
              <Checkbox
                checked={repeat}
                css={css`
                    margin-right: 0.5rem;
                `}
                aria-label={`select repeat`}
                onChange={event => {
                  event.stopPropagation();
                  setRepeat(prev => !prev);
                }}
              />
              <span>Repeat</span>
            </label>

            <div
              css={css`
                  display: flex;
                  align-items: center;
                  gap: 20px;
              `}
            >
              <label htmlFor={selectId}>Interval:</label>

              <PlaintextWhenDisabledDropdown
                id={selectId}
                editable={repeat}
                value={repeatInterval}
                onChange={setRepeatInterval}
                options={intervalOptions}
                promptOption="Select the repeat interval"
              />
            </div>
          </div>
          <div css={css`
              margin-top: 30px;
          `}>
            <EmailNotificationCheckbox
              checked={shouldNotify}
              onChange={event => {
                setShouldNotify(event.target.checked);
              }}
            />
            <SentimentSelector
              checked={skipSentiment}
              onChange={() => {
                setSkipSentiment(!skipSentiment);
              }}
            />
          </div>
          <div css={css`
              margin-top: 30px;
          `}>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              type="button"
              palette="green"
            >
              Submit
            </Button>
          </div>
        </div>

      </div>


    </div>
  );
};
