import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { StoreContext } from '../../StoreContext';
import { NumericField, ScoreField } from '../../classes/MetadataFields';
import Dropdown from '../../components/core/Dropdown';
import { TooltipBox, TooltipPointer } from '../../components/core/Tooltip';
import { useUniqueId } from '../../utils/hooks';
import { naturalSortByName } from '../../utils/NaturalSort';
import { useSearchParams } from '../../search_params';
import {
  fieldHasNoRange,
  instanceOf,
  noValidRanges
} from '../../drivers/utils';
import { SettingsLabel } from '../../components/SettingsLabel';
import { Colors } from '../../styles';

export function FieldSelector() {
  const { metadata } = React.useContext(StoreContext);
  const noFieldsHaveRange = noValidRanges(metadata);
  const numberFields = metadata.filter(instanceOf(NumericField));
  const scoreFields = metadata.filter(instanceOf(ScoreField));
  const fieldCount = numberFields.length + scoreFields.length;
  const idSuffix = useUniqueId();
  const selectId = `select-score-field${idSuffix}`;
  const { searchParams, updateSearch } = useSearchParams();

  return (
    fieldCount > 0 && (
      <>
        <SettingsLabel htmlFor={selectId}>
          Which score field should be used as an axis?
        </SettingsLabel>
        <Dropdown
          id={selectId}
          value={searchParams.field}
          css={css`
            background-color: ${searchParams.sync_fields === 'yes'
              ? Colors.green0
              : null};
          `}
          onChange={event => {
            const field = event.target.value;
            const updates = { field };
            if (searchParams.sync_fields === 'yes') {
              updates.drivers_of = field;
            }
            updateSearch(updates);
          }}
          disabled={noFieldsHaveRange}
          containerCss={css`
            width: 100%;
            margin: 0.5rem 0;
              padding:  5px;
          `}
        >
          <FieldSelectorOptions />
        </Dropdown>
        {!noFieldsHaveRange && searchParams.field === undefined && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
            `}
          >
            <TooltipPointer position="below" dark />
            <TooltipBox dark>
              <span data-tracking-item="drivers_select-a-number-hint">
                Please choose a number field to get started.
              </span>
            </TooltipBox>
          </div>
        )}
      </>
    )
  );
}

export const FieldSelectorOptions = () => {
  const { metadata } = React.useContext(StoreContext);
  const numberFields = metadata.filter(instanceOf(NumericField));
  const scoreFields = metadata.filter(instanceOf(ScoreField));

  const hasRange = field => !fieldHasNoRange(field);
  const hasNoValidFields =
    [...scoreFields, ...numberFields].filter(hasRange).length === 0;

  return (
    <>
      {hasNoValidFields && <option value="">No score data</option>}
      <FieldOptions label="Score fields" fields={scoreFields} />
      <FieldOptions label="Number fields" fields={numberFields} />
    </>
  );
};

const FieldOptions = ({ fields, label }) => {
  return (
    <optgroup label={`${label} (${fields.length})`}>
      {fields.length > 0 &&
        fields.sort(naturalSortByName).map(field => (
          <option
            key={field.name}
            value={field.name}
            disabled={fieldHasNoRange(field)}
          >
            {`${field.name}${
              fieldHasNoRange(field) ? ' (Field range too small)' : ''
            }`}
          </option>
        ))}
    </optgroup>
  );
};

FieldOptions.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(ScoreField),
      PropTypes.instanceOf(NumericField)
    ]).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired
};
