import React, { useContext } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { StoreContext } from '../../StoreContext';
import {
  ColorDropdown,
  useRecolorConcepts
} from '../../components/ColorDropdown';

export const BulkColorEditor = ({ selections }) => {
  const { activeConcepts } = useContext(StoreContext);
  const recolorConcepts = useRecolorConcepts();

  const onChange = color => {
    const sharedConceptIds = new Set(selections.selections);
    const conceptsToUpdate = activeConcepts.filter(c =>
      sharedConceptIds.has(c.sharedConceptId)
    );
    recolorConcepts(conceptsToUpdate, color);
    selections.clear();
  };

  const customColors = activeConcepts?.map((concept) => ({
    label: concept.color,
    value: concept.color,
  }));

  return (
    <Container>
      <ColorDropdown
        customColors={customColors}
        color={null}
        onChangeColor={onChange}
        menuPlacement="top"
        disabled={!selections.any}
        emptyValueLabel={`Set color (${selections.count})`}
      />
    </Container>
  );
};

BulkColorEditor.propTypes = {
  selections: PropTypes.shape({
    selections: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number.isRequired,
    any: PropTypes.bool.isRequired
  }).isRequired
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  /* Make color dropdown take up available space */
  > :first-child {
    flex: 1;
  }
`;
