import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Rating from '../../molecules/Rating';
import FeedbackForReply from '../../molecules/FeedbackForReply';

const RatingWithFeedback = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [ratingScore, setRatingScore] = useState(0);

  const handleRatingScoreChange = (value) => {
    setRatingScore(value);
    onSubmit(value, '');
  };

  const handleSubmit = (feedback) => {
    onSubmit(ratingScore, feedback);
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Rating
        placeholder={t('chat.rating.placeholder')}
        onChange={handleRatingScoreChange}
      />
      <FeedbackForReply
        visible={Boolean(ratingScore)}
        placeholder={t('chat.rating.request_feedback')}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

RatingWithFeedback.propTypes = {
  onSubmit: PropTypes.func,
};

export default RatingWithFeedback;
