import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import { ReviewsSourceSelect } from './ReviewsSourceSelect';
import { ReviewsInputData } from './ReviewsInputData';
import { RoutePatterns } from '../../../constants';
import { OptionsPage } from '../OptionsPage';
import {
  createProject,
  createReviewsTaskDashboard,
  deleteProject,
  removeProjectTask,
  createScrapersV2Task
} from '../../../utils/ApiUtilsV5';
import StreamSourceLayout from '../StreamSourceLayout';
import { TokenModal } from '../TokenModal';
import buildRoutePath from '../../../utils/buildRoutePath';
import { reviewsSources } from '../../../utils/streamData';
import { FeatureFlagsContext } from '../../../FeatureFlagsContext';

class SourceInput {
  constructor(id, inputs, setInputs, title, validation) {
    this.id = id;
    this.inputs = inputs;
    this.setInputs = setInputs;
    this.title = title;
    this.validation = validation;
  }
}

export const ReviewsPage = ({
  projectData,
  onAlert,
  sourcesInputs,
  setSourcesInputs,
  inputs,
  setInputs,
  repeat,
  setRepeat,
  repeatInterval,
  setRepeatInterval,
  setProjectId,
  preselectedSources,
  projectTasks
}) => {
  const streamDataRoute = projectData?.projectId
    ? buildRoutePath(RoutePatterns.UPDATE_PROJECT_STREAM_DATA, {
        workspaceId: projectData.workspaceId,
        projectId: projectData.projectId
      })
    : RoutePatterns.UPLOAD_STREAM_DATA;

  const SELECT_SOURCE_PATHNAME = `${streamDataRoute}/select/reviews/select-source`;
  const URLS_INPUT_PATHNAME = `${streamDataRoute}/select/reviews/urls-input`;
  const SELECT_INTERVAL_PATHNAME = `${streamDataRoute}/select/reviews/select-interval`;

  const history = useHistory();
  const location = useLocation();

  const [step, setStep] = useState(1);
  const [sources, setSources] = useState(reviewsSources);
  const [tokenModalIsOpen, setTokenModalIsOpen] = useState(false);
  const [tokenModalShouldNotify, setTokenModalShouldNotify] = useState(undefined);
  const [tokenModalSkipSentiment, setTokenModalSkipSentiment] = useState(undefined);

  const [errors, setErrors] = useState([]);

  const [requestInProcess, setRequestInProcess] = useState(false);

  const featureFlags = React.useContext(FeatureFlagsContext);

  const selectSource = (title, selected) => {
    const sourcesWithSelected = sources.map(source => {
      if (source.title !== title) return source;

      source.selected = selected || !source.selected;
      return source;
    });

    setSources(sourcesWithSelected);
  };

  useEffect(() => {
    if (preselectedSources && preselectedSources.length) {
      preselectedSources.forEach(source => {
        selectSource(source, true);
      });
    }
  }, [preselectedSources]);

  const selectedSources = useMemo(
    () => sources.filter(source => source.selected),
    [sources]
  );

  const createProjectName = () => {
    const titles = selectedSources.map(source => source.title);
    return titles.join(' | ') + ' Scrape';
  };

  const successHandler = message => {
    onAlert({
      type: 'success',
      message
    });
    setTimeout(() => {
      window.location.replace(window.location.origin);
    }, 1000);
  };

  const errorHandler = message => {
    onAlert({
      type: 'error',
      message
    });
  };

  const warningHandler = message => {
    onAlert({
      type: 'warning',
      message
    });
  };

  const validateInputs = sourceInput => {
    const { inputs, setInputs, validation } = sourceInput;

    const validatedInputs = inputs.map(input => ({
      ...input,
      error: !validation.test(input.value)
        ? 'Please provide a valid Reviews URL.'
        : ''
    }));

    setInputs(validatedInputs);

    return !validatedInputs.some(({ error }) => error !== '');
  };

  let existingProjectId = projectData.projectId;

  const submitReviewsTask = async (token, shouldNotify, skipSentiment) => {
    setRequestInProcess(true);

    try {
      if (!existingProjectId) {
        const createdProject = await createProject(
          projectData.workspaceId,
          projectData.name || createProjectName(),
          projectData.language,
          projectData.description
        );

        if (!createdProject) {
          throw new Error('Unexpected issue during project creation');
        }

        existingProjectId = createdProject.project_id;
      } else {
        if (!featureFlags.new_scrapers) {
          // for the legacy system: delete any existing tasks
          await removeProjectTask(existingProjectId, projectTasks[0]._id);
        }
      }

      const url_list = sourcesInputs
        .map(({ inputs }) => inputs.map(({ value }) => value))
        .flat();

      if (featureFlags.new_scrapers) {
        // use the new scrapers pipeline

        const taskResponse = await createScrapersV2Task(
          existingProjectId,
          url_list,
          repeat,
          repeatInterval,
          token,
          shouldNotify,
          skipSentiment
        );

        setRequestInProcess(false);
        successHandler(
          !projectData.projectId
            ? 'Reviews task successfully created!'
            : 'Reviews task successfully updated!'
        );
      } else {
        // use the legacy scrapers implementation

        const taskResponse = await createReviewsTaskDashboard(
          existingProjectId,
          url_list,
          repeat,
          repeatInterval,
          token,
          shouldNotify,
          skipSentiment
        );
        if (
          taskResponse?.status_code === 200 &&
          taskResponse?.status === 'success'
        ) {
          setRequestInProcess(false);
          successHandler(
            !projectData.projectId
              ? 'Reviews task successfully created!'
              : 'Reviews task successfully updated!',
            existingProjectId
          );
        } else {
          throw new Error('Something went wrong!');
        }
      }
    } catch (e) {
      try {
        if (!projectData.projectId && existingProjectId)
          await deleteProject(existingProjectId);
      } catch (e) {
        console.error(e);
      }
      setRequestInProcess(false);
      errorHandler(e.message);
    }
  };

  const submitHandler = (shouldNotify, skipSentiment, token) => {
    if (token || !repeat) {
      void submitReviewsTask(token, shouldNotify, skipSentiment);
    } else {
      setTokenModalShouldNotify(shouldNotify);
      setTokenModalSkipSentiment(skipSentiment);
      setTokenModalIsOpen(true);
    }
  };

  const checkProjectData = useCallback(() => {
    setErrors([]);
    if (!projectData.language) {
      setErrors(prevState => [
        ...prevState,
        "Please, select the dataset's language to continue"
      ]);
    }
    if (!projectData.workspaceId) {
      setErrors(prevState => [
        ...prevState,
        'Please, select the workspace to continue'
      ]);
    }
  }, [projectData.language, projectData.workspaceId]);

  const inputsWithError = () =>
    sourcesInputs.filter(input => !validateInputs(input));

  const nextStepHandler = () => {
    if (step === 2 && inputsWithError().length > 0) return;

    setStep(prevState => prevState + 1);
  };

  const goBackHandler = () => {
    if (step === 1) {
      if (streamDataRoute !== RoutePatterns.UPLOAD_STREAM_DATA) {
        console.log(history);
        return history.go(-1);
      } else {
        return history.push(streamDataRoute);
      }
    }
    setStep(prevState => prevState - 1);
  };

  useEffect(() => {
    const updatedSourcesInputs = selectedSources.map(source => {
      switch (source.title) {
        case 'Amazon':
          return new SourceInput(
            source.id,
            inputs.amazonInputs,
            setInputs.setAmazonInputs,
            source.title,
            source.validation
          );

        case 'Audible':
          return new SourceInput(
            source.id,
            inputs.audibleInputs,
            setInputs.setAudibleInputs,
            source.title,
            source.validation
          );

        case 'BestBuy':
          return new SourceInput(
            source.id,
            inputs.bestBuyInputs,
            setInputs.setBestBuyInputs,
            source.title,
            source.validation
          );

        case 'Costco':
          return new SourceInput(
            source.id,
            inputs.costcoInputs,
            setInputs.setCostcoInputs,
            source.title,
            source.validation
          );

        case 'Samsclub':
          return new SourceInput(
            source.id,
            inputs.samsclubInputs,
            setInputs.setSamsclubInputs,
            source.title,
            source.validation
          );

        case 'Target':
          return new SourceInput(
            source.id,
            inputs.targetInputs,
            setInputs.setTargetInputs,
            source.title,
            source.validation
          );

        case 'Walmart':
          return new SourceInput(
            source.id,
            inputs.walmartInputs,
            setInputs.setWalmartInputs,
            source.title,
            source.validation
          );

        case 'ConsumerAffairs':
          return new SourceInput(
            source.id,
            inputs.consumerAffairsInputs,
            setInputs.setConsumerAffairsInputs,
            source.title,
            source.validation
          );

        case 'Google':
          return new SourceInput(
            source.id,
            inputs.googleInputs,
            setInputs.setGoogleInputs,
            source.title,
            source.validation
          );

        case 'G2':
          return new SourceInput(
            source.id,
            inputs.g2Inputs,
            setInputs.setG2Inputs,
            source.title,
            source.validation
          );

        case 'TrustPilot':
          return new SourceInput(
            source.id,
            inputs.trustPilotInputs,
            setInputs.setTrustPilotInputs,
            source.title,
            source.validation
          );

        default:
          return new SourceInput(
            source.id,
            inputs.targetInputs,
            setInputs.setTargetInputs,
            source.title,
            source.validation
          );
      }
    });

    setSourcesInputs(updatedSourcesInputs);
  }, [
    selectedSources,
    inputs.amazonInputs,
    inputs.audibleInputs,
    inputs.bestBuyInputs,
    inputs.costcoInputs,
    inputs.samsclubInputs,
    inputs.targetInputs,
    inputs.walmartInputs,
    inputs.consumerAffairsInputs,
    inputs.googleInputs,
    inputs.g2Inputs,
    inputs.trustPilotInputs
  ]);

  useEffect(() => {
    if (step === 1 && location.pathname !== SELECT_SOURCE_PATHNAME)
      history.replace(SELECT_SOURCE_PATHNAME);

    if (step === 2 && location.pathname !== URLS_INPUT_PATHNAME)
      history.replace(URLS_INPUT_PATHNAME);

    if (step === 3 && location.pathname !== SELECT_INTERVAL_PATHNAME)
      history.replace(SELECT_INTERVAL_PATHNAME);
  }, [history, step]);

  useEffect(() => {
    if (location.pathname === SELECT_SOURCE_PATHNAME) setStep(1);

    if (location.pathname === URLS_INPUT_PATHNAME && selectedSources?.length)
      setStep(2);

    if (
      location.pathname === SELECT_INTERVAL_PATHNAME &&
      sourcesInputs.length &&
      inputsWithError().length === 0
    )
      setStep(3);
  }, [location.pathname, selectedSources.length]);

  useEffect(() => {
    if (location.pathname === URLS_INPUT_PATHNAME && !selectedSources.length)
      setStep(1);
  }, [location.pathname, selectedSources.length]);

  return (
    <>
      <StreamSourceLayout onGoBack={goBackHandler}>
        <Switch>
          <Route exact path={`${streamDataRoute}/select/reviews`}>
            <Redirect to={SELECT_SOURCE_PATHNAME} />
          </Route>
          <Route path={SELECT_SOURCE_PATHNAME}>
            <ReviewsSourceSelect
              sources={sources}
              selectSource={selectSource}
              nextIsDisabled={!selectedSources.length}
              onNext={nextStepHandler}
            />
          </Route>

          <Route path={URLS_INPUT_PATHNAME}>
            <ReviewsInputData
              onWarning={warningHandler}
              sourcesInputs={sourcesInputs}
              errors={errors}
              checkProjectData={checkProjectData}
              onNext={nextStepHandler}
              projectData={projectData}
              onError={errorHandler}
              selectSource={selectSource}
              setInputs={setInputs}
            />
          </Route>

          <Route path={SELECT_INTERVAL_PATHNAME}>
            <OptionsPage
              isLoading={requestInProcess}
              submitHandler={submitHandler}
              repeat={repeat}
              setRepeat={setRepeat}
              setRepeatInterval={setRepeatInterval}
              repeatInterval={repeatInterval}
            />
          </Route>
        </Switch>
      </StreamSourceLayout>

      <TokenModal
        isOpen={tokenModalIsOpen}
        onClose={() => setTokenModalIsOpen(false)}
        onError={errorHandler}
        onSubmit={submitReviewsTask}
        skipSentiment={tokenModalSkipSentiment}
        shouldNotify={tokenModalShouldNotify}
      />
    </>
  );
};
