import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const MessageContent = styled(Typography)`
  margin: 0;
  font-size: inherit;

  ul li {
    list-style: auto !important;
  }
`;
