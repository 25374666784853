import React from 'react';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../components/organisms/Dialog';
import Chat from '../Chat';
import AssistantInfo from '../AssistantInfo';
import {
  useSelectResetState,
  useSelectIsAwaitingForFeedback,
  useSelectAskToProvideFeedback,
  useSelectActiveChat,
} from '../Chat/store/selectors';
import ExpandIcon from '../../assets/images/expand.ico.png';
import HideIcon from '../../assets/images/hide.ico.png';
import CloseIcon from '../../assets/images/close.ico.png';
import { AIAssistantButton } from './styled';
import HeliosIcon from '../../assets/images/helios.png';

const AIAssistant = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const clearConversationHistory = useSelectResetState();
  const isAwaitingForFeedback = useSelectIsAwaitingForFeedback();
  const askToProvideFeedback = useSelectAskToProvideFeedback();
  const { messages } = useSelectActiveChat();

  const handleClose = () => {
    setVisible(!visible);
  };

  const actions = [
    {
      icon: {
        src: ExpandIcon,
        alt: t('main.header.actions.0'),
      },
      handleClick: () => setExpanded(!expanded),
    },
    {
      icon: {
        src: HideIcon,
        alt: t('main.header.actions.1'),
      },
      handleClick: handleClose,
    },
    {
      icon: {
        src: CloseIcon,
        alt: t('main.header.actions.2'),
      },
      disabled: false,
      handleClick: () => {
        if (!isAwaitingForFeedback && messages.length > 2)
          return askToProvideFeedback();
        clearConversationHistory();
        return handleClose();
      },
    },
  ];

  const animation = {
    open: {
      width: expanded ? '64.6875rem' : '40.625rem',
      height: '51.6rem',
      borderBottomRightRadius: '0.1875rem',
      zIndex: 1111,
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.35,
      },
    },
    closed: {
      width: '56px',
      height: '56px',
      borderBottomRightRadius: '1rem',
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.35,
      },
    },
  };

  return (
    <Suspense fallback="...loading">
      <Dialog
        animation={animation}
        state={{
          visible,
          expanded,
        }}
        actions={{
          header: actions,
        }}
      >
        <Chat key="chat" style={{ flexGrow: 1 }} />
        <AssistantInfo
          key="assistant-info"
          style={{ width: '45%', minWidth: '444px', ml: '1rem' }}
        />
      </Dialog>
      <AIAssistantButton
        color="primary"
        size="medium"
        aria-label="show-assistant"
        onClick={handleClose}
      >
        <img src={HeliosIcon} alt="Helios Icon" style={{ width: '100%', height: '100%' }} />
      </AIAssistantButton>

    </Suspense>
  );
};

export default AIAssistant;
