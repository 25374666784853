import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import AppHeader from '../../molecules/AppHeader';
import { DialogContainer, DialogContent, DialogContentColumn } from './styled';

const Dialog = ({ state, animation, actions, children }) => {
  const components = children
    ? state?.expanded
      ? children
      : [children[0]]
    : null;

  return (
    <DialogContainer
      component={motion.div}
      initial="closed"
      animate={state?.visible ? 'open' : 'closed'}
      variants={animation}
    >
      <AppHeader actions={actions?.header} />
      <DialogContent
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {components.map((child) => {
          return (
            <DialogContentColumn
              key={child?.key}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={child?.props?.style}
            >
              {child}
            </DialogContentColumn>
          );
        })}
      </DialogContent>
    </DialogContainer>
  );
};

Dialog.propTypes = {
  state: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    expanded: PropTypes.bool,
  }).isRequired,
  animation: PropTypes.object,
  actions: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Dialog;
