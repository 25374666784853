import React, { useRef } from 'react';
import { css } from '@emotion/react';
import { uniqueId } from 'lodash';

import { Button } from '../../../components/core/Button';
import SimpleModal from '../../../components/core/SimpleModal';
import { Colors } from '../../../styles';
import { readExcelFileAsCsv } from '../../FileUtils';
import { parseFileContents } from '../../CsvUtils';

const uploadFileFieldCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  box-sizing: border-box;
  background: ${Colors.blue0};
  border: 1px dashed ${Colors.blue1};
  cursor: pointer;
`;

const RedditUrlImportModal = ({ onClose, isOpen, setInputs }) => {
  const fileRef = useRef();

  const handleFileBrowser = () => {
    fileRef.current.click();
  };

  const transformFileToInputs = async file => {
    const readFile = await readExcelFileAsCsv(file);
    const parsedFile = parseFileContents(readFile);
    const inputs = parsedFile.rows.map(row => ({
      name: `url${uniqueId()}`,
      value: row[0].rawValue,
      error: ''
    }));
    setInputs(inputs);
  };

  const fileInputChangeHandler = async event => {
    if (event.target.files.length) {
      await transformFileToInputs(event.target.files[0]);
    }
    onClose();
  };

  const dragFileHandler = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const dropFileHandler = async event => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length) {
      await transformFileToInputs(droppedFiles[0]);
    }
    onClose();
  };

  const CSVData = [
    { url: 'https://www.reddit.com/r/oculus/' },
    { url: 'Your URL...' }
  ];

  const downloadCSVHandler = () => {
    const csvRows = [];
    const headers = Object.keys(CSVData[0]);
    csvRows.push(headers.join(','));

    for (const row of CSVData) {
      const values = headers.map(header => `"${row[header]}"`);
      csvRows.push(values.join(','));
    }

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Reddit-URLs-template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <SimpleModal onHide={onClose} isOpen={isOpen}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
        `}
      >
        <Button onClick={downloadCSVHandler}>Download .csv template</Button>
        <div
          css={css`
            width: 100%;
          `}
        >
          <input
            type="file"
            hidden
            ref={fileRef}
            onChange={fileInputChangeHandler}
          />
          <label
            onDrop={event => dropFileHandler(event)}
            onDragOver={event => dragFileHandler(event)}
            css={uploadFileFieldCSS}
            onClick={handleFileBrowser}
          >
            <div>
              Drag & drop or{' '}
              <span
                css={css`
                  font-weight: 700;
                `}
              >
                Browse
              </span>{' '}
              .csv file
            </div>
          </label>
        </div>
      </div>
    </SimpleModal>
  );
};

export default RedditUrlImportModal;
