import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { formatTimestamp } from '../utils/projectUtils';

export const CheckboxOption = ({
  option,
  selectedValues,
  handleCheckboxChange,
  disabledValues = [],
  checked,
  searchbar = false,
  setSearch,
  search,
  pStyle = '350px',
  labelStyle = {}
}) => {
  const isOptionSelected = selectedValues.includes(option.value);
  const disabledOption = () => {
    return !!disabledValues.find(value => value === option.value);
  };

  return (
    <LabelContainer disabled={disabledOption(option)}>
      <CheckboxLabel style={labelStyle} className={disabledOption(option) ? 'disabled' : ''}>
        <p
          css={css`
            margin: 0;
            width: ${pStyle};
          `}
        >
          {searchbar && (
            <input
              type="text"
              placeholder={'Search...'}
              value={search ? search : ''}
              onChange={e => setSearch(e.target.value)}
            />
          )}
          {option?.label}
        </p>
        {option.action && <div> {option.action}</div>}
        {option.creation_date && (
          <div> {formatTimestamp(option.creation_date)}</div>
        )}
        <input
          type="checkbox"
          value={option.value}
          checked={checked || isOptionSelected}
          onChange={() => handleCheckboxChange(option)}
          disabled={disabledOption(option)}
        />
      </CheckboxLabel>
    </LabelContainer>
  );
};

// Define a CSS class for the disabled state
const disabledStyle = css`
  opacity: 0.5; // Reduce opacity for disabled option
  color: gray; // Change text color to gray for disabled option
  cursor: not-allowed; // Change cursor to not-allowed for disabled option
`;

const LabelContainer = styled.div`
  // Add any common styles for LabelContainer here
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  column-gap: 1rem;

  // Define styles for the disabled state
  &.disabled {
    ${disabledStyle}; // Apply the disabled style here
  }
  &:last-child {
    margin-bottom: 0;
  }

  input[type='checkbox'] {
    margin-right: 0.5rem;
    height: inherit;
  }
`;
