import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { URLs, RoutePatterns } from '../constants';
import ExternalLink from './core/ExternalLink';
import Logout from './Logout';
import Menu from './core/Menu';
import { Icon, IconTypes } from './icons';
import { Colors } from '../styles';

export default function UserMenu({ username }) {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  // dropdown menu for user account links, logout link, etc.
  return (
    <MenuWrapper>
      <Menu
        isOpen={isDropdownVisible}
        updateIsOpen={isDropdownVisible => {
          setIsDropdownVisible(isDropdownVisible);
        }}
        buttonContent={<UserMenuButton username={username} />}
        menuCSS={userMenuCSS}
        trackingItem="global-nav-bar_user-menu_toggle-flyout"
      >
        <MenuItem>
          <Link
            css={menuLinkCSS}
            to={RoutePatterns.PROFILE}
            data-tracking-item="global-nav-bar_user-menu-flyout-item_settings"
          >
            Settings
          </Link>
        </MenuItem>
        <MenuItem>
          <ExternalLink
            css={menuLinkCSS}
            href={URLs.MAIN_SUPPORT}
            data-tracking-item="global-nav-bar_user-menu-flyout-item_support"
          >
            Support
          </ExternalLink>
        </MenuItem>
        <MenuItem>
          <Logout />
        </MenuItem>
      </Menu>
    </MenuWrapper>
  );
}

UserMenu.propTypes = {
  username: PropTypes.string.isRequired
};

const UserMenuButton = ({ username }) => {
  return (
    <>
      {username}{' '}
      <Icon type={IconTypes.CARET_STROKE} size="1rem" direction="down" />
    </>
  );
};

const MenuWrapper = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const MenuItem = styled.div`
  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const menuLinkCSS = css`
  color: ${Colors.blue4};
  margin: 0.5rem 0;

  &:hover {
    text-decoration: none;
  }
`;

const userMenuCSS = css`
  box-sizing: border-box;
  right: 0;
  white-space: nowrap;
  padding: 1em;
  margin-top: 0.5em;
`;
