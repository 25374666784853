import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Button } from './core/Button';
import { Colors } from '../styles';
import { Icon, IconTypes } from './icons';

export default function FilterToolHeader({ expandedFields, disableClearFilter, onClear, onSelectAll }) {
  const handleClick = expandedFields.all ? expandedFields.collapseAll : expandedFields.expandAll;

  return (
    <div
      css={css`
        display: flex;
        padding: 0.5rem;
        gap: 1rem;
        justify-content: space-between;
        border-bottom: 2px solid ${Colors.gray2};
      `}
    >
      <Button
        onClick={handleClick}
        css={css`
          svg {
            margin-right: 0.25rem;
            fill: none;
          }

          &:hover svg,
          &:active svg {
            fill: none;
          }
        `}
      >
        <Icon type={IconTypes.CARET_STROKE} direction={expandedFields.all ? 'right' : 'down'} size="14" />
        {expandedFields.all ? 'Collapse all' : 'Expand all'}
      </Button>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        <Button onClick={onSelectAll} data-tracking-item="filter-pane_clear-filter-button">
          <Icon type={IconTypes.CHECK} size="18" />
          Select all
        </Button>
        <Button disabled={disableClearFilter} onClick={onClear} data-tracking-item="filter-pane_clear-filter-button">
          <Icon type={IconTypes.ROTATE} size="18" />
          Clear all
        </Button>
      </div>
    </div>
  );
}

FilterToolHeader.propTypes = {
  expandedFields: PropTypes.shape({
    all: PropTypes.bool.isRequired,
    expandAll: PropTypes.func.isRequired,
    collapseAll: PropTypes.func.isRequired
  }),
  disableClearFilter: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired
};
