import PropTypes from 'prop-types';
import { InfoMessageContainer } from './styled';

const WithSytemInfo = ({ children, className }) => {
  return (
    <InfoMessageContainer variant="body2" className={className || ''}>
      {children}
    </InfoMessageContainer>
  );
};

WithSytemInfo.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { WithSytemInfo };
