import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ChatStatusInfoNotReadyContainer = styled(Box)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  text-align: center;
`;
