import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { StoreContext } from '../../StoreContext';
import { BubbleRadio } from '../../components/core/Radio';
import Dropdown from '../../components/core/Dropdown';
import { useFilter, useSearchParams } from '../../search_params';
import { TabLink } from '../TabTitles';
import { SettingsLabel } from '../../components/SettingsLabel';
import { FieldSelectorOptions } from './FieldSelector';
import { instanceOf } from '../../drivers/utils';
import { NumericField, ScoreField } from '../../classes/MetadataFields';
import { Icon, IconTypes } from '../../components/icons';
import { ConfigRadioWrapper } from './ConfigWrapper';
import { Colors } from '../../styles';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../utils/sentimentStatus';
import { useCurrentFeature } from '../../utils/hooks';
import { BubbleInput } from '../../components/core/BubbleInput';
import { Checkbox } from '../../components/core/Checkbox';
import PrevalentSizeControl from './PrevelantSizeControl';

export function WhichConceptsToVisualize() {
  return (
    <fieldset>
      <SettingsLabel as="legend">Which concepts to visualize</SettingsLabel>
      {[
        TopConceptsByPrevalence,
        ConversationClusters,
        ConceptsLinkedToScores,
        ConceptsLinkedToStrongFeeling,
        UniqueToFilterRadio,
        ActiveConceptTypeRadio
      ].map((Radio, i) => (
        <ConfigRadioWrapper key={i}>
          <Radio />
        </ConfigRadioWrapper>
      ))}
    </fieldset>
  );
}

function ConceptTypeRadio({ label, type, disabled = false }) {
  const { searchParams, updateSearch } = useSearchParams();
  return (
    <BubbleRadio
      name="Concept type"
      label={label}
      checked={type === searchParams.concepts}
      onChange={() => {
        updateSearch({ concepts: type });
      }}
      disabled={disabled}
    />
  );
}

ConceptTypeRadio.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

function ActiveConceptTypeRadio() {
  const { activeConcepts } = React.useContext(StoreContext);
  const feature = useCurrentFeature();

  const { searchParams } = useSearchParams();
  const selectedActive = searchParams.concepts === 'active';
  const compoundConcepts = activeConcepts.filter(concept => concept.boolean);

  let disabled;
  if (feature === 'galaxy') {
    disabled = activeConcepts.length === compoundConcepts.length;
  } else {
    disabled = activeConcepts.length === 0;
  }

  return (
    <>
      <ConceptTypeRadio
        type="active"
        label={`Active concepts (${activeConcepts.length})`}
        disabled={disabled}
      />
      <SettingsOptionNote>
        Open shared lists from the <TabLink tab="activeConcepts" /> tab
      </SettingsOptionNote>
      {feature === 'galaxy' && compoundConcepts.length > 0 && (
        <SettingsOptionNote>
          {`${compoundConcepts.length} edited concept${
            compoundConcepts.length > 1 ? 's' : ''
          } will not be shown in `}
          <Icon type={IconTypes.GALAXY} size="1rem" />
          Galaxy
        </SettingsOptionNote>
      )}
      {feature !== 'galaxy' && selectedActive && <IncludeOutliers />}

    </>
  );
}

const MIN_COUNT = 5;
const MAX_COUNT = 500;

function TopConceptsByPrevalence() {
  const { searchParams, updateSearch } = useSearchParams();
  const currentCount= Number(searchParams.prevalent_count)

  const decreaseCount = () => {
    if (currentCount > MIN_COUNT) {
      const step = currentCount > 100 ? 10 : 1;
      const newCount = currentCount - step;
      updateSearch({ prevalent_count: newCount.toString() });
    }
  };

  const increaseCount = () => {
    if (currentCount < MAX_COUNT) {
      const step = currentCount >= 100 ? 10 : 1;
      const newCount = currentCount + step;
      updateSearch({ prevalent_count: newCount.toString() });
    }
  };

  const handleBlur = (newSize) => {
    if (newSize < MIN_COUNT) newSize = MIN_COUNT;
    if (newSize > MAX_COUNT) newSize = MAX_COUNT;
    updateSearch({ prevalent_count: newSize.toString() });
  };

  return (
    <ConceptsDropdownRow>
      <ConceptTypeRadio
        type="prevalent"
        label={
          <LabelContainer>
            Top concepts by prevalence
            <Icon type={IconTypes.VOLUME} size="1rem" />
            <Icon type={IconTypes.GALAXY} size="1rem" />
          </LabelContainer>
        }
      />
      <PrevalentSizeControl
        disabled={searchParams.concepts !== 'prevalent'}
        size={currentCount}
        minSize={MIN_COUNT}
        maxSize={MAX_COUNT}
        handleIncrease={increaseCount}
        handleDecrease={decreaseCount}
        handleBlur={handleBlur}
      />
    </ConceptsDropdownRow>
  );
}

function ConceptsLinkedToScores() {
  const { searchParams, updateSearch } = useSearchParams();
  const { metadata } = React.useContext(StoreContext);

  const numberFields = metadata.filter(instanceOf(NumericField));
  const scoreFields = metadata.filter(instanceOf(ScoreField));
  const hasNoDriversFields = numberFields.length + scoreFields.length === 0;

  const selected = searchParams.concepts === 'drivers';

  return (
    <>
      <ConceptTypeRadio
        type="drivers"
        label={
          <LabelContainer>
            Concepts linked to scores
            <Icon type={IconTypes.DRIVERS} size="1rem" />
          </LabelContainer>
        }
        disabled={hasNoDriversFields}
      />
      <ConceptsDropdownRow
        css={css`
          padding: 5px;
          margin-left: 2rem;
        `}
      >
        <Dropdown
          containerCss={css`
            flex: 1;
            margin-right: 1rem;
          `}
          css={css`
            width: 100%;
            background-color: ${searchParams.sync_fields === 'yes'
              ? Colors.green0
              : null};
          `}
          value={searchParams.drivers_of}
          onChange={event => {
            const drivers_of = event.target.value;
            const updates = { drivers_of };
            if (searchParams.sync_fields === 'yes') {
              updates.field = drivers_of;
            }
            updateSearch(updates);
          }}
          disabled={searchParams.concepts !== 'drivers'}
          aria-label="Visualize drivers of"
        >
          <FieldSelectorOptions />
        </Dropdown>
        <Dropdown
          css={css`
            min-width: 5.5rem;
          `}
          value={searchParams.drivers_count}
          onChange={event =>
            updateSearch({ drivers_count: event.target.value })
          }
          disabled={!selected}
          aria-label="Number of driver suggestions"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">Up to 50</option>
        </Dropdown>
      </ConceptsDropdownRow>
    </>
  );
}

export const ConceptsDropdownRow = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  justify-content: space-between;
`;

function UniqueToFilterRadio() {
  const filter = useFilter();
  const emptyFilter = filter.length === 0;

  return (
    <>
      <ConceptTypeRadio
        type="unique_to_filter"
        label={`Unique to this filter${
          emptyFilter ? ' (requires a filter)' : ''
        }`}
        disabled={emptyFilter}
      />
      <SettingsOptionNote>
        Set filter on the <TabLink tab="filter" /> tab
      </SettingsOptionNote>
    </>
  );
}

function ConversationClusters() {
  return <ConceptTypeRadio type="clusters" label="Conversation clusters" />;
}

function ConceptsLinkedToStrongFeeling() {
  const { project } = useContext(StoreContext);
  const status = getSentimentStatus(project);

  return (
    <ConceptTypeRadio
      type="sentiment"
      label={
        <LabelContainer>
          Concepts linked to strong feeling
          <Icon type={IconTypes.SENTIMENT} size="1rem" />
        </LabelContainer>
      }
      disabled={status !== SENTIMENT_STATUS.READY}
    />
  );
}

export function IncludeOutliers() {
  const { searchParams, updateSearch } = useSearchParams();
  const handleOutlierChange = async value => {
    let newValue = searchParams.outlier_mode === value ? null : value;
    updateSearch({ outlier_mode: newValue });
  };
  return (
    <fieldset
      css={css`
        margin: 0.5rem 0;
        padding: 10px;
        border-radius: 8px;
      `}
    >
      <SettingsLabel as="legend">Outlier Options</SettingsLabel>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <BubbleInput
          as={Checkbox}
          label="Display Only Outliers"
          checked={searchParams.outlier_mode === 'only'}
          onChange={() => handleOutlierChange('only')}
        />
        <BubbleInput
          as={Checkbox}
          label="Include Outliers in Display"
          checked={searchParams.outlier_mode === 'include'}
          onChange={() => handleOutlierChange('include')}
        />
      </div>
    </fieldset>
  );
}

const SettingsOptionNote = styled.div`
  font-style: italic;
  margin: 0.5rem 0;
  margin-left: 4rem;
  font-size: 0.75rem;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 1rem;
  }
`;
