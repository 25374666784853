import _ from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { Button } from '../../components/core/Button';
import { getSharedViews, saveSharedView } from '../../actions';
import { useOverlayAlertController } from '../../components/core/Alert';
import { Icon, IconTypes } from '../../components/icons';
import { Popover } from '../../components/core/Popover';
import { EditPanel } from './EditPanel';
import { ViewSelector } from './ViewSelector';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { StoreContext } from '../../StoreContext';
import { SidePanelHeaderButton } from '../SidePanelHeaderButton';

export function ShareViewButton({ popoverOpen, togglePopover, closePopover }) {
  const buttonRef = useRef();
  const { projectId } = useParams();
  const alertController = useOverlayAlertController();
  const [selectedViewName, setSelectedViewName] = useState(null);
  // `viewToSave` is only used when trying to save a view whose name is already
  // in use and therefore requires the user's confirmation to overwrite the
  // existing view (through a modal, which needs to access that view).
  const [viewToSave, setViewToSave] = useState(null);
  const showOverwriteModal = viewToSave !== null;
  const { sharedViews } = useContext(StoreContext);
  const existingView = _.find(sharedViews, { name: selectedViewName });
  const [selectedViewNote, setSelectedViewNote] = useState(
    existingView?.note || ''
  );

  const dispatchUndoBanner = useDispatchUndoBanner();

  function closeModal() {
    setViewToSave(null);
  }

  function resetState() {
    setSelectedViewName(null);
    setViewToSave(null);
    alertController.clearAlert();
  }

  function togglePopoverAndReset() {
    togglePopover();
    resetState();
  }

  function closePopoverAndReset() {
    closePopover();
    resetState();
  }

  function showUndoRenameBanner(view, sharedViews) {
    const oldName = selectedViewName;
    const oldNameNote = selectedViewNote;
    const newName = view.name;
    const newNameNote = view.note;

    dispatchUndoBanner({
      message: `Renamed "${oldName}" to "${newName}"`,
      onUndo: () => {
        // Uses 2 separate calls to undo rename. If only the second one fails,
        // we might undo the rename, but fail to restore the clobbered view.
        const oldView = { ...view, name: oldName, note: oldNameNote };
        const clobberedView = _.find(sharedViews, {
          name: newName,
          note: newNameNote
        });

        return saveSharedView(projectId, oldView)
          .then(() => {
            setSelectedViewName(oldName);
            setSelectedViewNote(selectedViewNote);
          })
          .then(() => {
            if (clobberedView) {
              return saveSharedView(projectId, clobberedView);
            }
          });
      },
      trackingItem: 'undo-rename-view'
    });
  }

  function showUndoEditBanner(view) {
    dispatchUndoBanner({
      message: `Updated "${view.name}"`,
      onUndo: () => saveSharedView(projectId, existingView),
      trackingItem: 'undo-edit-view'
    });
  }

  function actuallySaveView(view, sharedViews) {
    saveSharedView(projectId, view)
      .then(() => {
        const action = view.shared_view_id ? 'edited' : 'created';
        const isRename = view.name !== selectedViewName;
        setSelectedViewName(view.name);
        setSelectedViewNote(view?.note);
        alertController.showSuccess(`Successfully ${action} view`);
        if (isRename) {
          showUndoRenameBanner(view, sharedViews);
        } else if (action === 'edited') {
          showUndoEditBanner(view);
        }
      })
      .catch(() => {
        alertController.showWarning('Unable to share view');
      })
      .finally(closeModal);
  }

  function saveView(view) {
    return getSharedViews(projectId).then(sharedViews => {
      const viewWithSameName = _.find(
        sharedViews,
        sharedView =>
          sharedView.name === view.name &&
          sharedView.shared_view_id !== view.shared_view_id
      );

      if (viewWithSameName) {
        setViewToSave(view);
      } else {
        actuallySaveView(view, sharedViews);
      }
    });
  }

  return (
    <>
      <SidePanelHeaderButton
        ref={buttonRef}
        onClick={togglePopoverAndReset}
        trackingItem="shared-views_share-button"
      >
        Share
      </SidePanelHeaderButton>
      {popoverOpen && (
        <Popover buttonRef={buttonRef} width="35rem">
          <ClosePopoverButton onClosePopover={closePopoverAndReset} />
          {selectedViewName == null ? (
            <ViewSelector
              onSelectView={setSelectedViewName}
              onSelectViewNote={setSelectedViewNote}
            />
          ) : (
            <EditPanel
              viewName={selectedViewName}
              viewNote={selectedViewNote}
              onSave={saveView}
              alertController={alertController}
              closePopover={closePopoverAndReset}
            />
          )}
        </Popover>
      )}
      <ConfirmationDialog
        confirmText="Replace"
        showing={showOverwriteModal}
        onConfirm={() => actuallySaveView(viewToSave, sharedViews)}
        onCancel={closeModal}
      >
        The shared view "{viewToSave?.name}" already exists. Do you want to
        replace it?
      </ConfirmationDialog>
    </>
  );
}

function ClosePopoverButton({ onClosePopover }) {
  const iconSize = '0.875rem';
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      `}
    >
      <Button
        flavor="subtle"
        onClick={onClosePopover}
        aria-label="Close"
        css={css`
          padding: 0;
        `}
      >
        <Icon type={IconTypes.CLOSE} size={iconSize} />
      </Button>
    </div>
  );
}
