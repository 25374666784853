import { useEffect, useRef, useState } from 'react';

import usePrevious from './usePrevious';

function useScrollTo() {
  const ref = useRef(null);
  const [scrollToLast, setScrollToLast] = useState(0);
  const prevScrollToLast = usePrevious(scrollToLast);

  const handleScrollToLast = () => {
    if (ref.current) {
      const parentElement = ref.current.parentNode;
      const scrollHeight = ref.current.scrollHeight;
      parentElement.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: scrollHeight,
      });
    }
  };

  useEffect(() => {
    // if (scrollToLast !== prevScrollToLast) {
    //   // handleScrollToLast();
    // }
    handleScrollToLast();
  }, [prevScrollToLast, scrollToLast]);

  return [ref, setScrollToLast];
}

export default useScrollTo;
