import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Combobox as ReachCombobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover
} from '@reach/combobox';
import PropTypes from 'prop-types';
import Sifter from 'sifter';

import { ZIndexes } from '../../../styles';
import { Button } from '../../../components/core/Button';

export const AddMergeAutosuggest = ({
  options,
  placeholder,
  submitSelection
}) => {
  const [inputTextOne, setInputTextOne] = useState('');
  const [inputTextTwo, setInputTextTwo] = useState('');

  const getSuggestions = (options, inputText) =>
    new Sifter(options.map(option => ({ value: option })))
      .search(inputText, {
        fields: ['value'],
        sort: [{ field: 'value', direction: 'asc' }]
      })
      .items.map(({ id: index }) => options[index])
      .slice(0, 10);

  const suggestionsOne = getSuggestions(options, inputTextOne);
  const suggestionsTwo = getSuggestions(options, inputTextTwo);

  // Check if both input fields have values to enable the "Select" button
  const isSelectDisabled = !inputTextOne || !inputTextTwo;

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        // Pass an object with both values to the submitSelection function
        submitSelection({ concept: inputTextOne, merge_with: inputTextTwo });
        setInputTextOne('');
        setInputTextTwo('');
      }}
    >
      <ComboboxContainer>
        <ReachCombobox onSelect={selection => setInputTextOne(selection)}>
          <ComboboxInput
            aria-label="First concept to merge"
            autocomplete="off"
            value={inputTextOne}
            placeholder={placeholder}
            onChange={event => setInputTextOne(event.target.value)}
          />
          {inputTextOne && suggestionsOne.length > 0 && (
            <ComboboxPopover css={popoverStyle}>
              <ComboboxList>
                {suggestionsOne.map(option => (
                  <ComboboxOption value={option} key={option} />
                ))}
              </ComboboxList>
            </ComboboxPopover>
          )}
        </ReachCombobox>
      </ComboboxContainer>
      <ComboboxContainer>
        <ReachCombobox onSelect={selection => setInputTextTwo(selection)}>
          <ComboboxInput
            aria-label="Second concept to merge"
            autocomplete="off"
            value={inputTextTwo}
            placeholder={placeholder}
            onChange={event => setInputTextTwo(event.target.value)}
          />
          {inputTextTwo && suggestionsTwo.length > 0 && (
            <ComboboxPopover css={popoverStyle}>
              <ComboboxList>
                {suggestionsTwo.map(option => (
                  <ComboboxOption value={option} key={option} />
                ))}
              </ComboboxList>
            </ComboboxPopover>
          )}
        </ReachCombobox>
      </ComboboxContainer>
      <Button type="submit" disabled={isSelectDisabled}>
        Select
      </Button>
    </Form>
  );
};

AddMergeAutosuggest.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  submitSelection: PropTypes.func.isRequired
};

const Form = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
`;

const ComboboxContainer = styled.div`
  flex-grow: 1;
  [data-reach-combobox] {
    width: 100%;
    margin-right: 1rem;
  }
  input {
    height: 2rem;
    margin: 0;
    width: 100%;
  }
`;

const popoverStyle = css`
  z-index: ${ZIndexes.modal + ZIndexes.flyout};
`;
