import React, { useState } from 'react';
import { css } from '@emotion/react';

import { Button } from '../../../components/core/Button';
import { HRSitesInputDataContent } from './HRSitesInputDataContent';
import { AlertTypes } from '../../../constants';
import Alert from '../../../components/core/Alert';
import HRSitesUrlImportModal from './HRSitesUrlImportModal';

export const HRSitesInputData = ({
  projectData,
  sourcesInputs,
  checkProjectData,
  errors,
  onNext,
  selectSource,
  setInputs,
  onError,
  onWarning
}) => {
  const [isImportUrlsOpen, setIsImportUrlsOpen] = useState(false);

  const openImportHandler = () => {
    setIsImportUrlsOpen(true);
  };
  const closeImportHandler = () => {
    setIsImportUrlsOpen(false);
  };

  return (
    <>
      <Button onClick={openImportHandler}>Import URLs</Button>

      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          flex-wrap: wrap;
          gap: 2rem;
          padding: 2rem;
          justify-items: center;
          @media (min-width: 850px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (min-width: 1250px) {
            grid-template-columns: repeat(
              ${sourcesInputs.length >= 3 ? 3 : sourcesInputs.length},
              1fr
            );
          }
        `}
      >
        {sourcesInputs.map(({ id, inputs, setInputs, title }) => (
          <HRSitesInputDataContent
            key={id}
            inputs={inputs}
            setInputs={setInputs}
            projectData={projectData}
            checkProjectData={checkProjectData}
            title={title}
            selectSource={selectSource}
          />
        ))}
      </div>

      <div
        css={css`
          display: flex;
          margin-top: 20px;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
        `}
      >
        <div>
          {errors?.length > 0 && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 20px;
              `}
            >
              {errors.map(error => (
                <Alert key={error} type={AlertTypes.ERROR}>
                  {error}
                </Alert>
              ))}
            </div>
          )}
        </div>
        <Button
          css={css`
            width: 100%;
            max-width: 350px;
          `}
          disabled={errors?.length > 0}
          onClick={onNext}
        >
          Next
        </Button>
      </div>

      <HRSitesUrlImportModal
        onWarning={onWarning}
        isOpen={isImportUrlsOpen}
        sourcesInputs={sourcesInputs}
        selectSource={selectSource}
        onClose={closeImportHandler}
        setInputs={setInputs}
        onError={onError}
      />
    </>
  );
};
