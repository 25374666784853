import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../components/core/Tooltip';
import * as DocUtils from '../utils/DocUtils';

export const ParagraphList = ({
  paragraphList,
  language,
  beforeFirst,
  afterLast,
  isSentimentIndication
}) => {
  return (
    <div className={DocUtils.setRtlClass('doc-text', language)}>
      {paragraphList.map((paragraph, idx) => (
        <p key={idx}>
          {idx === 0 && beforeFirst}
          <Paragraph paragraph={paragraph} isSentimentIndication={isSentimentIndication} />
          {idx === paragraphList.length - 1 && afterLast}
        </p>
      ))}
    </div>
  );
};

ParagraphList.propTypes = {
  paragraphList: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
  language: PropTypes.string.isRequired,
  beforeFirst: PropTypes.node,
  afterLast: PropTypes.node
};

const Paragraph = ({ paragraph, isSentimentIndication }) => {

  return paragraph.map(([type, matchText, sentiment], index) => (
    <React.Fragment key={index}>
      {type === 'text' ? (
        matchText
      ) : (
        <Tooltip
          className={`doc-text__match doc-text__match--${type}`+` `+ `doc-text__match-${isSentimentIndication ? sentiment: '' }`}
          anchor={matchText}
        >
          <p>
            {type === 'exact'
              ? 'This concept exactly matches your search.'
              : type === 'conceptual'
              ? 'This concept is related to your search.'
              : undefined}
          </p>
        </Tooltip>
      )}
    </React.Fragment>
  ));
};
