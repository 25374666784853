import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { StoreContext } from '../StoreContext';
import { NoResultsMessage } from './NoResultsMessage';
import DocSearchResults from '../documents/DocSearchResults';
import SidePanelSection from '../components/SidePanelSection';
import { TabPanel } from './Tabs';
import { notifier } from '../actions';
import { Button } from '../components/core/Button';
import DocDownloadModal from '../components/DocDownloadModal';
import { ProjectBrancher } from '../components/ProjectBrancher';
import { Colors } from '../styles';
import { downloadDocuments } from '../utils/ApiUtilsV5';
import { MetadataFilter } from '../documents/MetadataFilter';
import { useMetadataSelections } from '../documents/MetadataFilter/useMetadataFilter';
import { useFilter } from '../search_params';

export function DocumentsPanel({ showOverlay }) {
  const { searchInProgress, filterIsLoading } = useContext(StoreContext);
  const isLoading = filterIsLoading || searchInProgress;
  const selectedFields = useMetadataSelections();

  return (
    <TabPanel
      header={
        <>
          Documents
          <MetadataFilter selectedFields={selectedFields} />
        </>
      }
      showOverlay={showOverlay}
      css={css`
        display: flex;
        flex-direction: column;
      `}
      body={
        <>
          <DownloadOrCreate />
          <SidePanelSection
            css={css`
              flex: 1;
            `}
            asList={false}
          >
            <DocSearchResults
              loading={isLoading}
              noResultsMessage={
                <NoResultsMessage>
                  No matching documents for current settings
                </NoResultsMessage>
              }
              docViewerMetadata={selectedFields.viewer.fields}
              sidePanelMetadata={selectedFields.sidePanel.fields}
            />
          </SidePanelSection>
        </>
      }
    />
  );
}

function DownloadOrCreate() {
  const { workspaceId, projectId } = useParams();
  const filter = useFilter();
  const {
    workspaces,
    selection,
    project,
    filterCount,
    docSearchResults,
    userDefaultWorkspaceId,
    filterIsLoading,
    searchInProgress
  } = useContext(StoreContext);
  const isLoading = filterIsLoading || searchInProgress;
  const [downloading, setDownloading] = useState(false);
  const [openedModal, setOpenedModal] = useState('none');

  const downloadButton = (
    <Button
      flavor="subtle"
      css={css`
        text-decoration: underline;
      `}
      padded={false}
      onClick={() => {
        setOpenedModal('download');
      }}
      data-tracking-item="concept-detail-pane_download-matching-documents_open-modal"
      disabled={downloading}
    >
      Download
    </Button>
  );
  const createProjectbutton = (
    <Button
      flavor="subtle"
      css={css`
        text-decoration: underline;
      `}
      padded={false}
      onClick={() => {
        setOpenedModal('create project');
      }}
      data-tracking-item="concept-detail-pane_new-project-from-documents_open-modal"
    >
      create project
    </Button>
  );

  let subtitle;
  if (selection !== null) {
    subtitle = (
      <>
        Your new project will only have documents from your current view that
        match the concept <strong>"{selection.name}"</strong>.
      </>
    );
  } else {
    subtitle =
      'Your new project will only have documents from your current view.';
  }

  return (
    <div
      css={css`
        padding: 0.5rem;
        border-bottom: 1px solid ${Colors.gray2};
        font-size: 0.875rem;
      `}
    >
      {selection == null && _.isEmpty(filter) ? (
        <>{downloadButton} matching documents</>
      ) : (
        <>
          {downloadButton} or {createProjectbutton} from matching documents
        </>
      )}
      {!isLoading && docSearchResults && (
        <>
          <DocDownloadModal
            onHide={() => {
              setOpenedModal('none');
            }}
            showing={openedModal === 'download'}
            startDownload={(extension, matchType) => {
              setOpenedModal('none');
              setDownloading(true);
              downloadDocuments(
                projectId,
                project.name,
                selection,
                filter,
                extension,
                matchType
              )
                .catch(() => notifier.error('Downloading documents failed'))
                .finally(() => setDownloading(false));
            }}
          />
          {openedModal === 'create project' && (
            <ProjectBrancher
              workspaces={workspaces}
              userDefaultWorkspaceId={userDefaultWorkspaceId}
              srcProjectId={projectId}
              srcProjectWorkspaceId={workspaceId}
              srcProjectName={project.name}
              srcProjectDescription={project.description}
              filter={filter}
              filterCount={filterCount}
              selection={selection}
              trackingPrefix="concept-detail-pane_new-project-from-documents"
              onClose={() => setOpenedModal('none')}
              title="Create project from matching documents"
              subtitle={subtitle}
            />
          )}
        </>
      )}
    </div>
  );
}
