import React from 'react';

import { ShortcutsContainer, ShortcutsGroupTitle, Shortcut } from './styled';
import Stack from '@mui/material/Stack';
import useAssistantShortcuts from './hooks';

import { motion, AnimatePresence } from 'framer-motion';

const AssistantShortcuts = () => {
  const [shortcuts, onSelectShortcut, selectionDisabled] =
    useAssistantShortcuts();

  return (
    <ShortcutsContainer component="ul">
      <AnimatePresence>
        {Array.isArray(shortcuts) && shortcuts.length > 0 ? (
          shortcuts?.map(({ category, questions }) => (
            <li key={category}>
              <ShortcutsGroupTitle>{category}</ShortcutsGroupTitle>
              <Stack direction="row" flexWrap="wrap">
                {questions.map((question, idx) => (
                  <Shortcut
                    disabled={selectionDisabled}
                    key={`${question}_${idx}`}
                    label={question}
                    onClick={() => onSelectShortcut(question)}
                    component={motion.div}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ type: 'spring', damping: 20, stiffness: 80 }}
                  />
                ))}
              </Stack>
            </li>
          ))
        ) : (
          <></>
        )}
      </AnimatePresence>
    </ShortcutsContainer>
  );
};

export default AssistantShortcuts;
