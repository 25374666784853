import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { StoreContext } from '../../StoreContext';
import { Button } from '../../components/core/Button';
import { useConceptsWithRelevance } from '../../data_hooks';
import { addFromVisualization, restoreActiveConceptList } from '../../actions';
import Tooltip from '../../components/core/Tooltip';
import { useDispatchUndoBanner } from '../../UndoBanner';
import { Icon, IconTypes } from '../../components/icons';

export const AddFromVisualizationButton = ({ disabled = false }) => {
  const { projectId } = useParams();
  const { activeConcepts, activeConceptListName, suggestions } =
    useContext(StoreContext);
  const conceptsBeingVisualized = useConceptsWithRelevance();
  const dispatchUndoBanner = useDispatchUndoBanner();

  if (!conceptsBeingVisualized) {
    return null;
  }

  const activeConceptTexts = new Set(activeConcepts.map(ac => ac.toString()));
  const unsavedConceptsBeingVisualized = conceptsBeingVisualized?.filter(
    c => !activeConceptTexts.has(c.toString())
  );
  const conceptsToAddCount = unsavedConceptsBeingVisualized.length;
  const skippedConceptCount =
    conceptsBeingVisualized.length - conceptsToAddCount;
  const conceptLabel = conceptsToAddCount === 1 ? 'concept' : 'concepts';

  const copyConcepts = () => {
    addFromVisualization(
      projectId,
      conceptsBeingVisualized,
      activeConcepts,
      suggestions
    ).then(() => {
      let message = `Added ${conceptsToAddCount} ${conceptLabel} to Active Concepts`;
      if (skippedConceptCount > 0) {
        message += `\nDuplicate concepts (${skippedConceptCount}) were skipped`;
      }

      dispatchUndoBanner({
        message,
        onUndo: () =>
          restoreActiveConceptList(
            projectId,
            activeConceptListName,
            activeConcepts
          )
      });
    });
  };

  return (
    <Tooltip
      anchor={
        <Button
          onClick={copyConcepts}
          flavor="subtle"
          padded={false}
          css={css`
            &:disabled {
              pointer-events: none;
            }
          `}
          disabled={disabled || conceptsToAddCount === 0}
        >
          <Icon type={IconTypes.ADD} />
          Add from visualization
        </Button>
      }
    >
      {unsavedConceptsBeingVisualized.length > 0 ? (
        <>
          Click here to copy {conceptsToAddCount} visualized {conceptLabel} into
          your active concepts.
          {skippedConceptCount > 0 && (
            <> Duplicate concepts ({skippedConceptCount}) will be skipped</>
          )}
        </>
      ) : (
        'Visualized concepts are already in your active concepts'
      )}
    </Tooltip>
  );
};

AddFromVisualizationButton.propTypes = {
  disabled: PropTypes.bool
};
