import { CHAT_MESSAGE_TYPE } from '../../../types';

const getMessageType = (text, amountOfMessages, currentType) => {
  const conversationJustStarted = amountOfMessages <= 3;
  if (conversationJustStarted && text) {
    return text.toLowerCase().includes('yes')
      ? CHAT_MESSAGE_TYPE.EXEC_SUMMARY
      : CHAT_MESSAGE_TYPE.START_CONVERSATION;
  }

  return currentType;
};

export { getMessageType };
