import _ from 'lodash';
import React from 'react';
import { css } from '@emotion/react';

import { thousandify } from '../utils/NumFmtUtils';

export function validateDataset({ rows, columns, metadata }) {
  let issues = [];

  if (rows.length === 0) {
    issues.push(makeError('This dataset has no documents.', 'no-docs'));
  }

  const textColumn = columns.find(c => c.type === 'text');
  if (!textColumn) {
    issues.push(makeError('A Text data type column is necessary.', 'no-text'));
  }

  const existingFieldNames = metadata.map(m => m.name);
  const uploadedFieldNames = columns
    .filter(c => !(c.type === 'title' || c.type === 'text'))
    .map(c => c.name);
  const missingFields = _.difference(existingFieldNames, uploadedFieldNames);
  if (missingFields.length > 0) {
    const warning = (
      <>
        This dataset file is missing some fields that exist in your project.
        Your uploaded documents will have blank data for the following:
        <ul
          css={css`
            line-height: 1.2;
          `}
        >
          {missingFields.map(name => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </>
    );
    issues.push(makeWarning(warning, 'missing fields'));
  }

  return issues;
}

export function makeError(message, messageId, groupKey) {
  return {
    type: 'error',
    message,
    groupKey,
    id: `error-${groupKey ?? 'standalone'}-${messageId}`
  };
}

export function makeWarning(message, messageId, groupKey) {
  return {
    type: 'warning',
    message,
    messageId,
    groupKey,
    id: `warning-${groupKey ?? 'standalone'}-${messageId}`
  };
}

export function validateGroup(group, stats, groups, existingMetadata) {
  const existingField = existingMetadata?.find(f => f.name === group.name);
  const formattedType = _.upperFirst(group.type);
  const issues = [];
  if (stats?.tooLongValuesCount > 0) {
    const formattedNumber = thousandify(stats.tooLongValuesCount);
    issues.push(
      makeError(
        <>
          The maximum number of characters for a Text field is 500,000.{' '}
          {stats.tooLongValuesCount === 1 ? (
            <>
              There is <strong>{formattedNumber}</strong> Text field over this
              limit.
            </>
          ) : (
            <>
              There are <strong>{formattedNumber}</strong> Text fields over this
              limit.
            </>
          )}
        </>,
        'text-field-too-long',
        group.key
      )
    );
  }
  if (['title', 'text'].includes(group.type)) {
    const fieldCount = groups.filter(g => g.type === group.type).length;
    if (fieldCount > 1) {
      issues.push(
        makeError(
          <>
            Only one {formattedType} type field per project is allowed. There
            are <strong>{fieldCount}</strong>.
          </>,
          `one-${formattedType}-allowed`,
          group.key
        )
      );
    }
  }
  if (group.isMetadata && existingMetadata) {
    if (!existingField) {
      issues.push(
        makeWarning(
          <>
            This field does not exist in your project. Previously uploaded
            documents will have blank data for this field.
          </>,
          'new field',
          group.key
        )
      );
    } else if (group.originalTypes.some(t => t !== existingField.type)) {
      const mismatchTypes = group.originalTypes.filter(
        t => t !== existingField.type
      );

      mismatchTypes.forEach(t => {
        issues.push(
          makeWarning(
            <>
              This field already exists in the project as{' '}
              <strong>{_.upperFirst(existingField.type)}</strong>, but the file
              says it should be <strong>{_.upperFirst(t)}</strong>. It will be
              kept as <strong>{_.upperFirst(existingField.type)}</strong>.
            </>,
            `field-${t} already exists`,
            group.key
          )
        );
      });
    }
  }
  if (group.name === '') {
    issues.push(
      makeWarning(
        'Columns without headers cannot be uploaded.',
        'no header',
        group.key
      )
    );
  }
  if (group.indices.length > 1) {
    issues.push(
      makeWarning(
        <>
          There are <strong>{group.indices.length}</strong> columns with the
          same header. They will be combined when uploaded. You will not be able
          to separate them afterward.
        </>,
        'multiple columns in group',
        group.key
      )
    );
  }
  if (group.type === 'string' && stats?.uniqueValues.length > 10000) {
    issues.push(
      makeWarning(
        <>
          This field has too many unique strings to filter on. Consider setting
          this field to <strong>Skip</strong>.
        </>,
        'too many unique strings',
        group.key
      )
    );
  }
  if (stats?.unparsableValuesCount > 0) {
    const formattedNumber = thousandify(stats.unparsableValuesCount);
    issues.push(
      makeWarning(
        <>
          {stats.unparsableValuesCount === 1 ? (
            <>
              There is <strong>{formattedNumber}</strong> value we are unable to
              interpret as a <strong>{formattedType}</strong>. It will be left
              blank when uploaded.
            </>
          ) : (
            <>
              There are <strong>{formattedNumber}</strong> values we are unable
              to interpret as <strong>{formattedType}s</strong>. They will be
              left blank when uploaded.
            </>
          )}
        </>,
        'unparsable values',
        group.key
      )
    );
  }
  if (group.type === 'text' && stats?.emptyRowsCount > 0) {
    const formattedNumber = thousandify(stats.emptyRowsCount);
    issues.push(
      makeWarning(
        <>
          Documents without Text fields don't contribute to your concepts and
          won't count against your document limit.{' '}
          {stats.emptyRowsCount === 1 ? (
            <>
              There is <strong>{formattedNumber}</strong> document with no Text
              field to analyze.
            </>
          ) : (
            <>
              There are <strong>{formattedNumber}</strong> documents with no
              Text fields to analyze.
            </>
          )}
        </>,
        'documents without text',
        group.key
      )
    );
  }
  if (!existingField && group.originalTypes.length > 1) {
    issues.push(
      makeWarning(
        <>
          Columns with the same header were set to different data types in the
          dataset file. This was addressed by setting them to{' '}
          <strong>{formattedType}</strong>.
        </>,
        'heterogeneous types',
        group.key
      )
    );
  }
  if (stats?.nonEmptyRowsCount === 0) {
    issues.push(
      makeWarning(
        <>Columns without data cannot be uploaded.</>,
        'no column data',
        group.key
      )
    );
  } else if (group.isMetadata && stats?.uniqueValues?.length === 0) {
    issues.push(
      makeWarning(
        <>Columns with no valid data will not be uploaded.</>,
        'no valid data',
        group.key
      )
    );
  }
  return issues;
}
