import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import SimpleModal from '../components/core/SimpleModal';
import { Icon, IconTypes } from '../components/icons';
import { Button } from '../components/core/Button';

const RebuildUploadPageModal = ({ isOpen, onHide, columns, reformatData }) => {
  const [questions, setQuestions] = useState(
    columns.map(column => ({
      oldName: column.name,
      newName: column.name
    }))
  );

  useEffect(() => {
    setQuestions(
      columns.map(column => ({
        oldName: column.name,
        newName: column.name
      }))
    );
  }, [columns]);

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = questions.map((question, idx) =>
      idx === index ? { ...question, newName: value } : question
    );
    setQuestions(updatedQuestions);
  };

  const reformat = () => {
    reformatData(questions);
  };

  const calculateHeight = () => {
    return questions.length > 4 ? '50vh' : questions.length * 10 + 'vh';
  };
  const renderContent = () => (
    <ModalContent>
      <Title>Multiple text column upload configuration</Title>
      <div>
        <p>
          All text from all of these columns will be uploaded as Text. A
          metadata field with the name “Question” will be added so that you can
          filter on specific questions during your analysis.
        </p>
        <Title>
          Here are the default values for the “Question” metadata field:
        </Title>
        <Scrollbars style={{ minHeight: calculateHeight() }} autoHide>
          {questions.map((question, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                columnGap: '20px',
                justifyContent: 'space-around'
              }}
            >
              <ListItem style={{ marginRight: '10px' }}>
                {columns[index].name}
              </ListItem>
              <TextAreaField
                value={question.newName}
                onChange={e => handleQuestionChange(index, e.target.value)}
              />
            </div>
          ))}
        </Scrollbars>
        <ButtonStyle>
          <Button palette="green" onClick={reformat}>
            <Icon type={IconTypes.GEAR} size="18" />
            Reformat
          </Button>
        </ButtonStyle>
      </div>
    </ModalContent>
  );

  return (
    <SimpleModal
      isOpen={isOpen}
      onHide={onHide}
      containerCss={css`
        max-width:90%;
      `}
    >
      {renderContent()}
    </SimpleModal>
  );
};

const ModalContent = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90vw;
    width: auto; 
`;


const ButtonStyle = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1.5rem;
    color: #666;
    padding-top: 25px;
    width: 100%; 
    display: block; 
    word-wrap: break-word; 
    line-height: 1.4; 
`;


const TextAreaField = styled.textarea`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: auto; 
  resize: vertical; 
`;


RebuildUploadPageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  reformatData: PropTypes.func.isRequired
};

export default RebuildUploadPageModal;
