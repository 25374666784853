import React, { useState } from 'react';
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';

import { Button } from '../../../../../../../../components/core/Button';
import { Icon, IconTypes } from '../../../../../../../../components/icons';
import { ChatStatusInfoNotReadyContainer } from './styled';
import { buildChatbotData } from '../../../../../../../../utils/ApiUtilsV5';
import { useAssistantStore } from '../../../../store';

const ChatStatusInfoNotReady = ({ projectId, buildFailed = false }) => {
  const setChatbotStatus = useAssistantStore((state) => state.setChatbotStatus);
  const [isLoading, setIsLoading] = useState(false);

  const buildChatHandler = async () => {
    try {
      setIsLoading(true);
      await buildChatbotData(projectId);
      setChatbotStatus('building');
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  return (
    <ChatStatusInfoNotReadyContainer>
      {buildFailed ? (
        <Typography variant="h6" mb="0.5rem">
          Last Helios build attempt failed. Please contact Luminoso technical
          support for help. You may also retry the build again below.
        </Typography>
      ) : (
        <Typography variant="h6" mb="0.5rem">
          Before Helios can do its thing, a data preparation step is necessary.
          Click the button below to start the process
        </Typography>
      )}
      <div>
        <Button
          disabled={isLoading}
          onClick={buildChatHandler}
          css={css`
            width: 100%;
            display: flex;
            align-items: center;
            &:hover,
            &:active {
              svg {
                fill: none;
              }
            }
          `}
        >
          <Icon type={IconTypes.GEAR} size="1em" />
          Build Helios data
        </Button>
      </div>
    </ChatStatusInfoNotReadyContainer>
  );
};

export default ChatStatusInfoNotReady;
