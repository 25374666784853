import _ from 'lodash';
import { css } from '@emotion/react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { RoutePatterns } from '../constants';
import PlaceholderText from '../components/core/PlaceholderText';
import { ToolCard } from './Cards';
import { useBoundingClientRect, useFetch } from '../utils/hooks';
import { getConceptCounts } from '../utils/ApiUtilsV5';
import { RequestStatuses } from '../constants';
import { Colors } from '../styles';
import { thousandify } from '../utils/NumFmtUtils';
import { MostPrevalentConceptsVisualization } from './MostPrevalentConceptsVisualization';
import { getMoreDetailLink } from './utils';

const conceptSelector = { type: 'top', limit: 50 };

export function MostPrevalentConceptsCard({ workspaceId, projectId }) {
  const visualization = useRef();
  const { width, height } = useBoundingClientRect(visualization);

  const { status, response } = useFetch(
    getConceptCounts,
    projectId,
    conceptSelector
  );

  const concepts = _.orderBy(response?.matchCounts, 'matchCount', 'desc').slice(
    0,
    15
  );

  return (
    <ToolCard
      header="Which concepts are most prevalent?"
      blurb={<Blurb concepts={concepts} />}
      visualization={
        status === RequestStatuses.PENDING ? (
          <ToolCard.VisualizationPlaceholder />
        ) : (
          <div
            ref={visualization}
            css={css`
              height: 100%;
            `}
          >
            <MostPrevalentConceptsVisualization
              width={width}
              height={height}
              concepts={concepts}
            />
          </div>
        )
      }
      footer={
        <ToolCard.MoreDetailLink
          name="Volume feature"
          link={getMoreDetailLink(
            workspaceId,
            projectId,
            RoutePatterns.VOLUME,
            {
              concepts: 'prevalent',
              match_type: 'total',
              count: 50,
              sortby: 'default'
            }
          )}
          disabled={status !== RequestStatuses.FULFILLED}
          trackingItem="highlights_more-details-link_most-prevalent"
        />
      }
    />
  );
}

MostPrevalentConceptsCard.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

function Blurb({ concepts }) {
  return (
    <>
      Concepts like <BlurbConcept concept={concepts?.[0]} /> and{' '}
      <BlurbConcept concept={concepts?.[1]} /> appear more often than we’d
      expect, based on your language.
    </>
  );
}

Blurb.propTypes = {
  concepts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      matchCount: PropTypes.number.isRequired
    }).isRequired
  )
};

function BlurbConcept({ concept }) {
  const name = concept ? (
    <b>
      <bdi>{concept.name}</bdi>
    </b>
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );

  const matchCount = concept ? (
    <span
      css={css`
        color: ${Colors.blue6};
      `}
    >
      {thousandify(concept.matchCount)}
    </span>
  ) : (
    <PlaceholderText style={{ width: '3rem' }} />
  );

  return (
    <>
      "{name}" ({matchCount})
    </>
  );
}

BlurbConcept.propTypes = {
  concept: PropTypes.shape({
    name: PropTypes.string.isRequired,
    matchCount: PropTypes.number.isRequired
  })
};
