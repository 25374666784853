import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import html2canvas from 'html2canvas';

import { Mixins } from '../styles';
import SelectedConcept from './SelectedConcept';
import { MatchingDocuments } from './MatchingDocuments';
import Tooltip from '../components/core/Tooltip';
import { Button } from '../components/core/Button';
import { Icon, IconTypes } from '../components/icons';
import Spinner from '../components/core/Spinner';

export const SearchBar = memo(function SearchBar({
  onInputTextChange,
  searchMatchesSelection
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        ${Mixins.shadowOutset};
        ${Mixins.roundedCorners};
      `}
    >
      <SelectedConcept
        onInputTextChange={onInputTextChange}
        selectedConceptMatchesSearch={searchMatchesSelection}
      />
      <MatchingDocuments />
      <ExportAsPngButton />
    </div>
  );
});

SearchBar.propTypes = {
  onInputTextChange: PropTypes.func.isRequired,
  searchMatchesSelection: PropTypes.bool.isRequired
};

function ExportAsPngButton() {
  const [isLoading, setIsLoading] = useState(false);

  const inlineStyles = elem => {
    const children = elem.querySelectorAll('*');
    children.forEach(child => {
      const computedStyle = window.getComputedStyle(child);
      child.style.fill = computedStyle.fill;
      child.style.stroke = computedStyle.stroke;
      child.style.strokeDasharray = computedStyle.strokeDasharray;
      child.style.fontFamily = computedStyle.fontFamily;
    });
  };

  const exportToPNG = async () => {
    setIsLoading(true);

    let element =
      document.querySelector('.cloud') ||
      document.querySelector('.scatter-plot__data-points');
    if (!element) {
      exportToPngPrint();
    }

    if (
      element.tagName.toLowerCase() === 'svg' ||
      element.tagName.toLowerCase() === 'g'
    ) {
      inlineStyles(element);
      const pixelRatio = window.devicePixelRatio || 1;
      if (element.tagName.toLowerCase() === 'g') {
        const wrapperSVG = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        );
        const yElement = document.querySelector('.scatter-plot__y-axis');
        const xElement = document.querySelector('.scatter-plot__x-axis');
        const overallElement = document.querySelector(
          '.scatter-plot__y-axis-overall'
        );
        inlineStyles(yElement);
        inlineStyles(xElement);
        inlineStyles(overallElement);
        wrapperSVG.appendChild(yElement.cloneNode(true));
        wrapperSVG.appendChild(xElement.cloneNode(true));
        wrapperSVG.appendChild(overallElement.cloneNode(true));
        const bbox = element.getBBox();
        wrapperSVG.setAttribute(
          'viewBox',
          `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
        );
        wrapperSVG.setAttribute('width', bbox.width.toString());
        wrapperSVG.setAttribute('height', bbox.height.toString());
        wrapperSVG.appendChild(element.cloneNode(true));
        element = wrapperSVG;
        wrapperSVG.appendChild(element.cloneNode(true));
        element = wrapperSVG;
        if (pixelRatio > 1) {
          element.style.fontSize = '10px';
        }
      }

      // Adjust these values to match your desired PNG dimensions
      const desiredWidth = 1200;
      const desiredHeight = 700;
      const bbox = element.getBBox();
      const clonedElement = element.cloneNode(true);
      clonedElement.setAttribute('width', bbox.width.toString());
      clonedElement.setAttribute('height', bbox.height.toString());
      const svgData = new XMLSerializer().serializeToString(element);
      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      // Export logic for SVG
      exportSVGToPNG(
        url,
        desiredWidth,
        desiredHeight,
        pixelRatio,
        setIsLoading
      );
    }
  };

  const exportSVGToPNG = (url, width, height, pixelRatio, setLoading) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const imgData = canvas.toDataURL('image/png');
      downloadImage(imgData, 'visualization.png');
      setLoading(false);
    };
    img.onerror = () => {
      console.error('Error loading SVG data into image');
      setLoading(false);
    };
    img.src = url;
  };

  const downloadImage = (imgData, filename) => {
    const link = document.createElement('a');
    link.href = imgData;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getChildrenWithDataType = (element, dataType) => {
    return element.querySelectorAll(`[data-type='${dataType}']`);
  };

  const exportToPngPrint = () => {
    const element = document.getElementById('print');
    const childrenWithDataType = getChildrenWithDataType(
      element,
      'transparent'
    );

    if (!element) {
      return;
    }

    const type = element.dataset.type;
    element.style.height = 'auto';
    const setTransparentBackground = element => {
      if (!element.classList.contains('tooltip')) {
        element.style.backgroundColor = 'transparent';

        Array.from(element.children).forEach(child =>
          setTransparentBackground(child)
        );
      }
    };

    if (type === 'transparent') {
      setTransparentBackground(element);
    }

    childrenWithDataType.forEach(child => {
      child.style.opacity = '0';
      child.style.visibility = 'hidden';
    });

    html2canvas(element, {
      useCORS: true,
      backgroundColor: null,
      scale: 2,
    }).then(canvas => {
      element.style.height = '100%';
      childrenWithDataType.forEach(child => {
        child.style.opacity = '1';
        child.style.visibility = 'visible';
      });
      const imgData = canvas.toDataURL('image/png');
      downloadImage(imgData, 'visualization.png');
      setIsLoading(false);
    });
  };

  return (
    <div
      css={css`
        position: absolute;
        right: 20px;
      `}
    >
      <Tooltip
        anchor={
          <Button
            aria-label="Export as PNG"
            className="axis-control__btn"
            onClick={exportToPNG}
            data-tracking-item="galaxy_export-png"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Icon type={IconTypes.DOWNLOAD} />
                Export as PNG
              </>
            )}
          </Button>
        }
      >
        Export as PNG
      </Tooltip>
    </div>
  );
}
