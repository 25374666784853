import { chats } from '../../../../utils/api';

// const createChat = (set) => async () => {
//   try {
//     set({ isCreating: true });
//     const response = await chats.create();
//     console.log(response);
//     set((state) => ({
//       ...state,
//       isCreating: false,
//       chats: [response, ...state.chats],
//       activeChat: {
//         ...state.activeChat,
//         id: response.id,
//         messages: [],
//         isBotReplying: false,
//         isInteractionFromMessageRequired: false,
//       },
//     }));
//   } catch (error) {
//     set((state) => ({
//       isCreating: false,
//       errors: [...state.errors, error.message],
//     }));
//   }
// };

// bypass this for now since we are no longer using the softserve backend.

const createChat = (set) => async () => {
  set((state) => ({
    isCreating: false,
  }));
};

export default createChat;
