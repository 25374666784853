import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import ButtonBase from '@mui/material/ButtonBase';
import Toolbar from '@mui/material/Toolbar';

export const Header = styled(AppBar)`
  border-radius: 0.1875rem 0.1875rem 0 0;
  color: #fff;
`;

export const HeaderToolbar = styled(Toolbar)`
  height: 3.5rem;
`;

export const HeaderButton = styled(ButtonBase)`
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: #fff;

  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
