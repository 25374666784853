import { css } from '@emotion/react';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Body, Cell, Header, HeaderCell, Row, Table } from './core/Table';
import { Checkbox } from './core/Checkbox';
import PlaceholderText from './core/PlaceholderText';
import { Colors, Mixins } from '../styles';
import naturalSort from '../utils/NaturalSort';
import { useRandom, useSelections } from '../utils/hooks';
import { useSearchParams } from '../search_params';
import { Icon, IconTypes } from './icons';

const TableContext = React.createContext({});

export default function MultiSelectTable({
  rows,
  columns,
  primaryKey,
  selections,
  editable,
  disabled,
  getSubrows,
  subrowColumns
}) {
  const { searchParams, updateSearch } = useSearchParams();
  const { sortColumn, sortDirection } = getSortColumnAndDirection(
    searchParams,
    columns
  );
  let sortedRows = undefined;

  if (rows) {
    const sortFunction = columns.find(c => c.key === sortColumn).sort;
    sortedRows = [...rows].sort((a, b) =>
      (sortFunction ?? naturalSort)(a[sortColumn], b[sortColumn])
    );
    if (sortDirection === 'desc') {
      sortedRows.reverse();
    }
  }

  const augmentedSubrowColumns = subrowColumns?.map((column, i) => {
    return { ...column, width: columns[i].width };
  });

  return (
    <Table
      onSortChange={(column, direction) => {
        updateSearch({ sortby: column, sortdirection: direction });
      }}
      sortedColumn={sortColumn}
      sortDirection={sortDirection}
    >
      <TableContext.Provider
        value={{
          columns,
          rows: sortedRows,
          selections,
          disabled,
          editable,
          getSubrows,
          primaryKey,
          subrowColumns: augmentedSubrowColumns
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            ${Mixins.shadowOutset};
            ${Mixins.roundedCorners};
            max-height: 100%;
          `}
        >
          <div
            css={css`
              position: sticky;
              top: 0;
              z-index: 1;
            `}
          >
            {rows == null ? <LoadingTableHeader /> : <MultiSelectTableHeader />}
          </div>
          <div
            css={css`
              flex: 1;
              min-height: 0;
              overflow: auto;
            `}
          >
            {rows == null ? <LoadingTableBody /> : <MultiSelectTableBody />}
          </div>
        </div>
      </TableContext.Provider>
    </Table>
  );
}

MultiSelectTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  primaryKey: PropTypes.string.isRequired,
  selections: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

function MultiSelectTableHeader() {
  const { columns, selections, editable, disabled, getSubrows } =
    useContext(TableContext);
  const displayCheckbox = editable && !getSubrows;

  return (
    <Header borderColor={Colors.gray2}>
      <Row css={columnsCSS(columns)}>
        <Cell>
          {displayCheckbox && (
            <Checkbox
              aria-label="Select all"
              onChange={selections.selectAll}
              checked={selections.all}
              indeterminate={selections.any && !selections.all}
              disabled={disabled}
            />
          )}
        </Cell>
        {columns.map(cell => {
          return (
            <HeaderCell key={cell.key} sortingKey={cell.key} invertedSort>
              {cell.label}
            </HeaderCell>
          );
        })}
      </Row>
    </Header>
  );
}

function MultiSelectTableBody() {
  const { rows, primaryKey, getSubrows, editable } = useContext(TableContext);

  return (
    <Body>
      {rows.map(row => {
        return getSubrows != null ? (
          <ExpandableRow key={row[primaryKey]} row={row} />
        ) : editable ? (
          <SelectableRow key={row[primaryKey]} row={row} />
        ) : (
          <PlainRow key={row[primaryKey]} row={row} />
        );
      })}
    </Body>
  );
}

function ExpandableRow({ row }) {
  const { getSubrows, primaryKey, selections, subrowColumns } =
    useContext(TableContext);

  return (
    <>
      <CheckboxRow row={row} checkbox={ExpandableCheckbox} />
      <TableContext.Provider value={{ columns: subrowColumns }}>
        {selections.includes(row[primaryKey]) &&
          // NOTE: We can get away with using the index as the key below because
          // subrows aren't sortable -- if we make them sortable at some point,
          // we should also find sensible keys for the subrows.
          getSubrows(row).map((subrow, i) => <PlainRow key={i} row={subrow} />)}
      </TableContext.Provider>
    </>
  );
}

function SelectableRow({ row }) {
  return <CheckboxRow row={row} checkbox={MultiSelectTableCheckbox} />;
}

function PlainRow({ row }) {
  return <CheckboxRow row={row} checkbox={null} />;
}

function CheckboxRow({ row, checkbox: Checkbox }) {
  const { disabled, selections, primaryKey, columns } =
    useContext(TableContext);
  const toggleRow = () => {
    selections.toggleRow(row[primaryKey]);
  };
  const displayCheckbox = Checkbox != null;

  return (
    <Row
      css={css`
        ${columnsCSS(columns)};
        ${row.cssAttributes};
        padding-left: 0.5rem;
      `}
      active={displayCheckbox && selections.includes(row[primaryKey])}
      onClick={displayCheckbox && !disabled && row.clickable ? toggleRow : null}
    >
      <Cell>{displayCheckbox ? <Checkbox row={row} /> : null}</Cell>
      {columns.map(column => {
        return (
          <MultiSelectTableCell key={column.key} row={row} column={column} />
        );
      })}
    </Row>
  );
}

function ExpandableCheckbox({ row }) {
  return (
    <MultiSelectTableCheckbox
      row={row}
      css={css`
        i {
          font-weight: bold;
        }
      `}
      checkedIcon={<Icon type={IconTypes.CARET_STROKE} direction="down" />}
      uncheckedIcon={<Icon type={IconTypes.CARET_STROKE} direction="right" />}
      outlined={false}
    />
  );
}

function MultiSelectTableCheckbox({ row, ...checkboxProps }) {
  const { selections, primaryKey, disabled } = useContext(TableContext);
  if (!row.clickable) {
    return null;
  }

  return (
    <Checkbox
      aria-label={row.label ?? row[primaryKey]}
      checked={selections.includes(row[primaryKey])}
      // Even though the Row's onClick handler will toggle the row normally,
      // for accessibility, the checkbox should also have an onChange handler.
      onChange={() => {
        selections.toggleRow(row[primaryKey]);
      }}
      onClick={event => {
        event.stopPropagation();
      }}
      disabled={disabled}
      {...checkboxProps}
    />
  );
}

function MultiSelectTableCell({
  row,
  column: { key, render = row => row[key] }
}) {
  return (
    <Cell
      css={css`
        min-width: 0;
      `}
    >
      <span
        css={css`
          width: 100%;
          ${Mixins.ellipsify};
        `}
      >
        {render(row)}
      </span>
    </Cell>
  );
}

MultiSelectTableCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
    render: PropTypes.func
  })
};

export function columnsCSS(columns) {
  return css`
    grid-template-columns: 0.1fr ${columns.map(c => c.width).join(' ')};
  `;
}

export function useClickableSelections(rows, primaryKey, ...inputs) {
  // Remove any rows that aren't clickable to prevent bulk actions from being taken on them
  const selectableRows = rows?.filter(row => row.clickable) ?? [];
  return useSelections(selectableRows, primaryKey, ...inputs);
}

const getSortColumnAndDirection = (params, columns) => {
  const defaultColumn = columns.find(
    column => column.defaultSortColumn === true
  );
  return {
    sortColumn: params.sortby ?? defaultColumn?.key ?? columns[0].key,
    sortDirection:
      params.sortdirection ?? defaultColumn?.defaultSortDirection ?? 'asc'
  };
};

function LoadingTableBody() {
  return (
    <Body hoverable={false}>
      {[...Array(50)].map((element, index) => {
        return <LoadingRow key={index} />;
      })}
    </Body>
  );
}

function LoadingTableHeader() {
  return (
    <Header borderColor={Colors.gray2}>
      <LoadingRow />
    </Header>
  );
}

function LoadingRow() {
  const { columns } = useContext(TableContext);

  return (
    <Row
      css={css`
        ${columnsCSS(columns)};
        padding: 0 0.5rem;
      `}
    >
      <Cell />
      {columns.map(column => (
        <LoadingCell key={column.key} />
      ))}
    </Row>
  );
}

function LoadingCell() {
  const width = `${useRandom(20, 60)}%`;
  return (
    <Cell
      css={css`
        position: relative;
      `}
    >
      <PlaceholderText style={{ width }} />
    </Cell>
  );
}
