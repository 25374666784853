import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Input } from '@mui/material';

import { Title } from './components';
import { Card } from '../components/Card';
import { StoreContext } from '../StoreContext';
import { Button } from '../components/core/Button';
import { objHasPerm } from '../utils/common';
import { editProjectCollocation } from '../actions';

export const CollocationSection = () => {
  const { project } = useContext(StoreContext);
  const hasChangeLanguagePermission = objHasPerm(project, 'write');

  if (!hasChangeLanguagePermission) {
    return null;
  }

  return (
    <Card data-test-id="collocation-section">
      <Card.Section>
        <Title>Collocation Setting</Title>
      </Card.Section>
      <Card.Separator />
      <Card.Section>
        <p>
          A collocation is a grouping of multiple Concepts which itself is
          treated as a Concept. Concept groupings that occur with enough
          frequency are automatically recognized as collocations. By default,
          the maximum number of concepts which will automatically be combined
          into a collocation is 5. This maximum can be modified below.
        </p>
      </Card.Section>
      <Card.Section>
        <UpdateCollocationBlock collocation={project.last_build_info.max_ngram_length} />
      </Card.Section>
    </Card>
  );
};

const UpdateCollocationBlock = ({ collocation }) => {
  const [value, setValue] = useState(collocation || 5);
  const [isEditing, setIsEditing] = useState(false);

  const toggleIsEditing = () => {
    if (isEditing) {
      editProjectCollocation(value);
    }
    setIsEditing(!isEditing);
  };

  const handleUpdateValue = e => {
    const { value } = e.target;

    if (value <= 10 && value > 1) {
      setValue(value);
    }
  };

  return (
    <>
      <RowDiv>
        Maximum number of Concept to collocate:
        {isEditing ? (
          <Input
            type="number"
            value={value}
            onChange={handleUpdateValue}
            sx={{
              width: '40px',
              border: 'none !important',
              outline: 'none',
              '& input[type=number]::-webkit-inner-spin-button': {
                opacity: 1
              },
              '& input': {
                border: 'none',
                boxShadow: 'none',
                fontFamily: 'Lato, Helvetica, Arial, sans-serif',
                fontSize: '1rem',
                fontWeight: 600
              }
            }}
          />
        ) : (
          <StyledSpan>{value}</StyledSpan>
        )}
        <Button
          aria-label="Edit standart collocation"
          onClick={toggleIsEditing}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </RowDiv>
    </>
  );
};

const StyledSpan = styled.span`
    display: flex;
    flex-direction: row;
    width: 40px;
    padding-bottom: 2.5px;
    align-items: center;
    font-weight: 700;
    justify-content: center;
`;

const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
`;