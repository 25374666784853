import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Colors, Mixins } from '../../styles';
import { Icon, IconTypes } from '../icons';
import Spinner from './Spinner';
import { CheckboxOption } from '../../project_management/CheckboxOption';

const MultiSelectDropdown = ({
  options,
  onChange,
  containerCss,
  disabled,
  isLoading,
  selectedValues,
  setSelectedValues,
  placeholder,
  disabledValues,
  setSelectAll
}) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const dropdownRef = useRef(null);
  const [search, setSearch] = useState('');

  const isSelectAllChecked = () => {
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );

    return filteredOptions.every(option =>
      selectedValues.includes(option.value)
    );
  };

  useEffect(() => {
    // Update selectAll state based on filtered options
    setSelectAll(isSelectAllChecked());
  }, [selectedValues, search, options]);

  const handleSelectAll = () => {
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );

    const isAllFilteredSelected = filteredOptions.every(option =>
      selectedValues.includes(option.value)
    );

    if (!isAllFilteredSelected) {
      // Select all filtered options
      const newSelectedValues = new Set([
        ...selectedValues,
        ...filteredOptions.map(option => option.value)
      ]);
      setSelectedValues([...newSelectedValues]);
      setSelectAll(true);
    } else {
      // Deselect only the filtered ones
      const remainingValues = selectedValues.filter(
        value => !filteredOptions.some(option => option.value === value)
      );
      setSelectedValues(remainingValues);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleCheckboxChange = option => {
    const updatedValues = selectedValues.includes(option.value)
      ? selectedValues.filter(v => v !== option.value)
      : [...selectedValues, option.value];
    setSelectedValues(updatedValues);
    onChange(updatedValues);
  };

  const toggleDropdown = () => !disabled && setIsOpen(!isOpen);
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <DropdownContainer containerCss={containerCss}>
      <div ref={dropdownRef}>
        {isLoading && <DropdownSpinner />}
        <DropdownHeader
          onClick={toggleDropdown}
          disabled={disabled || isLoading}
        >
          <Placeholder>
            {selectedValues.length > 0
              ? `${selectedValues.length} Projects selected`
              : placeholder
                ? placeholder
                : 'placeholder'}
          </Placeholder>
          <StyledIcon
            type={IconTypes.CARET_STROKE}
            direction={isOpen ? 'up' : 'down'}
            css={css`
              position: absolute;
              pointer-events: none;
              top: 50%;
              transform: translate(0, -50%);
              right: 0.5rem;
            `}
          />
        </DropdownHeader>
        {isOpen && (
          <CheckboxContainer disabled={disabled || isLoading}>
            <CheckboxOption
              searchbar={true}
              search={search}
              key="selectAll"
              option={{
                value: 'selectAll',
                action: 'Select/Deselect all'
              }}
              setSearch={setSearch}
              selectedValues={selectedValues}
              handleCheckboxChange={handleSelectAll}
              disabledValues={disabledValues}
              checked={isSelectAllChecked()}
            ></CheckboxOption>
            <hr />
            {filteredOptions?.map(option => (
              <CheckboxOption
                key={option.value}
                option={option}
                selectedValues={selectedValues}
                handleCheckboxChange={handleCheckboxChange}
                disabledValues={disabledValues}
              ></CheckboxOption>
            ))}
          </CheckboxContainer>
        )}
      </div>
    </DropdownContainer>
  );
};

export default MultiSelectDropdown;

MultiSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  containerCss: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string
};

const DropdownContainer = styled(({ containerCss, ...props }) => (
  <span {...props} />
))`
  ${props => props.containerCss}
  display: inline-block;
  position: relative;
`;

const CheckboxContainer = styled.div`
  ${Mixins.shadowOutset}
  ${Mixins.roundedCorners}
  background: white;
  border: none;
  padding: 0.5rem;
  min-width: 3rem;
  cursor: pointer;
  overflow-y: scroll;
  height: 250px;
  position: absolute;
  width: max-content;
  z-index: 100;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 3px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${Colors.gray0};
      color: ${Colors.gray5};
      cursor: default;
    `}
`;

const DropdownSpinner = () => (
  <span
    css={css`
      position: absolute;
      pointer-events: none;
      left: 0.5rem;
      top: 25%;
    `}
  >
    <Spinner />
  </span>
);

const DropdownHeader = styled.div`
  ${Mixins.shadowOutset}
  ${Mixins.roundedCorners}
    background: white;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${Colors.gray0};
      color: ${Colors.gray5};
      cursor: default;
    `}
`;

const Placeholder = styled.span`
  // Styles for the placeholder text when no options are selected
`;

const StyledIcon = styled(Icon)`
  // Additional styles for the icon if needed
`;
