import React from 'react';
import styled from '@emotion/styled';

const MergeBoxInfo = ({ concept, mergeInfo }) => {
  const searchConcept = concept[0];

  const mergedConcepts = findByConcept(mergeInfo, searchConcept);

  if (mergedConcepts.length === 0) {
    return null;
  }

  return (
    <div>
      <TextAreaContainer>
        {mergedConcepts.length && (
          <>
            {mergedConcepts.map((conceptItem, index) => (
              <div key={index}>
                <b>{conceptItem.concept}</b> merged with{' '}
                <b>{conceptItem.merge_with}</b>
              </div>
            ))}
          </>
        )}
      </TextAreaContainer>
    </div>
  );
};

const TextAreaContainer = styled.div`
  padding: 10px;
  max-height: 8vh;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  overflow-y: auto;
`;

export default MergeBoxInfo;

function findByConcept(arr, conceptSearch) {
  return arr.filter(
    item => item.concept === conceptSearch || item.merge_with === conceptSearch
  );
}
