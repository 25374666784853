import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ActionButton } from './styled';
import { CHAT_MESSAGE_TYPE } from '../../../../../types';

const WithButtons = ({ onClick, children, placeholder }) => {
  const [disabled, setDisabled] = useState(false);

  if (!children) {
    return null;
  }

  const handleClick = (value) => {
    setDisabled(true);
    const answer = placeholder ? `${placeholder} ${value}` : value;
    //Shoud be fixed to CHAT_MESSAGE_TYPE.BUTTONS after implementation on
    onClick(answer, CHAT_MESSAGE_TYPE.TEXT);
  };

  const buttons = Array.isArray(children) ? children : children?.buttons;

  return (
    <>
      {children?.text && <Typography>{children?.text}</Typography>}
      <Box>
        {buttons.map((answer, idx) => (
          <ActionButton
            disabled={disabled}
            key={`${answer}_${idx}`}
            onClick={() => handleClick(answer)}
          >
            {answer}
          </ActionButton>
        ))}
      </Box>
    </>
  );
};

WithButtons.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export { WithButtons };
