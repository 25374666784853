import React, { useState } from 'react';
import { css } from '@emotion/react';

import { StoreContext } from '../../../StoreContext';
import { Button } from '../../../components/core/Button';
import { Card } from '../../../components/Card';
import ManageDeleteDocumentModal from './ManageDeleteDocumentModal';
import ConfirmDeleteDocumentsModal from './ConfirmDeleteDocumentsModal';
import { Title } from '../../components';

const DeleteDocumentSection = () => {
  const { docSearchResults, deletedDocs, project, selection } =
    React.useContext(StoreContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const deletedDocsExist = deletedDocs.length > 0;

  return (
    <>
      <DeleteDocumentTitle/>
      <Card.Separator />
      {deletedDocsExist && <div
        css={css`
            color: red;
            padding: 20px`}
      >
        {deletedDocs.length} documents marked for deletion
      </div>}
      <Card.Section>
        <div
          css={css`
              display: flex;
              flex-direction: row;
              gap: 1rem`}
        >
          <Button
            onClick={() => setShowEditModal(true)}
          >
            Select Documents
          </Button>
          {deletedDocsExist &&
            <Button palette="green"
                    css={css`
                        width: 150px;
                        align-self: center;`
                    }
                    onClick={() => setShowConfirmModal(true)}
            >
              Rebuild
            </Button>}
        </div>
      </Card.Section>
      <ManageDeleteDocumentModal
        deletedDocs={deletedDocs}
        docSearchResults={docSearchResults}
        isOpen={showEditModal}
        onHide={() => setShowEditModal(false)}
        openConfirmModal={() => setShowConfirmModal(true)}
      />
      <ConfirmDeleteDocumentsModal
        isOpen={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        project={project}
        deletedDocs={deletedDocs}
        selection={selection}
      />
    </>
  );
};

export default DeleteDocumentSection;

const DeleteDocumentTitle = () => {
  return (
    <>
      <Card.Section
        css={css`
            display: flex;
            flex-direction: row;
        `}
      >
        <Title
          css={css`
              margin: auto 0;
              font-size: 1.25rem;
          `}
        >
          Delete Documents
        </Title>
      </Card.Section>
    </>
  );
};
