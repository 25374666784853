import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Card } from '../../../components/Card';
import { MergeCurrentBuildCard, MergeNextBuildCard } from '../ConceptsCard';
import { EditableConceptsListMerge } from '../ConceptsList';
import SimpleModal from '../../../components/core/SimpleModal';
import { Button } from '../../../components/core/Button';
import { Colors } from '../../../styles';
import { Icon, IconTypes } from '../../../components/icons';
import { useConceptCountContext } from '../ConceptCountProvider';
import Tooltip from '../../../components/core/Tooltip';
import { AddMergeAutosuggest } from './AddMergeAutosuggest';

export default function EditMergeAssertionsModal({
  isOpen,
  onHide,
  apiConceptManagement,
  topConcepts,
  updateNextBuild
}) {
  const { requestCount } = useConceptCountContext();

  const [conceptManagement, setConceptManagement] = useState(
    apiConceptManagement.withSortedBuilds
  );
  // The modal doesn't get unmounted when the user closes it, so we need to
  // re-sort the supplied concept management for every new editing session.
  useEffect(() => {
    setConceptManagement(apiConceptManagement.withSortedBuilds);
  }, [apiConceptManagement]);

  const addConcept = mergedConcept => {
    const { concept, merge_with } = mergedConcept;
    const cleanedConcept = concept.trim().replace(/\s+/g, ' ');
    const cleanedMergeWith = merge_with.trim().replace(/\s+/g, ' ');
    if (!cleanedConcept || !cleanedMergeWith) {
      return;
    }
    requestCount(cleanedConcept, cleanedMergeWith);

    setConceptManagement(conceptManagementInformation =>
      conceptManagementInformation.withAddedMergeConcept({
        concept: cleanedConcept,
        merge_with: cleanedMergeWith
      })
    );
  };

  const removeConcept = concept => {
    setConceptManagement(conceptManagementInformation =>
      conceptManagementInformation.withRemovedMergeConcept(concept)
    );
  };

  const resetChanges = () => {
    setConceptManagement(
      conceptManagement => conceptManagement.withChangesResetMerge
    );
  };

  const saveAndCloseDisabled = conceptManagement.nextBuildIsEqualMerge(
    apiConceptManagement
  );
  const closeModal = () => {
    setConceptManagement(apiConceptManagement);
    onHide();
  };
  return (
    <SimpleModal
      isOpen={isOpen}
      onHide={closeModal}
      header={<h4>Merge</h4>}
      css={css`
        width: 80vw;
        max-width: 50rem;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
      `}
      containerCss={css`
        max-width: 80vw;
      `}
    >
      <ColumnsContainer>
        <ConceptsCardColumn data-test-id="current-build-column">
          <MergeCurrentBuildCard
            currentConcepts={conceptManagement.currentBuild.merge}
          />
        </ConceptsCardColumn>
        <MiddleColumn data-test-id="middle-column">
          <SearchAndAddConceptsSection>
            <AddMergeAutosuggest
              options={topConcepts}
              placeholder="Type concept"
              submitSelection={addConcept}
            />
          </SearchAndAddConceptsSection>
          <EditableConceptsListMerge
            concepts={conceptManagement.nextBuild.merge}
            onRemoveMerge={removeConcept}
          />
        </MiddleColumn>
        <ConceptsCardColumn data-test-id="next-build-column">
          <Button
            onClick={resetChanges}
            css={css`
              width: fit-content;
            `}
          >
            <Icon type={IconTypes.ROTATE} />
            <span>Reset changes</span>
          </Button>
          <MergeNextBuildCard
            hasChanges={conceptManagement.hasMergeChanges}
            conceptsDiff={conceptManagement.mergeDiff}
          />
        </ConceptsCardColumn>
      </ColumnsContainer>
      <BottomButtonsRow>
        <Button onClick={closeModal} palette="red">
          Cancel
        </Button>
        <Tooltip
          disabled={!saveAndCloseDisabled}
          anchor={
            <Button
              onClick={() => {
                updateNextBuild({
                  ...conceptManagement.nextBuild,
                  merge: conceptManagement.nextBuild.merge
                });
                onHide();
              }}
              disabled={saveAndCloseDisabled}
              palette="green"
              css={css`
                &:disabled {
                  pointer-events: none;
                }
              `}
            >
              Save & close
            </Button>
          }
        >
          There are no changes to save
        </Tooltip>
      </BottomButtonsRow>
    </SimpleModal>
  );
}

const ConceptsCardColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const MiddleColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: ${Colors.gray0} 1px;
  border-style: none solid;
`;

const SearchAndAddConceptsSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;

const ColumnsContainer = styled(Card.Section)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${Colors.gray0};
  flex: 1;
  overflow-x: auto;
  padding: 0;
`;
