import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

export const Input = styled(InputBase)`
  margin: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: #f7f7f7;
  font-size: 0.875rem;
  flex: 1;
  & .MuiInputBase-input {
    box-shadow: none;
  }
`;
